import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {ITransportCompany} from "../../../../../../../utils/interfaces/my_documents/ITransportCompany";


const useApiCreateTransportCompany = (): UseMutationResult<ITransportCompany, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<ITransportCompany> {
        try {
            const response = await post(`/api/company/${companyToken}/my_documents/transport_company/`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<ITransportCompany, CustomAxiosError, any>(
        {
            mutationFn: (data) => doCreate({companyToken: selectedCompany?.company_token, data}),
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ['useApiGetTransportCompany', obj.id]});
                await queryClient.invalidateQueries({queryKey: ['useApiGetTransportCompanies']});
            },
        }
    );
};

export default useApiCreateTransportCompany;