import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ILot} from "../../../../../../utils/interfaces/tannery_raw_material/ILot";


const useApiGetLots = (
    companyToken: string | undefined
): UseQueryResult<ILot[], CustomAxiosError> => {
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<ILot[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_raw_material/lots`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getLots'],
        queryFn: () => doGet(companyToken)
    })

};

export default useApiGetLots