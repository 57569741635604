import React, {useState} from "react";
import {IPeopleArticle} from "../../interfaces/tannery_production/articles/IPeopleArticle";
import {IArticle} from "../../interfaces/tannery_production/articles/IArticle";
import {IColor} from "../../interfaces/tannery_production/articles/IColor";
import {
    IOrderScheduleDetail
} from "../../interfaces/tannery_production/order_schedule/IOrderScheduleDetail";
import {IPeopleProduct} from "../../interfaces/service/people/product_list/IPeopleProduct";
import {IProduct} from "../../interfaces/service/people/product_list/IProduct";
import {IPrice} from "../../interfaces/service/people/product_list/price/IPrice";
import {IOrderScheduleWorking} from "../../interfaces/tannery_production/order_schedule/IOrderScheduleWorking";
import {
    IOrderScheduleTanneryDyeing
} from "../../interfaces/tannery_production/order_schedule/IOrderScheduleTanneryDyeing";
import {IRawChoice} from "../../interfaces/tannery_production/order_schedule/IRawChoice";


type TanneryProductionContextType = {

    selectedPeopleArticle: IPeopleArticle | undefined,
    selectPeopleArticle: (selectedPeopleArticle: IPeopleArticle | undefined) => void,

    selectedArticle: IArticle | undefined,
    selectArticle: (selectedArticle: IArticle | undefined) => void,

    selectedColor: IColor | undefined,
    selectColor: (selectedColor: IColor | undefined) => void,

    selectedCompanyOrderSchedule: IOrderScheduleDetail | undefined,
    selectCompanyOrderSchedule: (selectedCompanyOrderSchedule: IOrderScheduleDetail | undefined) => void,

    isRawMaterialOpen: boolean,
    selectIsRawMaterialOpen: (isRawMaterialOpen: boolean) => void,

    selectedPeopleProduct: IPeopleProduct | undefined,
    selectPeopleProduct: (selectedPeopleProduct: IPeopleProduct | undefined) => void,

    selectedProduct: IProduct | undefined,
    selectProduct: (selectedProduct: IProduct | undefined) => void,

    selectedPrice: IPrice | undefined,
    selectPrice: (selectedPrice: IPrice | undefined) => void,

    isWorkingsOpen: boolean,
    selectIsWorkingsOpen: (isWorkingsOpen: boolean) => void,

    selectedWorking: IOrderScheduleWorking | undefined,
    selectWorking: (selectedWorking: IOrderScheduleWorking | undefined) => void,

    isDyeOpen: boolean,
    selectIsDyeOpen: (isDyesOpen: boolean) => void,

    selectedDye: IOrderScheduleTanneryDyeing | undefined,
    selectDye: (selectedDye: IOrderScheduleTanneryDyeing | undefined) => void,

    isFinalMeasurementOpen: boolean,
    selectIsFinalMeasurementOpen: (isFinalMeasurementOpen: boolean) => void,

    selectedRawChoiceOrder: IRawChoice | undefined,
    selectRawChoiceOrder: (selectedRawChoiceOrder: IRawChoice | undefined) => void,

}

const TanneryProductionContext = React.createContext<TanneryProductionContextType | undefined>(undefined);

function useTanneryProductionContextValue() {
    const [selectedPeopleArticle, setSelectedPeopleArticle] = useState<IPeopleArticle>();
    const [selectedArticle, setSelectedArticle] = useState<IArticle>();
    const [selectedColor, setSelectedColor] = useState<IColor>();
    const [selectedCompanyOrderSchedule, setSelectedCompanyOrderSchedule] = useState<IOrderScheduleDetail>();
    const [isRawMaterialOpen, setIsRawMaterialOpen] = useState<boolean>(false)
    const [selectedPeopleProduct, setSelectedPeopleProduct] = useState<IPeopleProduct>();
    const [selectedProduct, setSelectedProduct] = useState<IProduct>();
    const [selectedPrice, setSelectedPrice] = useState<IPrice>();
    const [isWorkingsOpen, setIsWorkingsOpen] = useState<boolean>(false)
    const [selectedWorking, setSelectedWorking] = useState<IOrderScheduleWorking>();
    const [isDyeOpen, setIsDyeOpen] = useState<boolean>(false)
    const [selectedDye, setSelectedDye] = useState<IOrderScheduleTanneryDyeing>();
    const [isFinalMeasurementOpen, setIsFinalMeasurementOpen] = useState<boolean>(false)
    const [selectedRawChoiceOrder, setSelectedRawChoiceOrder] = useState<IRawChoice>()

    const selectPeopleArticle = (selectedPeopleArticle: IPeopleArticle | undefined) => {
        setSelectedPeopleArticle(selectedPeopleArticle)
    }

    const selectArticle = (selectedArticle: IArticle | undefined) => {
        setSelectedArticle(selectedArticle)
    }

    const selectColor = (selectedColor: IColor | undefined) => {
        setSelectedColor(selectedColor)
    }

    const selectCompanyOrderSchedule = (selectedCompanyOrderSchedule: IOrderScheduleDetail | undefined) => {
        setSelectedCompanyOrderSchedule(selectedCompanyOrderSchedule)
    }

    const selectIsRawMaterialOpen = (isRawMaterialOpen: boolean) => {
        setIsRawMaterialOpen(isRawMaterialOpen)
    }

    const selectPeopleProduct = (selectedPeopleProduct: IPeopleProduct | undefined) => {
        setSelectedPeopleProduct(selectedPeopleProduct)
    }

    const selectProduct = (selectedProduct: IProduct | undefined) => {
        setSelectedProduct(selectedProduct)
    }

    const selectPrice = (selectedPrice: IPrice | undefined) => {
        setSelectedPrice(selectedPrice)
    }

    const selectIsWorkingsOpen = (isWorkingsOpen: boolean) => {
        setIsWorkingsOpen(isWorkingsOpen)
    }

    const selectWorking = (selectedWorking: IOrderScheduleWorking | undefined) => {
        setSelectedWorking(selectedWorking)
    }

    const selectIsDyeOpen = (isDyeOpen: boolean) => {
        setIsDyeOpen(isDyeOpen)
    }

    const selectDye = (selectedDye: IOrderScheduleTanneryDyeing | undefined) => {
        setSelectedDye(selectedDye)
    }

    const selectIsFinalMeasurementOpen = (isFinalMeasurementOpen: boolean) => {
        setIsFinalMeasurementOpen(isFinalMeasurementOpen)
    }

    const selectRawChoiceOrder = (selectedObj: IRawChoice | undefined) => {
        setSelectedRawChoiceOrder(selectedObj)
    }

    return {
        selectedPeopleArticle, selectPeopleArticle,
        selectedArticle, selectArticle,
        selectedColor, selectColor,
        selectedCompanyOrderSchedule, selectCompanyOrderSchedule,
        isRawMaterialOpen, selectIsRawMaterialOpen,
        selectedPeopleProduct, selectPeopleProduct,
        selectedProduct, selectProduct,
        selectedPrice, selectPrice,
        isWorkingsOpen, selectIsWorkingsOpen,
        selectedWorking, selectWorking,
        isDyeOpen, selectIsDyeOpen,
        selectedDye, selectDye,
        isFinalMeasurementOpen, selectIsFinalMeasurementOpen,
        selectedRawChoiceOrder, selectRawChoiceOrder,
    }


}

function TanneryProductionProvider({children}: { children: React.ReactNode }) {
    const value = useTanneryProductionContextValue();
    return <TanneryProductionContext.Provider value={value}>{children}</TanneryProductionContext.Provider>
}

function useTanneryProduction() {
    const context = React.useContext(TanneryProductionContext);
    if (!context) {
        throw new Error('useTanneryProduction must be used within a TanneryProductionProvider');
    }
    return context;
}

export {
    TanneryProductionProvider,
    useTanneryProduction,
}
