import React, {useEffect, useState} from 'react';
import {IPeopleArticle} from "../../../../../../../utils/interfaces/tannery_production/articles/IPeopleArticle";
import {IArticle} from "../../../../../../../utils/interfaces/tannery_production/articles/IArticle";
import {IColor} from "../../../../../../../utils/interfaces/tannery_production/articles/IColor";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {useQueryClient} from "@tanstack/react-query";
import useApiUpdateColor from "./api-hooks/useApiUpdateColor";
import useApiCreateColor from "./api-hooks/useApiCreateColor";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import {Box} from "@mui/material";
import MuiInputTextField from "../../../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";


interface ColorFormProps {
    person: IPeopleArticle;
    article: IArticle;
    colorToEdit: IColor | null;
    onClose: () => void;
}

const ColorForm: React.FC<ColorFormProps> = ({
                                                 colorToEdit,
                                                 person: initialPerson,
                                                 article: initialArticle,
                                                 onClose
                                             }) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();

    const [personObj, setPersonObj] = useState<IPeopleArticle>(initialPerson)
    const [article, setArticle] = useState<IArticle>(initialArticle)
    const [name, setName] = useState(colorToEdit?.color_name);

    const mutationHook = colorToEdit ? useApiUpdateColor : useApiCreateColor;
    const mutation = mutationHook(selectedCompany?.company_token, personObj.id);


    useEffect(() => {
        if (colorToEdit) {
            setName(colorToEdit.color_name)
            setArticle(initialArticle)
        }
    }, [colorToEdit]);


    const handleSubmit = async () => {

        try {
            const colorData = {
                color_name: name,
                parent_id: article.id,
                people_id: personObj.id
                // Add other fields as needed
            };

            if (colorToEdit !== null) {
                // Editing an existing article
                await mutation.mutateAsync({people_id: personObj.id, id: colorToEdit.id, data: colorData});
                queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
                displayMessage('Color updated successfully');
            } else {
                // Adding a new person
                await mutation.mutateAsync(colorData);
                queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
                displayMessage('Color added successfully');
            }

            onClose(); // Close the form
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while saving the Color');
                console.log(error)
            }
        }
    };

    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setName(e.target.value)}
                    label='name'
                    type="text"
                    value={name || ''}
                    required={true}
                />
                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*<MuiFabButtonDelete onClick={() => {*/}
                    {/*    setDeleteConfirmationOpen(true)*/}
                    {/*}}/>*/}
                    {colorToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit}/>}
                </Box>
            </form>
        </Box>
    );
};

export default ColorForm;
