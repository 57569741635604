import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {CustomAxiosError} from "../../../../../logics/api_config";
import Loading from "../../../../ui/Loading";
import {useTranslation} from "react-i18next";
import {IFiscalRegistry} from "../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import useApiGetFiscalRegistries from "./api-hooks/useApiGetFiscalRegistries";
import {Box, TextField} from "@mui/material";
import MuiAutocomplete from "../../../../ui/mui/mui-input/MuiAutoComplete";
import {useTheme} from "@mui/material/styles";
import useApiGetFiscalRegistry from "./api-hooks/useApiGetFiscalRegistry";
import {useService} from "../../../../../utils/contexts/service/service-context";
import {Add, Edit} from "@mui/icons-material";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import FiscalRegistryForm from "./FiscalRegistryForm";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";


interface Props {
    fiscal_registry: IFiscalRegistry | undefined,
    onChange: (fiscal_registry: IFiscalRegistry | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
    clearable?: boolean,
    withEditCreateButtons?: boolean
}

const FiscalRegistrySelect = ({
                                  fiscal_registry: initialObj,
                                  onChange,
                                  label,
                                  required,
                                  withEditCreateButtons = true,
                                  clearable = true,
                                  ...props
                              }: Props) => {
    const {displayError, displayMessage} = useToast()
    const {selectedCompany} = useAuth()
    const {t} = useTranslation();
    const theme = useTheme();
    const {selectedPerson} = useService()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [objs, setObjs] = useState<IFiscalRegistry[]>([]);
    const [obj, setObj] = useState<IFiscalRegistry | undefined>(initialObj)

    const {
        data,
        isLoading,
        error,
    } = useApiGetFiscalRegistries();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }

    }, [initialObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IFiscalRegistry | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);

    const {
        data: detailedObj,
    } = useApiGetFiscalRegistry(selectedPerson?.id);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>

                        <MuiAutocomplete
                            id="registry-select"
                            options={objs}
                            getOptionLabel={(option) => option.people.people_name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            disableClearable={!clearable}
                            minWidth={300}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.people.people_name === value?.people.people_name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('customer')}
                                    required={required}
                                />
                            )}

                        >
                            {withEditCreateButtons &&
                                <MRTMuiButton
                                    small={true}
                                    icon={obj ?
                                        <Edit sx={{color: theme.palette.primary.main}}/>
                                        :
                                        <Add sx={{color: theme.palette.primary.main}}/>}
                                    onClick={() => {
                                        setIsEditFormOpen(true)
                                    }} label={obj ? 'edit fiscal registry' : 'add fiscal registry'}/>}
                        </MuiAutocomplete>
                    </Box>


                    <MuiModal
                        top="50%"
                        left="50%"
                        width={'120vh'}
                        open={isEditFormOpen}
                        disableBackdropClick={true}
                        onClose={() => setIsEditFormOpen(false)}
                    >
                        <FiscalRegistryForm
                            person={obj?.people}
                            onClose={() => setIsEditFormOpen(false)}
                        />
                    </MuiModal>
                </>
            )}
        </>
    );
}

export default FiscalRegistrySelect;