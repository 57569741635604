import React, {useEffect, useState} from 'react';
import VerificationPeriodForm from "../../verification_period/VerificationPeriodForm";
import FilterAccordionButton from "../../../../../ui/mui/mui-accordion/mui-filter-accordion/FilterAccordionButton";
import useApiGetVerificationPeriod from "../../verification_period/api-hooks/useApiGetVerificationPeriod";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {
    IVerificationPeriod
} from "../../../../../../utils/interfaces/lwg_dashboard/verification_period/IVerificationPeriod";
import Loading from "../../../../../ui/Loading";
import {IPeriod} from "../../../../../../utils/interfaces/lwg_dashboard/reports/workings/IPeriod";
import OpenerButton from "../../../../../ui/OpenerButton";
import useApiGetReportWorkings from "../api_hooks/useApiGetReportWorkinks";
import {
    IOrderScheduleDetail
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail";
import SubContractorList from "./SubContractorList";
import {FaSkull} from "react-icons/fa";
import AgDiv from "../../../../../ui/divs/AgDiv";
import MuiFetchingSpinner from "../../../../../ui/mui/MuiFetchingSpinner";
import OrderScheduleTable from "../../../tannery_production/order_schedule/OrderScheduleTable";


interface Props {
    scrollableHeight?: number;
}

const LwgWorkingsReports = ({scrollableHeight = 100,}: Props) => {
    const {displayError} = useToast();
    const {selectedCompany} = useAuth();
    const [isReportBySubcontractorsOpen, setIsReportBySubcontractorsOpen] = useState<boolean>(true)
    const [isPendingOrders, setIsPendingOrders] = useState<boolean>(false)
    const [openAccordion, setOpenAccordion] = useState<boolean>(false)
    const [missingVerificationPeriod, setMissingVerificationPeriod] = useState<boolean>(false)
    const [period, setPeriod] = useState<IPeriod>()
    const [pendingOrders, setPendingOrders] = useState<IOrderScheduleDetail[] | undefined>()
    const [pendingOrdersCount, setPendingOrdersCount] = useState<number | undefined>()

    const [verificationPeriod, setVerificationPeriod] = useState<IVerificationPeriod>()
    const {
        data: verificationPeriodData,
        isLoading: isLoadingVerificationPeriodData,
        error: errorVerificationPeriodData
    } = useApiGetVerificationPeriod();
    const [startingDate, setStartingDate] = useState<string | undefined>(verificationPeriod?.starting_date);
    const [endingDate, setEndingDate] = useState<string | undefined>(verificationPeriod?.ending_date);

    if (errorVerificationPeriodData) {
        const axiosError = errorVerificationPeriodData as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const {data, isLoading, error, isFetching} = useApiGetReportWorkings();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const openReportByWorkings = () => {
        setIsReportBySubcontractorsOpen(true)
        setIsPendingOrders(false);
    };

    const openPendingOrders = () => {
        setIsReportBySubcontractorsOpen(false)
        setIsPendingOrders(true);
    };

    useEffect(() => {
        if (data) {
            setPeriod(data);
            setPendingOrders(data.pending_orders)
            setPendingOrdersCount(data.pending_orders_count)
        }
    }, [data]);

    useEffect(() => {
        if (verificationPeriodData) {
            setVerificationPeriod(verificationPeriodData);
            setStartingDate(verificationPeriodData.starting_date);
            setEndingDate(verificationPeriodData.ending_date);
        }
    }, [verificationPeriodData]);


    useEffect(() => {
        setMissingVerificationPeriod(false);
        if (!startingDate || !endingDate) {
            setMissingVerificationPeriod(true);
        }
    }, [startingDate, endingDate]);

    return (
        <>{isLoading ?
            <Loading/>
            :
            <>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='p-2'>
                    <FilterAccordionButton
                        className={`rounded font-medium text-xs uppercase tracking-wider ${
                            missingVerificationPeriod ? 'bg-red-600 animate-pulse' : ''
                        }`}
                        onClick={() => setOpenAccordion(!openAccordion)}
                        isOpen={openAccordion}
                        title='verification_period'
                    >
                    </FilterAccordionButton>
                </div>
                {openAccordion &&
                    <VerificationPeriodForm openAccordion={openAccordion} verificationPeriod={verificationPeriodData}/>}

                <AgDiv label='lwg_workings_pending_orders'
                       icons={[pendingOrdersCount && pendingOrdersCount > 0 ?
                           <FaSkull className='animate-pulse text-red-600'/> :
                           '']}
                       value={pendingOrdersCount && pendingOrdersCount.toString()}
                       minWidth="560px" maxWidth="560px"/>


                {isFetching && <MuiFetchingSpinner text="Fetching data..."/>}

                <div className="p-4">

                    <div className='flex flex-row'>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='p-2'>
                            <OpenerButton
                                className="rounded font-medium text-xs uppercase tracking-wider"
                                onClick={openReportByWorkings}
                                isOpen={isReportBySubcontractorsOpen}
                                title='by_subcontractor'
                                width={'200px'}
                            >
                            </OpenerButton>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='p-2'>
                            <OpenerButton
                                className="rounded font-medium text-xs uppercase tracking-wider"
                                onClick={openPendingOrders}
                                isOpen={isPendingOrders}
                                title='show_pending_orders'
                                width={'200px'}
                            >
                            </OpenerButton>
                        </div>

                    </div>
                    {isReportBySubcontractorsOpen &&
                        <SubContractorList subcontractors={data?.workings.subcontractors}
                                           totalWorkedSqm={data?.workings.total_worked_sqm}/>}
                    {isPendingOrders && <OrderScheduleTable orders_schedule={pendingOrders}/>}
                </div>
            </>}
        </>
    );
};

export default LwgWorkingsReports;
