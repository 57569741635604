import {CustomAxiosError, useApiCalls} from "../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IUm} from "../../../../../utils/interfaces/service/IUm";
import {useAuth} from "../../../../../utils/contexts/auth-context";


const useApiGetUm = (
    pk: number | undefined
): UseQueryResult<IUm, CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(pk: number | undefined): Promise<IUm> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/service/ums/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['useApiGetUm', pk],
        queryFn: () => doGet(pk),
        enabled: !!pk
    })
};

export default useApiGetUm