import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IChemicalProductManualMovement
} from "../../../../../../../utils/interfaces/chemicals_products/IChemicalProductManualMovement";


const useApiGetManualChemicalProductMovement = (
    product_id: number | undefined,
    pk: number | undefined,
): UseQueryResult<IChemicalProductManualMovement, CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined,
                         product_id: number | undefined,
                         pk: number | undefined,
    ): Promise<IChemicalProductManualMovement> {
        try {
            const url = `/api/company/${company_token}/chemicals_management/chemical_product/${product_id}/manual_movement/${pk}/`;

            const response = await get(url);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetManualChemicalProductMovement", pk],
        queryFn: () => doGet(selectedCompany?.company_token, product_id, pk),
        enabled: !!pk && !!product_id
    })
};

export default useApiGetManualChemicalProductMovement;