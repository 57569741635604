import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IDocCompanyDocReason
} from "../../../../../../../utils/interfaces/my_documents/doc_reason/IDocCompanyDocReason";


const useApiGetCompanyDocReasons = (): UseQueryResult<IDocCompanyDocReason[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IDocCompanyDocReason[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/doc/company_doc_reason`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["getCompanyDocReasons"],
        queryFn: () => doGet(selectedCompany?.company_token,),

    })
};

export default useApiGetCompanyDocReasons