import {CustomAxiosError, useApiCalls} from "../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";

interface NextCodeResponse {
    suggested_id: string;
}


const useGetNextLotCode = (
    companyToken: string | undefined
): UseQueryResult<NextCodeResponse, CustomAxiosError> => {

    const {get} = useApiCalls()

    const doGet = async (company_token: string | undefined): Promise<NextCodeResponse> => {
        try {
            const response = await get(`/api/company/${company_token}/tannery_raw_material/lots/next_lot_id`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getNextLotCode'],
        queryFn: () => doGet(companyToken)
    })

};

export default useGetNextLotCode