import React, {useEffect, useState} from 'react';
import {ITanneryDyeingProduct} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingProduct";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import useApiGetTanneryDyeingProducts from "./api-hooks/useApiGetTanneryDyeingProducts";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import Loading from "../../../../../ui/Loading";
import {useTranslation} from "react-i18next";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import {TextField} from "@mui/material";


interface Props {
    product: ITanneryDyeingProduct | null,
    onChange: (product: ITanneryDyeingProduct | null) => void,
    className: string,
    label?: string
    clearable?: boolean
    required?: boolean
    withDefaultValue?: boolean
    isDisabled?: boolean
}

const TanneryDyeingProductSelect = ({
                                        product: initialObj,
                                        onChange,
                                        label,
                                        clearable,
                                        required,
                                        withDefaultValue = true,
                                        isDisabled = false
                                    }: Props) => {
    const {t} = useTranslation();
    const {displayError, displayMessage} = useToast()
    const {selectedCompany} = useAuth()

    const [obj, setObj] = useState<ITanneryDyeingProduct | null>(initialObj);
    const [objs, setObjs] = useState<ITanneryDyeingProduct[]>([]);
    
    const {
        data,
        isLoading,
        error,
    } = useApiGetTanneryDyeingProducts(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }

    }, [initialObj]);


    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: ITanneryDyeingProduct | null) => {
        onChange(newValue);
    };


    return (
        isLoading ? (
            <Loading/>
        ) : (
            <MuiAutocomplete
                id="um-select"
                options={objs}
                getOptionLabel={(option) => option.people_name + ' - ' + option.product_name}
                value={initialObj ? obj : null}
                onChange={handleOnChange}
                isDisabled={isDisabled}
                isOptionEqualToValue={(option, value) => option.id === value?.id && option.id === value?.id}
                renderInput={(params) => (
                    <TextField
                        sx={{textTransform: 'capitalize'}}
                        {...params}
                        label={label || t('um')}
                        required={required}
                    />
                )}
            />
        )
    );
}

export default TanneryDyeingProductSelect;