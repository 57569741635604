import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {INextDocNumber} from "../../../../../../../utils/interfaces/my_documents/doc/INextDocNumber";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";


const useApiGetNextDocNumber = (
    doc_prefix: string | undefined,
    doc_type_id: number | undefined,
    doc_year: number | undefined
): UseQueryResult<INextDocNumber, CustomAxiosError> => {
    const {selectedSystemDate} = useAppGarage();
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(
        company_token: string | undefined,
        doc_year: number | undefined,
        doc_prefix: string | undefined,
        doc_type_id: number | undefined
    ): Promise<INextDocNumber> {
        try {
            const response = await get(
                `/api/company/${company_token}/my_documents/doc/next_doc_number/?doc_prefix=${doc_prefix}&doc_year=${doc_year}&doc_type_id=${doc_type_id}`
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["getNextDocNumber"],
        enabled: selectedSystemDate !== undefined && doc_prefix !== undefined && doc_type_id !== undefined,
        queryFn: () => doGet(selectedCompany?.company_token, doc_year, doc_prefix, doc_type_id)
    })
};

export default useApiGetNextDocNumber;
