import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IChemicalProductMovement} from "../../../../../../utils/interfaces/chemicals_products/IChemicalProductMovement";


const useApiGetChemicalProductMovements = (
    product_id: number | undefined,
    until_timestamp: number | undefined,
    from_timestamp: number | undefined,
    to_timestamp: number | undefined
): UseQueryResult<IChemicalProductMovement[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined,
                         product_id: number | undefined,
                         until_timestamp: number | undefined,
                         from_timestamp: number | undefined,
                         to_timestamp: number | undefined
    ): Promise<IChemicalProductMovement[]> {
        try {
            const defaultParams = {
                until_timestamp: String(until_timestamp || Math.floor(Date.now() / 1000)),
                to_timestamp: String(to_timestamp || Math.floor(Date.now() / 1000)),
                from_timestamp: String(from_timestamp || 1),
            };
            const queryParams = new URLSearchParams(defaultParams).toString();
            const url = `/api/company/${company_token}/chemicals_management/chemical_product/${product_id}/movement/?${queryParams}`;

            const response = await get(url);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetChemicalProductMovements", product_id],
        queryFn: () => doGet(selectedCompany?.company_token, product_id, until_timestamp, from_timestamp, to_timestamp),
        enabled: !!product_id
    })
};

export default useApiGetChemicalProductMovements;