import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {
    IOrderScheduleDetail
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail";


const useApiGetCompanyOrderScheduleByColor = (
    companyToken: string | undefined,
    color_id: number | undefined
): UseQueryResult<IOrderScheduleDetail[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(
        company_token: string | undefined,
        color_id: number | undefined
    ): Promise<IOrderScheduleDetail[]> {
        try {
            if (color_id !== undefined) {
                const response = await get(
                    `/api/company/${company_token}/tannery_production/order_schedule/${color_id}/get_all_by_color`
                );
                return response?.data;
            } else {
                return []; // Return an empty array when color_id is undefined
            }
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getCompanyOrderScheduleByColor', color_id],
        queryFn: () => doGet(companyToken, color_id),
        enabled: !!color_id,
    })
};

export default useApiGetCompanyOrderScheduleByColor;
