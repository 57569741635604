import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import {MyDocumentsProvider} from "../../../../../utils/contexts/my_documents/my_documents-context";
import DocTable from "./doc/DocTable";


const DocScreen = () => {

    return (
        <>

            <MaxWidthDiv maxWidth='1980px'>
                <MyDocumentsProvider>
                    {/*<DocList/>*/}
                    <DocTable/>
                </MyDocumentsProvider>
            </MaxWidthDiv>


        </>
    );
};

export default DocScreen;
