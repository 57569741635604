import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";


const useApiUpdateFiscalRegistry = (): UseMutationResult<IFiscalRegistry, CustomAxiosError, {
    people_id: number | undefined,
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()
    let _people_id: number | undefined;

    const {put} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            people_id: number | undefined,
                            pk: number | undefined,
                            data: any): Promise<IFiscalRegistry> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/fiscal_registry/${pk}/people/${people_id}/`, data);
            return response.data as IFiscalRegistry;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IFiscalRegistry, CustomAxiosError, {
        people_id: number | undefined,
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({people_id, id, data}) => {
            _people_id = people_id;
            return await doUpdate(selectedCompany?.company_token, people_id, id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ["useApiGetFiscalRegistry", _people_id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetFiscalRegistries"]});
        },
    });
};


export default useApiUpdateFiscalRegistry;