import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";


const useApiManuallyClosedToggleCompanyOrderSchedule = (
    companyToken: string | undefined
): UseMutationResult<void, CustomAxiosError, { id: number; }, unknown> => {

    const {put} = useApiCalls();
    const queryClient = useQueryClient();

    async function doUpdate(company_token: string | undefined,
                            pk: number,): Promise<void> {
        try {
            await put(`/api/company/${company_token}/tannery_production/order_schedule/${pk}/manually_closed_toggle`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        id: number;
    }>({
        mutationFn: ({id,}) => doUpdate(companyToken, id),
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']})
            queryClient.invalidateQueries({queryKey: ["useApiGetHistoricalCompanyOrderSchedule"]});
        }
    });
};

export default useApiManuallyClosedToggleCompanyOrderSchedule;