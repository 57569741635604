import React, {useEffect, useState} from 'react';
import {IOtherDestination} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IOtherDestination";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import useApiGetOtherDestinations from "./api-hooks/useApiGetOtherDestinations";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import useApiGetOtherDestination from "./api-hooks/useApiGetOtherDestination";
import Loading from "../../../../../ui/Loading";
import {Box, Popover} from "@mui/material";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {Add, Edit} from "@mui/icons-material";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import TextField from "@mui/material/TextField";
import OtherDestinationForm from "./OtherDestinationForm";
import OtherDestinationTable from "./OtherDestinationTable";


interface Props {
    obj: IOtherDestination | undefined,
    onChange: (obj: IOtherDestination | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
    fiscal_registry: IFiscalRegistry | undefined,
}

const OtherDestinationSelect = ({
                                    obj: initialObj,
                                    label,
                                    onChange,
                                    required,
                                    fiscal_registry,
                                    ...props
                                }: Props) => {

    const {displayError} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [objs, setObjs] = useState<IOtherDestination[]>([]);
    const [obj, setObj] = useState<IOtherDestination | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
        error,
    } = useApiGetOtherDestinations(fiscal_registry?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetOtherDestination(fiscal_registry?.id, obj?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);


    const handleOnChange = (event: React.SyntheticEvent, newValue: IOtherDestination | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);

    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="other_destination-select"
                            options={objs}
                            getOptionLabel={(option) => option.business_name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.business_name === value?.business_name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('other_destination')}
                                    required={required}
                                />
                            )}
                        >
                            <MRTMuiButton
                                small={true}
                                icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => {
                                    setIsListTableOpen(true)
                                }} label={'show_list'}/>

                            <MRTMuiButton
                                small={true}
                                icon={obj ?
                                    <Edit sx={{color: theme.palette.primary.main}}/>
                                    :
                                    <Add sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => {
                                    setIsEditFormOpen(true)
                                }} label={obj ? 'edit_other_destination' : 'add_other_destination'}/>
                        </MuiAutocomplete>
                    </Box>

                    <Popover
                        id={'edit_popover'}
                        open={isEditFormOpen}
                        anchorEl={anchorEl}
                        onClose={() => setIsEditFormOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <OtherDestinationForm objToEdit={obj ? obj : null}
                                              fiscal_registry={fiscal_registry}
                                              onSelect={onChange}
                                              onClose={() => setIsEditFormOpen(false)}/>
                    </Popover>

                    <Popover
                        id={'show_list'}
                        open={isListTableOpen}
                        anchorEl={anchorEl}
                        onClose={() => setIsListTableOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <Box sx={{padding: 2}}>
                            <OtherDestinationTable fiscal_registry={fiscal_registry}
                                                   alreadySelectedObj={obj}
                                                   onSelect={onChange}
                                                   onClose={() => setIsListTableOpen(false)}
                            />
                        </Box>
                    </Popover>
                </>
            )}
        </>
    );
}

export default OtherDestinationSelect;