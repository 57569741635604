import {CustomAxiosError, useApiCalls} from "../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {ICurrency} from "../../../../../utils/interfaces/my_documents/ICurrency";


const useApiGetCurrency = (): UseQueryResult<ICurrency[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<ICurrency[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/currency`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetCurrency"],
        queryFn: () => doGet(selectedCompany?.company_token),
    })
};

export default useApiGetCurrency