import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IIncoterm} from "../../../../../../utils/interfaces/my_documents/IIncoterm";


const getIconsForCustomField = (row: IIncoterm, theme: any, t: any) => {
    const icons = [];


    icons.push(
        // row.audit_expiry_date && shouldShowSkullIcon ?
        //     <FaSkull className='animate-pulse text-red-600' title={t("has_record_zero")}/> :
        //     <FaSkull className='text-transparent' title={t("has_record_zero")}/>,
        // <GiCow className=''
        //        color={row.used_in_period ? '#eab308' : 'transparent'}
        //        title={row.used_in_period ? 'used in lwg period' : ''}
        // />,
        // <MdWorkspaces className=''
        //               color={row.has_worked_in_period ? '#eab308' : 'transparent'}
        //               title={row.has_worked_in_period ? 'used in lwg period' : ''}
        // />,
        // <BiLeaf className=''
        //         color={row.coefficient > 0 ? icon_color : 'red'}
        //         title={row.coefficient > 0 ? 'is lwg' : 'is not lwg'}
        // />
        null
    );


    return icons;
};

export const customCellRenderer = (rowData: IIncoterm, theme: any, t: any) => {
    const icons = getIconsForCustomField(rowData, theme, t);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customCellRenderer;
