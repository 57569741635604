import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IDocTransport} from "../../../../../../../../utils/interfaces/my_documents/doc/doc_trasnport/IDocTransport";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";


const useApiGetDocTransport = (doc_year: number | undefined, doc_id: number | undefined): UseQueryResult<IDocTransport, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, doc_year: number | undefined, doc_id: number | undefined): Promise<IDocTransport> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/doc/${doc_year}/${doc_id}/doc_transport/`);
            return response?.data[0];
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetDocTransport", doc_year, doc_id],
        enabled: !!doc_year && !!doc_id,
        queryFn: () => doGet(selectedCompany?.company_token, doc_year, doc_id),
    })
};

export default useApiGetDocTransport