import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from "@tanstack/react-query";


const useApiCreateWorking = (companyToken: string | undefined): UseMutationResult<void, CustomAxiosError, any, unknown> => {

    const {post} = useApiCalls();

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<void> {
        try {
            await post(`/api/company/${companyToken}/tannery_production/workings/`, data);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, any>(
        {
            mutationFn: (data) => doCreate({companyToken, data})
        }
    );
};

export default useApiCreateWorking;