import React, {useState} from "react";
import {ICompanyApplication} from "../interfaces/accounts/ICompanyApplication";


type AppGarageContextType = {

    selectedSystemDate: string | undefined,
    selectSystemDate: (system_date: string | undefined) => void,

    selectedSystemYear: number | undefined,
    selectSystemYear: (selectedSystemYear: number | undefined) => void,

    availableCompanyUserApplications: ICompanyApplication[] | undefined,
    selectAvailableCompanyUserApplications: (availableCompanyApplications: ICompanyApplication[] | undefined) => void,

    availableCompanyApplications: ICompanyApplication[] | undefined,
    selectAvailableCompanyApplications: (availableCompanyApplications: ICompanyApplication[] | undefined) => void,

}

const AppGarageContext = React.createContext<AppGarageContextType | undefined>(undefined);

function useTanneryRawMaterialContextValue() {
    const [selectedSystemDate, setSelectedSystemDate] = useState<string | undefined>();
    const [selectedSystemYear, setSelectedSystemYear] = useState<number | undefined>();
    const [availableCompanyUserApplications, setAvailableCompanyUserApplications] = useState<ICompanyApplication[]>();
    const [availableCompanyApplications, setAvailableCompanyApplications] = useState<ICompanyApplication[]>();
    const selectAvailableCompanyUserApplications = (availableCompanyUserApplications: ICompanyApplication[] | undefined) => {
        setAvailableCompanyUserApplications(availableCompanyUserApplications)
    }

    const selectAvailableCompanyApplications = (availableCompanyApplications: ICompanyApplication[] | undefined) => {
        setAvailableCompanyApplications(availableCompanyApplications)
    }

    const selectSystemDate = (system_date: string | undefined) => {
        if (system_date) {
            localStorage.setItem('@system_date', system_date);
            const year = new Date(system_date).getFullYear();
            selectSystemYear(year);
            localStorage.setItem('@system_year', system_date);
        }
        setSelectedSystemDate(system_date)
    }

    const selectSystemYear = (system_year: number | undefined) => {
        setSelectedSystemYear(system_year)
    }


    return {
        selectedSystemDate, selectSystemDate,
        selectedSystemYear, selectSystemYear,
        availableCompanyUserApplications, selectAvailableCompanyUserApplications,
        availableCompanyApplications, selectAvailableCompanyApplications,

    }
}

function AppGarageProvider({children}: { children: React.ReactNode }) {
    const value = useTanneryRawMaterialContextValue();
    return <AppGarageContext.Provider value={value}>{children}</AppGarageContext.Provider>
}

function useAppGarage() {
    const context = React.useContext(AppGarageContext);
    if (!context) {
        throw new Error('useAppGarage must be used within a AppGarageProvider');
    }
    return context;
}

export {
    AppGarageProvider,
    useAppGarage,
}
