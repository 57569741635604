// PeopleCategories.tsx
import React from 'react';
import {Box, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import MuiCheckboxComponent from '../../../../ui/mui/mui-input/MuiCheckBox';
import useApiGetCategories from './api-hooks/useApiGetPeopleCategories';
import {useAuth} from '../../../../../utils/contexts/auth-context';
import Loading from '../../../../ui/Loading';
import {CustomAxiosError} from "../../../../../logics/api_config";
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";

interface PeopleCategoriesProps {
    selectedCategories: string[];
    handleCategoryToggle: (categoryName: string) => void;
    isFilter?: boolean
}

const PeopleCategories: React.FC<PeopleCategoriesProps> = ({
                                                               selectedCategories,
                                                               handleCategoryToggle,
                                                               isFilter = false,
                                                           }) => {
    const {t} = useTranslation();
    const {displayError} = useToast();
    const {selectedCompany} = useAuth();
    const theme = useTheme()

    const color = isFilter ? theme.palette.filterColor.main : theme.palette.primary.main

    const {data: categories, isLoading: categoriesLoading, error: categoriesError} =
        useApiGetCategories(selectedCompany?.company_token);

    if (categoriesError) {
        const axiosError = categoriesError as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    return (
        <Box className={'mt-4'}>
            <Typography variant="caption" color={color} style={{textTransform: 'uppercase', fontSize: '1.2rem'}}>
                {t('Categories')}
            </Typography>
            {categoriesLoading ? (
                <Loading/>
            ) : (
                <Box className="space-y-2">
                    {categories?.map((category) => (
                        <MuiCheckboxComponent
                            isFilter={isFilter}
                            key={category.id}
                            label={t(category.category_name)}
                            checked={selectedCategories.includes(category.category_name)}
                            onChange={() => handleCategoryToggle(category.category_name)}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default PeopleCategories;
