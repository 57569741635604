import React from 'react';
import {Box, Button, Grid, Typography} from '@mui/material';
import FilterMotionDiv from './FilterMotionDiv';
import MuiFabButtonFilter from "./MuiFabButtonFilter";

type MuiFilterAccordionProps = {
    isOpen: boolean;
    onToggle: () => void;
    children: React.ReactNode;
};

const MuiFilterAccordion: React.FC<MuiFilterAccordionProps> = ({isOpen, onToggle, children}) => {
    return (

        <Box display="" flexDirection="column" alignItems="center" p={0}>
            <Box mb={1} mt={1} display="flex" justifyContent="center" alignItems="center">
                <MuiFabButtonFilter onClick={onToggle} isOpen={isOpen}/>
            </Box>

            {isOpen && (
                <FilterMotionDiv isOpen={isOpen}>
                    <Grid container spacing={1}>
                        {React.Children.map(children, (child, index) => (
                            <Grid item xs={12} md={4} key={index}>
                                {child}
                            </Grid>
                        ))}
                    </Grid>
                </FilterMotionDiv>
            )}
        </Box>

    );
};

export default MuiFilterAccordion;
