import React, {useState, useEffect} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {CustomAxiosError} from "../../../../../logics/api_config";
import {useQueryClient} from "@tanstack/react-query";
import {ILwgSite} from "../../../../../utils/interfaces/tannery_raw_material/ILwgSite";
import useApiUpdateLwgSite from "./api-hooks/useApiUpdateLwgSite";
import useApiCreateLwgSite from "./api-hooks/useApiCreateLwgSite";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import {formatDateTimeForInput} from "../../../../../logics/helpers";
import useApiGetLwgSite from "./api-hooks/useApiGetLwgSite";


interface LwgSitesFormProps {
    lwgSiteToEdit: ILwgSite | null | undefined;
    onClose: () => void;
}

const LwgSiteForm: React.FC<LwgSitesFormProps> = ({lwgSiteToEdit, onClose}) => {
    const {t} = useTranslation();
    const {displayError, displayMessage} = useToast();
    const queryClient = useQueryClient();

    const updateMutation = useApiUpdateLwgSite()
    const createMutation = useApiCreateLwgSite()

    const [lwg_site_name, setLwg_site_name] = useState<string>();
    const [lwg_site_address, setLwg_site_address] = useState<string>();
    const [lwg_site_city, setLwg_site_city] = useState<string>();
    const [lwg_site_state, setLwg_site_state] = useState<string>();
    const [is_lwg, setIs_lwg] = useState<boolean>();
    const [coefficient, setCoefficient] = useState<number>()
    const [urn, setUrn] = useState<string>();
    const [type, setType] = useState<string>();
    const [auditCategory, setAuditCategory] = useState<string>();
    const [auditExpityDate, setAuditExpityDate] = useState<string>();

    const {
        data: detailedObj,
    } = useApiGetLwgSite(lwgSiteToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setLwg_site_name(detailedObj.lwg_site_name)
            setLwg_site_address(detailedObj.lwg_site_address)
            setLwg_site_city(detailedObj.lwg_site_city)
            setLwg_site_state(detailedObj.lwg_site_state)
            setIs_lwg(detailedObj.is_lwg)
            setCoefficient(detailedObj.coefficient)
            setUrn(detailedObj.urn)
            setType(detailedObj.type)
            setAuditCategory(detailedObj.audit_category)
            setAuditExpityDate(detailedObj.audit_expiry_date)
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const lwgSiteData = {
                lwg_site_name: lwg_site_name,
                lwg_site_address: lwg_site_address,
                lwg_site_city: lwg_site_city,
                lwg_site_state: lwg_site_state,
                is_lwg: is_lwg,
                coefficient: coefficient || 0,
                urn: urn,
                type: type,
                audit_category: auditCategory,
                audit_expiry_date: auditExpityDate
            };

            if (lwgSiteToEdit !== null && lwgSiteToEdit !== undefined) {
                await updateMutation.mutateAsync({id: lwgSiteToEdit.id, data: lwgSiteData});
                displayMessage(t('update_successfully'));
            } else {
                await createMutation.mutateAsync(lwgSiteData);
                displayMessage(t('create_successfully'));
            }
            onClose();
            await queryClient.invalidateQueries({queryKey: ['getCompanyLwgSites']});
            await queryClient.invalidateQueries({queryKey: ['getPeopleProducts']});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            }
            if (axiosError.response && axiosError.response.data && axiosError.response.data.coefficient) {
                displayError(t(axiosError.response.data.coefficient));
            } else {
                displayError(t('saving_failure'));
            }
        }
    };

    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setLwg_site_name(e.target.value)}
                    label='business_name'
                    type="text"
                    value={lwg_site_name || ''}
                    required={true}
                />
                <Box className="grid grid-cols-2 gap-4">
                    <MuiInputTextField
                        onChange={(e) => setLwg_site_address(e.target.value)}
                        label='address'
                        type="text"
                        value={lwg_site_address || ''}
                        required={true}
                    />
                    <MuiInputTextField
                        onChange={(e) => setLwg_site_city(e.target.value)}
                        label='city'
                        type="text"
                        value={lwg_site_city || ''}
                        required={true}
                    />
                </Box>

                <Box className="grid grid-cols-2 gap-4">
                    <MuiInputTextField
                        onChange={(e) => setLwg_site_state(e.target.value)}
                        label='state'
                        type="text"
                        value={lwg_site_state || ''}
                        required={true}
                    />
                    <MuiInputTextField
                        onChange={(e) => setCoefficient(parseFloat(e.target.value))}
                        label='coefficient'
                        type="number"
                        value={coefficient || 0}
                        required={false}
                        step={'any'}
                    />
                </Box>
                <Box className="grid grid-cols-2 gap-4">
                    <MuiInputTextField
                        onChange={(e) => setUrn(e.target.value)}
                        label='lwg_urn'
                        type="text"
                        value={urn || ''}
                        required={false}
                    />
                    <MuiInputTextField
                        onChange={(e) => setAuditExpityDate(e.target.value)}
                        label='lwg_expiry_date'
                        type="date"
                        value={formatDateTimeForInput(auditExpityDate) || ''}
                        required={false}
                    />
                </Box>

                <Box className="grid grid-cols-2 gap-4">
                    <MuiInputTextField
                        onChange={(e) => setAuditCategory(e.target.value)}
                        label='lwg_audit_category'
                        type="text"
                        value={auditCategory || ''}
                        required={false}
                    />
                    <MuiInputTextField
                        onChange={(e) => setType(e.target.value)}
                        label='lwg_site_type'
                        type="text"
                        value={type || ''}
                        required={false}
                    />
                </Box>
                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*<MuiFabButtonDelete onClick={() => {*/}
                    {/*    setDeleteConfirmationOpen(true)*/}
                    {/*}}/>*/}
                    {lwgSiteToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit}/>}
                </Box>
            </form>
        </Box>
    );
};

export default LwgSiteForm;
