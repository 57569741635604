import React, {useEffect, useMemo, useState} from 'react';
import {FaSitemap} from "react-icons/fa";
import {IArticle} from "../../../../../../utils/interfaces/tannery_production/articles/IArticle";
import {IPeopleArticle} from "../../../../../../utils/interfaces/tannery_production/articles/IPeopleArticle";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import useApiDeleteArticle from "./api-hooks/useApiDeleteArticle";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import customArticleCellRenderer from "./customArticleCellRenderer";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_RowSelectionState,
    useMaterialReactTable
} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import MuiBorderedBox from "../../../../../ui/mui/MuiBorderedBox";
import ColorTable from "./colors/ColorTable";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import ArticleForm from "./ArticleForm";
import {useTheme} from "@mui/material/styles";
import PeopleSelect from "../../../service/people/PeopleSelect";
import {IPeople} from "../../../../../../utils/interfaces/service/people/IPeople";
import useApiUpdateArticle from "./api-hooks/useApiUpdateArticle";
import NorthEastIcon from '@mui/icons-material/NorthEast';
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";


interface Props {
    articles: IArticle[]
    person: IPeopleArticle
}

interface IArticleWithCustomField extends IArticle {
    custom_field: JSX.Element;
}


const ArticleTable = ({articles: initialArticles, person: initialPerson}: Props) => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const updateMutation = useApiUpdateArticle(selectedCompany?.company_token)

    const [isPeopleSelectorOpen, setIsPeopleSelectorOpen] = useState(false);
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [newSelectedPerson, setNewSelectedPerson] = useState<IPeopleArticle>()
    const handleNewPersonChange = async (newCustomer: IPeople | null) => {
        if (newCustomer === null) {
            return
        }

        const updatePromises = table.getSelectedRowModel().rows.map(obj => updateMutation.mutateAsync({
            people_id: initialPerson.id,
            id: obj.original.id,
            data: {
                product_name: obj.original.product_name,
                macro_spessore_id: obj.original.macro_spessore.id,
                macro_linea_id: obj.original.macro_linea.id,
                macro_category_id: obj.original.macro_category?.id,
                people_id: newCustomer.id,
                thickness: obj.original.thickness,
                size: obj.original.size,
                parent_id: null,
            },
        }));

        try {
            await Promise.all(updatePromises);
        } catch (error) {
        }

        setIsPeopleSelectorOpen(false)
        await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
    }

    const [selectedRow, setSelectedRow] = useState<IArticle | undefined>()
    const [rows, setRows] = useState<IArticle[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)


    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteArticle(selectedCompany?.company_token, initialPerson.id, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {

            await apiDelete({
                companyToken: selectedCompany?.company_token,
                people_id: initialPerson.id,
                pk: selectedRow?.id
            });
            displayMessage('Article deleted successfully');

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the article');
            }
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    useEffect(() => {
        if (initialArticles) {
            setRows(initialArticles);
        }
    }, [initialArticles]);

    const [articlesWithCustomField, setArticlesWithCustomField] = useState<IArticleWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IArticleWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customArticleCellRenderer(row, theme, t),
        }));
        setArticlesWithCustomField(dataWithCustomField);
    }, [rows]);


    const columns = useMemo<MRT_ColumnDef<IArticle>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'product_name',
                header: t('product_name'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'thickness',
                header: t('thickness'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'size',
                header: t('size'),
                filterFn: 'contains',

            },
        ],
        [rows]
    );


    const table = useMaterialReactTable<IArticle>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: articlesWithCustomField ? articlesWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),

        muiTableBodyRowProps: ({row, staticRowIndex, table}) => ({
            onClick: (event) => setSelectedRow(row.original),
            ...getMuiTablePaperProps(),
        }),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu();
                }}
                table={table}
                disabled={row.original.colors.length > 0}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 0
                    }}>
                        <FaSitemap color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'articles'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_article'}/>
                    {table.getSelectedRowModel().rows.length > 0 &&
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                            <MRTMuiButton
                                bigAtRender={true}
                                icon={<NorthEastIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                                onClick={() => {
                                    setIsPeopleSelectorOpen(true)
                                }} label={t('move to another customer')}/>
                        </Box>
                    }
                </>
            </Box>
        ),
        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('colors'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            <MuiBorderedBox>
                <ColorTable article={row.original} colors={row.original.colors} person={initialPerson}/>
            </MuiBorderedBox>,

        enableRowSelection: true,
        positionToolbarAlertBanner: 'none',
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });


    return <>
        <MaterialReactTable table={table}/>

        <MuiAlertDialog
            open={isDeleteConfirmationOpen}
            title="delete_confirmation"
            content="ask_for_delete"
            onClose={handleCancelDelete}
            onConfirm={handleConfirmDelete}
        />

        <MuiModal top="50%" left="50%" width={'50vh'} open={isEditFormOpen} onClose={() => setIsEditFormOpen(false)}>
            <ArticleForm articleToEdit={selectedRow ? selectedRow : null}
                         person={initialPerson}
                         onClose={() => setIsEditFormOpen(false)}/>
        </MuiModal>

        <MuiModal top="50%" left="50%" width={'50vh'} open={isPeopleSelectorOpen} disableBackdropClick={true}
                  onClose={() => setIsPeopleSelectorOpen(false)}>
            <PeopleSelect people={initialPerson} onChange={handleNewPersonChange} className={''}
                          category_person={'customer'}/>
            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={() => setIsPeopleSelectorOpen(false)}/>
            </Box>

        </MuiModal>

    </>;
};

export default ArticleTable;

