import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IRegistry} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/IRegistry";


const useApiCreateRegistry = (): UseMutationResult<IRegistry, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    async function doCreate({companyToken, fiscal_registry_id, data}: {
        companyToken?: string,
        fiscal_registry_id: number | undefined
        data: any
    }): Promise<IRegistry> {
        try {
            const response = await post(`/api/company/${companyToken}/my_documents/fiscal_registry/${fiscal_registry_id}/registry`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IRegistry, CustomAxiosError, any>(
        {
            mutationFn: ({fiscal_registry_id, data}: {
                fiscal_registry_id: number | undefined,
                data: any
            }) => doCreate({
                companyToken: selectedCompany?.company_token,
                fiscal_registry_id,
                data
            }),
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ['useApiGetRegistry', obj.id]});
                await queryClient.invalidateQueries({queryKey: ['useApiGetRegistries']});
            },
        }
    );
};

export default useApiCreateRegistry;