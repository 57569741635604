import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";


const useApiUpdateProduct = (
    companyToken: string | undefined
): UseMutationResult<void, CustomAxiosError, { people_id: number | undefined; id: number; data: any }, unknown> => {

    const {put} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            people_id: number | undefined,
                            pk: number,
                            data: any): Promise<void> {
        try {
            await put(`/api/company/${company_token}/service/people/${people_id}/product/${pk}/update`, data);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, {
        people_id: number | undefined;
        id: number;
        data: any;
    }>({
        mutationFn: ({id, people_id, data}) => doUpdate(companyToken, people_id, id, data)
    });
};


export default useApiUpdateProduct;

