import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IProductionRecord} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IProductionRecord";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiGetProductionRecords = (): UseQueryResult<IProductionRecord[], CustomAxiosError> => {
    const {selectedCompany} = useAuth()
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IProductionRecord[]> {
        try {
            const response = await get(`/api/company/${company_token}/lwg_dashboard/lwg_reports/production_records`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetProductionRecords"],
        queryFn: () => doGet(selectedCompany?.company_token),
    })


};

export default useApiGetProductionRecords