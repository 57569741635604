import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ITanneryDyeingRecipe} from "../../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingRecipe";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";


const useApiGetTanneryDyeingRecipe = (
    company_token: string | undefined,
    tannery_dyeing_id: number | undefined
): UseQueryResult<ITanneryDyeingRecipe[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, tannery_dyeing_id: number | undefined): Promise<ITanneryDyeingRecipe[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_dyeing/${tannery_dyeing_id}/recipe`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getTanneryDyeingRecipe', tannery_dyeing_id],
        queryFn: () => doGet(company_token, tannery_dyeing_id)
    })

};

export default useApiGetTanneryDyeingRecipe