import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IMyCompanyRegistry} from "../../../../../../utils/interfaces/my_company/my_company_registry/IMyCompanyRegistry";


const useApiUpdateMyCompanyRegistry = (): UseMutationResult<IMyCompanyRegistry, CustomAxiosError, {
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()
    const {put} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            data: any): Promise<IMyCompanyRegistry> {
        try {
            const response = await put(`/api/company/${company_token}/my_company/my_company_registry/detail/`, data);
            return response.data as IMyCompanyRegistry;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IMyCompanyRegistry, CustomAxiosError, {
        data: any
    }, unknown>({
        mutationFn: async ({data}) => {
            return await doUpdate(selectedCompany?.company_token, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ["useApiGetMyCompanyRegistry"]});

        },
    });
};


export default useApiUpdateMyCompanyRegistry;