import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IMacroLinea} from "../../../../../../../../utils/interfaces/tannery_production/articles/IMacroLinea";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";


const useApiGetMacroLineeProduzione = (
    companyToken: string | undefined
): UseQueryResult<IMacroLinea[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined): Promise<IMacroLinea[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/articles/macro_linee_produzione/`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getMacroLineeProduzione'],
        queryFn: () => doGet(companyToken)
    })
};

export default useApiGetMacroLineeProduzione