import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import {CustomAxiosError, useApiCalls} from "../../../logics/api_config";
import {useAuth} from "../../../utils/contexts/auth-context";


interface ApiResponse {
    refresh: string,
    access: string,
}


interface LoginForm {
    username: string;
    password: string;
}


const useDoPostLogin = (): UseMutationResult<ApiResponse, CustomAxiosError, LoginForm> => {
    const queryClient = useQueryClient();
    const {post} = useApiCalls()
    const {endpoint} = useAuth()

    const doPostLogin = async (data: LoginForm): Promise<ApiResponse> => {
        try {
            console.log('sigin in in:', endpoint)
            const response = await post('/login', data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<ApiResponse, CustomAxiosError, LoginForm>({
        mutationFn: doPostLogin,
        onSuccess: async (responseData) => {
            localStorage.setItem('accessToken', responseData.access);
            await queryClient.invalidateQueries({queryKey: ['whoAmI']});
        },
    })
};

export default useDoPostLogin;