import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';


const useApiDeleteFinalMeasurement = (companyToken: string | undefined, pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    companyToken?: string,
    pk?: number
}, unknown> => {
    const queryClient = useQueryClient();

    const {DELETE} = useApiCalls()

    async function doDelete({companyToken, pk}: {
        companyToken?: string,
        pk?: number
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/tannery_production/order_schedule/final_measurement/${pk}/delete`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, { companyToken?: string, pk?: number }>(
        {
            mutationFn: () => doDelete({companyToken: companyToken, pk: pk}),
            onSuccess: async () => {
                // await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe']})
                // await queryClient.invalidateQueries({queryKey: ['getDyeToDye']})
                // await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleCurrent']});
                // await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleHistorical']});
                // await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleDrafts']});
                // await queryClient.invalidateQueries({queryKey: ['getDyeByOrder']});
                // await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
                // await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
            }
        });
};

export default useApiDeleteFinalMeasurement