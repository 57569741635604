import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from '@tanstack/react-query';


const useApiDeleteProduct = (companyToken: string | undefined, people_id: number | undefined, pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    companyToken?: string,
    people_id: number | undefined,
    pk: number | undefined
}, unknown> => {

    const {DELETE} = useApiCalls();

    async function doDelete({companyToken, people_id, pk}: {
        companyToken?: string,
        people_id: number | undefined,
        pk: number | undefined
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/service/people/${people_id}/product/${pk}/delete`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        companyToken?: string,
        people_id: number | undefined,
        pk: number | undefined
    }>(
        {mutationFn: () => doDelete({companyToken: companyToken, people_id: people_id, pk: pk})});
};

export default useApiDeleteProduct