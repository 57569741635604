import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import PaymentTypeSelect from "./PaymentTypeSelect";
import {IPaymentType} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IPaymentType";
import {
    IPaymentDetailedWithString
} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IPaymentDetailedWithString";
import useApiGetDocPaymentDetailed from "./api-hooks/useApiGetDocPaymentDetailed";
import {IPaymentDayType} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IPaymentDayType";
import {
    IFirstPaymentDays
} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IFirstPaymentDays";
import {IPaymentsNumber} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IPaymentsNumber";
import {
    IFurtherPaymentDays
} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IFurtherPaymentDays";
import {
    ISpecialMonthEnds
} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/ISpecialMonthEnds";
import {
    INewSpecialMonthDay
} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/INewSpecialMonthDay";
import PaymentDayTypeSelect from "./PaymentDayTypeSelect";
import FirstPaymentDaysSelect from "./FirstPaymentDaysSelect";
import PaymentsNumberSelect from "./PaymentsNumberSelect";
import FurtherPaymentDaysSelect from "./FurtherPaymentDaysSelect";
import SpecialMonthEndsSelect from "./SpecialMonthEndsSelect";
import NewSpecialMonthDaySelect from "./NewSpecialMonthDaySelect";
import {
    IPaymentDiscountRate
} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IPaymentDiscountRate";
import PaymentDiscountRateSelect from "./PaymentDiscountRateSelect";
import PaymentCard from "./PaymentCard";


interface Props {
    doc_payment: string | undefined;
    onChange: (doc_payment: string, doc_payment_detailed_string: string | undefined) => void;
    onClose: () => void;
}

const PaymentForm: React.FC<Props> = ({doc_payment: initialDocPayment, onClose, onChange}) => {
    const {t} = useTranslation()

    const [obj, setObj] = useState<IPaymentDetailedWithString | undefined | null>()
    const [docPayment, setDocPayment] = useState(initialDocPayment)
    const [returningDocPayment, setReturningDocPayment] = useState(initialDocPayment)
    const [docPaymentDetailedString, setDocPaymentDetailedString] = useState<string | undefined>()

    const [paymentType, setPaymentType] = useState<IPaymentType | undefined>()
    const [paymentDayType, setPaymentDayType] = useState<IPaymentDayType | undefined>()
    const [firstPaymentDays, setFirstPaymentDays] = useState<IFirstPaymentDays | undefined>()
    const [paymentsNumber, setPaymentsNumber] = useState<IPaymentsNumber | undefined>()
    const [furtherPaymentDays, setFurtherPaymentDays] = useState<IFurtherPaymentDays | undefined>()
    const [specialMonthEnds, setSpecialMonthEnds] = useState<ISpecialMonthEnds | undefined>()
    const [newSpecialMonthDay, setNewSpecialMonthDay] = useState<INewSpecialMonthDay | undefined>()
    const [paymentDiscountRate, setPaymentDiscountRate] = useState<IPaymentDiscountRate | undefined>()

    useEffect(() => {
        setDocPayment(initialDocPayment)
    }, [initialDocPayment]);

    const handleDocPaymentChange = () => {
        if (returningDocPayment) {
            console.log(returningDocPayment, docPaymentDetailedString)
            onChange(returningDocPayment, docPaymentDetailedString)
        }
        onClose()
    }

    const {
        data: _detailedObj,
        refetch: refetchDetailedPreSaveObj
    } = useApiGetDocPaymentDetailed(returningDocPayment);


    const {
        data: detailedObj,
    } = useApiGetDocPaymentDetailed(initialDocPayment);


    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
            setPaymentType(detailedObj?.detailed_doc_payment.payment_type);
            setPaymentDayType(detailedObj?.detailed_doc_payment.payment_day_type);
            setFirstPaymentDays(detailedObj?.detailed_doc_payment.first_payment_days);
            setPaymentsNumber(detailedObj?.detailed_doc_payment.payments_number);
            setFurtherPaymentDays(detailedObj?.detailed_doc_payment.further_payment_days);
            setSpecialMonthEnds(detailedObj?.detailed_doc_payment.special_month_ends);
            setNewSpecialMonthDay(detailedObj?.detailed_doc_payment.new_special_month_day);
            setPaymentDiscountRate(detailedObj.detailed_doc_payment.discount_rate);
            setDocPaymentDetailedString(detailedObj?.doc_payment_detailed_string);
        }
    }, [detailedObj]);


    useEffect(() => {
        if (_detailedObj) {
            setObj(_detailedObj);
            setPaymentType(_detailedObj?.detailed_doc_payment.payment_type);
            setPaymentDayType(_detailedObj?.detailed_doc_payment.payment_day_type);
            setFirstPaymentDays(_detailedObj?.detailed_doc_payment.first_payment_days);
            setPaymentsNumber(_detailedObj?.detailed_doc_payment.payments_number);
            setFurtherPaymentDays(_detailedObj?.detailed_doc_payment.further_payment_days);
            setSpecialMonthEnds(_detailedObj?.detailed_doc_payment.special_month_ends);
            setNewSpecialMonthDay(_detailedObj?.detailed_doc_payment.new_special_month_day);
            setPaymentDiscountRate(_detailedObj.detailed_doc_payment.discount_rate)
            setDocPaymentDetailedString(_detailedObj?.doc_payment_detailed_string)
        }
    }, [_detailedObj]);


    useEffect(() => {
        const codes = [
            paymentType && paymentType.code,
            paymentDayType && paymentDayType.code,
            firstPaymentDays && firstPaymentDays.code,
            paymentsNumber && paymentsNumber.code,
            furtherPaymentDays && furtherPaymentDays.code,
            specialMonthEnds && specialMonthEnds.code,
            newSpecialMonthDay && newSpecialMonthDay.code,
            paymentDiscountRate && paymentDiscountRate.code
        ];

        if (paymentType?.code === '1' && firstPaymentDays?.number_of_days === 0) {
            codes[2] = '3';
        }

        if (paymentDayType?.name === 'df') {
            codes[5] = '0';
        }

        if (!specialMonthEnds) {
            codes[6] = '0';
        }

        if (!newSpecialMonthDay) {
            codes[7] = '0';
        }

        if (!paymentDiscountRate) {
            codes[8] = '0';
        }

        const concatenatedCodes = codes.join('');
        if (concatenatedCodes !== returningDocPayment && concatenatedCodes.length === 8) {
            setReturningDocPayment(concatenatedCodes);
        }


    }, [paymentType, paymentDayType, firstPaymentDays, paymentsNumber, furtherPaymentDays, specialMonthEnds, newSpecialMonthDay, paymentDiscountRate, returningDocPayment]);


    return (
        <Box sx={{padding: 2}}>
            <form>
                <span className={'form_title'}>{t('doc_payment')}</span>
                <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}
                     className='input-select-container mt-2'>
                    <PaymentTypeSelect
                        label={'select_payment_type'}
                        onChange={(obj) => setPaymentType(obj)}
                        className={''}
                        obj={paymentType}
                    />
                    {paymentType?.name !== 'not_handled' &&
                        <>
                            <PaymentDayTypeSelect
                                label={'select_payment_day_type'}
                                onChange={(obj) => setPaymentDayType(obj)}
                                className={''}
                                obj={paymentDayType}
                            />
                            <FirstPaymentDaysSelect
                                label={'select_first_payment_day'}
                                onChange={(obj) => setFirstPaymentDays(obj)}
                                className={''}
                                obj={firstPaymentDays}
                            />
                            <PaymentsNumberSelect
                                label={'select_payments_number'}
                                onChange={(obj) => setPaymentsNumber(obj)}
                                className={''}
                                obj={paymentsNumber}
                            />
                            {paymentsNumber && paymentsNumber.number_of_payments > 1 &&

                                <FurtherPaymentDaysSelect
                                    label={'select_further_payment_days'}
                                    onChange={(obj) => setFurtherPaymentDays(obj)}
                                    className={''}
                                    obj={furtherPaymentDays}
                                />
                            }
                            {paymentDayType?.name === 'fm' &&
                                <>
                                    <SpecialMonthEndsSelect
                                        label={'select_special_month_ends'}
                                        onChange={(obj) => setSpecialMonthEnds(obj)}
                                        className={''}
                                        obj={specialMonthEnds}
                                    />
                                    {specialMonthEnds?.name !== 'not_handled' &&
                                        <NewSpecialMonthDaySelect
                                            label={'select_new_special_month_day'}
                                            onChange={(obj) => setNewSpecialMonthDay(obj)}
                                            className={''}
                                            obj={newSpecialMonthDay}
                                        />}
                                </>}

                        </>}
                    <PaymentDiscountRateSelect
                        label={'select payment discount'}
                        onChange={(obj) => setPaymentDiscountRate(obj)}
                        className={''}
                        obj={paymentDiscountRate}
                    />
                    <PaymentCard obj={_detailedObj?.doc_payment_detailed_string}/>
                </div>

                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    <MuiFabButtonSaveAs onClick={handleDocPaymentChange}/>
                </Box>

            </form>
        </Box>
    );
};

export default PaymentForm;