import React, {useEffect, useRef, useState} from 'react';
import AnimatedRow from "../../../../../ui/AnimatedRow";
import AgDiv from "../../../../../ui/divs/AgDiv";
import {IRecord} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IRecord";
import RecordRow from "./RecordRow";

interface Props {
    scrollableHeight?: number;
    records: IRecord[]
}

const RecordList = ({scrollableHeight = 100, records: initialRecords}: Props) => {
    const [records, setRecords] = useState<IRecord[]>(initialRecords);
    const [filteredData, setFilteredData] = useState<IRecord[] | undefined>(records);
    const [totalPcs, setTotalPcs] = useState<number>()
    const [totalSqm, setTotalSqm] = useState<number>()
    const [totalLwgSqm, setTotalLwgSqm] = useState<number>()
    const [totalCoefficientSqm, setTotalCoefficientSqm] = useState<number>()

    useEffect(() => {
        setRecords(initialRecords)
    }, [initialRecords]);

    useEffect(() => {
        if (records) {
            const total_pcs = records.reduce((accumulator: any, object: IRecord) => {
                return accumulator + object.pcs_produced;
            }, 0);
            const total_sqm = records.reduce((accumulator: any, object: IRecord) => {
                return accumulator + object.sqm_produced;
            }, 0);
            const total_lwg_sqm = records.reduce((accumulator: any, object: IRecord) => {
                return accumulator + object.lwg_sqm_produced;
            }, 0);
            const total_coefficient_sqm_produced = records.reduce((accumulator: any, object: IRecord) => {
                return accumulator + object.coefficient_sqm_produced;
            }, 0);

            setTotalPcs(total_pcs);
            setTotalSqm(total_sqm);
            setTotalLwgSqm(total_lwg_sqm);
            setTotalCoefficientSqm(total_coefficient_sqm_produced);
            setFilteredData(records);
        }

    }, [records]);


    const mapped_data =
        filteredData?.map((obj: IRecord, index: any) =>
            <AnimatedRow key={obj.tannery_dyeing_id} index={index / 3}>
                <RecordRow record={obj}/>
            </AnimatedRow>
        )


    return (

        <>
            <div className="p-4 ml-8">
                <div>
                    <div className='flex flex-row capitalize font-bold'>
                        <AgDiv label={'tannery_dyeing'} value={''}
                               minWidth="320px" maxWidth="320px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'tannery_dyeing_date'} value={''}
                               minWidth="150px" maxWidth="150px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'pcs_produced'} value={''}
                               minWidth="150px" maxWidth="150px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'avg_pcs'} value={''}
                               minWidth="100px" maxWidth="100px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'sqm_produced'} value={''}
                               minWidth="150px" maxWidth="150px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'lwg_sqm_produced'} value={''}
                               minWidth="150px" maxWidth="150px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'coefficient_sqm_produced'} value={''}
                               minWidth="150px" maxWidth="150px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'order_id'} value={''}
                               minWidth="250px" maxWidth="250px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'article'} value={''}
                               minWidth="250px" maxWidth="250px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'color'} value={''}
                               minWidth="250px" maxWidth="250px" className={'AgDivHeader'}

                        />


                    </div>
                    {mapped_data}
                    <div className='flex flex-row'>
                        <AgDiv value={'Totals:'}
                               minWidth="320px" maxWidth="320px"
                               className={'agDiv_transparent_div ml-0'}

                        />
                        <AgDiv label={''} value={''} className={'agDiv_transparent_div ml-2'}
                               minWidth="150px" maxWidth="150px"/>
                        <AgDiv label='' value={totalPcs && totalPcs.toFixed(2)}
                               minWidth="150px" maxWidth="150px" className={'AgDivFooter'}/>
                        <AgDiv label={''} value={''} className={'agDiv_transparent_div ml-1'}
                               minWidth="100px" maxWidth="100px"/>
                        <AgDiv label='' value={totalSqm && totalSqm.toFixed(2)}
                               minWidth="150px" maxWidth="150px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalLwgSqm && totalLwgSqm.toFixed(2)}
                               minWidth="150px" maxWidth="150px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalCoefficientSqm && totalCoefficientSqm.toFixed(2)}
                               minWidth="150px" maxWidth="150px" className={'AgDivFooter'}/>
                    </div>
                </div>


            </div>
        </>)


};

export default RecordList;