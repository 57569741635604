import React from 'react';
import HomePage from "./HomePage";


const HomeScreen = () => {


    return (
        <>
            <HomePage/>
        </>
    );
};

export default HomeScreen;
