import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IDocList} from "../../../../../../../utils/interfaces/my_documents/doc/IDocList";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";


const useApiGetDocs = (): UseQueryResult<IDocList[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {selectedSystemYear} = useAppGarage();
    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, doc_year: number | undefined): Promise<IDocList[]> {
        if (doc_year === undefined) {
            return [];
        }

        try {
            const response = await get(`/api/company/${company_token}/my_documents/doc/${doc_year}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetDocs", selectedSystemYear],
        enabled: !!selectedSystemYear,
        queryFn: () => doGet(selectedCompany?.company_token, selectedSystemYear),
        refetchInterval: 15000,
    })

};

export default useApiGetDocs;