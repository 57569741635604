import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {ITanneryDyeingRecipe} from "../../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingRecipe";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {ILwgSite} from "../../../../../../../utils/interfaces/tannery_raw_material/ILwgSite";
import {useTanneryDyeing} from "../../../../../../../utils/contexts/tannery_dyeing/tannery_dyeing-context";


const useApiCreateTanneryDyeingRecipe = (): UseMutationResult<ITanneryDyeingRecipe, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const {selectTanneryDyeingRecipe} = useTanneryDyeing()

    const {post} = useApiCalls();

    async function doCreate({companyToken, tannery_dyeing_id, data}: {
        companyToken?: string,
        tannery_dyeing_id: number | undefined,
        data: any
    }): Promise<ITanneryDyeingRecipe> {
        try {
            const response = await post(`/api/company/${companyToken}/tannery_dyeing/${tannery_dyeing_id}/recipe`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<ITanneryDyeingRecipe, CustomAxiosError, any>(
        {
            mutationFn: ({tannery_dyeing_id, data}: {
                tannery_dyeing_id: number | undefined,
                data: any
            }) => {

                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    tannery_dyeing_id,
                    data
                })
            },
            onSuccess: async () => {

            },
            onSettled: async (newData) => {
                selectTanneryDyeingRecipe(newData);
            }
        }
    );
};


export default useApiCreateTanneryDyeingRecipe;