import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';


const useApiDeleteDye = (companyToken: string | undefined, pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    companyToken?: string,
    pk?: number
}, unknown> => {
    const queryClient = useQueryClient();

    const {DELETE} = useApiCalls()

    async function doDelete({companyToken, pk}: {
        companyToken?: string,
        pk?: number
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/tannery_production/order_schedule/tannery_dyeing/${pk}/delete`);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, { companyToken?: string, pk?: number }>(
        {
            mutationFn: () => doDelete({companyToken: companyToken, pk: pk}),
            onSuccess: async () => {

            }
        });
};

export default useApiDeleteDye