import React from 'react';
import {useTranslation} from "react-i18next";
import {TbAlertTriangle} from "react-icons/tb";
import MuiModal from "./mui/mui_modal/MuiModal";
import {Box} from "@mui/material";
import MuiFabButtonConfirm from "./mui/mui_buttons/fab/extended/MuiFabButtonConfirm";
import MuiFabButtonBack from "./mui/mui_buttons/fab/extended/MuiFabButtonBack";

interface ReadOnlyDialogProps {
    isOpen: boolean;
    onClose: (readonly: boolean) => void;
    user: string | undefined
    hasBeenLockedForInvoicing: boolean
    hasBeenInvoiced: boolean
}


const EnterOrCloseChooser: React.FC<ReadOnlyDialogProps> = ({
                                                                isOpen,
                                                                onClose,
                                                                user,
                                                                hasBeenLockedForInvoicing = false,
                                                                hasBeenInvoiced = false
                                                            }) => {
    const {t} = useTranslation()

    if (!isOpen) {
        return null;
    }

    let message;

    if (hasBeenInvoiced) {
        message = 'The document is invoiced. No modifications can be made here.';
    } else if (hasBeenLockedForInvoicing) {
        message = 'The document is currently being invoiced by another user.';
    } else {
        message = 'The document is locked by another user.';
    }


    return (
        <>
            <MuiModal top="50%" left="50%" width={'50vh'} open={isOpen} onClose={() => onClose}>
                <Box className="flex items-center justify-center mb-4 animate-ping">
                    <TbAlertTriangle className="text-aGred" style={{fontSize: "2rem"}}/>
                </Box>
                <Box className="flex items-center justify-center mb-4">
                    <p className={'uppercase text-aGred text-[20px] font-extrabold'}>{t(message)}</p>
                </Box>
                <Box className="flex items-center justify-center mb-4">
                    <p className={'uppercase text-aGred text-[20px] font-extrabold'}>{user}</p>
                </Box>

                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    {!hasBeenLockedForInvoicing &&
                        <MuiFabButtonConfirm onClick={() => onClose(false)} label={t('enter_and_modify')}/>}
                    <MuiFabButtonBack onClick={() => onClose(true)} label={t('close')}/>
                </Box>

            </MuiModal>


        </>

    );
};

export default EnterOrCloseChooser;
