import React, {useEffect, useMemo, useState} from 'react';
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import {
    IOrderScheduleWorking
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleWorking";
import useApiGetWorkingsOrders from "./api-hooks/useApiGetWorkingsOrders";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import useApiDeleteWorkingOrder from "./api-hooks/useApiDeleteWorkingOrder";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Edit} from "@mui/icons-material";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import {Avatar, Box, Button, FormControlLabel, Popover, Switch} from "@mui/material";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import {formatDateTime} from "../../../../../../logics/helpers";
import WorkingsForm from "./WorkingsForm";
import useApiGetHistoricalWorkingsOrders from "./api-hooks/useApiGetHistoricalWorkingsOrders";
import useApiGetUms from "../../../service/api-hooks/useApiGetUms";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import MRTWorkingsFiltersPopOver from "./MRTWorkingsFiltersPopOver";
import {convertToCsv} from "../../../../../../logics/csv_converter";
import {CSVLink} from "react-csv";
import MuiFetchingSpinner from "../../../../../ui/mui/MuiFetchingSpinner";
import {MdWorkspaces} from "react-icons/md";
import {FaUnlink} from "react-icons/fa";


interface Props {
    workings?: IOrderScheduleWorking[]
}

const WorkingsTable = ({workings}: Props) => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const icon_color = theme.palette.primary.main
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [isHistorical, setIsHistorical] = useState<boolean>(false)
    const [selectedRow, setSelectedRow] = useState<IOrderScheduleWorking | undefined>()
    const [rows, setRows] = useState<IOrderScheduleWorking[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteWorkingOrder(selectedCompany?.company_token, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({companyToken: selectedCompany?.company_token, pk: selectedRow?.id});
            displayMessage('Working deleted successfully');

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the Working');
            }
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['getWorkingOrdersByOrder']});
        await queryClient.invalidateQueries({queryKey: ['getWorkingOrders']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const {
        data: ums,
    } = useApiGetUms();


    const {
        data: currentData,
        isLoading,
        error,
        isFetching,
        refetch
    } = useApiGetWorkingsOrders(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const {
        data: historicalData,
    } = useApiGetHistoricalWorkingsOrders(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (workings) {
            setRows(workings);
        }
        if (isHistorical && !workings) {
            setRows(historicalData);
        }
        if (!isHistorical && !workings) {
            setRows(currentData);
        }

    }, [isHistorical, currentData, historicalData, workings]);


    const [openFilters, setOpenFilters] = useState<boolean>(false)
    const [startDateFilter, setStartDateFilter] = useState<string>()
    const [endDateFilter, setEndDateFilter] = useState<string>()
    const handleToggleFilters = () => {
        setStartDateFilter(undefined);
        setEndDateFilter(undefined);
        setOpenFilters(!openFilters);
    };

    const [filteredData, setFilteredData] = useState(rows);
    useEffect(() => {
        if (rows) {
            let mov_list_with_start_date = rows;
            if (startDateFilter) {
                const startDate = new Date(startDateFilter);
                startDate.setDate(startDate.getDate() - 1);
                mov_list_with_start_date = rows.filter((mov: any) => {
                    const mov_startDate = new Date(mov.returning_date);
                    return mov_startDate >= startDate;
                });
            }

            let mov_list_with_end_date = mov_list_with_start_date;
            if (endDateFilter) {
                const endDate = new Date(endDateFilter);
                mov_list_with_end_date = mov_list_with_start_date.filter((mov: any) => {
                    const mov_endDate = new Date(mov.returning_date);
                    return mov_endDate <= endDate;
                });
            }
            setFilteredData(mov_list_with_end_date);
            handleSetCsvData(mov_list_with_end_date);
        }
    }, [endDateFilter, rows, startDateFilter]);


    const keysToInclude = [
        "order_code",
        "article_name",
        "color_name",
        "people_name",
        "working",
        "outgoing_date",
        "returning_date",
        "quantity",
        "um",
        "price",
    ];

    const customLabels = [
        "Order ID",
        "Article",
        "Color",
        "SubContractor",
        "Working",
        "Out",
        "Returning",
        "Working Quantity",
        "Um",
        "Price",
    ];

    const handleExportRows = (rows: MRT_Row<IOrderScheduleWorking>[]) => {
        const rowData = rows.map((row) => row.original);
        handleSetCsvData(rowData);

    };

    const [csvData, setCsvData] = useState<string | undefined>();
    const handleSetCsvData = (jsonData: IOrderScheduleWorking[]) => {
        const csvData: string = convertToCsv(jsonData, keysToInclude, customLabels);
        setCsvData(csvData);
    };


    const totalPcs = useMemo(() => rows?.reduce((acc, curr) => acc + curr.quantity, 0), [rows]);
    const totalValue = useMemo(() => rows?.reduce((acc, curr) => acc + curr.quantity * curr.price, 0), [rows]);

    const columns = useMemo<MRT_ColumnDef<IOrderScheduleWorking>[]>(
        () => [
            {
                accessorKey: 'order_code',
                header: t('order_code'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'article_name',
                header: t('article_name'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'color_name',
                header: t('color'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'subcontractor',
                header: t('subcontractor'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'working',
                header: t('working'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'outgoing_date',
                header: t('outgoing_date'),
                accessorFn: (row) => (row.outgoing_date && formatDateTime(row.outgoing_date)),
                enableColumnFilter: false,
            },
            {
                accessorKey: 'returning_date',
                header: t('returning_date'),
                accessorFn: (row) => row.returning_date ? formatDateTime(row.returning_date) : null,
                enableColumnFilter: false,
            },
            {
                accessorKey: 'um',
                header: t('um'),
                filterFn: 'contains',
                accessorFn: (row) => t(row.um),
            },
            {
                accessorKey: 'price',
                header: t('price'),
                filterFn: 'between',
            },
            {
                accessorKey: 'quantity',
                header: t('quantity'),
                aggregationFn: 'sum',
                filterFn: 'between',
                Footer: () => <div>{totalPcs}</div>,
            },
            {
                accessorKey: 'value',
                header: t('value'),
                aggregationFn: 'sum',
                filterFn: 'between',
                accessorFn: (row) => (row.quantity * row.price).toFixed(2) + ' €',
                Footer: () => <div>{totalValue?.toFixed(2)} €</div>,
            },


        ],
        [rows, totalPcs, isHistorical]
    );


    const table = useMaterialReactTable<IOrderScheduleWorking>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: filteredData ? filteredData : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: !workings,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: !workings,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
            columnVisibility: {
                order_code: !workings,
                article_name: !workings,
                color_name: !workings
            }
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<FaUnlink color={theme.palette.warning.main}/>}
                key="unlink"
                label={t("unlink")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <MdWorkspaces color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'workings'}/>
                    {!workings &&
                        <MRTWorkingsFiltersPopOver
                            onToggle={handleToggleFilters}
                            startDateFilter={startDateFilter}
                            setStartDateFilter={setStartDateFilter}
                            endDateFilter={endDateFilter}
                            setEndDateFilter={setEndDateFilter}
                        />}
                    {!workings &&
                        <Button
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                            onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon/>}
                        >
                            {csvData &&
                                <CSVLink data={csvData} filename={'all_rows.csv'}>
                                    {t('export_to_csv')}
                                </CSVLink>}
                        </Button>}
                    {!workings &&
                        <>
                            <FormControlLabel control={
                                <Switch
                                    color="primary"
                                    onChange={() => setIsHistorical(!isHistorical)}
                                />
                            } label={isHistorical ? t('historical') : t('current')}
                                              style={{color: theme.palette.primary.main}}
                            />
                        </>}

                </>
            </Box>
        ),
    });


    return <>

        <>
            <MaterialReactTable table={table}/>

            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />

            <Popover
                id={'edit_popover'}
                open={isEditFormOpen}
                // anchorEl={anchorEl}
                onClose={() => setIsEditFormOpen(false)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <WorkingsForm workingToEdit={selectedRow ? selectedRow : null}
                              onClose={() => setIsEditFormOpen(false)}/>
            </Popover>

            {isFetching &&
                <MuiFetchingSpinner text="Fetching data..."/>
            }
        </>
    </>;
};

export default WorkingsTable;
