import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IDocDetail} from "../../../../../../../../utils/interfaces/my_documents/doc/IDocDetail";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";


const useApiCreateDocsInvoicing = (): UseMutationResult<IDocDetail[], CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {post} = useApiCalls()


    async function doCreate({data}: {
        data: any
    }): Promise<IDocDetail[]> {
        try {
            const action = data['action']
            if (action === 'preview') {
                const response = await post(`/api/company/${selectedCompany?.company_token}/my_documents/doc/docs_invoicing/`, data, {responseType: 'blob'});
                const blob = new Blob([response.data], {type: 'application/pdf'});
                const blobUrl = window.URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
                return data;
            } else {
                const response = await post(`/api/company/${selectedCompany?.company_token}/my_documents/doc/docs_invoicing/`, data);
                return response.data;
            }

        } catch (error) {
            throw error;
        }
    }

    return useMutation<IDocDetail[], CustomAxiosError, any>(
        {
            mutationFn: ({data}: {
                data: any
            }) => {
                return doCreate({
                    data
                })
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetDocs"]});
            },
        }
    );
};


export default useApiCreateDocsInvoicing;


