import {IChemicalProductList} from "../../../../../utils/interfaces/chemicals_products/IChemicalProductList";

export const filterChemicalProductsList = (
    data: IChemicalProductList[],
    personFilter: string,
    productFilter: string,
) => {
    function filterByPerson(data: IChemicalProductList[], filter: string) {
        return data.filter((obj) =>
            obj.people.people_name.toLowerCase().includes(filter.trim().toLowerCase())
        );
    }

    function filterByProduct(data: IChemicalProductList[], filter: string) {
        return data.filter((obj) =>
            obj.product_name.toLowerCase().includes(filter.trim().toLowerCase())
        );
    }

    const filteredByPerson = filterByPerson(data, personFilter);

    return filterByProduct(filteredByPerson, productFilter);
};
