import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";


const useApiUpdatePerson = (
    companyToken: string | undefined
): UseMutationResult<void, CustomAxiosError, { id: number; data: any }, unknown> => {

    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined,
                            pk: number,
                            data: any): Promise<void> {
        try {
            await put(`/api/company/${company_token}/service/people/${pk}/`, data);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        id: number;
        data: any;
    }>({
        mutationFn: ({id, data}) => doUpdate(companyToken, id, data)
    });
};


export default useApiUpdatePerson;