const PETROL_APPGARAGE = "#02959c";
const PETROL_APPGARAGE_DARK = "#025f63";
const PETROL_APPGARAGE_LIGHT = "#6cb1b8";
const PINK_APPGARAGE_LIGHT = "#ff9a9a";
const RED_APPGARAGE_LIGHT = "#e61a3b";
const ACIDGREEN_APPGARAGE = "#8cbd25";
const ACIDGREEN_APPGARAGE_DARK = "#738f39";
const ACIDGREEN_APPGARAGE_LIGHT = "#c8e38f";


export default {
    PETROL_APPGARAGE,
    PETROL_APPGARAGE_DARK,
    PETROL_APPGARAGE_LIGHT,
    PINK_APPGARAGE_LIGHT,
    RED_APPGARAGE_LIGHT,
    ACIDGREEN_APPGARAGE,
    ACIDGREEN_APPGARAGE_DARK,
    ACIDGREEN_APPGARAGE_LIGHT,
}
