import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IIncoterm} from "../../../../../../../utils/interfaces/my_documents/IIncoterm";


const useApiGetIncoterm = (pk: number | undefined): UseQueryResult<IIncoterm, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, pk: number | undefined): Promise<IIncoterm> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/incoterm/${pk}/`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetIncoterm", pk],
        queryFn: () => doGet(selectedCompany?.company_token, pk),
        enabled: !!pk,
    })

};

export default useApiGetIncoterm