import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IOrderScheduleTanneryDyeing
} from "../../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleTanneryDyeing";


const useApiGetDye = (pk: number | undefined): UseQueryResult<IOrderScheduleTanneryDyeing, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls();

    async function doGet(
        company_token: string | undefined,
        pk: number | undefined,
    ): Promise<IOrderScheduleTanneryDyeing> {
        try {
            const url = `/api/company/${company_token}/tannery_production/order_schedule/tannery_dyeing/${pk}`;

            const response = await get(url);
            return response.data[0];
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getDyeDetail', pk],
        queryFn: () => doGet(selectedCompany?.company_token, pk),
        enabled: !!pk,
    })
};

export default useApiGetDye;