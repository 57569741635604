import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {GiFizzingFlask} from "react-icons/gi";
import LabelIcon from "@mui/icons-material/Label";
import {IPrice} from "../../../../../../../utils/interfaces/service/people/product_list/price/IPrice";


const getIconsForCustomField = (row: IPrice, theme: any, t: any) => {
    const icons = [];
    const icon_color = theme.palette.primary.main

    icons.push(
        null
    );


    return icons;
};

export const customPriceCellRenderer = (rowData: IPrice, theme: any, t: any) => {
    const icons = getIconsForCustomField(rowData, theme, t);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customPriceCellRenderer;
