import React, {useEffect} from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import ChemicalProductsTable from "./ChemicalProductsTable";


const ChemicalProductsScreen = () => {


    return (
        <>
            <MaxWidthDiv maxWidth='1024px'>
                <ChemicalProductsTable/>
            </MaxWidthDiv>
        </>
    );
};

export default ChemicalProductsScreen;
