import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {useTheme} from "@mui/material/styles";
import {
    IOrderScheduleTanneryDyeing
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleTanneryDyeing";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Edit} from "@mui/icons-material";
import {FaBalanceScale, FaLink, FaUnlink} from "react-icons/fa";
import {Avatar, Box} from "@mui/material";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import AddIcon from "@mui/icons-material/Add";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import DyeForm from "./DyeForm";
import useApiGetDyeToDye from "./api-hooks/useApiGetDyeToDye";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {ITanneryDyeingSchedule} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingSchedule";
import useApiUpdateDye from "./api-hooks/useApiUpdateDye";
import {useQueryClient} from "@tanstack/react-query";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import {useLocation} from "react-router-dom";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";


interface Props {
    orders?: IOrderScheduleTanneryDyeing[],
    tannery_dyeing?: ITanneryDyeingSchedule,
    scope?: 'link' | 'unlink' | 'recipe'
    onClose?: () => void;
}


const OrderScheduleTanneryDyeingTable = ({orders: initialOrders, tannery_dyeing, scope, onClose}: Props) => {
    const {displayError} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const location = useLocation();
    const {availableCompanyUserApplications} = useAppGarage()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
    const [isToDyeOrdersModalOpen, setIsToDyeOrdersModalOpen] = useState<boolean>(false)

    const [rows, setRows] = useState<IOrderScheduleTanneryDyeing[] | undefined>()
    const [selectedRow, setSelectedRow] = useState<IOrderScheduleTanneryDyeing | undefined>()

    const isCurrent = location.pathname.startsWith('/apps/tannery_dyeing/dyeing_schedule/current')


    const {
        data: toDyeData,
        error,
        isLoading
    } = useApiGetDyeToDye(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (initialOrders) {
            setRows(initialOrders);
        } else {
            setRows(toDyeData);
        }
    }, [initialOrders, toDyeData]);


    const putMutation = useApiUpdateDye(selectedCompany?.company_token);
    const handleToggleDye = async (selectedRow: IOrderScheduleTanneryDyeing) => {
        if (selectedRow && tannery_dyeing) {
            const _tannery_dyeing_id = selectedRow.tannery_dyeing ? undefined : tannery_dyeing?.id

            try {
                const data = {
                    tannery_dyeing_id: _tannery_dyeing_id || null,
                    order_id: selectedRow.order.id,
                    weight: selectedRow.weight,
                    ready_to_dye_at: selectedRow.ready_to_dye_at,
                };

                await putMutation.mutateAsync({
                    pk: selectedRow.id,
                    data: data,
                });
                onClose && onClose()
                await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleCurrent', tannery_dyeing.id]});
                await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingSchedule']});


            } catch (error) {
                const axiosError = error as CustomAxiosError;
                if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                    displayError(axiosError.response.data.detail);
                } else {
                    displayError('An error occurred while saving the TanneryDyeing');
                }
            }
        }
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe']})
        await queryClient.invalidateQueries({queryKey: ['getDyeToDye']})
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleCurrent']});
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleHistorical']});
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleDrafts']});
        await queryClient.invalidateQueries({queryKey: ['getDyeByOrder']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});

    };


    const columns = useMemo<MRT_ColumnDef<IOrderScheduleTanneryDyeing>[]>(
        () => [

            {
                accessorKey: 'order.order_id',
                header: t('order_id'),

            },
            {
                accessorKey: 'order.article_name',
                header: t('article'),

            },
            {
                accessorKey: 'order.color.color_name',
                header: t('color'),

            },
            {
                accessorFn: (row) => {
                    return row.order.quantity ? (row.order.quantity).toFixed(2) : '';
                },
                header: t('quantity'),
            },
            {
                accessorFn: (row) => {
                    return row.weight ? (row.weight).toFixed(2) : '';
                },
                header: t('weight'),
            },

        ],
        [rows]
    );

    const table_label = (() => {
        switch (scope) {
            case 'link':
                return 'odst_ready_to_dye';
            case 'unlink':
                return 'odst_in_working';
            default:
                return 'odst_ready_to_dye';
        }
    })();


    const table = useMaterialReactTable<IOrderScheduleTanneryDyeing>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: rows ? rows : [],
        renderEmptyRowsFallback: ({table}) => (
            <span></span>
        ),
        enableTableHead: !!(rows && rows.length > 0),
        enableTableFooter: !!(rows && rows.length > 0),
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enablePagination: false,
        enableFilters: false,
        enableTopToolbar: isCurrent && scope !== 'recipe',
        enableColumnActions: false,
        enableDensityToggle: false,
        enableHiding: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        initialState: {density: 'compact', pagination: {pageSize: 25, pageIndex: 0}, expanded: true,},
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: !!tannery_dyeing && isCurrent && scope !== 'recipe',
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu();
                }}
                table={table}
                disabled={isCurrent && !availableCompanyUserApplications?.some(app => app.in_project_application_name === 'tannery_production')}
            />,
            <MRT_ActionMenuItem
                icon={<FaLink color={theme.palette.warning.main}/>}
                key="link"
                label={t("link")}
                onClick={() => {
                    handleToggleDye(row.original)
                    closeMenu();
                }}
                table={table}
                disabled={scope !== 'link'}
            />,
            <MRT_ActionMenuItem
                icon={<FaUnlink color={theme.palette.warning.main}/>}
                key="unlink"
                label={t("unlink")}
                onClick={() => {
                    handleToggleDye(row.original)
                    closeMenu();
                }}
                table={table}
                disabled={scope !== 'unlink'}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <FaBalanceScale color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={table_label}/>
                    {tannery_dyeing && scope !== 'link' && (toDyeData && toDyeData.length > 0) &&
                        <MRTMuiButton
                            icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                            onClick={() => {
                                setIsToDyeOrdersModalOpen(true);
                            }} label={'link_order'}/>}
                </>
            </Box>
        ),

    });


    return <>
        <>
            <MaterialReactTable table={table}/>


            <MuiModal
                top="50%"
                left="50%"
                width={'50vh'}
                open={isEditFormOpen}
                onClose={() => setIsEditFormOpen(false)}
            >
                <DyeForm dyeToEdit={selectedRow ? selectedRow : null}
                    // scope={scope}
                         onClose={() => setIsEditFormOpen(false)}
                />
            </MuiModal>

            <MuiModal
                top="50%"
                left="50%"
                width={'70vh'}
                open={isToDyeOrdersModalOpen && (toDyeData && toDyeData.length > 0)}
                onClose={() => setIsToDyeOrdersModalOpen(false)}
            >
                <OrderScheduleTanneryDyeingTable tannery_dyeing={tannery_dyeing}
                                                 scope={'link'}
                                                 onClose={() => setIsToDyeOrdersModalOpen(false)}/>
            </MuiModal>

            <MuiModal
                top="50%"
                left="50%"
                width={'50vh'}
                open={isFormOpen}
                onClose={() => setIsFormOpen(false)}
            >
                <DyeForm dyeToEdit={selectedRow ? selectedRow : null}

                         onClose={() => setIsFormOpen(false)}
                />
            </MuiModal>


            {onClose && <Box style={{display: 'flex', justifyContent: 'center'}} className={''}>
                <MuiFabButtonBack onClick={onClose}/>
            </Box>}
        </>
    </>;
};

export default OrderScheduleTanneryDyeingTable;
