import React, {useEffect, useState} from "react";
import {VictoryPie} from "victory";
import './Victory.css';
import {useTranslation} from "react-i18next";
import appGARAGEcolors from "../mui/appGARAGEcolors";

type PercentageChartProps = {
    data: { label: string; value: number }[] | undefined;
    title?: string;
};

const PercentagesChart: React.FC<PercentageChartProps> = ({data, title}) => {
    const {t} = useTranslation();

    const [animatedData, setAnimatedData] = useState<{ label: string; value: number }[]>(
        (data ?? []).map((item) => ({...item, value: 0}))
    );

    useEffect(() => {
        const animationDuration = 1000;
        const frameRate = 60;
        const frames = (animationDuration / 1000) * frameRate;

        const interval = setInterval(() => {
            setAnimatedData((prevData: any) => {
                const newData = prevData.map((item: any, index: any) => {
                    const targetValue = data && data[index].value;
                    const step = ((targetValue || 0) - item.value) / frames;
                    const nextValue = item.value + step;
                    return {...item, value: nextValue};
                });

                if (data && newData.every((item: any, index: any) => {
                    const targetValue = data[index]?.value;
                    return Math.abs(item.value - (targetValue || 0)) < 0.01;
                })) {
                    // Stop the animation when we are close enough to the target values
                    clearInterval(interval);
                }

                return newData;
            });
        }, (animationDuration / frames));

        return () => {
            clearInterval(interval);
        };
    }, [data]);

    // Define a custom color scale with your desired colors
    const customColorScale = ["#60b8c3", "#bad478", "#7c9c30", "#02959c", "#ff9a9a", "#e61a3b", appGARAGEcolors.ACIDGREEN_APPGARAGE];

    return (
        <div className="percentage-chart-container">
            {title && <h2 className='percentage-title'>{t(title)}</h2>}
            <div className="">
                <div className="chart-wrapper">
                    <VictoryPie
                        data={animatedData}
                        x="label"
                        y="value"
                        colorScale={customColorScale}
                        innerRadius={100}
                        labelComponent={<></>}
                        padAngle={({datum}) => datum.value * 0.2} // Adjust the factor to control the gap
                        style={{
                            data: {
                                fillOpacity: 0.9, stroke: appGARAGEcolors.ACIDGREEN_APPGARAGE, strokeWidth: 3
                            },

                        }}
                    />
                </div>
                <div className="percentages-label-wrapper">
                    {animatedData.map((item: any) => (
                        <div key={item.label} className="label-item">
                            <div className="label-name">{item.label}:</div>
                            <div className="label-value">{`${item.value.toFixed(2)}%`}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PercentagesChart;
