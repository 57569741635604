import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IDocDetail} from "../../../../../../../utils/interfaces/my_documents/doc/IDocDetail";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";


const useApiCreateDoc = (): UseMutationResult<IDocDetail, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {post} = useApiCalls()
    const {selectedSystemYear} = useAppGarage();


    async function doCreate({companyToken, doc_year, data}: {
        companyToken?: string,
        doc_year: number | undefined,
        data: any
    }): Promise<IDocDetail> {
        try {
            const response = await post(`/api/company/${companyToken}/my_documents/doc/${doc_year}/`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IDocDetail, CustomAxiosError, any>(
        {
            mutationFn: ({doc_year, data}: {
                doc_year: number | undefined,
                data: any
            }) => {

                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    doc_year,
                    data
                })
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetDocs", selectedSystemYear]});
            },
        }
    );
};


export default useApiCreateDoc;
