import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from '@tanstack/react-query';


const useApiDeleteCompanyOrderSchedule = (companyToken: string | undefined, color_id: number | undefined, pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    companyToken?: string,
    color_id: number | undefined,
    pk?: number
}, unknown> => {

    const {DELETE} = useApiCalls();

    async function doDelete({companyToken, color_id, pk}: {
        companyToken?: string,
        color_id: number | undefined,
        pk?: number
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/tannery_production/order_schedule/${color_id}/${pk}/delete`);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, { companyToken?: string, color_id: number | undefined, pk?: number }>(
        {mutationFn: () => doDelete({companyToken: companyToken, color_id: color_id, pk: pk})});
};


export default useApiDeleteCompanyOrderSchedule