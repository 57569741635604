import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {IMyCompanyRegistry} from "../../../../../../utils/interfaces/my_company/my_company_registry/IMyCompanyRegistry";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiCreateVerificationPeriod = (): UseMutationResult<IMyCompanyRegistry, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<IMyCompanyRegistry> {
        try {
            const response = await post(`/api/company/${companyToken}/lwg_dashboard/verification_period/`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IMyCompanyRegistry, CustomAxiosError, any>(
        {
            mutationFn: ({data}: {
                data: any
            }) => {

                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetVerificationPeriod"]});
            },
        }
    );
};


export default useApiCreateVerificationPeriod;