import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useQueryClient} from "@tanstack/react-query";
import {IProduct} from "../../../../../../utils/interfaces/service/people/product_list/IProduct";
import useApiUpdateProduct from "./api-hooks/useApiUpdateProduct";
import useApiCreateProduct from "./api-hooks/useApiCreateProduct";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import MuiCheckboxComponent from "../../../../../ui/mui/mui-input/MuiCheckBox";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import useApiDeleteProduct from "./api-hooks/useApiDeleteProduct";
import MuiFabButtonDelete from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonDelete";
import {IPeople} from "../../../../../../utils/interfaces/service/people/IPeople";


interface Props {
    productToEdit: IProduct | null | undefined;
    onClose: () => void;
    scope?: string
    person: IPeople | undefined;
}

const ProductForm: React.FC<Props> = ({productToEdit, onClose, person}) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();
    const {t} = useTranslation()


    const createMutation = useApiCreateProduct(selectedCompany?.company_token, person?.id);
    const updateMutation = useApiUpdateProduct(selectedCompany?.company_token)

    const [name, setName] = useState<string>();
    const [quantityMultiplier, setQuantityMultiplier] = useState<number>(1);
    const [isChemicalProduct, setIsChemicalProduct] = useState<boolean>()


    useEffect(() => {
        if (productToEdit) {
            setName(productToEdit.product_name);
            setQuantityMultiplier(productToEdit.quantity_multiplier);
            setIsChemicalProduct(productToEdit.is_chemical_product);
        } else {
            const calculatedIsChemicalProduct = person?.peoplecategory_set?.some(
                category => category.category_name === "chemical_supplier"
            );
            setIsChemicalProduct(calculatedIsChemicalProduct);
        }
    }, [productToEdit]);


    const handleSubmit = async () => {
        try {
            const data = {
                product_name: name,
                quantity_multiplier: quantityMultiplier,
                is_chemical_product: isChemicalProduct,
                people_id: person?.id
            };

            if (productToEdit) {
                await updateMutation.mutateAsync({
                    people_id: person?.id,
                    id: productToEdit.id,
                    data: data,
                });
                displayMessage('Product updated successfully');
            } else {
                await createMutation.mutateAsync(
                    data
                );

                displayMessage('Product added successfully');
            }
            await queryClient.invalidateQueries({queryKey: ['getPeopleProducts']});
            onClose();
        } catch (error) {
        }
    };

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const deleteMutation = useApiDeleteProduct(selectedCompany?.company_token, person?.id, productToEdit?.id);
    const {mutateAsync: apiDelete} = deleteMutation;
    const handleConfirmDelete = async () => {
        if (productToEdit) {
            try {
                await apiDelete({
                    companyToken: selectedCompany?.company_token,
                    people_id: person?.id,
                    pk: productToEdit?.id
                });
            } catch (error) {
            }
            setDeleteConfirmationOpen(false);
            onClose();
            await queryClient.invalidateQueries({queryKey: ['getPeopleProducts']});
        }
    }

    return (
        <>
            <Box className="space-y-6">
                <form>
                    <MuiInputTextField
                        onChange={(e) => setName(e.target.value)}
                        label='product_name'
                        type="text"
                        value={name || ''}
                        required={true}
                    />

                    {!isChemicalProduct &&
                        <MuiInputTextField
                            onChange={(e) => setQuantityMultiplier(parseFloat(e.target.value))}
                            label='quantity_multiplier'
                            type="number"
                            value={quantityMultiplier || ''}
                            required={true}
                        />}

                    <MuiCheckboxComponent
                        label={t('is_chemical_product')}
                        checked={isChemicalProduct ? isChemicalProduct : false}
                        onChange={() => setIsChemicalProduct(!isChemicalProduct)}
                    />

                    <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                        <MuiFabButtonBack onClick={onClose}/>
                        {productToEdit &&
                            <MuiFabButtonDelete onClick={() => {
                                setDeleteConfirmationOpen(true)
                            }}/>}
                        {productToEdit ?
                            <MuiFabButtonSaveAs onClick={handleSubmit}/>
                            :
                            <MuiFabButtonSave onClick={handleSubmit}/>}
                    </Box>
                </form>
                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />
            </Box>
        </>

    );
};

export default ProductForm;
