import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {ILwgSite} from "../../../../../../utils/interfaces/tannery_raw_material/ILwgSite";
import {ITanneryDyeingSchedule} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingSchedule";


const useApiUpdateTanneryDyeingSchedule = (
    companyToken: string | undefined
): UseMutationResult<ITanneryDyeingSchedule, CustomAxiosError, { id: number; data: any }, unknown> => {
    const queryClient = useQueryClient();
    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined,
                            pk: number,
                            data: any): Promise<ITanneryDyeingSchedule> {
        try {
            const response = await put(`/api/company/${company_token}/tannery_dyeing/${pk}/update`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<ITanneryDyeingSchedule, CustomAxiosError, {
        id: number;
        data: any;
    }>({
        mutationFn: ({id, data}) => doUpdate(companyToken, id, data),

        onSettled: (newData, error, variables, previousData) => {

            if (newData) {
                const historical_previous = queryClient.getQueryData<ITanneryDyeingSchedule[]>(['getTanneryDyeingScheduleHistorical']);
                if (historical_previous) {

                    // optimistic ['remove or add based on closed_at field ']
                    if (!newData.closed_at) {
                        const updated = historical_previous.filter(obj => obj.id !== newData.id);
                        queryClient.setQueryData(['getTanneryDyeingScheduleHistorical'], updated);
                    }

                    if (newData.tannery_dyeing_status === 'historical') {
                        // console.log('aggiungo in historical')
                        const updated = [newData, ...historical_previous];
                        queryClient.setQueryData(['getTanneryDyeingScheduleHistorical'], updated);
                    }
                }

                const draft_previous = queryClient.getQueryData<ITanneryDyeingSchedule[]>(['getTanneryDyeingScheduleDrafts']);
                if (draft_previous) {

                    // optimistic ['remove or add based on closed_at field ']
                    if (!newData.closed_at) {
                        const updated = draft_previous.filter(obj => obj.id !== newData.id);
                        queryClient.setQueryData(['getTanneryDyeingScheduleDrafts'], updated);
                    }

                    if (newData.tannery_dyeing_status === 'draft') {
                        // console.log('aggiungo in draft')
                        const updated = [newData, ...draft_previous];
                        queryClient.setQueryData(['getTanneryDyeingScheduleDrafts'], updated);
                    }
                }
            }
        }
    });
};


export default useApiUpdateTanneryDyeingSchedule;

