import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";

interface NextCodeResponse {
    suggested_id: string;
}


const useApiGetNextCompanyOrderScheduleid = (
    companyToken: string | undefined
): UseQueryResult<NextCodeResponse, CustomAxiosError> => {

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<NextCodeResponse> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/order_schedule/next_order_id`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getCompanyNextOrderScheduleCode'],
        queryFn: () => doGet(companyToken),
    })
};

export default useApiGetNextCompanyOrderScheduleid