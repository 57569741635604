import React from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Button, Popover, Tooltip} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MRTFiltersBox from "../../../../../ui/mui/mui_react_table/MRTFiltersBox";
import MuiTypography from "../../../../../ui/mui/mui-display-data/MuiTypography";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import {formatDateTimeForInput} from "../../../../../../logics/helpers";
import PeopleCategories from "../PeopleCategories";


interface MRTFiltersTooltipProps {
    onToggle: () => void;
    filterPerson: string | undefined;
    setFilterPerson: (value: string) => void;
    filterProduct: string | undefined;
    setFilterProduct: (value: string) => void;
    category_person: string | undefined;
    selectedCategories: string[] | [];
    setSelectedCategories: (value: string[]) => void;
    handleCategoryToggle: (value: string) => void;
}

const MRTPeopleProductsFiltersPopOver: React.FC<MRTFiltersTooltipProps> = ({
                                                                               onToggle,
                                                                               filterPerson,
                                                                               setFilterPerson,
                                                                               filterProduct,
                                                                               setFilterProduct,
                                                                               category_person,
                                                                               selectedCategories,
                                                                               handleCategoryToggle
                                                                           }) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleToggle = () => {
        onToggle()
        setAnchorEl(null);
    };


    return (
        <>
            <Tooltip disableFocusListener
                     title={t('additional_filters')}
                     placement="top"
            >
                <Button onClick={handleClick}>
                    <FilterAltIcon
                        sx={{
                            color: !!filterProduct || !!filterPerson || selectedCategories.length > 0
                                ? theme.palette.filterColor.main
                                : theme.palette.disabledColor.main,
                            '&:hover': {
                                color: theme.palette.filterColor.main,
                                cursor: 'pointer',
                            }, fontSize: '2rem'
                        }}
                    />
                </Button>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <MRTFiltersBox onToggle={handleToggle}>
                    <MuiInputTextField label="Person Filter"
                                       value={filterPerson}
                                       borderColor={theme.palette.filterColor.main}
                                       onFocusBorderColor={theme.palette.filterColor.dark}
                                       onChange={(e) => setFilterPerson(e.target.value)}
                    />
                    <MuiInputTextField label="Product/Service Filter"
                                       value={filterProduct}
                                       borderColor={theme.palette.filterColor.main}
                                       onFocusBorderColor={theme.palette.filterColor.dark}
                                       onChange={(e) => setFilterProduct(e.target.value)}
                    />
                    {!category_person && <PeopleCategories
                        selectedCategories={selectedCategories}
                        handleCategoryToggle={handleCategoryToggle}
                        isFilter={true}
                    />}
                </MRTFiltersBox>
            </Popover>
        </>
    );
};

export default MRTPeopleProductsFiltersPopOver;
