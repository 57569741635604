import React, {useEffect, useRef, useState} from 'react';
import AnimatedRow from "../../../../../ui/AnimatedRow";
import AgDiv from "../../../../../ui/divs/AgDiv";
import LwgSiteRow from "./LwgSiteRow";
import {ILwgSite} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/ILwgSite";


interface Props {
    scrollableHeight?: number;
    lwg_sites: ILwgSite[] | undefined,
}

const LwgSiteList = ({scrollableHeight = 100, lwg_sites: initialLwgSites}: Props) => {

    const [lwgSites, setLwgSites] = useState(initialLwgSites)
    const [filteredData, setFilteredData] = useState<ILwgSite[] | undefined>(lwgSites);
    const [totalPcs, setTotalPcs] = useState<number>()
    const [totalSqm, setTotalSqm] = useState<number>()
    const [totalLwgSqm, setTotalLwgSqm] = useState<number>()
    const [totalCoefficientSqm, setTotalCoefficientSqm] = useState<number>()


    useEffect(() => {
        if (lwgSites) {
            const total_pcs = lwgSites.reduce((accumulator: any, object: ILwgSite) => {
                return accumulator + object.pcs_produced;
            }, 0);
            const total_sqm = lwgSites.reduce((accumulator: any, object: ILwgSite) => {
                return accumulator + object.sqm_produced;
            }, 0);
            const total_lwg_sqm = lwgSites.reduce((accumulator: any, object: ILwgSite) => {
                return accumulator + object.lwg_sqm_produced;
            }, 0);
            const total_coefficient_sqm = lwgSites.reduce((accumulator: any, object: ILwgSite) => {
                return accumulator + object.coefficient_sqm_produced;
            }, 0);
            setTotalPcs(total_pcs);
            setTotalSqm(total_sqm);
            setTotalLwgSqm(total_lwg_sqm);
            setTotalCoefficientSqm(total_coefficient_sqm)
            setFilteredData(lwgSites);

        }

    }, [lwgSites]);

    useEffect(() => {
        setLwgSites(initialLwgSites)
    }, [initialLwgSites]);


    const mapped_data =
        filteredData?.map((obj: ILwgSite, index: any) =>
            <AnimatedRow key={obj.id} index={index / 3}>
                <LwgSiteRow lwgSite={obj}/>
            </AnimatedRow>
        )


    return (
        <>
            <div className="p-4">
                <div
                    style={{
                        height: `calc(${scrollableHeight}vh - 500px)`,
                        overflowY: 'auto',
                        overflow: 'auto',
                        whiteSpace: 'nowrap',
                        minWidth: '100%',
                        scrollbarWidth: 'auto',
                        scrollbarColor: '#02959c transparent',
                    }}
                    className='scroll-content no-scrollbar'
                >
                    <div className='flex flex-row capitalize font-bold'>
                        <AgDiv label={'lwg_sites'} value={''}
                               minWidth="320px" maxWidth="320px" className={'AgDivHeader'}

                        />
                        <AgDiv label='coeff' value={''}
                               minWidth="100px" maxWidth="100px" className={'AgDivHeader'}/>
                        <AgDiv label='pcs_produced' value={''}
                               minWidth="260px" maxWidth="260px" className={'AgDivHeader'}/>
                        <AgDiv label='sqm_produced' value={''}
                               minWidth="260px" maxWidth="260px" className={'AgDivHeader'}/>
                        <AgDiv label='lwg_sqm_produced' value={''}
                               minWidth="260px" maxWidth="260px" className={'AgDivHeader'}/>
                        <AgDiv label='coefficient_sqm_produced' value={''}
                               minWidth="260px" maxWidth="260px" className={'AgDivHeader'}/>
                    </div>
                    {mapped_data}
                    <div className='flex flex-row'>
                        <AgDiv value={'Totals:'}
                               minWidth="320px" maxWidth="320px" className={'agDiv_transparent_div ml-2'}
                        />
                        <AgDiv label='' value={''} className={'agDiv_transparent_div'}
                               minWidth="100px" maxWidth="100px"/>
                        <AgDiv label='' value={totalPcs && totalPcs.toFixed(2)}
                               minWidth="260px" maxWidth="260px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalSqm && totalSqm.toFixed(2)}
                               minWidth="260px" maxWidth="260px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalLwgSqm && totalLwgSqm.toFixed(2)}
                               minWidth="260px" maxWidth="260px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalCoefficientSqm && totalCoefficientSqm.toFixed(2)}
                               minWidth="260px" maxWidth="260px" className={'AgDivFooter'}/>
                    </div>
                </div>


            </div>
        </>

    );
};

export default LwgSiteList;