import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import LabelledMuiTypography from "../../../../../ui/mui/mui-display-data/LabelledMuiTypography";


interface Props {
    obj: string | undefined,
}

const PaymentCard = ({
                         obj: initialObj,
                     }: Props) => {
    const theme = useTheme();
    const [obj, setObj] = useState<string | undefined>(initialObj)

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    return (
        <>
            <Box sx={{
                display: 'flex-1',
                flexDirection: 'column',
                alignItems: 'flex-end',
                border: '1px solid lightgrey',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: theme.palette.backgroundAppGarageColor.gray_50
            }}>
                <LabelledMuiTypography label={''} value={obj} upperCaseValue={true}/>

            </Box>
        </>

    );
}

export default PaymentCard;