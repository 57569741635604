import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import {ILwgSite} from "../../../../../../utils/interfaces/tannery_raw_material/ILwgSite";


const useApiDeleteLwgSite = (companyToken: string | undefined, lwg_site_id: number | undefined): UseMutationResult<void, CustomAxiosError, {
    companyToken?: string,
    lwg_site_id?: number
}, unknown> => {
    const queryClient = useQueryClient();
    const {DELETE} = useApiCalls()

    const doDelete = async ({companyToken, lwg_site_id}: {
        companyToken?: string,
        lwg_site_id?: number
    }): Promise<void> => {
        try {
            await DELETE(`/api/company/${companyToken}/tannery_raw_material/lwg_sites/${lwg_site_id}`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, { companyToken?: string, lwg_site_id?: number }>(
        {
            mutationFn: () => doDelete({companyToken, lwg_site_id}),
            onSettled: async () => {
                const previous = queryClient.getQueryData<ILwgSite[]>(['getCompanyLwgSites']);

                if (previous) {
                    const updated = previous.filter(obj => obj.id !== lwg_site_id);
                    queryClient.setQueryData(['getCompanyLwgSites'], updated);
                }
            }
        });
};

export default useApiDeleteLwgSite


// note_marco ['doDelete con eliminazione immediata (invalidare nel componente per evitare tempi di attesa del reftech ']