import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IDocPayment} from "../../../../../../../utils/interfaces/my_documents/doc_payment/IDocPayment";
import {IoHandLeftOutline} from "react-icons/io5";
import {FaLock} from "react-icons/fa6";
import useApiDocPaymentManuallyModifiedAtToggle from "./api-hooks/useApiDocPaymentManuallyModifiedAtToggle";
import useApiDocPaymentLockedAtToggle from "./api-hooks/useApiDocPaymentLockedAtToggle";
import {useTranslation} from "react-i18next";
import {FaSkull} from "react-icons/fa";
import MRTMuiButton from "../../../../../../ui/mui/mui_react_table/MRTMuiButton";

// note_marco: customCellRenderer in hook example
const useCustomCellRenderer = (row: IDocPayment, theme: any, onOpenFiscalRegistryForm: () => void) => {
    const {t} = useTranslation()
    const toggleManuallyModifiedAtMutation = useApiDocPaymentManuallyModifiedAtToggle(row.doc);
    const toggleLockedAtMutation = useApiDocPaymentLockedAtToggle(row.doc);


    const handleSubmit = async (mutation: any) => {
        try {
            await mutation.mutateAsync({id: row.id});
        } catch (error) {
        }
    }

    const getIconsForCustomField = (row: IDocPayment, theme: any) => {
        const icons = [];


        icons.push(
            <MRTMuiButton
                small={true}
                icon={<IoHandLeftOutline
                    color={row.manually_modified_at ? theme.palette.secondary.main : theme.palette.disabledColor.main}/>}
                onClick={() => handleSubmit(toggleManuallyModifiedAtMutation)}
                label='locked_at'
            />
        );

        icons.push(
            <MRTMuiButton
                small={true}
                icon={<FaLock color={row.locked_at ? theme.palette.secondary.main : theme.palette.disabledColor.main}/>}
                onClick={() => handleSubmit(toggleLockedAtMutation)}
                label='locked_at'
            />
        );


        if (!row.bank && row.payment.payment_type.id === 2) {
            icons.push(
                <MRTMuiButton
                    small={true}
                    icon={<FaSkull className='animate-pulse text-red-600'/>}
                    onClick={() => {
                        onOpenFiscalRegistryForm();
                    }} label={'customer bank missing (click to edit customer FiscalRegistry)'}/>
            )
        }

        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" flexDirection="row" sx={{gap: 0.5}}>
                    {icons}
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;