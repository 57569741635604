import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {Box} from "@mui/material";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {useTranslation} from "react-i18next";
import useApiDeleteMyCompanyWarehouse from "./api-hooks/useApiDeleteMyCompanyWarehouse";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import {IMyCompanyWarehouse} from "../../../../../utils/interfaces/my_company/my_company_warehouse/IMyCompanyWarehouse";
import useApiGetMyCompanyWarehouse from "./api-hooks/useApiGetMyCompanyWarehouse";
import useApiCreateMyCompanyWarehouse from "./api-hooks/useApiCreateMyCompanyWarehouse";
import useApiUpdateMyCompanyWarehouse from "./api-hooks/useApiUpdateMyCompanyWarehouse";
import {INation} from "../../../../../utils/interfaces/my_documents/INation";
import NationSelect from "../../my_documents/NationSelect";


interface Props {
    objToEdit: IMyCompanyWarehouse | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IMyCompanyWarehouse | undefined) => void,
}

const MyCompanyWarehouseForm: React.FC<Props> = ({objToEdit, onClose, onSelect}) => {
    const {displayError, displayMessage} = useToast();
    const {t} = useTranslation()

    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteMyCompanyWarehouse(objToEdit?.id);
    const {mutateAsync: apiDelete} = deleteMutation;
    const handleConfirmDelete = async () => {
        if (objToEdit) {
            try {
                await apiDelete({pk: objToEdit.id});
            } catch (error) {
            }
            setConfirmationOpen(false);
            onClose();
        }

    };
    const handleCancelDelete = () => {
        setConfirmationOpen(false);
    };

    const [name, setName] = useState<string>();
    const [address, setAddress] = useState<string | undefined>()
    const [city, setCity] = useState<string | undefined>()
    const [cap, setCap] = useState<string | undefined>()
    const [province, setProvince] = useState<string | undefined>()
    const [nation, setNation] = useState<INation>()

    const handleNationChange = (obj: INation | undefined) => {
        setNation(obj);
    };


    const createMutation = useApiCreateMyCompanyWarehouse();
    const updateMutation = useApiUpdateMyCompanyWarehouse();

    const {
        data: detailedObj,
    } = useApiGetMyCompanyWarehouse(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setName(detailedObj?.name);
            setAddress(detailedObj?.address);
            setCity(detailedObj?.city);
            setCap(detailedObj?.cap);
            setProvince(detailedObj?.province);
            setNation(detailedObj?.nation)
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                name: name,
                address: address,
                city: city,
                cap: cap,
                province: province,
                nation: nation?.id
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
                displayMessage(t('Updated successfully'));
            } else {
                newData = await createMutation.mutateAsync(payload);
                displayMessage(t('Added successfully'));
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (name) {
            setAllRequirementAreSatisfied(true);
        }
    }, [name]);


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setName(e.target.value)}
                    label='name'
                    type="text"
                    value={name}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setAddress(e.target.value)}
                    label='address'
                    type="text"
                    value={address}
                    required={false}
                />

                <Box className="grid grid-cols-2 gap-4">
                    <MuiInputTextField
                        onChange={(e) => setCity(e.target.value)}
                        label='city'
                        type="text"
                        value={city}
                        required={false}
                    />

                    <MuiInputTextField
                        onChange={(e) => setCap(e.target.value)}
                        label='cap'
                        type="text"
                        value={cap}
                        required={false}
                    />
                </Box>

                <Box className="grid grid-cols-2 gap-4">
                    <MuiInputTextField
                        onChange={(e) => setProvince(e.target.value)}
                        label='province'
                        type="text"
                        value={province}
                        required={false}
                    />

                    <NationSelect
                        label={'nation'}
                        obj={nation}
                        onChange={handleNationChange}
                        className={''}
                        required={true}
                    ></NationSelect>
                </Box>


                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*{objToEdit &&*/}
                    {/*    <MuiFabButtonDelete onClick={() => {*/}
                    {/*        setConfirmationOpen(true)*/}
                    {/*    }}/>}*/}
                    {objToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>}
                </Box>
            </form>
            <MuiAlertDialog
                open={isConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </Box>
    );
};

export default MyCompanyWarehouseForm;