import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {INation} from "../../../../utils/interfaces/my_documents/INation";
import {useToast} from "../../../../utils/contexts/toast-context";
import useApiGetNation from "./api-hooks/useApiGetNation";
import {CustomAxiosError} from "../../../../logics/api_config";
import Loading from "../../../ui/Loading";
import {Box, TextField} from "@mui/material";
import MuiAutocomplete from "../../../ui/mui/mui-input/MuiAutoComplete";
import useApiGetNationDetailed from "./api-hooks/useApiGetNationDetailed";


interface Props {
    obj: INation | undefined,
    onChange: (obj: INation | undefined) => void,
    className: string,
    label?: string,
    clearable?: boolean
    required?: boolean
}

const NationSelect = ({obj: initialObj, onChange, label, clearable, required, ...props}: Props) => {
    const {displayError, displayMessage} = useToast()
    const {t} = useTranslation();
    const {className = ''} = props;
    const [objs, setObjs] = useState<INation[]>([]);
    const [obj, setObj] = useState<INation | undefined>(initialObj)

    const {
        data,
        isLoading,
        error,
    } = useApiGetNation();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }

    }, [initialObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: INation | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }

    };

    const {
        data: detailedObj,
    } = useApiGetNationDetailed(obj?.id);


    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>

                        <MuiAutocomplete
                            id="nation-select"
                            options={objs}
                            getOptionLabel={(option) => option.name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('currency')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>

                </>
            )}
        </>
    );
}

export default NationSelect;