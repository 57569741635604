import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IIncoterm} from "../../../../../../../utils/interfaces/my_documents/IIncoterm";


const useApiCreateIncoterm = (): UseMutationResult<IIncoterm, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<IIncoterm> {
        try {
            const response = await post(`/api/company/${companyToken}/my_documents/incoterm/`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IIncoterm, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetIncoterms"]});
            },
        }
    );
};


export default useApiCreateIncoterm;