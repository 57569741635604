import React, {useEffect, useRef} from 'react';
import Tooltip from '@mui/material/Tooltip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
    Button,
    Popover,
    useTheme
} from '@mui/material';
import MRTFiltersBox from "../../../../ui/mui/mui_react_table/MRTFiltersBox";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import {useTranslation} from "react-i18next";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";


interface MRTFiltersTooltipProps {
    onToggle: () => void;

    filterTanneryDyeingId: string;
    setFilterTanneryDyeingId: (value: string) => void;
    filterOrderId: string;
    setFilterOrderId: (value: string) => void;
    filterPerson: string;
    setFilterPerson: (value: string) => void;
    filterArticle: string;
    setFilterArticle: (value: string) => void;
    filterThickness: string;
    setFilterThickness: (value: string) => void;
    filterColor: string;
    setFilterColor: (value: string) => void;
    filterTanneryDyeingDescription: string;
    setFilterTanneryDyeingDescription: (value: string) => void;
}

const MRTTanneryDyeingScheduleFiltersPopOver: React.FC<MRTFiltersTooltipProps> = ({
                                                                                      onToggle,
                                                                                      filterTanneryDyeingId,
                                                                                      setFilterTanneryDyeingId,
                                                                                      filterOrderId,
                                                                                      setFilterOrderId,
                                                                                      filterPerson,
                                                                                      setFilterPerson,
                                                                                      filterArticle,
                                                                                      setFilterArticle,
                                                                                      filterThickness,
                                                                                      setFilterThickness,
                                                                                      filterColor,
                                                                                      setFilterColor,
                                                                                      filterTanneryDyeingDescription,
                                                                                      setFilterTanneryDyeingDescription
                                                                                  }) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {availableCompanyUserApplications} = useAppGarage()

    const shouldFocusInput = true;
    const inputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = () => {
        onToggle()
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>

            <Tooltip disableFocusListener
                     title={t('additional_filters')}
                     placement="top"
            >
                <Button onClick={handleClick}>
                    <FilterAltIcon
                        sx={{
                            color: !!filterTanneryDyeingId || !!filterOrderId || !!filterPerson || !!filterArticle || !!filterThickness || !!filterColor
                                ? theme.palette.filterColor.main
                                : theme.palette.disabledColor.main,
                            '&:hover': {
                                color: theme.palette.filterColor.main,
                                cursor: 'pointer',
                            }, fontSize: '2rem'
                        }}
                    />
                </Button>
            </Tooltip>


            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MRTFiltersBox onToggle={handleToggle}>
                    <MuiInputTextField label="Person Filter..."
                                       value={filterPerson}
                                       borderColor={theme.palette.filterColor.main}
                                       onFocusBorderColor={theme.palette.filterColor.dark}
                                       onChange={(e) => setFilterPerson(e.target.value)}/>
                    <MuiInputTextField label="TanneryDyeing Id filter..."
                                       value={filterTanneryDyeingId}
                                       borderColor={theme.palette.filterColor.main}
                                       onFocusBorderColor={theme.palette.filterColor.dark}
                                       onChange={(e) => setFilterTanneryDyeingId(e.target.value)}
                                       inputRef={shouldFocusInput ? inputRef : undefined}/>
                    <MuiInputTextField label="TanneryDyeing Description filter..."
                                       value={filterTanneryDyeingDescription}
                                       borderColor={theme.palette.filterColor.main}
                                       onFocusBorderColor={theme.palette.filterColor.dark}
                                       onChange={(e) => setFilterTanneryDyeingDescription(e.target.value)}
                                       inputRef={shouldFocusInput ? inputRef : undefined}/>
                    <MuiInputTextField label="Thickness filter..."
                                       value={filterThickness}
                                       borderColor={theme.palette.filterColor.main}
                                       onFocusBorderColor={theme.palette.filterColor.dark}
                                       onChange={(e) => setFilterThickness(e.target.value)}
                                       inputRef={shouldFocusInput ? inputRef : undefined}/>
                    {availableCompanyUserApplications?.some(app => app.in_project_application_name === 'tannery_production') &&
                        <MuiInputTextField label="Order Id filter..."
                                           value={filterOrderId}
                                           borderColor={theme.palette.filterColor.main}
                                           onFocusBorderColor={theme.palette.filterColor.dark}
                                           onChange={(e) => setFilterOrderId(e.target.value)}
                                           inputRef={shouldFocusInput ? inputRef : undefined}/>}
                    <MuiInputTextField label="Article filter..."
                                       value={filterArticle}
                                       borderColor={theme.palette.filterColor.main}
                                       onFocusBorderColor={theme.palette.filterColor.dark}
                                       onChange={(e) => setFilterArticle(e.target.value)}
                                       inputRef={shouldFocusInput ? inputRef : undefined}/>
                    <MuiInputTextField label="Color filter..."
                                       value={filterColor}
                                       borderColor={theme.palette.filterColor.main}
                                       onFocusBorderColor={theme.palette.filterColor.dark}
                                       onChange={(e) => setFilterColor(e.target.value)}
                                       inputRef={shouldFocusInput ? inputRef : undefined}/>
                </MRTFiltersBox>
            </Popover>
        </>
    );
};

export default MRTTanneryDyeingScheduleFiltersPopOver;
