import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
    Box,
    Button,
    Popover,
    styled,
    tooltipClasses, TooltipProps,
    Typography,
    useTheme
} from '@mui/material';
import MRTFiltersBox from "../../../../ui/mui/mui_react_table/MRTFiltersBox";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import {useTranslation} from "react-i18next";


interface MRTFiltersTooltipProps {
    onToggle: () => void;
    filterLwgSiteType: string;
    setFilterLwgSiteType: (value: string) => void;
    filterChoiceName: string;
    setFilterChoiceName: (value: string) => void;
}

const MRTLotsFiltersPopOver: React.FC<MRTFiltersTooltipProps> = ({
                                                                     onToggle,
                                                                     filterLwgSiteType,
                                                                     setFilterLwgSiteType,
                                                                     filterChoiceName,
                                                                     setFilterChoiceName,
                                                                 }) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = () => {
        onToggle()
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>

            <Tooltip disableFocusListener
                     title={t('additional_filters')}
                     placement="top"
            >
                <Button onClick={handleClick}>
                    <FilterAltIcon
                        sx={{
                            color: !!filterLwgSiteType || !!filterChoiceName
                                ? theme.palette.filterColor.main
                                : theme.palette.disabledColor.main,
                            '&:hover': {
                                color: theme.palette.filterColor.main,
                                cursor: 'pointer',
                            }, fontSize: '2rem'
                        }}
                    />
                </Button>
            </Tooltip>


            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MRTFiltersBox onToggle={handleToggle}>
                    <MuiInputTextField
                        label="lwg_site_type"
                        value={filterLwgSiteType}
                        borderColor={theme.palette.filterColor.main}
                        onFocusBorderColor={theme.palette.filterColor.dark}
                        onChange={(e) => setFilterLwgSiteType(e.target.value)}
                    />
                    <MuiInputTextField
                        label="choice_name"
                        value={filterChoiceName}
                        borderColor={theme.palette.filterColor.main}
                        onFocusBorderColor={theme.palette.filterColor.dark}
                        onChange={(e) => setFilterChoiceName(e.target.value)}
                    />
                </MRTFiltersBox>
            </Popover>
        </>
    );
};

export default MRTLotsFiltersPopOver;
