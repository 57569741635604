import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {IDocPayment} from "../../../../../../../../utils/interfaces/my_documents/doc_payment/IDocPayment";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";


const useApiCreateDocPayment = (doc_id: number | undefined): UseMutationResult<IDocPayment, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        doc_id: number | undefined,
        data: any
    }): Promise<IDocPayment> {
        try {
            const response = await post(`/api/company/${companyToken}/my_documents/doc/${doc_id}/doc_payment/`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IDocPayment, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    doc_id,
                    data
                })
            },
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetDocPayments", doc_id]});
            },
        }
    );
};


export default useApiCreateDocPayment;