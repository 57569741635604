import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IPeopleProduct} from "../../../../../utils/interfaces/service/people/product_list/IPeopleProduct";
import {IoIosFlask} from "react-icons/io";
import {MdWorkspaces} from "react-icons/md";
import {SlPeople} from "react-icons/sl";


const getIconsForCustomField = (row: IPeopleProduct, theme: any, t: any) => {
    const icons = [];
    const icon_color = theme.palette.primary.main

    icons.push(
        (() => {
            switch (true) {
                case row.peoplecategory_set.some(category => category.category_name === "chemical_supplier"):
                    return <IoIosFlask
                        style={{color: theme.palette.primary.main}}/>;
                case row.peoplecategory_set.some(category => category.category_name === "subcontractor"):
                    return <MdWorkspaces
                        style={{color: theme.palette.primary.main}}/>;
                default:
                    return <SlPeople style={{color: theme.palette.primary.main}}/>;
            }
        })()
    );


    return icons;
};

export const customPeopleCellRenderer = (rowData: IPeopleProduct, theme: any, t: any) => {
    const icons = getIconsForCustomField(rowData, theme, t);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customPeopleCellRenderer;
