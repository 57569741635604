import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import LabelledInput from '../ui/LabelledInput';
import MuiButton from '../ui/mui/mui_buttons/MuiButton';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../utils/contexts/auth-context';
import Loading from '../ui/Loading';
import logo from '../../assets/company_logo.png';
import christmas from '../../assets/natale.jpg';
import usePostLogin from "./api-hooks/usePostLogin";
import {CustomAxiosError} from "../../logics/api_config";
import {useToast} from "../../utils/contexts/toast-context";
import TypeWriter from "../ui/TypeWriter";
import {useAppGarage} from "../../utils/contexts/appGarage-context";
import {getCurrentDate} from "../../logics/helpers";


const LoginForm = () => {
    const {displayError, displayMessage} = useToast()
    const {setIsAuthenticated, setUser, setPassword} = useAuth();
    const {selectSystemDate} = useAppGarage()
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const loginMutation = usePostLogin();
    const signin = async (username: string, password: string) => {
        try {
            const response = await loginMutation.mutateAsync({
                username: username,
                password: password,
            });
            if (response) {
                selectSystemDate(getCurrentDate())
                setIsAuthenticated(true);
                localStorage.setItem('@access_token', response.access);
                localStorage.setItem('@refresh_token', response.refresh);
                displayMessage("Welcome in appGarage!");
                navigate('/home');

            }
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            }
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setUser(e.currentTarget.user.value)
        setPassword(e.currentTarget.password.value)

        let username = e.currentTarget.user.value;
        let password = e.currentTarget.password.value;

        if (username && password) {
            await signin(username, password);

        }
        setLoading(false);

    };

    const texts = ['Buone ', 'Feste '];
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [texts.length]);

    return (
        <div className="flex justify-center items-center mt-10 r">
            <motion.form
                className="shadow-2xl p-2 bg-gray-100 rounded"
                onSubmit={handleSubmit}
                initial={{opacity: 0, y: -50}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
            >
                <div className="hero container max-w-screen-lg mx-auto pb-10 justify-center mt-2">
                    <img className="mx-auto" src={logo} alt="" width="200" height="200"/>
                    {/*<img className="mx-auto" src={christmas} alt="" width="200" height="200"/>*/}
                </div>

                {/*<h2 className={`font-medium uppercase text-sm text-center text-gray-600 ${texts.length > 0 ? 'mt-[50px]' : ''}`}></h2>*/}

                <LabelledInput label="User" name="user" required={true} className="mb-2"/>
                <LabelledInput label="Password" name="password" required={true} type="password"/>
                <div className="mt-4">
                    {loading ? (
                        <div className="flex flex-row justify-center">
                            <Loading label="Accesso in corso"/>
                        </div>
                    ) : (
                        <MuiButton className="w-full rounded-lg bg-aGpetrol text-white py-8 px-4 text-center"
                                   type="submit">
                            Accedi
                        </MuiButton>
                    )}
                </div>
            </motion.form>
            {/*{texts.length > 0 &&*/}
            {/*    <div className="fixed top-[380px] left-0 right-0 text-center p-4">*/}
            {/*        {texts.map((text, index) => (*/}
            {/*            <TypeWriter key={index} text={text} typingSpeed={100} firstLetterColor="#e61a3b"/>*/}
            {/*        ))}*/}
            {/*    </div>*/}
            {/*}*/}
        </div>
    );
};

export default LoginForm;
