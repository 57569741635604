import React from 'react';
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi"
import {useTranslation} from "react-i18next";

type Props = {
    onClick?: () => void,
    children?: React.ReactNode,
    type?: 'button' | 'submit',
    className?: string,
    isOpen: boolean,
    title: string,
    width?: string,
}

const OpenerButton = ({isOpen, children, type = 'button', className = '', onClick, title, width}: Props) => {
    const {t} = useTranslation();

    const handleClick = () => {
        onClick && onClick();
    }

    let defaultClassName: string = 'flex justify-center items-center text-white hover:bg-aGacidGreen px-5 py-2.5'; // Center text

    if (isOpen) {
        defaultClassName += ' bg-aGacidGreenSelected';
    } else {
        defaultClassName += ' bg-aGacidLightGreen';
    }

    const buttonStyle = width ? {width} : {};

    return (
        <button
            type={type}
            className={`${defaultClassName} ${className}`}
            style={buttonStyle}
            onClick={handleClick}
        >
            {t(title)}
        </button>
    );
};

export default OpenerButton;
