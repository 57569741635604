import {useMutation, UseMutationResult} from '@tanstack/react-query';
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";


const useApiDeleteTanneryDyeingRecipe = (companyToken: string | undefined, tannery_dyeing_id: number | undefined, pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    companyToken?: string | undefined,
    tannery_dyeing_id: number | undefined,
    pk?: number
}, unknown> => {

    const {DELETE} = useApiCalls();

    async function doDelete({companyToken, tannery_dyeing_id, pk}: {
        companyToken?: string | undefined,
        tannery_dyeing_id: number | undefined,
        pk?: number | undefined
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/tannery_dyeing/${tannery_dyeing_id}/recipe/${pk}`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        companyToken?: string | undefined,
        tannery_dyeing_id: number | undefined,
        pk?: number
    }>(
        {mutationFn: () => doDelete({companyToken: companyToken, tannery_dyeing_id: tannery_dyeing_id, pk: pk})});
};


export default useApiDeleteTanneryDyeingRecipe