import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ITanneryDyeingSchedule} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingSchedule";


const useApiGetTanneryDyeingScheduleCurrent = (
    companyToken: string | undefined, pk: number | undefined
): UseQueryResult<ITanneryDyeingSchedule, CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, pk: number | undefined): Promise<ITanneryDyeingSchedule> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_dyeing/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getTanneryDyeingScheduleCurrent', pk],
        queryFn: () => doGet(companyToken, pk),
        enabled: !!pk,
    })
};

export default useApiGetTanneryDyeingScheduleCurrent