import React, {useEffect} from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import {
    TanneryRawMaterialProvider
} from "../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";
import {TanneryDyeingProvider} from "../../../../../utils/contexts/tannery_dyeing/tannery_dyeing-context";
import {
    TanneryProductionProvider
} from "../../../../../utils/contexts/tannery_production/tannery_production-context";
import PeopleArticleTable from "./PeopleArticleTable";


const PeopleArticlesScreen = () => {


    return (
        <>

            <MaxWidthDiv maxWidth='1980px'>
                <TanneryRawMaterialProvider>
                    <TanneryDyeingProvider>
                        <TanneryProductionProvider>
                            <PeopleArticleTable/>
                        </TanneryProductionProvider>
                    </TanneryDyeingProvider>
                </TanneryRawMaterialProvider>
            </MaxWidthDiv>


        </>
    );
};

export default PeopleArticlesScreen;
