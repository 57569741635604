import React, {useEffect, useState} from 'react';
import AgDiv from "../../../../../ui/divs/AgDiv";
import {cutString, formatDate, formatDateTime} from "../../../../../../logics/helpers";
import {ISubcontractor} from "../../../../../../utils/interfaces/lwg_dashboard/reports/workings/ISubcontractor";
import {IRecord} from "../../../../../../utils/interfaces/lwg_dashboard/reports/workings/IRecord";


interface Props {
    record: IRecord,
}

const RecordRow = ({record: initialObj}: Props) => {
    const [obj, setObj] = useState<IRecord>(initialObj);
    const [selectedObj, setSelectedObj] = useState<IRecord | undefined>();

    const handleSelection = () => {
        if (selectedObj?.id === obj.id) {
            setSelectedObj(undefined);
        } else {
            setSelectedObj(obj)
        }
    }

    useEffect(() => {
        setObj(initialObj)
    }, [initialObj]);


    return (
        <>
            <div
                className={`flex flex-row ${selectedObj?.id === obj.id ? 'font-bold' : ''}`}
                style={{flex: 1}}
            >
                <div
                    onClick={handleSelection}
                    className={`flex flex-row ${selectedObj?.id === obj.id ? 'font-bold' : ''}`}
                    // style={{flex: 1}}
                >
                    <AgDiv value={formatDateTime(obj.returning_date)}
                           minWidth="130px" maxWidth="130px"
                           icons={[]}
                    />
                    <AgDiv value={obj.order.order_id}
                           minWidth="200px" maxWidth="200px"
                           icons={[]}
                    />
                    <AgDiv value={obj.order.article_name}
                           minWidth="200px" maxWidth="200px"
                           icons={[]}
                    />
                    <AgDiv value={obj.order.color.color_name}
                           minWidth="200px" maxWidth="200px"
                           icons={[]}
                    />
                    <AgDiv value={obj.um}
                           minWidth="90px" maxWidth="90px"
                           icons={[]}
                    />
                    <AgDiv value={obj.quantity}
                           minWidth="90px" maxWidth="90px"
                           icons={[]}
                    />
                    <AgDiv value={obj.order_avg_sqm}
                           minWidth="120px" maxWidth="120px"
                           icons={[]}
                    />
                    <AgDiv value={obj.quantity_multiplier}
                           minWidth="60px" maxWidth="60px"
                           icons={[]}
                    />
                    <AgDiv value={obj.worked_sqm.toFixed(2)}
                           minWidth="150px" maxWidth="150px"
                           icons={[]}
                    />
                    <AgDiv value={cutString(obj.working, 25)}
                           minWidth="240px" maxWidth="240px"
                           icons={[]}
                    />
                </div>

            </div>
            {/*{selectedLwgSite?.id === lwgSite.id && <LotList lots={lwgSite.lots}/>}*/}


        </>
    );
};

export default RecordRow;
