import React, {useEffect, useState} from 'react';
import {ILot} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/ILot";
import {GiCow} from "react-icons/gi";
import AgDiv from "../../../../../ui/divs/AgDiv";
import ChoiceList from "./ChoiceList";
import {FaSkull} from "react-icons/fa";
import {useTranslation} from "react-i18next";
import {BiLeaf} from "react-icons/bi";


interface Props {
    lot: ILot,
}

const LotRow = ({lot: initialLot}: Props) => {
    const {t} = useTranslation()
    const [lot, setLot] = useState<ILot>(initialLot);
    const [selectedLot, setSelectedLot] = useState<ILot | undefined>();

    const handleSelection = () => {
        if (selectedLot?.id === lot.id) {
            setSelectedLot(undefined);
        } else {
            setSelectedLot(lot)
        }
    }

    useEffect(() => {
        setLot(initialLot)
    }, [initialLot]);


    return (
        <>
            <div
                className={`flex flex-row ${selectedLot?.id === lot.id ? 'font-bold' : ''}`}
                style={{flex: 1}}
            >
                <div
                    onClick={handleSelection}
                    className={`flex flex-row ${selectedLot?.id === lot.id ? 'font-bold' : ''}`}
                    // style={{flex: 1}}
                >
                    <AgDiv value={lot.lot_id}
                           minWidth="320px" maxWidth="320px"
                           icons={[
                               lot.has_zero_records &&
                               <FaSkull className='animate-pulse text-red-600' title={t("has_record_zero")}/>,
                               <BiLeaf className=''
                                       color={lot.lwg_coefficient > 0 ? '#60b8c3' : 'red'}
                                       title={lot.lwg_coefficient > 0 ? 'is lwg' : 'is not lwg'}
                               />,
                               <GiCow color={'#60b8c3'}/>,
                           ]}
                    />

                    <AgDiv label='' value={lot.pcs_produced.toFixed(2)}
                           minWidth="260px" maxWidth="260px"/>
                    <AgDiv label='' value={lot.sqm_produced.toFixed(2)}
                           minWidth="260px" maxWidth="260px"/>
                    <AgDiv label='' value={lot.lwg_sqm_produced.toFixed(2)}
                           minWidth="260px" maxWidth="260px"/>
                    <AgDiv label='' value={lot.coefficient_sqm_produced.toFixed(2)}
                           minWidth="260px" maxWidth="260px"/>


                </div>


            </div>
            {selectedLot?.id === lot.id && <ChoiceList choices={lot.choices}/>}


        </>
    );
};

export default LotRow;
