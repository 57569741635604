import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IDocDetail} from "../../../../../../../utils/interfaces/my_documents/doc/IDocDetail";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";


const useApiUpdateDoc = (): UseMutationResult<IDocDetail, CustomAxiosError, {
    doc_year: number | undefined,
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth();
    const {selectedSystemYear} = useAppGarage();
    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined,
                            doc_year: number | undefined,
                            pk: number | undefined,
                            data: any): Promise<IDocDetail> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/doc/${doc_year}/${pk}/`, data);
            return response.data as IDocDetail;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IDocDetail, CustomAxiosError, {
        doc_year: number | undefined,
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({doc_year, id, data}) => {
            return await doUpdate(selectedCompany?.company_token, doc_year, id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetDoc', data.id]});
            await queryClient.invalidateQueries({queryKey: ['useApiGetDocRows', data.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetDocs", selectedSystemYear]});
        },
    });
};


export default useApiUpdateDoc;