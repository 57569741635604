import React, {useEffect, useRef, useState} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {useQueryClient} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import useApiUpdateMyCompanyRegistry from "./api-hooks/useApiUpdateMyCompanyRegistry";
import useApiGetMyCompanyRegistry from "./api-hooks/useApiGetMyCompanyRegistry";
import {IMyCompanyRegistry} from "../../../../../utils/interfaces/my_company/my_company_registry/IMyCompanyRegistry";
import useApiCreateMyCompanyRegistry from "./api-hooks/useApiCreateMyCompanyRegistry";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import {Box} from "@mui/material";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {
    IMyCompanyBank
} from "../../../../../utils/interfaces/my_company/my_company_registry/my_company_bank/IMyCompanyBank";
import MyCompanyBankSelect from "./my_company_bank/MyCompanyBankSelect";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import {INation} from "../../../../../utils/interfaces/my_documents/INation";
import NationSelect from "../../my_documents/NationSelect";


interface Props {
    onClose: () => void;
}

const MyCompanyRegistryForm: React.FC<Props> = ({onClose}: Props) => {
    const {t} = useTranslation();
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();

    const shouldFocusInput = true;
    const inputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const [myCompanyRegistry, setMyCompanyRegistry] = useState<IMyCompanyRegistry>()
    const {data} = useApiGetMyCompanyRegistry();


    const updateMutation = useApiUpdateMyCompanyRegistry();
    const createMutation = useApiCreateMyCompanyRegistry();

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);

    const [businessName, setBusinessName] = useState<string | undefined>(myCompanyRegistry?.business_name);
    const [address, setAddress] = useState<string | undefined>(myCompanyRegistry?.address);
    const [cap, setCap] = useState<string | undefined>(myCompanyRegistry?.cap);
    const [city, setCity] = useState<string | undefined>(myCompanyRegistry?.city);
    const [province, setProvince] = useState<string | undefined>(myCompanyRegistry?.province);
    const [fiscalCode, setFiscalCode] = useState<string | undefined>(myCompanyRegistry?.fiscal_code);
    const [vatNumber, setVatNumber] = useState<string | undefined>(myCompanyRegistry?.vat_number);
    const [siaCode, setSiaCode] = useState<string | undefined>(myCompanyRegistry?.sia_code);
    const [reaOffice, setReaOffice] = useState<string | undefined>(myCompanyRegistry?.rea_office);
    const [reaNumber, setReaNumber] = useState<string | undefined>(myCompanyRegistry?.rea_number);
    const [socialCapital, setSocialCapital] = useState<string | undefined>(myCompanyRegistry?.social_capital);
    const [soleShareHolder, setSoleShareHolder] = useState<string | undefined>(myCompanyRegistry?.sole_shareholder);
    const [liquidationStatus, setLiquidationStatus] = useState<string | undefined>(myCompanyRegistry?.liquidation_status);
    const [fePersonName, setFePersonName] = useState<string | undefined>(myCompanyRegistry?.fe_person_name);
    const [fePersonSurname, setFePersonSurname] = useState<string | undefined>(myCompanyRegistry?.fe_person_surname);
    const [socialSecurityFundType, setSocialSecurityFundType] = useState<string | undefined>(myCompanyRegistry?.social_security_fund_type);
    const [myCompanyBank, setMyCompanyBank] = useState<IMyCompanyBank | undefined>(myCompanyRegistry?.my_company_bank);
    const [nation, setNation] = useState<INation>()
    const [feNameFile, setFeNameFile] = useState<string>()

    const handleMyCompanyChange = (obj: IMyCompanyBank | undefined) => {
        setMyCompanyBank(obj)
    }

    const handleNationChange = (obj: INation | undefined) => {
        setNation(obj);
    };


    const handleSubmit = async () => {
        try {
            const payload = {
                business_name: businessName,
                address: address,
                cap: cap,
                city: city,
                province: province,
                fiscal_code: fiscalCode,
                vat_number: vatNumber,
                sia_code: siaCode,
                rea_office: reaOffice,
                rea_number: reaNumber,
                social_capital: socialCapital ? socialCapital : null,
                sole_shareholder: soleShareHolder,
                liquidation_status: liquidationStatus,
                fe_person_name: fePersonName,
                fe_person_surname: fePersonSurname,
                social_security_fund_type: socialSecurityFundType,
                my_company_bank: myCompanyBank ? myCompanyBank?.id : null,
                nation: nation?.id,
                setFeNameFile: feNameFile,
            };

            if (myCompanyRegistry !== null && myCompanyRegistry !== undefined) {
                await updateMutation.mutateAsync({data: payload});
                displayMessage(t('update_successfully'));
            } else {
                await createMutation.mutateAsync({data: payload});
                displayMessage(t('create_successfully'));
            }

            await queryClient.invalidateQueries({queryKey: ['getMyCompanyRegistry']});
        } catch (error) {
        }
        onClose();
    };

    useEffect(() => {
        setMyCompanyRegistry(data);
        setBusinessName('');
        setAddress('');
        setCap('');
        setCity('');
        setProvince('');
        setFiscalCode('');
        setVatNumber('');
        setSiaCode('');
        setReaOffice('');
        setReaNumber('');
        setSocialCapital('');
        setSoleShareHolder('');
        setLiquidationStatus('');
        setFePersonName('');
        setFePersonSurname('');
        setSocialSecurityFundType('');
        setMyCompanyBank(undefined);
        if (data) {
            setMyCompanyRegistry(data);
            setBusinessName(data.business_name);
            setAddress(data.address);
            setCap(data.cap);
            setCity(data.city);
            setProvince(data.province);
            setFiscalCode(data.fiscal_code);
            setVatNumber(data.vat_number);
            setSiaCode(data.sia_code);
            setReaOffice(data.rea_office);
            setReaNumber(data.rea_number);
            setSocialCapital(data.social_capital);
            setSoleShareHolder(data.sole_shareholder);
            setLiquidationStatus(data.liquidation_status);
            setFePersonName(data.fe_person_name);
            setFePersonSurname(data.fe_person_surname);
            setSocialSecurityFundType(data.social_security_fund_type);
            setMyCompanyBank(data.my_company_bank);
            setNation(data.nation);
            setFeNameFile(data.fe_namefile);
        }
    }, [data, selectedCompany]);

    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (businessName && address && cap && city && province) {
            setAllRequirementAreSatisfied(true);
        }
    }, [businessName, address, cap, city, province]);

    return (
        <Box className="mt-2">
            <form onSubmit={handleSubmit}>

                <MuiInputTextField
                    label="business_name"
                    type="text"
                    value={businessName || ''}
                    onChange={(e) => setBusinessName(e.target.value)}
                    required={true}
                    inputRef={shouldFocusInput ? inputRef : undefined}
                />

                <MuiInputTextField
                    label="address"
                    type="text"
                    value={address || ''}
                    onChange={(e) => setAddress(e.target.value)}
                    required={true}
                />


                <Box className="grid grid-cols-3 gap-4">
                    <MuiInputTextField
                        label="cap"
                        type="text"
                        value={cap || ''}
                        onChange={(e) => setCap(e.target.value)}
                        required={true}
                    />

                    <MuiInputTextField
                        label="city"
                        type="text"
                        value={city || ''}
                        onChange={(e) => setCity(e.target.value)}
                        required={true}
                    />

                    <MuiInputTextField
                        label="province"
                        type="text"
                        value={province || ''}
                        onChange={(e) => setProvince(e.target.value)}
                        required={true}
                    />
                </Box>

                <Box className="grid grid-cols-3 gap-4">
                    <MuiInputTextField
                        label="fiscal_code"
                        type="text"
                        value={fiscalCode || ''}
                        onChange={(e) => setFiscalCode(e.target.value)}
                        required={true}
                    />

                    <MuiInputTextField
                        label="vat_number"
                        type="text"
                        value={vatNumber || ''}
                        onChange={(e) => setVatNumber(e.target.value)}
                        required={true}
                    />
                </Box>

                <Box className="grid grid-cols-3 gap-4">
                    <MuiInputTextField
                        label="sia_code"
                        type="text"
                        value={siaCode || ''}
                        onChange={(e) => setSiaCode(e.target.value)}
                        required={false}
                    />
                    <MuiInputTextField
                        label="rea_office"
                        type="text"
                        value={reaOffice || ''}
                        onChange={(e) => setReaOffice(e.target.value)}
                        required={false}
                    />
                    <MuiInputTextField
                        label="rea_number"
                        type="text"
                        value={reaNumber || ''}
                        onChange={(e) => setReaNumber(e.target.value)}
                        required={false}
                    />
                </Box>

                <Box className="grid grid-cols-3 gap-4">
                    <MuiInputTextField
                        label="social_capital"
                        type="text"
                        value={socialCapital || ''}
                        onChange={(e) => setSocialCapital(e.target.value)}
                        required={false}
                    />
                    <MuiInputTextField
                        label="sole_shareholder"
                        type="text"
                        value={soleShareHolder || ''}
                        onChange={(e) => setSoleShareHolder(e.target.value)}
                        required={false}
                    />
                </Box>

                <MuiInputTextField
                    label="liquidation_status"
                    type="text"
                    value={liquidationStatus || ''}
                    onChange={(e) => setLiquidationStatus(e.target.value)}
                    required={false}
                />


                <Box className="grid grid-cols-3 gap-4">
                    <MuiInputTextField
                        label="fe_person_name"
                        type="text"
                        value={fePersonName || ''}
                        onChange={(e) => setFePersonName(e.target.value)}
                        required={false}
                    />
                    <MuiInputTextField
                        label="fe_person_surname"
                        type="text"
                        value={fePersonSurname || ''}
                        onChange={(e) => setFePersonSurname(e.target.value)}
                        required={false}
                    />
                    <MuiInputTextField
                        label="last fe namefile"
                        type="text"
                        value={feNameFile || ''}
                        onChange={(e) => setFeNameFile(e.target.value)}
                        required={false}
                    />
                </Box>

                <MuiInputTextField
                    label="social_security_fund_type"
                    type="text"
                    value={socialSecurityFundType || ''}
                    onChange={(e) => setSocialSecurityFundType(e.target.value)}
                    required={false}
                />

                <MyCompanyBankSelect obj={myCompanyBank} onChange={handleMyCompanyChange} className={''}/>
                <NationSelect
                    label={'nation'}
                    obj={nation}
                    onChange={handleNationChange}
                    className={''}
                    required={true}
                ></NationSelect>


                <Box className="flex justify-center mt-2 gap-x-2">
                    <MuiFabButtonBack onClick={onClose}/>
                    {myCompanyRegistry ? (
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                    ) : (
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                    )}
                </Box>

            </form>

        </Box>
    );
};

export default MyCompanyRegistryForm;
