// MuiFabButtonConfirm.tsx
import React from 'react';
import Fab from '@mui/material/Fab';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Zoom from "@mui/material/Zoom";
import {useTranslation} from "react-i18next";

interface MuiFabButtonAddProps {
    onClick: () => void;
    label?: string;
}

const MuiFabButtonConfirm: React.FC<MuiFabButtonAddProps> = ({onClick, label}) => {
    const {t} = useTranslation()

    return (
        <Zoom in={true} unmountOnExit>
            <Fab color="primary" aria-label="add" onClick={onClick} variant="extended">
                <ThumbUpAltIcon style={{color: 'white'}}/>
                {label && (
                    <span style={{textTransform: 'uppercase', color: 'white', marginLeft: '8px'}}>
                        {t(label)}
                    </span>
                )}
            </Fab>
        </Zoom>
    );
};

export default MuiFabButtonConfirm;
