import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {
    IOrderScheduleTanneryDyeing
} from "../../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleTanneryDyeing";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";


const useApiGetDyeToDye = (
    companyToken: string | undefined
): UseQueryResult<IOrderScheduleTanneryDyeing[], CustomAxiosError> => {
    const {availableCompanyUserApplications} = useAppGarage()

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined): Promise<IOrderScheduleTanneryDyeing[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_dyeing/orders_schedule/to_dye`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }


    return useQuery({
        queryKey: ['getDyeToDye'],
        queryFn: () => doGet(companyToken),
        enabled: !!availableCompanyUserApplications?.some(app => app.in_project_application_name === 'tannery_dyeing')
    })
};

export default useApiGetDyeToDye