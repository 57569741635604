import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IProduct} from "../../../../../../utils/interfaces/service/people/product_list/IProduct";
import {GiFizzingFlask} from "react-icons/gi";
import LabelIcon from "@mui/icons-material/Label";


const getIconsForCustomField = (row: IProduct, theme: any, t: any) => {
    const icons = [];
    const icon_color = theme.palette.primary.main

    icons.push(
        (() => {
            switch (true) {
                case row.is_chemical_product:
                    return <GiFizzingFlask
                        style={{color: theme.palette.primary.main}}/>;


                default:
                    return <LabelIcon
                        style={{color: theme.palette.primary.main}}/>;
            }
        })()
    );


    return icons;
};

export const customProductCellRenderer = (rowData: IProduct, theme: any, t: any) => {
    const icons = getIconsForCustomField(rowData, theme, t);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customProductCellRenderer;
