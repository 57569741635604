import React, {useState, useEffect} from 'react';
import {IOtherDestination} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IOtherDestination";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {INation} from "../../../../../../utils/interfaces/my_documents/INation";
import useApiCreateOtherDestination from "./api-hooks/useApiCreateOtherDestination";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import useApiUpdateOtherDestination from "./api-hooks/useApiUpdateOtherDestination";
import useApiGetOtherDestination from "./api-hooks/useApiGetOtherDestination";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import NationSelect from "../../NationSelect";
import {Box} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import {useQueryClient} from "@tanstack/react-query";


interface Props {
    objToEdit: IOtherDestination | null | undefined;
    onClose: () => void;
    fiscal_registry: IFiscalRegistry | undefined,
    onSelect?: (obj: IOtherDestination | undefined) => void,
}

const OtherDestinationForm: React.FC<Props> = ({objToEdit, onClose, fiscal_registry, onSelect}) => {
    const {displayError, displayMessage} = useToast();
    const queryClient = useQueryClient();

    const [code, setCode] = useState<string | undefined>()
    const [businessName, setBusinessName] = useState<string | undefined>()
    const [address, setAddress] = useState<string | undefined>()
    const [city, setCity] = useState<string | undefined>()
    const [cap, setCap] = useState<string | undefined>()
    const [province, setProvince] = useState<string | undefined>()
    const [nation, setNation] = useState<INation>()

    const createMutation = useApiCreateOtherDestination();
    const updateMutation = useApiUpdateOtherDestination();

    const handleNationChange = (obj: INation | undefined) => {
        setNation(obj);
    };

    const {
        data: detailedObj,
    } = useApiGetOtherDestination(fiscal_registry?.id, objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setCode(detailedObj.code);
            setBusinessName(detailedObj.business_name);
            setAddress(detailedObj.address);
            setCity(detailedObj.city);
            setCap(detailedObj.cap);
            setProvince(detailedObj.province);
            setNation(detailedObj.nation);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {

        try {
            const payload = {
                code: code,
                business_name: businessName,
                address: address,
                city: city,
                cap: cap,
                province: province,
                nation: nation?.id
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({
                    id: detailedObj.id,
                    fiscal_registry_id: fiscal_registry?.id,
                    data: payload
                });
                displayMessage('Updated successfully');
                await queryClient.invalidateQueries({queryKey: ["useApiGetOtherDestination", fiscal_registry?.id, detailedObj.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetOtherDestinations", fiscal_registry?.id]});
            } else {
                newData = await createMutation.mutateAsync({fiscal_registry_id: fiscal_registry?.id, data: payload});
                displayMessage('Added successfully');
                await queryClient.invalidateQueries({queryKey: ["useApiGetOtherDestinations", fiscal_registry?.id]});
            }
            onClose();
            onSelect && onSelect(newData);
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.detail) {
                    const detailError = axiosError.response.data.detail;
                    displayError(detailError);
                } else {
                    displayError('An error occurred while saving the object');
                }
            } else {
                displayError('An error occurred while saving the object');
            }
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (businessName && address && cap && city && province && nation && code) {
            setAllRequirementAreSatisfied(true);
        }
    }, [businessName, address, cap, city, province, nation, code]);


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setCode(e.target.value)}
                    label='code'
                    type="text"
                    value={code || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setBusinessName(e.target.value)}
                    label='businessName'
                    type="text"
                    value={businessName || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setAddress(e.target.value)}
                    label='address'
                    type="text"
                    value={address || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setCity(e.target.value)}
                    label='city'
                    type="text"
                    value={city || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setCap(e.target.value)}
                    label='cap'
                    type="text"
                    value={cap || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setProvince(e.target.value)}
                    label='province'
                    type="text"
                    value={province || ''}
                    required={true}
                />


                <NationSelect
                    label={'nation'}
                    obj={nation}
                    onChange={handleNationChange}
                    className={''}
                    required={true}
                ></NationSelect>

                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*{objToEdit &&*/}
                    {/*    <MuiFabButtonDelete onClick={() => {*/}
                    {/*        setConfirmationOpen(true)*/}
                    {/*    }}/>}*/}
                    {objToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>}
                </Box>
            </form>
        </Box>
    );
};

export default OtherDestinationForm;