import React, {useState, useEffect} from "react";
import {VictoryPie} from "victory";
import './Victory.css';
import {useTranslation} from "react-i18next";

type PercentageChartProps = {
    targetPercentage: number;
    title?: string;
};

const PercentageChart: React.FC<PercentageChartProps> = ({targetPercentage, title}) => {
    const {t} = useTranslation();
    const [currentPercentage, setCurrentPercentage] = useState<number>(0);

    useEffect(() => {
        const animationDuration = 1000;
        const frameRate = 60;
        const frames = (animationDuration / 1000) * frameRate;
        const step = targetPercentage / frames;

        const interval = setInterval(() => {
            if (currentPercentage < targetPercentage) {
                setCurrentPercentage(currentPercentage + step);
            } else {
                setCurrentPercentage(targetPercentage);
                clearInterval(interval);
            }
        }, (animationDuration / frames));

        return () => {
            clearInterval(interval);
        };
    }, [currentPercentage, targetPercentage]);

    return (
        <div className="percentage-chart-container">
            {title && <h2 className='percentage-title'>{t(title)}</h2>}
            <div className="chart-wrapper">
                <VictoryPie
                    data={[
                        {x: "Percentage", y: currentPercentage},
                        {x: "Remaining", y: 100 - currentPercentage},
                    ]}
                    colorScale={["#60b8c3", "#E5E5E5"]}
                    innerRadius={100}
                    labelComponent={<></>}
                    style={{
                        data: {
                            fillOpacity: 0.9, stroke: "#60b8c3", strokeWidth: 3
                        },

                    }}
                />
                <div className="percentage-label">{currentPercentage.toFixed(2)}</div>
            </div>
        </div>
    );
};

export default PercentageChart;
