import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";


const useApiDeleteDocRow = (
    doc_year: number | undefined,
    doc_id: number | undefined,
    pk: number | undefined,
    version: number
): UseMutationResult<void, CustomAxiosError, {
    doc_year: number | undefined,
    doc_id: number | undefined,
    pk?: number | undefined
    version: number;
}, unknown> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {DELETE} = useApiCalls()

    async function doDelete({companyToken, doc_year, doc_id, pk, version}: {
        companyToken?: string,
        doc_year: number | undefined,
        doc_id: number | undefined,
        pk?: number,
        version: number
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/my_documents/doc/${doc_year}/${doc_id}/doc_row/${pk}/`, {data: {version}});
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, {
        doc_year: number | undefined,
        doc_id: number | undefined,
        pk?: number | undefined,
        version: number
    }>(
        {
            mutationFn: () => doDelete({
                companyToken: selectedCompany?.company_token,
                doc_year: doc_year,
                doc_id: doc_id,
                pk: pk,
                version: version,
            }),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ['useApiGetDocRows', doc_id]});
                await queryClient.invalidateQueries({queryKey: ['useApiGetDoc', doc_id]});
            },
        })
}

// // Updated useApiDeleteDocRow hook to accept 'version' parameter
// const useApiDeleteDocRowf = (
//     pk: number,
//     version: number
// ): UseMutationResult<void, CustomAxiosError, number> => {
//     const queryClient = useQueryClient();
//     const {selectedSystemDate} = useAppGarage();
//     const {selectedCompany} = useAuth();
//     const {selectedDoc} = useMyDocuments();
//     const dateObject = selectedSystemDate ? new Date(selectedSystemDate) : null;
//     const year = dateObject?.getFullYear();
//
//     return useMutation<void, CustomAxiosError, number>(() =>
//             doDelete(selectedCompany?.company_token, year, selectedDoc?.id, pk, version),
//         {
//             onSuccess: () => {
//                 queryClient.invalidateQueries('getDocRows');
//                 queryClient.invalidateQueries(['getDoc', selectedDoc?.id]);
//             }
//         }
//     );
// };

export default useApiDeleteDocRow;
