import React from 'react';
import {useAuth} from '../../utils/contexts/auth-context';
import {Link, Navigate} from "react-router-dom";
import {motion} from "framer-motion";
import {useAppGarage} from "../../utils/contexts/appGarage-context";


const LogOutScreen = () => {
    const {isAuthenticated, signout} = useAuth();

    const handleSubmit = async () => {
        signout();
    }


    return (
        <>
            {(isAuthenticated) ?


                <div>
                    <nav className="bg-aGpetrol">
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <motion.div
                                className="flex items-center justify-between"
                                initial={{height: 32}}
                                animate={{height: 32}}
                                transition={{duration: 0.5}}
                            >
                            </motion.div>
                        </div>
                    </nav>

                    <div className="flex-1 flex flex-col items-center">
                        <button
                            className='mt-10 w-1/2 rounded-lg bg-aGpetrol text-white py-8 px-4 text-center font-bold'
                            onClick={handleSubmit}>
                            Esci
                        </button>
                    </div>

                </div>
                :
                <Navigate to="/" replace/>

            }


        </>
    )
};

export default LogOutScreen;




