import React from 'react';
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {Button, Popover} from "@mui/material";
import MRTFiltersBox from "../../../../ui/mui/mui_react_table/MRTFiltersBox";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";


interface MRTFiltersTooltipProps {
    onToggle: () => void;
    filterPerson: string | undefined;
    setFilterPerson: (value: string) => void;
    filterArticle: string | undefined;
    setFilterArticle: (value: string) => void;
    filterThickness: string | undefined;
    setFilterThickness: (value: string) => void;
    filterSize: string | undefined;
    setFilterSize: (value: string) => void;
    filterColor: string | undefined;
    setFilterColor: (value: string) => void;
    filterOrderId: string | undefined;
    setFilterOrderId: (value: string) => void;
}

const MRTWorkingsFiltersPopOver: React.FC<MRTFiltersTooltipProps> = ({
                                                                         onToggle,
                                                                         filterPerson, setFilterPerson,
                                                                         filterArticle, setFilterArticle,
                                                                         filterThickness, setFilterThickness,
                                                                         filterSize, setFilterSize,
                                                                         filterColor, setFilterColor,
                                                                         filterOrderId, setFilterOrderId
                                                                     }) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = () => {
        onToggle()
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (
        <>
            <Tooltip disableFocusListener
                     title={t('additional_filters')}
                     placement="top"
            >
                <Button onClick={handleClick}>
                    <FilterAltIcon
                        sx={{
                            color: !!filterPerson || !!filterArticle || !!filterThickness || filterSize || filterColor || filterOrderId
                                ? theme.palette.filterColor.main
                                : theme.palette.disabledColor.main,
                            '&:hover': {
                                color: theme.palette.filterColor.main,
                                cursor: 'pointer',
                            }, fontSize: '2rem'
                        }}
                    />
                </Button>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MRTFiltersBox onToggle={handleToggle}>
                    <MuiInputTextField
                        onChange={(e) => setFilterPerson(e.target.value)}
                        label='people'
                        type="text"
                        value={filterPerson}
                        required={false}
                    />

                    <MuiInputTextField
                        onChange={(e) => setFilterArticle(e.target.value)}
                        label='article'
                        type="text"
                        value={filterArticle}
                        required={false}
                    />
                    <MuiInputTextField
                        onChange={(e) => setFilterThickness(e.target.value)}
                        label='thickness'
                        type="text"
                        value={filterThickness}
                        required={false}
                    />
                    <MuiInputTextField
                        onChange={(e) => setFilterSize(e.target.value)}
                        label='size'
                        type="text"
                        value={filterSize}
                        required={false}
                    />
                    <MuiInputTextField
                        onChange={(e) => setFilterColor(e.target.value)}
                        label='color'
                        type="text"
                        value={filterColor}
                        required={false}
                    />
                    <MuiInputTextField
                        onChange={(e) => setFilterOrderId(e.target.value)}
                        label='order_id'
                        type="text"
                        value={filterOrderId}
                        required={false}
                    />

                </MRTFiltersBox>
            </Popover>
        </>
    );
};

export default MRTWorkingsFiltersPopOver;
