import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";
import {IDocRow} from "../../../../../../../../utils/interfaces/my_documents/doc/IDocRow";
import {useMyDocuments} from "../../../../../../../../utils/contexts/my_documents/my_documents-context";


const useApiUpdateDocRow = (): UseMutationResult<IDocRow, CustomAxiosError, {
    doc_year: number | undefined,
    doc_id: number | undefined,
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()
    const {selectedDocDetail} = useMyDocuments();

    const {put} = useApiCalls()


    async function doUpdate(company_token: string | undefined,
                            doc_year: number | undefined,
                            doc_id: number | undefined,
                            pk: number | undefined,
                            data: any): Promise<IDocRow> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/doc/${doc_year}/${doc_id}/doc_row/${pk}/`, data);
            return response.data as IDocRow;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IDocRow, CustomAxiosError, {
        doc_year: number | undefined,
        doc_id: number | undefined,
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({doc_year, doc_id, id, data}) => {
            return await doUpdate(selectedCompany?.company_token, doc_year, doc_id, id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetDoc', selectedDocDetail?.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetDocRows", selectedDocDetail?.id]});

        },
    });
};

export default useApiUpdateDocRow;
