import React, {useState} from "react";
import {IPeople} from "../../interfaces/service/people/IPeople";
import {IFiscalRegistry} from "../../interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {IProduct} from "../../interfaces/service/people/product_list/IProduct";


type ServiceContextType = {
    selectedPerson: IPeople | undefined,
    selectPerson: (selectedPerson: IPeople | undefined) => void,

    selectedProduct: IProduct | undefined,
    selectProduct: (selectedProduct: IProduct | undefined) => void,

    selectedFiscalRegistry: IFiscalRegistry | undefined,
    selectFiscalRegistry: (selectedFiscalRegistry: IFiscalRegistry | undefined) => void,

}

const ServiceContext = React.createContext<ServiceContextType | undefined>(undefined);

function useServiceContextValue() {
    const [selectedPerson, setSelectedPerson] = useState<IPeople>();
    const [selectedProduct, setSelectedProduct] = useState<IProduct>();
    const [selectedFiscalRegistry, setSelectFiscalRegistry] = useState<IFiscalRegistry>();

    const selectPerson = (selectedObj: IPeople | undefined) => {
        // console.log('person', selectedObj)
        setSelectedPerson(selectedObj)
    }

    const selectProduct = (selectedObj: IProduct | undefined) => {
        // console.log('person', selectedObj)
        setSelectedProduct(selectedObj)
    }


    const selectFiscalRegistry = (selectedObj: IFiscalRegistry | undefined) => {
        // console.log('fiscal_registry', selectedObj)
        setSelectFiscalRegistry(selectedObj)
    }

    return {
        selectedPerson, selectPerson,
        selectedProduct, selectProduct,
        selectedFiscalRegistry, selectFiscalRegistry
    }
}

function ServiceProvider({children}: { children: React.ReactNode }) {
    const value = useServiceContextValue();
    return <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
}

function useService() {
    const context = React.useContext(ServiceContext);
    if (!context) {
        throw new Error('useService must be used within a ServiceProvider');
    }
    return context;
}

export {
    ServiceProvider,
    useService,
}
