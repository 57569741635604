import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";


const useApiMoveTanneryDyeingRecipe = (
    companyToken: string | undefined
): UseMutationResult<void, CustomAxiosError, {
    tannery_dyeing_id: number | undefined;
    pk: number | undefined;
    row_position: number | undefined
}, unknown> => {

    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined,
                            tannery_dyeing_id: number | undefined,
                            pk: number | undefined,
                            row_position: number | undefined): Promise<void> {
        try {
            await put(`/api/company/${company_token}/tannery_dyeing/${tannery_dyeing_id}/recipe/${pk}/move_to/${row_position}`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        tannery_dyeing_id: number | undefined;
        pk: number | undefined;
        row_position: number | undefined;
    }>({
        mutationFn: ({
                         pk,
                         tannery_dyeing_id,
                         row_position
                     }) => doUpdate(companyToken, tannery_dyeing_id, pk, row_position)
    });
};


export default useApiMoveTanneryDyeingRecipe;