import React from 'react';
import {
    TanneryProductionProvider
} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import MaxWitdthDiv from "../../../../../ui/MaxWitdthDiv";
import WorkingsTable from "./WorkingsTable";


const WorkingsScreen = () => {


    return (
        <>

            <MaxWitdthDiv maxWidth='1980px'>
                <TanneryProductionProvider>
                    <WorkingsTable/>
                </TanneryProductionProvider>

            </MaxWitdthDiv>

        </>
    );
};

export default WorkingsScreen;
