import React, {useState} from "react";
import {IDocCompanyDocType} from "../../interfaces/my_documents/doc_type/IDocCompanyDocType";
import {IDocCompanyDocReason} from "../../interfaces/my_documents/doc_reason/IDocCompanyDocReason";
import {IDocList} from "../../interfaces/my_documents/doc/IDocList";
import {IDocRow} from "../../interfaces/my_documents/doc/IDocRow";
import {IDocDetail} from "../../interfaces/my_documents/doc/IDocDetail";


type MyDocumentsContextType = {
    selectedDoc: IDocList | undefined,
    selectDoc: (selectedDoc: IDocList | undefined) => void,

    selectedDocDetail: IDocDetail | undefined,
    selectDocDetail: (selectedDoc: IDocDetail | undefined) => void,

    selectedDocRow: IDocRow | undefined,
    selectDocRow: (selectedDocRow: IDocRow | undefined) => void,

    selectedCompanyDocType: IDocCompanyDocType | undefined,
    selectCompanyDocType: (selectedDocTypeName: IDocCompanyDocType) => void,

    selectedCompanyDocReason: IDocCompanyDocReason | undefined,
    selectCompanyDocReason: (selectedCompanyDocReason: IDocCompanyDocReason) => void,

    selectedDocVersion: number,
    selectDocVersion: (selectedDocVersion: number) => void,

}

const MyDocumentsContext = React.createContext<MyDocumentsContextType | undefined>(undefined);

function useMyDocumentsContextValue() {
    const [selectedDoc, setSelectedDoc] = useState<IDocList>();
    const [selectedDocDetail, setSelectedDocDetail] = useState<IDocDetail>();
    const [selectedDocRow, setSelectedDocRow] = useState<IDocRow>();
    const [selectedCompanyDocType, setSelectedCompanyDocType] = useState<IDocCompanyDocType | undefined>()
    const [selectedCompanyDocReason, setSelectedCompanyDocReason] = useState<IDocCompanyDocReason | undefined>()
    const [selectedDocVersion, setSelectedDocVersion] = useState<number>(1)
    const [hasBeenLockedByAnother, setHasBeenLockedByAnother] = useState<boolean>(false);
    const [hasBeenModifiedByAnother, setHasBeenModifiedByAnother] = useState<boolean>(false);

    const selectDoc = (selectedDoc: IDocList | undefined) => {
        setSelectedDoc(selectedDoc)
    }

    const selectDocDetail = (selectedDoc: IDocDetail | undefined) => {
        setSelectedDocDetail(selectedDoc)
    }

    const selectDocRow = (selectedDocRow: IDocRow | undefined) => {
        setSelectedDocRow(selectedDocRow)
    }

    const selectCompanyDocType = (companyDocType: IDocCompanyDocType) => {
        setSelectedCompanyDocType(companyDocType)
    }

    const selectCompanyDocReason = (companyDocReason: IDocCompanyDocReason) => {
        setSelectedCompanyDocReason(companyDocReason)
    }

    const selectDocVersion = (docVersion: number) => {
        setSelectedDocVersion(docVersion)
    }

    const selectHasBeenLockedByAnother = (hasBeenLockedByAnother: boolean) => {
        setHasBeenLockedByAnother(hasBeenLockedByAnother)
    }

    const selectHasBeenModifiedByAnother = (hasBeenModifiedByAnother: boolean) => {
        setHasBeenModifiedByAnother(hasBeenModifiedByAnother)
    }


    return {
        selectedDoc, selectDoc,
        selectedDocDetail, selectDocDetail,
        selectedDocRow, selectDocRow,
        selectedCompanyDocType, selectCompanyDocType,
        selectedCompanyDocReason, selectCompanyDocReason,
        selectedDocVersion, selectDocVersion,
        hasBeenLockedByAnother, selectHasBeenLockedByAnother,
        hasBeenModifiedByAnother, selectHasBeenModifiedByAnother
    }
}

function MyDocumentsProvider({children}: { children: React.ReactNode }) {
    const value = useMyDocumentsContextValue();
    return <MyDocumentsContext.Provider value={value}>{children}</MyDocumentsContext.Provider>
}

function useMyDocuments() {
    const context = React.useContext(MyDocumentsContext);
    if (!context) {
        throw new Error('useMyDocuments must be used within a MyDocumentsProvider');
    }
    return context;
}

export {
    MyDocumentsProvider,
    useMyDocuments,
}
