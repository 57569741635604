import React from 'react';
import {ImSpinner2} from "react-icons/im";

type Props = {
    label?: string,
    className?: string,
}

const Loading = ({label = '', className = ''}: Props) => {
    return (
        <div className={`flex flex-row items-center justify-center ${className} p-2 text-aGgreen`}>
            <div>{label}</div>
            <div className={`flex flex-row items-center justify-center ${label != '' ? 'ml-1' : ''}`}>
                <ImSpinner2 className="animate-spin text-aGgreen"/>
            </div>
        </div>
    );
};

export default Loading;