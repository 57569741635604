import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IFirstPaymentDays
} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IFirstPaymentDays";


const useApiGetFirstPaymentDayss = (): UseQueryResult<IFirstPaymentDays[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IFirstPaymentDays[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/payment/first_payment_days`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetFirstPaymentDayss"],
        queryFn: () => doGet(selectedCompany?.company_token,),
    })
};

export default useApiGetFirstPaymentDayss