import {useEffect} from 'react';
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {useAuth} from "../contexts/auth-context";
import {useToast} from "../contexts/toast-context";
import {CustomAxiosError} from "../../logics/api_config";
import {useTranslation} from "react-i18next";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
    _retry?: boolean;
}

const useAxiosInstance = (config: AxiosRequestConfig = {}): { interceptedAxiosInstance: AxiosInstance } => {
    const ENDPOINT = process.env.REACT_APP_ENDPOINT || '';
    const {displayError, displayMessage, displayWarning} = useToast();
    const {endpoint} = useAuth();
    const access_token = localStorage.getItem('@access_token');
    const {t} = useTranslation()

    const customAxiosInstance = axios.create({
        baseURL: endpoint,
        headers: {
            'Authorization': `Bearer ${access_token}`
        },
        // withCredentials: true,
        ...config
    });

    useEffect(() => {
        const handleSuccess = (response: AxiosResponse) => {
            // console.log(response.status)
            // displayMessage(response.statusText)
            return response
        };

        const handleError = async (error: CustomAxiosError): Promise<AxiosResponse | never> => {
            const originalRequest = error.config as CustomAxiosRequestConfig;

            // refreshing token
            if (originalRequest && error.response?.status === 401 && error.response.data?.code === 'token_not_valid' && !originalRequest._retry) {
                originalRequest._retry = true;

                try {
                    const response = await axios.post<{ access: string }>(
                        `${ENDPOINT}/api/token/refresh/`,
                        {refresh: localStorage.getItem('@refresh_token')},
                        {
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            // withCredentials: true,
                        }
                    );

                    if (response.status === 200) {
                        localStorage.setItem("@access_token", response.data.access);
                        customAxiosInstance.defaults.headers.common["Authorization"] = `Bearer ${response.data.access}`;

                        if (!originalRequest.headers) {
                            originalRequest.headers = {};
                        }
                        originalRequest.headers["Authorization"] = `Bearer ${response.data.access}`;
                        return customAxiosInstance(originalRequest);
                    }
                } catch (refreshError) {
                    displayError("Session expired. Please log in again.");
                }
            }

            if (error.response?.status === 403) {
                console.log("Permission error:", error.response.data.detail);
                displayError(t(error.response.data.detail));
                return Promise.resolve({
                    data: [],
                    message: error.response.data.detail,
                    status: 403
                }) as any;
            }


            if (error.response && error.response.status >= 400) {
                let error_message = '';

                if (error.response.data.detail === 'NOT_SHOW_THIS_ERROR') {
                    // throw error without showing it
                    return Promise.reject(error);
                }

                // Check for 'detail' error
                if (error.response.data.detail) {
                    error_message += error.response.data.detail + ' ';
                }

                // Check for 'non_field_errors'
                if (error.response.data.non_field_errors) {
                    error.response.data.non_field_errors.forEach((e) => {
                        error_message += e + ' ';
                    });
                }

                // Handle other potential errors that are arrays of strings
                Object.keys(error.response.data).forEach((key) => {
                    const value = error.response.data[key];
                    if (Array.isArray(value)) {
                        value.forEach((e) => {
                            error_message += t(key) + ': ' + t(e) + '\n';
                        });
                    }
                });

                // Set a default message if no specific error messages were found
                if (!error_message) {
                    error_message = 'An unknown error occurred';
                }

                // Display the error message
                displayError(t(error_message.trim()));
                console.log(`Error ${error.response.status}:`, error.response);

                return Promise.reject(error);
            }


            if (error.response.status === 500) {
                displayError('500 Error');
                console.log("500 error:", error.response);
                return Promise.reject(error);
            }

            if (error) {
                console.log("Interceptor Error", error)
            }

            return Promise.reject(error);
        };

        const interceptor = customAxiosInstance.interceptors.response.use(handleSuccess, handleError);

        return () => {
            customAxiosInstance.interceptors.response.eject(interceptor);
        };
    }, [customAxiosInstance]);

    return {interceptedAxiosInstance: customAxiosInstance};
};

export default useAxiosInstance;
