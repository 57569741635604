import React, {useEffect, useMemo, useState} from 'react';
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../utils/contexts/toast-context";

import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import {
    useTanneryRawMaterial
} from "../../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";
import customCellRenderer from "./customCellRenderer";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    getMuiTableBodyCellProps, getMuiTableBodyRowProps,
    getMuiTableHeadCellProps, getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {useTheme} from "@mui/material/styles";
import useApiGetChoicesByLot from "./api-hooks/useApiGetChoicesByLot";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {ILot} from "../../../../../../utils/interfaces/tannery_raw_material/ILot";
import {Avatar, Box, Popover} from "@mui/material";
import OrderScheduleTable from "../../../tannery_production/order_schedule/OrderScheduleTable";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import {IChoiceWithUsedPcs} from "../../../../../../utils/interfaces/tannery_raw_material/IChoiceWithUsedPcs";
import {Delete, Edit} from "@mui/icons-material";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import ChoicesForm from "./ChoicesForm";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import useApiDeleteChoice from "./api-hooks/useApiDeleteChoice";
import {useLocation} from "react-router-dom";
import RawMaterialForm from "../../../tannery_production/order_schedule/raw_material/RawMaterialForm";
import MuiBorderedBox from "../../../../../ui/mui/MuiBorderedBox";
import {GiCow} from "react-icons/gi";
import {FaLink} from "react-icons/fa";


interface Props {
    lot: ILot,
}

interface IChoiceWithCustomField extends IChoiceWithUsedPcs {
    custom_field: JSX.Element;
}

const ChoicesTable = ({lot: initialLot}: Props) => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isRawMaterialFormOpen, setRawMaterialFormOpen] = useState(false);
    const [openFilters, setOpenFilter] = useState<boolean>(true)

    const location = useLocation();
    const isInTanneryRawMaterial = location.pathname.startsWith('/apps/tannery_raw_material');
    const isInTanneryProduction = location.pathname.startsWith('/apps/tannery_production');

    const {selectedChoice, selectChoice, selectLot} = useTanneryRawMaterial()

    const [rows, setRows] = useState<IChoiceWithUsedPcs[] | undefined>()
    const [lot, setLot] = useState<ILot | undefined>(initialLot)

    const {data, isLoading, error, refetch} = useApiGetChoicesByLot(selectedCompany?.company_token, lot?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteChoice(selectedCompany?.company_token, selectedChoice?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({choice_id: selectedChoice?.id, company_token: selectedCompany?.company_token});
            await queryClient.invalidateQueries({queryKey: ['getLots']});
            await queryClient.invalidateQueries({queryKey: ['getChoicesByLot', selectedChoice?.id]});
            displayMessage('Choice deleted successfully');

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the Choice');
            }
        }
        setDeleteConfirmationOpen(false);
        await refetch()
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const [choicesWithCustomField, setChoicesWithCustomField] = useState<IChoiceWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IChoiceWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customCellRenderer(row, theme),
        }));
        setChoicesWithCustomField(dataWithCustomField);
    }, [rows]);

    useEffect(() => {
        setLot(lot);
        selectLot(lot);
    }, [initialLot]);


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    const totalPcs = useMemo(() => {
        return data?.reduce((acc, curr) => acc + (curr.pcs ?? 0), 0) ?? 0;
    }, [data]);

    const totalSqm = useMemo(() => {
        return data?.reduce((acc, curr) => acc + (curr.sqm ?? 0), 0) ?? 0;
    }, [data]);

    const totalRemainingPcs = useMemo(() => {
        return data?.reduce((acc, curr) => acc + ((curr.pcs - curr.used_pcs) ?? 0), 0) ?? 0;
    }, [data]);


    const columns = useMemo<MRT_ColumnDef<IChoiceWithUsedPcs>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'lot_id',
                header: t('choice_name'),

            },
            {
                accessorKey: 'pcs',
                header: t('pcs'),
                aggregationFn: 'sum',
                Footer: () => <div>{totalPcs.toFixed(2)}</div>,
            },
            {
                accessorKey: 'sqm',
                header: t('sqm'),
                aggregationFn: 'sum',
                Footer: () => <div>{totalSqm.toFixed(2)}</div>,
            },
            {
                accessorKey: 'total_value_eur',
                header: t('eur'),

            },
            {
                accessorFn: (row) => {
                    return row.sqm ? (row.sqm / row.pcs).toFixed(2) : '';
                },
                header: t('sqm/pcs'),
            },
            {
                accessorFn: (row) => {
                    return row.total_value_eur ? (row.total_value_eur / row.pcs).toFixed(2) : '';
                },
                header: t('eur/pcs'),
            },
            {
                accessorFn: (row) => {
                    return row.total_value_eur ? (row.total_value_eur / row.sqm).toFixed(2) : '';
                },
                header: t('eur/sqm'),
            },
            {
                accessorFn: (row) => {
                    return (row.pcs - row.used_pcs);
                },
                header: t('remaining_pieces'),
                aggregationFn: 'sum',
                Footer: () => <div>{totalRemainingPcs.toFixed(2)}</div>,
            },

        ],
        [data]
    );


    const table = useMaterialReactTable<IChoiceWithUsedPcs>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: choicesWithCustomField ? choicesWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enablePagination: false,
        enableFilters: false,
        enableColumnActions: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        initialState: {density: 'compact', pagination: {pageSize: 25, pageIndex: 0}},
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={() => {
                    selectChoice(row.original);
                    setIsEditFormOpen(true)
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    selectChoice(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<FaLink color={theme.palette.primary.main}/>}
                key="add_choice_to_order_schedule"
                label={t("add_choice_to_order_schedule")}
                onClick={(event) => {
                    selectChoice(row.original);
                    setAnchorEl(event.currentTarget);
                    setRawMaterialFormOpen(true);
                    closeMenu();
                }
                }
                table={table}
                disabled={!isInTanneryProduction}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <GiCow color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'choices'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            selectChoice(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_choice'}/>
                </>
            </Box>
        ),

        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('orders'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            row.original.order_schedules && row.original.order_schedules.length > 0 ? (
                <MuiBorderedBox>
                    <OrderScheduleTable orders_schedule={row.original.order_schedules}/>
                </MuiBorderedBox>
            ) : null,
    });


    return <>

        <>
            <MaterialReactTable table={table}/>

            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />

            <MuiModal
                top="50%"
                left="50%"
                width={'45vh'}
                open={isEditFormOpen}
                onClose={() => setIsEditFormOpen(false)}
            >
                <ChoicesForm
                    choiceToEdit={selectedChoice ? selectedChoice : null}
                    onClose={() => setIsEditFormOpen(false)}/>
            </MuiModal>

            <Popover
                id={'raw_material_popover'}
                open={isRawMaterialFormOpen}
                anchorEl={anchorEl}
                onClose={() => setRawMaterialFormOpen(false)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <RawMaterialForm choiceOrderToEdit={undefined}
                                 onClose={() => setRawMaterialFormOpen(false)}/>
            </Popover>

        </>
    </>;
};

export default ChoicesTable;
