import React, {useEffect} from 'react';
import MaxWidthDiv from "../../../../../ui/MaxWitdthDiv";

import {
    TanneryProductionProvider
} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import {
    TanneryRawMaterialProvider
} from "../../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";
import LwgWorkingsReports from "./LwgWorkingsReports";


const LwgWorkingsReportScreen = () => {


    return (
        <>

            <MaxWidthDiv maxWidth='1980px'>
                <TanneryRawMaterialProvider>
                    <TanneryProductionProvider>
                        <LwgWorkingsReports/>
                    </TanneryProductionProvider>
                </TanneryRawMaterialProvider>
            </MaxWidthDiv>


        </>
    );
};

export default LwgWorkingsReportScreen;
