import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IIncoterm} from "../../../../../../../utils/interfaces/my_documents/IIncoterm";


const useApiUpdateIncoterm = (): UseMutationResult<IIncoterm, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()

    const {put} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            pk: number | undefined,
                            data: any): Promise<IIncoterm> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/incoterm/${pk}/`, data);
            return response.data as IIncoterm;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IIncoterm, CustomAxiosError, {
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doUpdate(selectedCompany?.company_token, id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetIncoterm', data.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetIncoterms"]});
        },
    });
};


export default useApiUpdateIncoterm;