import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {ITransportCompany} from "../../../../../../../utils/interfaces/my_documents/ITransportCompany";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiUpdateTransportCompany = (): UseMutationResult<ITransportCompany, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()

    const {put} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            pk: number,
                            data: any): Promise<ITransportCompany> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/transport_company/${pk}/`, data);
            return response.data as ITransportCompany;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<ITransportCompany, CustomAxiosError, { id: number; data: any }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doUpdate(selectedCompany?.company_token, id, data);
        },
        onSuccess: async (obj) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetTransportCompany', obj.id]});
            await queryClient.invalidateQueries({queryKey: ['useApiGetTransportCompanies']});
        },
    });
};


export default useApiUpdateTransportCompany;

// note_marco ['update con auto invalidation e ritorno dell'oggetto']