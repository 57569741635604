import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {ITanneryDyeingRecipe} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingRecipe";


const getIconsForCustomField = (row: ITanneryDyeingRecipe, theme: any) => {
    const icons = [];


    icons.push(
        // (row.pcs - row.used_pcs < 0) ? (
        //     <FaSkull
        //         // color='60b8c3'
        //         color={theme.palette.warning.main}
        //         title="USED MORE PCS THAN AVAILABLE"
        //     />
        // ) : (
        //     <FaSkull
        //         color='transparent'
        //         title=''
        //     />
        // ),
        // (row.order_schedules && row.order_schedules.length > 0) ? (
        //     <AiOutlineBorderlessTable
        //         // color='60b8c3'
        //         color={theme.palette.primary.main}
        //         title="ORDERS INSIDE"
        //     />
        // ) : (
        //     <AiOutlineBorderlessTable
        //         color='transparent'
        //         title=''
        //     />
        // ),
        null
    );


    return icons;
};

export const customCellRenderer = (rowData: ITanneryDyeingRecipe, theme: any) => {
    const icons = getIconsForCustomField(rowData, theme);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customCellRenderer;
