import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {
    IVerificationPeriod
} from "../../../../../../utils/interfaces/lwg_dashboard/verification_period/IVerificationPeriod";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiGetVerificationPeriod = (): UseQueryResult<IVerificationPeriod, CustomAxiosError> => {
    const {selectedCompany} = useAuth()
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IVerificationPeriod> {
        try {
            const response = await get(`/api/company/${company_token}/lwg_dashboard/verification_period`);
            return response?.data[0];
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetVerificationPeriod"],
        queryFn: () => doGet(selectedCompany?.company_token),
    })
};

export default useApiGetVerificationPeriod