import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IDocDetail} from "../../../../../../../utils/interfaces/my_documents/doc/IDocDetail";

// async function doUpdacte(company_token: string | undefined,
//                          doc_year: number,
//                          pk: number,
//                          action: string,
// ): Promise<void> {
//
//     try {
//         await API.put(`/api/company/${company_token}/my_documents/doc/${doc_year}/${pk}/${action}/manage_lock`);
//     } catch (error) {
//         throw error;
//     }
// }


const useApiManageDocLock = (): UseMutationResult<void, CustomAxiosError, {
    doc_year: number | undefined,
    id: number | undefined;
    action: string;
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth();
    const {put} = useApiCalls();
    let _id: number | undefined;

    async function doUpdate(company_token: string | undefined,
                            doc_year: number | undefined,
                            pk: number | undefined,
                            action: string): Promise<void> {
        try {
            await put(`/api/company/${company_token}/my_documents/doc/${doc_year}/${pk}/${action}/manage_lock`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        doc_year: number | undefined,
        id: number | undefined;
        action: string
    }, unknown>({
        mutationFn: async ({doc_year, id, action}) => {
            _id = id;
            return await doUpdate(selectedCompany?.company_token, doc_year, id, action);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetDoc', _id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetDocs"]});

        },
    });
};


// const useApiManageDocLock = (initialPk: number | undefined): UseMutationResult<
//     void,
//     CustomAxiosError,
//     { pk: number; doc_year: number; action: string },
//     unknown
// > => {
//     const {selectedCompany} = useAuth();
//     const queryClient = useQueryClient();
//
//     return useMutation<void, CustomAxiosError, { pk: number; doc_year: number; action: string }>(
//         ({pk, doc_year, action}) => doUpdate(selectedCompany?.company_token, doc_year, pk, action),
//         {
//             onSuccess: () => {
//                 queryClient.invalidateQueries(['useApiGetDoc', initialPk]);
//                 queryClient.invalidateQueries('useApiGetDocs');
//             },
//         }
//     );
// };

export default useApiManageDocLock;
