import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {Box} from "@mui/material";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {IBank} from "../../../../../../utils/interfaces/my_documents/IBank";
import useApiCreateBank from "./api-hooks/useApiCreateBank";
import useApiUpdateBank from "./api-hooks/useApiUpdateBank";
import useApiGetBank from "./api-hooks/useApiGetBank";
import {useTranslation} from "react-i18next";


interface Props {
    objToEdit: IBank | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IBank | undefined) => void,
}

const BankForm: React.FC<Props> = ({objToEdit, onClose, onSelect}) => {
    const {displayError, displayMessage} = useToast();
    const {t} = useTranslation()

    const [code, setCode] = useState<string>();
    const [name, setName] = useState<string>();
    const [agency, setAgency] = useState<string>();
    const [abi, setAbi] = useState<string>();
    const [cab, setCab] = useState<string>();

    const createMutation = useApiCreateBank();
    const updateMutation = useApiUpdateBank();

    const {
        data: detailedObj,
    } = useApiGetBank(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setCode(detailedObj.code);
            setName(detailedObj.name);
            setAgency(detailedObj.agency);
            setAbi(detailedObj.abi);
            setCab(detailedObj.cab);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                code: code,
                name: name,
                agency: agency,
                abi: abi,
                cab: cab,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
                displayMessage(t('Updated successfully'));
            } else {
                newData = await createMutation.mutateAsync(payload);
                displayMessage(t('Added successfully'));
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (abi && cab) {
            setAllRequirementAreSatisfied(true);
        }
    }, [abi, cab]);


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setCode(e.target.value)}
                    label='code'
                    type="text"
                    value={code || ''}
                    required={false}
                />

                <MuiInputTextField
                    onChange={(e) => setName(e.target.value)}
                    label='name'
                    type="text"
                    value={name || ''}
                    required={false}
                />

                <MuiInputTextField
                    onChange={(e) => setAgency(e.target.value)}
                    label='agency'
                    type="text"
                    value={agency || ''}
                    required={false}
                />

                <MuiInputTextField
                    onChange={(e) => setAbi(e.target.value)}
                    label='abi'
                    type="text"
                    value={abi || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setCab(e.target.value)}
                    label='cab'
                    type="text"
                    value={cab || ''}
                    required={true}
                />

                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*{objToEdit &&*/}
                    {/*    <MuiFabButtonDelete onClick={() => {*/}
                    {/*        setConfirmationOpen(true)*/}
                    {/*    }}/>}*/}
                    {objToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>}
                </Box>
            </form>
        </Box>
    );
};

export default BankForm;