import React from 'react';
import {CSVLink} from 'react-csv';
import {ImSpinner2} from 'react-icons/im';

type Props = {
    onClick?: () => void,
    children?: React.ReactNode,
    type?: 'button' | 'submit',
    className?: string,
    isFetching?: boolean,
    csvData: any,
};

const CsvButton = ({children, type = 'button', className = '', onClick, isFetching, csvData}: Props) => {


    return (
        <>
            {isFetching ? (
                <div
                    className='flex bg-aGacidLightGreen text-white hover:bg-aGacidGreen px-5 py-2.5 rounded font-medium text-xs uppercase tracking-wider'>
                    <span className="mr-2">LOADING...</span>
                    <ImSpinner2 className="animate-spin text-white"/>
                </div>
            ) : (
                <CSVLink
                    data={csvData}
                    filename="exported-data.csv"
                    className=""

                >
                    <button
                        type={type}
                        className={`flex bg-aGacidLightGreen text-white hover:bg-aGacidGreen px-5 py-2.5 rounded font-medium text-xs uppercase tracking-wider ${className}`}
                    >
                        EXPORT CSV
                    </button>
                </CSVLink>
            )}
        </>
    );
};

export default CsvButton;
