import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";


const useApiCreateChoice = (company_token: string | undefined): UseMutationResult<void, CustomAxiosError, any, unknown> => {

    const {post} = useApiCalls()

    async function doCreate({company_token, data}: { company_token?: string, data: any }): Promise<void> {
        try {
            await post(`/api/company/${company_token}/tannery_raw_material/lots/choices/`, data);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, any>(
        {
            mutationFn: (data) => doCreate({company_token, data})
        }
    );
};

export default useApiCreateChoice;