import React, {useState} from 'react';
import MaxWidthDiv from "../../../ui/MaxWitdthDiv";
import {Box} from "@mui/material";
import MyCompanyWarehouseSelect from "../my_company/my_company_warehouse/MyCompanyWarehouseSelect";
import {IMyCompanyWarehouse} from "../../../../utils/interfaces/my_company/my_company_warehouse/IMyCompanyWarehouse";
import MyWarehouseProductsTable from "./MyWarehouseProducts/MyWarehouseProductsTable";


const MyCompanyScreen = () => {

    const [warehouse, setWarehouse] = useState<IMyCompanyWarehouse | undefined>()

    const handleWarehouseChange = (obj: IMyCompanyWarehouse | undefined) => {
        setWarehouse(obj)
    }

    return (
        <>

            <MaxWidthDiv maxWidth='1024px'>
                <Box sx={{marginTop: 2}}>
                    <Box sx={{py: 1}}>
                        <MyCompanyWarehouseSelect
                            obj={undefined}
                            onChange={handleWarehouseChange}
                            withDefaultValueIfEmpty={true}
                            label={'warehouses'} className={''}/>
                    </Box>

                    {warehouse && <MyWarehouseProductsTable warehouse={warehouse}/>}


                </Box>


            </MaxWidthDiv>


        </>
    );
};

export default MyCompanyScreen;
