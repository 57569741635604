import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from "@tanstack/react-query";


const useApiUpdateChoice = (companyToken: string | undefined): UseMutationResult<void, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {

    const {put} = useApiCalls()
    
    const doUpdate = async (company_token: string | undefined,
                            id: number,
                            data: any): Promise<void> => {

        try {
            await put(`/api/company/${company_token}/tannery_raw_material/lots/choices/${id}/update`, data);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        id: number;
        data: any;
    }>({
        mutationFn: ({id, data}) => doUpdate(companyToken, id, data)
    });

};

export default useApiUpdateChoice;