import React from 'react';
import {FaSkull} from "react-icons/fa";
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {ILwgSite} from "../../../../../utils/interfaces/tannery_raw_material/ILwgSite";
import {GiCow} from "react-icons/gi";
import {MdWorkspaces} from "react-icons/md";
import {BiLeaf} from "react-icons/bi";


const getIconsForCustomField = (row: ILwgSite, theme: any, t: any) => {
    const icons = [];
    const currentDate = new Date();
    const auditExpiryDate = new Date(row.audit_expiry_date);
    const timeDifference = auditExpiryDate.getTime() - currentDate.getTime();
    const daysDifference = timeDifference / (24 * 60 * 60 * 1000);
    const shouldShowSkullIcon = daysDifference <= 10;

    const icon_color = theme.palette.primary.main


    icons.push(
        row.audit_expiry_date && shouldShowSkullIcon ?
            <FaSkull className='animate-pulse text-red-600' title={t("has_record_zero")}/> :
            <FaSkull className='text-transparent' title={t("has_record_zero")}/>,
        <GiCow className=''
               color={row.used_in_period ? '#eab308' : 'transparent'}
               title={row.used_in_period ? 'used in lwg period' : ''}
        />,
        <MdWorkspaces className=''
                      color={row.has_worked_in_period ? '#eab308' : 'transparent'}
                      title={row.has_worked_in_period ? 'used in lwg period' : ''}
        />,
        <BiLeaf className=''
                color={row.coefficient > 0 ? icon_color : 'red'}
                title={row.coefficient > 0 ? 'is lwg' : 'is not lwg'}
        />
    );


    return icons;
};

export const customCellRenderer = (rowData: ILwgSite, theme: any, t: any) => {
    const icons = getIconsForCustomField(rowData, theme, t);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customCellRenderer;
