import React, {useState, useEffect} from 'react';
import {IIncoterm} from "../../../../../../utils/interfaces/my_documents/IIncoterm";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import useApiCreateIncoterm from "./api_hooks/useApiCreateIncoterm";
import useApiUpdateIncoterm from "./api_hooks/useApiUpdateIncoterm";
import useApiGetIncoterm from "./api_hooks/useApiGetIncoterm";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {Box} from "@mui/material";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";


interface Props {
    objToEdit: IIncoterm | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IIncoterm | undefined) => void,
}

const IncotermForm: React.FC<Props> = ({objToEdit, onClose, onSelect}) => {
    const {displayError, displayMessage} = useToast();

    const [isoCode, setIsoCode] = useState<string>();
    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();

    const createMutation = useApiCreateIncoterm();
    const updateMutation = useApiUpdateIncoterm();

    const {
        data: detailedObj,
    } = useApiGetIncoterm(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setName(detailedObj.name);
            setDescription(detailedObj.description);
            setIsoCode(detailedObj.iso_code);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                name: name,
                description: description,
                iso_code: isoCode,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
                displayMessage('Updated successfully');
            } else {
                newData = await createMutation.mutateAsync(payload);
                displayMessage('Added successfully');
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.detail) {
                    const detailError = axiosError.response.data.detail;
                    displayError(detailError);
                } else {
                    displayError('An error occurred while saving the object');
                }
            } else {
                displayError('An error occurred while saving the object');
            }
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (name && description && isoCode) {
            setAllRequirementAreSatisfied(true);
        }
    }, [description, isoCode, name]);


    return (
        <Box sx={{padding: 2}}>
            <form>

                <MuiInputTextField
                    onChange={(e) => setName(e.target.value)}
                    label='name'
                    type="text"
                    value={name || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setDescription(e.target.value)}
                    label='description'
                    type="text"
                    value={description || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setIsoCode(e.target.value)}
                    label='iso_code'
                    type="text"
                    value={isoCode || ''}
                    required={true}
                />

                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*{objToEdit &&*/}
                    {/*    <MuiFabButtonDelete onClick={() => {*/}
                    {/*        setConfirmationOpen(true)*/}
                    {/*    }}/>}*/}
                    {objToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>}
                </Box>
            </form>
        </Box>
    );
};

export default IncotermForm;