import React, {useContext, useState} from "react";
import {ICompany} from "../interfaces/accounts/ICompany";
import {IUser} from "../interfaces/accounts/IUser";


type ProviderProps = {
    children: React.ReactNode,
}

type AuthContextType = {
    token: string,
    refreshToken: string,
    signout: () => void,
    // doRefreshToken: () => Promise<boolean>,
    isAuthenticated: boolean,
    setIsAuthenticated: (isAuthenticated: boolean) => void,
    loadAuthenticationData: () => void,
    authenticatedUser: IUser | undefined,
    loadAuthenticatedUser: (authenticatedUser: IUser) => void,
    selectedCompany: ICompany | undefined,
    loadSelectedCompany: (selectedCompany: ICompany | undefined) => void,
    availableCompanies: ICompany[] | undefined,
    loadAvailableCompanies: (availableCompanies: ICompany[] | undefined) => void,
    userLevel: number,
    loadUserLevel: (userLevel: number) => void,
    endpoint: string | undefined,
    setEndpoint: (endpoint: string | undefined) => void,
    user: string | undefined,
    setUser: (user: string | undefined) => void,
    password: string | undefined,
    setPassword: (password: string | undefined) => void,
}


const authContext = React.createContext<AuthContextType | undefined>(undefined);


function useAuth() {
    const context = useContext(authContext);
    if (!context) {
        throw new Error('useAuth must be used within an authProvider');
    }
    return context;
}

function useProvideAuth() {
    const [token, setToken] = useState("");
    const [refreshToken, setRefreshToken] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authenticatedUser, setAuthenticatedUser] = useState<IUser | undefined>(undefined);
    const [selectedCompany, setSelectedCompany] = useState<ICompany | undefined>(undefined);
    const [availableCompanies, setAvaialableCompanies] = useState<ICompany[] | undefined>();
    const [userLevel, setUserLevel] = useState<number>(0)
    const [endpoint, setEndpoint] = useState(process.env.REACT_APP_ENDPOINT);
    const [user, setUser] = useState<string | undefined>()
    const [password, setPassword] = useState<string | undefined>()


    const loadAuthenticatedUser = (authenticatedUser: IUser | undefined) => {
        if (authenticatedUser) {
            setAuthenticatedUser(authenticatedUser);
        }
    }


    const loadSelectedCompany = (selectedCompany: ICompany | undefined) => {
        if (selectedCompany) {
            setSelectedCompany(selectedCompany);

            // endpoint switcher (switch only if in production)
            if (process.env.REACT_APP_ENDPOINT !== 'http://localhost:8000') {
                setEndpoint(selectedCompany.endpoint_backend_name);
            }

        } else {
            setSelectedCompany(undefined);
        }
    }


    const loadAvailableCompanies = (availableCompanies: ICompany[] | undefined) => {
        setAvaialableCompanies(availableCompanies);
    }

    const loadUserLevel = (userLevel: number) => {
        setUserLevel(userLevel);
    }


    const signout = () => {
        setToken('');
        setRefreshToken('');
        setIsAuthenticated(false);
        setAuthenticatedUser(undefined);
        setSelectedCompany(undefined);
        setEndpoint(process.env.REACT_APP_ENDPOINT);
        localStorage.clear();
    }

    // async function doRefreshToken(): Promise<boolean> {
    //     if (!refreshToken) {
    //         let refresh_saved = await localStorage.getItem('@refresh_token');
    //         if (refresh_saved) {
    //             await setRefreshToken(refresh_saved);
    //         }
    //     }
    //
    //     let response = await doPostRefreshToken(refreshToken);
    //     if (response) {
    //         let {data} = response.data;
    //         if (data.token && data.refresh_token) {
    //             localStorage.setItem('@access_token', data.token);
    //             localStorage.setItem('@refresh_token', data.refresh_token);
    //             setToken(data.token);
    //             setRefreshToken(data.refresh_token);
    //             return true;
    //         }
    //     }
    //
    //     return false;
    // }

    async function loadAuthenticationData() {
        const access = await localStorage.getItem('@access_token');
        const refresh = await localStorage.getItem('@refresh_token');

        if (access) await setToken(access);
        if (refresh) await setRefreshToken(refresh);
    }

    return {
        token,
        refreshToken,

        signout,
        // doRefreshToken,
        isAuthenticated,
        setIsAuthenticated,
        loadAuthenticationData,
        authenticatedUser,
        loadAuthenticatedUser,
        selectedCompany,
        loadSelectedCompany,
        availableCompanies,
        loadAvailableCompanies,
        userLevel,
        loadUserLevel,
        endpoint, setEndpoint,
        user, setUser,
        password, setPassword
    }
}

function ProvideAuth({children}: ProviderProps) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    )
}

export {
    ProvideAuth,
    useAuth
}