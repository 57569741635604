import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IOtherDestination} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/IOtherDestination";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiGetOtherDestination = (fiscal_registry_id: number | undefined, pk: number | undefined): UseQueryResult<IOtherDestination, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, fiscal_registry_id: number | undefined, pk: number | undefined): Promise<IOtherDestination> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/fiscal_registry/${fiscal_registry_id}/other_destination/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetOtherDestination", fiscal_registry_id, pk],
        enabled: !!fiscal_registry_id && !!pk,
        queryFn: () => doGet(selectedCompany?.company_token, fiscal_registry_id, pk),
    })
};

export default useApiGetOtherDestination