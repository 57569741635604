import React, {useEffect, useState} from 'react';
import {IMacroCategory} from "../../../../../../../utils/interfaces/tannery_production/articles/IMacroCategory";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import useApiGetMacroCategory from "./api-hooks/useApiGetMacroCategory";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import Loading from "../../../../../../ui/Loading";
import {Box} from "@mui/material";
import MuiAutocomplete from "../../../../../../ui/mui/mui-input/MuiAutoComplete";
import TextField from "@mui/material/TextField";


interface Props {
    category: IMacroCategory | undefined,
    onChange: (category: IMacroCategory | null) => void,
    label?: string,
    required?: boolean
}

const MacroCategorySelect = ({category: initialObj, onChange, label, required}: Props) => {
    const {displayError, displayMessage} = useToast()
    const {selectedCompany} = useAuth()
    const theme = useTheme();
    const {t} = useTranslation();

    const [objs, setObjs] = useState<IMacroCategory[]>([]);
    const [obj, setObj] = useState<IMacroCategory | null | undefined>(initialObj);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const {
        data,
        isLoading,
        error,
    } = useApiGetMacroCategory(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        setObj(initialObj);
    }, [initialObj]);


    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IMacroCategory | null) => {
        setObj(newValue);
        onChange(newValue);
    };


    return (
        isLoading ? (
            <Loading/>
        ) : (
            <>
                <Box sx={{alignItems: 'center', width: '100%'}}>
                    <MuiAutocomplete
                        id="macro_category-select"
                        options={objs}
                        getOptionLabel={(option) => option.category_name}
                        value={obj ? obj : null}
                        onChange={handleOnChange}
                        isOptionEqualToValue={(option, value) => option.id === value?.id && option.category_name === value?.category_name}
                        renderInput={(params) => (
                            <TextField
                                sx={{textTransform: 'capitalize'}}
                                {...params}
                                label={label || t('macro_category')}
                                required={required}
                            />
                        )}
                    />
                </Box>
            </>
        )
    );
}

export default MacroCategorySelect;