import Papa from 'papaparse';


export const convertToCsv = (
    jsonData: any[] | undefined,
    keysToInclude: string[],
    customLabels: string[]
): string => {
    if (!Array.isArray(jsonData)) {
        throw new Error("Invalid input data. Expected an array.");
    }

    const filteredCsvData: Record<string, any>[] = jsonData.map((item) => {
        const filteredItem: Record<string, any> = {};
        keysToInclude.forEach((key, index) => {
            const keyParts: string[] = key.split('.');
            let value: any = item;
            for (const keyPart of keyParts) {
                if (value && value.hasOwnProperty(keyPart)) {
                    value = value[keyPart];
                } else {
                    value = undefined;
                    break;
                }
            }
            // filteredItem[customLabels[index]] = value;
            filteredItem[customLabels[index]] = typeof value === 'number' ? value.toLocaleString('it-IT') : value;
        });
        return filteredItem;
    });

    return Papa.unparse(filteredCsvData, {
        header: true,
        delimiter: ';',
        decimalSeparator: ',',
    } as Papa.UnparseConfig);
};