import React from 'react';
import {TanneryProductionProvider} from "../../../../../utils/contexts/tannery_production/tannery_production-context";
import OrderSchedule from "./OrderSchedule";
import {
    TanneryRawMaterialProvider
} from "../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import {TanneryDyeingProvider} from "../../../../../utils/contexts/tannery_dyeing/tannery_dyeing-context";


const OrderScheduleScreen = () => {


    return (
        <>

            <MaxWidthDiv maxWidth='1980px'>
                <TanneryProductionProvider>
                    <TanneryDyeingProvider>
                        <TanneryRawMaterialProvider>
                            <OrderSchedule/>
                        </TanneryRawMaterialProvider>
                    </TanneryDyeingProvider>
                </TanneryProductionProvider>
            </MaxWidthDiv>


        </>
    );
};

export default OrderScheduleScreen;
