import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useToast} from '../../../../../utils/contexts/toast-context';
import {CustomAxiosError} from '../../../../../logics/api_config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableCell from '@mui/material/TableCell';
import {BodyTableCell, BodyTableNumberCell, HeaderTableCell} from "../../../../ui/mui/mui_table/TableCells";
import {CustomTableRow} from "../../../../ui/mui/mui_table/CustomTableRow";
import StyledTablePagination from "../../../../ui/mui/mui_table/StyledTablePagination";
import {formatDateTime} from "../../../../../logics/helpers";
import Loading from "../../../../ui/Loading";
import {GiBarrel} from "react-icons/gi";
import {RxHand} from "react-icons/rx";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import {IChemicalProductMovement} from "../../../../../utils/interfaces/chemicals_products/IChemicalProductMovement";
import useApiGetChemicalProductMovements from "./api-hooks/useApiGetChemicalProductMovements";
import MuiFabButtonAdd from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonAdd";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import ManualChemicalProductsMovementForm from "./ManualChemicalProductsMovementForm";
import MuiScrollableBox from "../../../../ui/mui/MuiScrollableBox";
import {Box} from "@mui/material";
import useApiGetIncoterm from "../../my_documents/fiscal_registry/incoterm/api_hooks/useApiGetIncoterm";
import useApiGetChemicalProduct from "./api-hooks/useApiGetChemicalProduct";
import {useService} from "../../../../../utils/contexts/service/service-context";

interface Props {
    product_id: number
    onClose: () => void;
    onRefetch: () => void;
    until_timestamp: number | undefined,
    from_timestamp: number | undefined,
    to_timestamp: number | undefined
}

const ChemicalProductsMovementsTable = ({
                                            product_id,
                                            onClose,
                                            onRefetch,
                                            until_timestamp,
                                            from_timestamp,
                                            to_timestamp
                                        }: Props) => {
    const {t} = useTranslation();
    const {displayError} = useToast();


    const [isManualMovementFormOpen, setIsManualMovementFormOpen] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<IChemicalProductMovement | null>(null);


    const handleCloseManualMovementForm = () => {
        setIsManualMovementFormOpen(false);
        onRefetch();
    };

    const handleEditRow = (row: IChemicalProductMovement) => {
        setSelectedRow(row);
        if (row.movement_type === "manual") {
            setIsManualMovementFormOpen(true);
        }
    };

    const handleCreateManualMovement = () => {
        setSelectedRow(null);
        setIsManualMovementFormOpen(true);
    };


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const {
        data: detailedChemicalProduct,
    } = useApiGetChemicalProduct(product_id, until_timestamp, to_timestamp, from_timestamp);


    const {
        data,
        isLoading,
        error,
        refetch: refetchChemicalProductMovement
    } = useApiGetChemicalProductMovements(product_id, until_timestamp, from_timestamp, to_timestamp);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    const [filteredData, setFilteredData] = useState(data);
    useEffect(() => {
        if (data) {
            setFilteredData(data);
        }
    }, [data]);

    useEffect(() => {
        refetchChemicalProductMovement();
    }, [until_timestamp, from_timestamp, to_timestamp]);

    const maxTableHeight = 650
    const dynamicMaxHeight = '80vh';

    return (
        isLoading ? <Loading/> :
            <>
                <MuiScrollableBox maxHeight={dynamicMaxHeight}>
                    <TableContainer sx={{maxHeight: maxTableHeight}}>
                        <Table stickyHeader aria-label="sticky table" size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <HeaderTableCell>{t('')}</HeaderTableCell>
                                    <HeaderTableCell>{t('movement_date')}</HeaderTableCell>
                                    <HeaderTableCell textAlign={'right'}>{t('total_kgs')}</HeaderTableCell>
                                    <HeaderTableCell>{t('description_alias')}</HeaderTableCell>
                                    <HeaderTableCell textAlign={'right'}>{t('balance')}</HeaderTableCell>
                                    <HeaderTableCell textAlign={'right'}>{t('value_balance')}</HeaderTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData
                                    ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                        <CustomTableRow
                                            key={index}
                                            onClick={() => {
                                                handleEditRow(row)
                                            }}
                                        >
                                            <BodyTableCell>
                                                {(() => {
                                                    switch (row.movement_type) {
                                                        case 'tannery_dyeing':
                                                            return <GiBarrel className={'text-aGpetrol'}/>;
                                                        case 'manual':
                                                            return <RxHand className={'text-aGpetrol'}/>;
                                                        default:
                                                            return null; // You can return a default icon or nothing if no match
                                                    }
                                                })()}
                                            </BodyTableCell>
                                            <BodyTableCell>{formatDateTime(row.movement_date)}</BodyTableCell>
                                            <BodyTableNumberCell>{row.total_kgs.toFixed(2)}</BodyTableNumberCell>
                                            <BodyTableCell>{row.description_alias}</BodyTableCell>
                                            <BodyTableNumberCell>{row.balance.toFixed(2)} kgs</BodyTableNumberCell>
                                            <BodyTableNumberCell>{row.total_value_balance.toFixed(2)} €</BodyTableNumberCell>
                                        </CustomTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <StyledTablePagination
                        count={filteredData ? filteredData.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </MuiScrollableBox>
                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    <MuiFabButtonAdd onClick={handleCreateManualMovement} label={'add_manual_product_movement'}/>
                </Box>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'50vh'}
                    open={isManualMovementFormOpen}
                    onClose={handleCloseManualMovementForm}
                >
                    <ManualChemicalProductsMovementForm
                        objToEdit={selectedRow}
                        chemicalProductDetail={detailedChemicalProduct}
                        onClose={handleCloseManualMovementForm}
                    />
                </MuiModal>
            </>

    );
};

export default ChemicalProductsMovementsTable;
