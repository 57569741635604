import React, {useEffect, useState} from 'react';
import {useToast} from '../../../../../../utils/contexts/toast-context';
import {CustomAxiosError} from '../../../../../../logics/api_config';
import {useQueryClient} from '@tanstack/react-query';
import {IDocDetail} from "../../../../../../utils/interfaces/my_documents/doc/IDocDetail";
import useApiUpdateDoc from "./api-hooks/useApiUpdateDoc";
import {IDocCompanyDocType} from "../../../../../../utils/interfaces/my_documents/doc_type/IDocCompanyDocType";
import DocTypeSelect from "../doc_type/DocTypeSelect";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";
import {IDocCompanyDocReason} from "../../../../../../utils/interfaces/my_documents/doc_reason/IDocCompanyDocReason";
import DocReasonSelect from "../doc_reason/DocReasonSelect";
import FiscalRegistrySelect from "../../fiscal_registry/FiscalRegistrySelect";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import RegistrySelect from "../../fiscal_registry/registry/RegistrySelect";
import {IRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IRegistry";
import useApiGetDoc from "./api-hooks/useApiGetDoc";
import {IDocList} from "../../../../../../utils/interfaces/my_documents/doc/IDocList";
import useApiManageDocLock from "./api-hooks/useApiManageDocLock";
import {useService} from "../../../../../../utils/contexts/service/service-context";
import useApiGetNextDocNumber from "./api-hooks/useApiGetNextDocNumber";
import useApiCreateDoc from "./api-hooks/useApiCreateDoc";
import {ICurrency} from "../../../../../../utils/interfaces/my_documents/ICurrency";
import CurrencySelect from "../../CurrencySelect";
import {useMyDocuments} from "../../../../../../utils/contexts/my_documents/my_documents-context";
import EnterOrCloseChooser from "../../../../../ui/EnterOrCloseChooser";
import {useTranslation} from "react-i18next";
import DocTransportForm from "./doc_transport/DocTransportForm";
import MuiButton from "../../../../../ui/mui/mui_buttons/MuiButton";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import {Box, Grid} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import DocRowTable from "./doc_row/DocRowTable";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiBorderedBox from "../../../../../ui/mui/MuiBorderedBox";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import useApiGetCurrencyDetailed from "../../api-hooks/useApiGetCurrencyDetailed";
import PaymentForm from "../../fiscal_registry/payment/PaymentForm";
import PaymentCard from "../../fiscal_registry/payment/PaymentCard";
import PaymentIcon from '@mui/icons-material/Payment';
import DocPaymentTable from "./doc_payment/DocPaymentTable";
import BankCard from "../../fiscal_registry/bank/BankCard";
import {IStatementOfIntent} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IStatementOfIntent";
import StatementOfIntentSelect from "../../fiscal_registry/statement_of_intent/StatementOfIntentSelect";
import useApiUpdateDocRowsVatCode from "./api-hooks/useApiUpdateDocRowsVatCode";


interface Props {
    docNotDetailed: IDocList | undefined;
    onClose: () => void;
    isOpen: boolean,
}

const DocForm: React.FC<Props> = ({docNotDetailed: initialDocNotDetailed, onClose, isOpen}) => {
    const {displayMessage, displayWarning} = useToast();
    const {t} = useTranslation()
    const queryClient = useQueryClient();
    const {selectFiscalRegistry, selectPerson} = useService()
    const {selectedSystemDate, selectedSystemYear} = useAppGarage()
    const {
        selectedDocVersion,
        selectDocVersion,
        selectDocDetail,
        selectedDocDetail,
    } = useMyDocuments()


    const [docNumber, setDocNumber] = useState<number | undefined>()
    const [docPrefix, setDocPrefix] = useState<string | undefined>()
    const [docType, setDocType] = useState<IDocCompanyDocType | undefined>()
    const [currency, setCurrency] = useState<ICurrency | undefined>()
    const [currencyExchange, setCurrencyExchange] = useState<number>(1)
    const [docYear, setDocYear] = useState<number | undefined>()
    const [docDate, setDocDate] = useState<string | undefined>()
    const [docReason, setDocReason] = useState<IDocCompanyDocReason | undefined>()
    const [fiscalRegistry, setFiscalRegistry] = useState<IFiscalRegistry | undefined>()
    const [registry, setRegistry] = useState<IRegistry | undefined>()
    const [docPayment, setDocPayment] = useState<string | undefined>()
    const [detailedDocPayment, setDetailedDocPayment] = useState<string | undefined>()
    const [statementOfIntent, setStatementOfIntent] = useState<IStatementOfIntent | undefined>()

    const [docDetailed, setDocDetailed] = useState<IDocDetail | undefined>()
    const [docNotDetailed, setDocNotDetailed] = useState<IDocList | undefined>()

    const [allRequirementsAreSatisfied, setAllRequirementsAreSatisfied] = useState<boolean>(false)

    const [hasBeenModifiedByAnother, setHasBeenModifiedByAnother] = useState<boolean>(false)
    const [hasBeenLockedForInvoicing, setHasBeenLockedForInvoicing] = useState<boolean>(false)
    const [hasBeenInvoiced, setHasBeenInvoiced] = useState<boolean>(false)
    const [enterChooserOpen, setEnterChooserOpen] = useState(false);
    const [isDocTransportFormOpen, setIsDocTransportFormOpen] = useState(false);
    const [isDocPaymentTableOpen, setIsDocPaymentTableOpen] = useState(false);
    const [isPaymentFormOpen, setIsPaymentFormOpen] = useState(false);

    const updateDocRowsVatCodeMutation = useApiUpdateDocRowsVatCode()
    const updateMutation = useApiUpdateDoc()
    const createMutation = useApiCreateDoc()
    const manageDocLockMutation = useApiManageDocLock();

    const handleClosePaymentForm = () => {
        if (docDetailed?.company_doc_type.doc_type.requires_doc_payment) {
            // displayWarning(t('You must save the Doc to update DocPayments'))
        }
        setIsPaymentFormOpen(false)
    }

    const handleCurrencyChange = (obj: ICurrency | undefined) => {
        setCurrency(obj);
    };

    const handleCompanyDocTypeChange = (companyDocType: IDocCompanyDocType | undefined) => {
        setDocType(companyDocType);
    };

    const handleCompanyDocReasonChange = (companyDocReason: IDocCompanyDocReason | undefined) => {
        setDocReason(companyDocReason);
    };

    // suggest fiscalRegistry settings if it changes
    const handleFiscalRegistryChange = (fiscalRegistry: IFiscalRegistry | undefined) => {
        selectPerson(fiscalRegistry?.people);
        setFiscalRegistry(fiscalRegistry);
        setRegistry(fiscalRegistry?.registry);
        if (docDetailed?.fiscal_registry !== fiscalRegistry) {
            setDocPayment(fiscalRegistry?.doc_payment);
            setDetailedDocPayment(fiscalRegistry?.detailed_doc_payment)
            setStatementOfIntent(fiscalRegistry?.statement_of_intent)
        }
    };


    const handleDocPaymentChange = (_docPayment: string | undefined, _detailedDocPayment: string | undefined) => {
        setDocPayment(_docPayment)
        setDetailedDocPayment(_detailedDocPayment)
        handleSubmitUpdate(false, _docPayment);
    }

    const handleStatementOfIntentChange = async (_statementOfIntent: IStatementOfIntent | undefined) => {
        setStatementOfIntent(_statementOfIntent)
        if (docDetailed && docDetailed.statement_of_intent !== _statementOfIntent) {
            handleSubmitUpdate(false, docPayment, _statementOfIntent ? _statementOfIntent.id : null);
            if (docDetailed) {
                await updateDocRowsVatCodeMutation.mutateAsync({
                    id: docDetailed.id,
                    doc_year: docDetailed.doc_year,
                    vat_code_id: _statementOfIntent ? 2 : fiscalRegistry?.default_vat_code.id
                });
                // selectDocVersion(selectedDocVersion + 1)
                await queryClient.invalidateQueries({queryKey: ['useApiGetDocRows', docDetailed.id]});
            }

        }
    }


    const handleRegistryChange = (registry: IRegistry | undefined) => {
        setRegistry(registry);
    };


    const handleClose = async () => {
        await manageDocLockMutation.mutateAsync({
            id: docDetailed?.id,
            doc_year: docDetailed?.doc_year,
            action: 'unlock'
        });
        setDocNotDetailed(undefined);
        selectDocDetail(undefined);
        setIsDocTransportFormOpen(false);
        // selectDocVersion(undefined);
        onClose()
    };

    const handleSubmitCreate = async () => {
        try {
            const payload = {
                doc_number: docNumber,
                doc_prefix: docPrefix,
                doc_type_id: docType?.id,
                doc_year: docYear,
                doc_date: docDate,
                doc_reason_id: docReason?.id,
                fiscal_registry: fiscalRegistry?.id,
                registry: registry?.id,
                version: selectedDocVersion,
                currency_id: currency?.id,
                currency_exchange: currencyExchange,
                doc_payment: docPayment,
                statement_of_intent: statementOfIntent ? statementOfIntent.id : null,
            };

            let newData;

            newData = await createMutation.mutateAsync({doc_year: docYear, data: payload});
            displayMessage('Doc created successfully');
            setDocDetailed(newData);
            selectDocVersion(newData.version)
        } catch (error) {

        }

    };

    const handleSubmitUpdate = async (andClose: boolean = true,
                                      _docPayment: string | undefined = undefined,
                                      _statementOfIntentId: number | null | undefined = undefined
    ) => {

        let statementOfIntentId = _statementOfIntentId !== undefined ? _statementOfIntentId : statementOfIntent ? statementOfIntent.id : null;


        try {
            const payload = {
                doc_number: docNumber,
                doc_prefix: docPrefix,
                doc_type_id: docType?.id,
                doc_year: docYear,
                doc_date: docDate,
                doc_reason_id: docReason?.id,
                fiscal_registry: fiscalRegistry?.id,
                registry: registry?.id,
                version: selectedDocVersion,
                currency_id: currency?.id,
                currency_exchange: currencyExchange,
                doc_payment: _docPayment ? _docPayment : docPayment,
                statement_of_intent: statementOfIntentId,

                andClose: andClose
            };

            let newData;

            if (docDetailed !== null && docDetailed !== undefined) {
                newData = await updateMutation.mutateAsync({
                    id: docDetailed.id,
                    doc_year: docDetailed.doc_year,
                    data: payload
                });
                displayMessage('Doc updated successfully');
                selectDocVersion(selectedDocVersion + 1)


                if (andClose) {
                    if (newData?.company_doc_type.doc_type.requires_doc_transport) {
                        setIsDocTransportFormOpen(true);
                    } else {
                        await handleClose();
                    }
                }
            }
        } catch (error) {
        }
    };


    const {data: nextDocNumber, error: errorNexDocNumber} = useApiGetNextDocNumber(docPrefix, docType?.id, docYear);
    if (errorNexDocNumber) {
        const axiosError = errorNexDocNumber as CustomAxiosError;
    }

    useEffect(() => {
        console.log('nextDocNumber:', docPrefix, docType?.id, docYear, nextDocNumber)
    }, [nextDocNumber]);


    useEffect(() => {
        if (nextDocNumber && !docDetailed) {
            setDocNumber(nextDocNumber.next_doc_number)
        }
    }, [nextDocNumber]);


    useEffect(() => {
        const queryKey = ['getNextDocNumber'];
        queryClient.invalidateQueries({queryKey});
    }, [docType?.id, docPrefix, docYear]);


    const {data, error} = useApiGetDoc(docNotDetailed?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;

    }


    useEffect(() => {
        if (data) {
            setDocDetailed(data);
        } else {
            if (selectedSystemYear) {
                setDocPrefix(selectedSystemYear.toString().slice(2, 4))
                setDocYear(selectedSystemYear)
                setDocDate(selectedSystemDate)
            }
        }
    }, [data]);

    // lock the document if has been modified by another
    useEffect(() => {
        // console.log('docDetailed?.version: ', docDetailed?.version)
        // console.log('selectedDocVersion:', selectedDocVersion)
        let timer: NodeJS.Timeout;

        const checkDocVersion = () => {
            setHasBeenModifiedByAnother(false);
            if (docDetailed && selectedDocVersion !== docDetailed?.version) {
                setHasBeenModifiedByAnother(true);
            }
        };

        timer = setTimeout(checkDocVersion, 2000);

        return () => {
            clearTimeout(timer);
        };
    }, [docDetailed]);


    useEffect(() => {
        if (docDetailed && !selectedDocDetail) {
            selectDocDetail(docDetailed);
            selectPerson(docDetailed.fiscal_registry.people)
            setDocNumber(docDetailed.doc_number);
            setDocPrefix(docDetailed.doc_prefix);
            setDocType(docDetailed.company_doc_type);
            setDocYear(docDetailed.doc_year);
            setDocDate(docDetailed.doc_date);
            setDocReason(docDetailed.company_doc_reason);
            setFiscalRegistry(docDetailed.fiscal_registry)
            setRegistry(docDetailed.registry)
            selectFiscalRegistry(docDetailed.fiscal_registry)
            setCurrency(docDetailed.currency)
            setCurrencyExchange(docDetailed.currency_exchange)
            setDocPayment(docDetailed.doc_payment)
            setDetailedDocPayment(docDetailed.detailed_doc_payment)
            setStatementOfIntent(docDetailed.statement_of_intent);
        }
    }, [docDetailed]);


    const {
        data: defaultCurrency,
    } = useApiGetCurrencyDetailed(1);

    useEffect(() => {
        if (!initialDocNotDetailed) {
            selectDocDetail(undefined);
            // setDocNumber(undefined);
            setDocType(undefined);
            setDocReason(undefined);
            setFiscalRegistry(undefined)
            setRegistry(undefined)
            selectFiscalRegistry(undefined)
            setStatementOfIntent(undefined);
            setCurrency(defaultCurrency);
        }
    }, [initialDocNotDetailed]);


    // lock the document if it's free
    useEffect(() => {
        if (docDetailed) {
            if (!docDetailed.locked_at && !docDetailed.locked_by_me && !docDetailed.invoiced_at) {
                if (docDetailed) {
                    console.log('lock because it is free')
                    setEnterChooserOpen(false);
                    manageDocLockMutation.mutateAsync({
                        id: docDetailed.id,
                        doc_year: docDetailed.doc_year,
                        action: 'lock'
                    });
                }
            }
        }
    }, [docDetailed?.locked_at]);


    // ask for lock if the document is not free
    useEffect(() => {
        if (docDetailed) {
            if (docDetailed.locked_at && !docDetailed.locked_by_me) {
                if (docDetailed) {
                    setEnterChooserOpen(true);
                }
            }
        }
    }, [docDetailed?.locked_at]);

    // open wait modal if the document is locked for invoicing
    useEffect(() => {
        if (docDetailed) {
            docDetailed.lock_for_invoicing_at ? setHasBeenLockedForInvoicing(true) : setHasBeenLockedForInvoicing(false)
            docDetailed.lock_for_invoicing_at ? setEnterChooserOpen(true) : setEnterChooserOpen(false)
        }
    }, [docDetailed?.lock_for_invoicing_at]);

    useEffect(() => {
        if (docDetailed) {
            docDetailed.invoiced_at ? setHasBeenInvoiced(true) : setHasBeenInvoiced(false)
            docDetailed.invoiced_at ? setEnterChooserOpen(true) : setEnterChooserOpen(false)
        }
    }, [docDetailed?.invoiced_at]);


    const handleEnterChooserClose = (choose: boolean) => {
        setEnterChooserOpen(false);
        if (choose !== null) {
            if (!choose && docDetailed) {
                manageDocLockMutation.mutateAsync({
                    id: docDetailed.id,
                    doc_year: docDetailed.doc_year,
                    action: 'lock'
                });
            }
            if (choose && docDetailed) {
                handleClose()
            }
        }
    };


    useEffect(() => {
        if (initialDocNotDetailed) {
            setDocNotDetailed(initialDocNotDetailed)
        } else {
            setDocNotDetailed(undefined);
            setDocDetailed(undefined);
        }
    }, [initialDocNotDetailed]);


    useEffect(() => {
        if (docDetailed) {
            if (docDetailed.lock_duration > 0 && !docDetailed.locked_by_me) {
                setEnterChooserOpen(true);
            }
        }
    }, [docDetailed?.locked_at]);


    useEffect(() => {
        if (initialDocNotDetailed) {
            selectDocVersion(initialDocNotDetailed.version)
        }
    }, [initialDocNotDetailed]);


    useEffect(() => {
        setAllRequirementsAreSatisfied(false)
        if (docYear && docPrefix && docType && registry && fiscalRegistry && docReason && currency) {
            setAllRequirementsAreSatisfied(true)
        }
    }, [docPrefix, docType, docYear, selectedDocVersion, registry, fiscalRegistry, docReason, currency, docNotDetailed, hasBeenModifiedByAnother]);

    // useEffect(() => {
    //     console.log('-- selectedDocVersion', selectedDocVersion)
    // }, [selectedDocVersion]);

    // console.log('initialDocNotDetailed', initialDocNotDetailed)
    // console.log('docDetailed', docDetailed)

    // useEffect(() => {
    //     console.log(docPayment)
    //     console.log(detailedDocPayment)
    // }, [docPayment]);


    return (
        <>
            {isOpen && (
                <Box sx={{display: 'flex-1', flexDirection: 'row', padding: 2, justifyContent: 'center'}}>
                    {/*<Grid item xs={12} style={{textAlign: 'center'}}>*/}
                    {/*    <Box className={'mt-0 gap-x-2 mb-4'}>*/}
                    {/*        <MuiFabButtonBack onClick={onClose}/>*/}
                    {/*    </Box>*/}
                    {/*</Grid>*/}

                    <form encType="multipart/form-data">
                        <MuiBorderedBox>
                            <Grid container spacing={2} sx={{
                                padding: 2,
                                gap: 1,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                            }}>
                                <Box sx={{display: 'flex-1',}}>
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginTop: 1,
                                        marginBottom: 1,
                                        gap: 1
                                    }}>

                                        <MuiInputTextField
                                            label="doc_date"
                                            type="date"
                                            value={docDate || selectedSystemDate}
                                            onChange={(e) => setDocDate(e.target.value)}
                                            required={true}
                                        />

                                        <MuiInputTextField
                                            label="doc_year"
                                            type="number"
                                            value={docYear || selectedSystemYear}
                                            onChange={(e) => setDocYear(parseInt(e.target.value))}
                                            required={true}
                                        />

                                        <MuiInputTextField
                                            label="doc_number"
                                            type="number"
                                            value={docNumber}
                                            onChange={(e) => setDocNumber(parseInt(e.target.value))}
                                            required={true}
                                        />

                                        <label style={{margin: '0 5px'}}> / </label>

                                        <MuiInputTextField
                                            label="doc_prefix"
                                            type="text"
                                            value={docPrefix}
                                            onChange={(e) => setDocPrefix(e.target.value)}
                                            required={true}
                                        />
                                    </Box>

                                    <Box sx={{
                                        display: 'flex-1',
                                        flexDirection: 'column',
                                        alignItems: 'flex-end',
                                        border: '1px solid lightgrey',
                                        borderRadius: '10px',
                                        padding: '10px',
                                    }}>
                                        <DocTypeSelect
                                            label={'doc_type.name'}
                                            doc_type={docType}
                                            onChange={handleCompanyDocTypeChange}
                                            className={''}
                                            required={true}
                                            clearable={false}
                                        ></DocTypeSelect>

                                        <DocReasonSelect
                                            label={'doc_reason.name'}
                                            doc_reason={docReason}
                                            onChange={handleCompanyDocReasonChange}
                                            className={'mt-1'}
                                            required={true}
                                            clearable={false}
                                        ></DocReasonSelect>
                                        <StatementOfIntentSelect
                                            label="select a statement of intent"
                                            onChange={handleStatementOfIntentChange}
                                            className=""
                                            obj={statementOfIntent}
                                            fiscal_registry={fiscalRegistry}
                                        />
                                    </Box>


                                </Box>


                                <Box sx={{
                                    display: 'flex-1',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    border: '1px solid lightgrey',
                                    borderRadius: '10px',
                                    padding: '10px',
                                }}>
                                    <FiscalRegistrySelect
                                        label={'customer'}
                                        fiscal_registry={fiscalRegistry}
                                        onChange={handleFiscalRegistryChange}
                                        className={''}
                                        required={true}
                                    ></FiscalRegistrySelect>
                                    <RegistrySelect
                                        label={'registry'}
                                        registry={registry}
                                        fiscal_registry={fiscalRegistry}
                                        onChange={handleRegistryChange}
                                        className={'mt-1'}
                                        required={true}
                                    ></RegistrySelect>
                                </Box>


                                <Box sx={{
                                    display: 'flex-1',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    border: '1px solid lightgrey',
                                    borderRadius: '10px',
                                    padding: '10px',
                                }}>
                                    <CurrencySelect
                                        label={'currency'}
                                        obj={currency}
                                        onChange={handleCurrencyChange}
                                        className={''}
                                        required={true}
                                    ></CurrencySelect>

                                    <MuiInputTextField
                                        label="currency_exchange"
                                        type="number"
                                        value={currencyExchange}
                                        onChange={(e) => setCurrencyExchange(parseFloat(e.target.value))}
                                        required={true}
                                    />

                                    {docDetailed?.doc_transport &&
                                        <Box className={'mt-1.5'}>
                                            <MuiButton
                                                onClick={() => setIsDocTransportFormOpen(true)}>{t('doc_transport')}</MuiButton>
                                        </Box>
                                    }
                                </Box>

                                <Box sx={{
                                    display: 'flex-1',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    border: '1px solid lightgrey',
                                    borderRadius: '10px',
                                    padding: '10px',
                                }}>
                                    <Box className="gap-x-2"
                                         style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
                                        <Box className="gap-x-2" style={{display: 'flex', gap: '0.5rem'}}>
                                            <MuiButton
                                                onClick={() => setIsPaymentFormOpen(true)}>{t('doc_payment')}</MuiButton>
                                            {docDetailed?.doc_payments && docDetailed?.doc_payments.length > 0 &&
                                                <>
                                                    <MuiButton onClick={() => setIsDocPaymentTableOpen(true)}>
                                                        <PaymentIcon className={'mr-2'}/>
                                                        {t('payments')}
                                                    </MuiButton>
                                                </>
                                            }
                                        </Box>
                                        {detailedDocPayment &&
                                            <PaymentCard obj={detailedDocPayment}/>}
                                        {docDetailed?.fiscal_registry.bank &&
                                            <BankCard obj={docDetailed?.fiscal_registry.bank}/>}
                                    </Box>
                                </Box>


                            </Grid>
                        </MuiBorderedBox>


                        <MuiBorderedBox>
                            <DocRowTable docDetailed={docDetailed}/>
                        </MuiBorderedBox>


                        <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                            <MuiFabButtonBack onClick={handleClose}/>
                            {docDetailed ?
                                <MuiFabButtonSaveAs onClick={() => handleSubmitUpdate(true)}
                                                    isDisabled={!allRequirementsAreSatisfied}/>
                                :
                                <MuiFabButtonSave onClick={handleSubmitCreate}
                                                  isDisabled={!allRequirementsAreSatisfied}/>}
                        </Box>
                    </form>

                    <MuiAlertDialog
                        open={hasBeenModifiedByAnother}
                        title="dock_modified_by_other_user"
                        content={docDetailed?.invoiced_at ? t('The document is invoiced. No modifications can be made here.') : t('dock_modified_by_other_user') + docDetailed?.last_update_by_user_name}
                        onClose={handleClose}
                        //onConfirm={handleClose}
                    />


                    <EnterOrCloseChooser
                        isOpen={enterChooserOpen && !hasBeenModifiedByAnother}
                        hasBeenLockedForInvoicing={hasBeenLockedForInvoicing}
                        hasBeenInvoiced={hasBeenInvoiced}
                        onClose={handleEnterChooserClose}
                        user={docDetailed?.locked_by_user_name}
                    />


                    <MuiModal top="50%" left="50%" width={'100vh'} open={isDocTransportFormOpen}
                              onClose={() => setIsDocTransportFormOpen(false)}>
                        <DocTransportForm objToEdit={docDetailed?.doc_transport}
                            // onClose={!docDetailed?.doc_transport ? () => handleClose() : () => setIsDocTransportFormOpen(false)}
                                          onClose={handleClose}
                                          doc={docDetailed}/>
                    </MuiModal>

                    <MuiModal top="50%" left="50%" width={'50vh'} open={isPaymentFormOpen}
                              onClose={() => setIsPaymentFormOpen(false)}
                              disableBackdropClick={true}
                    >
                        <PaymentForm doc_payment={docPayment}
                                     onChange={handleDocPaymentChange}
                                     onClose={handleClosePaymentForm}
                        />
                    </MuiModal>

                    <MuiModal top="50%" left="50%" width={'120vh'} open={isDocPaymentTableOpen}
                              onClose={() => setIsDocPaymentTableOpen(false)}
                              disableBackdropClick={true}
                    >
                        {docDetailed &&
                            <DocPaymentTable doc={docDetailed} onClose={() => setIsDocPaymentTableOpen(false)}/>}
                    </MuiModal>

                </Box>)}
        </>
    );
};

export default DocForm;
