import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {
    IMyCompanyBank
} from "../../../../../../../utils/interfaces/my_company/my_company_registry/my_company_bank/IMyCompanyBank";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiCreateMyCompanyBank = (): UseMutationResult<IMyCompanyBank, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<IMyCompanyBank> {
        try {
            const response = await post(`/api/company/${companyToken}/my_company/my_company_registry/bank`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IMyCompanyBank, CustomAxiosError, any>(
        {
            mutationFn: (
                data
            ) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetMyCompanyBanks"]});
            },
        }
    );
};


export default useApiCreateMyCompanyBank;