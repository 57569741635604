import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import Loading from "../../../../../ui/Loading";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import {IPaymentsNumber} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IPaymentsNumber";
import useApiGetPaymentsNumbers from "./api-hooks/useApiGetPaymentsNumbers";
import useApiGetPaymentsNumber from "./api-hooks/useApiGetPaymentsNumber";


interface Props {
    obj: IPaymentsNumber | undefined,
    onChange: (obj: IPaymentsNumber | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
}

const PaymentsNumberSelect = ({
                                  obj: initialObj,
                                  label,
                                  onChange,
                                  required,
                              }: Props) => {

    const {displayError} = useToast()
    const {t} = useTranslation()


    const [objs, setObjs] = useState<IPaymentsNumber[]>([]);
    const [obj, setObj] = useState<IPaymentsNumber | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
        error,
    } = useApiGetPaymentsNumbers();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetPaymentsNumber(obj?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IPaymentsNumber | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="payments_number-select"
                            options={objs}
                            getOptionLabel={(option) => option.number_of_payments.toString()}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.code === value?.code}
                            disableClearable={true}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('payments_number')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>
                </>
            )}
        </>
    );
}

export default PaymentsNumberSelect;