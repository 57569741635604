import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import appGARAGEcolors from "./appGARAGEcolors";

interface CustomPalette {
    filterColor: {
        light: string;
        main: string;
        dark: string;
        contrastText: string;
    },
    backgroundAppGarageColor: {
        light: string;
        main: string;
        dark: string;
        gray_50: string;
        contrastText: string;
    },
    textAppGarageColor: {
        tw400: string;
        tw500: string;
        tw600: string;
        tw700: string;
        tw800: string;
    },
    tableHoverColor: {
        main: string;
    },
    disabledColor: {
        main: string;
    },
}

declare module '@mui/material/styles' {
    interface Palette extends CustomPalette {
    }

    interface PaletteOptions extends CustomPalette {
    }
}

// light: '#cee9eb',

const appGarageTheme = createTheme({
    palette: {
        primary: {
            main: appGARAGEcolors.PETROL_APPGARAGE,
            light: appGARAGEcolors.PETROL_APPGARAGE_LIGHT,
            dark: appGARAGEcolors.PETROL_APPGARAGE_DARK,
        },
        secondary: {
            main: appGARAGEcolors.PINK_APPGARAGE_LIGHT,
        },
        warning: {
            main: appGARAGEcolors.RED_APPGARAGE_LIGHT,
        },
        background: {
            paper: '#f8fafc',
        },
        filterColor: {
            light: appGARAGEcolors.ACIDGREEN_APPGARAGE_LIGHT,
            main: appGARAGEcolors.ACIDGREEN_APPGARAGE,
            dark: appGARAGEcolors.ACIDGREEN_APPGARAGE_DARK,
            contrastText: '#ffffff',
        },
        backgroundAppGarageColor: {
            light: '#f1f8f9',
            main: '#f1f5f9',
            dark: '#e4e7eb',
            gray_50: '#f9fafb',
            contrastText: appGARAGEcolors.PETROL_APPGARAGE,
        },
        tableHoverColor: {
            main: '#cee9eb',
        },
        disabledColor: {
            main: '#b6d4d6',
        },
        textAppGarageColor: {
            tw400: '#94a3b8',
            tw500: '#64748b',
            tw600: '#475569',
            tw700: '#334155',
            tw800: '#1e293b'
        },

    },
    typography: {
        fontFamily: "'Arial Rounded MT Regular', sans-serif",
    },

});


const responsiveAppGarageTheme = responsiveFontSizes(appGarageTheme);

export default responsiveAppGarageTheme;