import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {IRawChoice} from "../../../../../../../utils/interfaces/tannery_production/order_schedule/IRawChoice";


const useApiGetRawChoiceOrders = (
    companyToken: string | undefined, order_id: number | undefined
): UseQueryResult<IRawChoice[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, order_id: number | undefined): Promise<IRawChoice[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/order_schedule/raw_material/${order_id}/order_id`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["getOrderRawChoicesOrders", order_id],
        queryFn: () => doGet(companyToken, order_id),
        enabled: !!order_id
    })
};

export default useApiGetRawChoiceOrders