// MuiFabButtonSaveAs.tsx
import React from 'react';
import Fab from '@mui/material/Fab';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import Zoom from "@mui/material/Zoom";

interface MuiFabButtonAddProps {
    onClick: () => void;
    isDisabled?: boolean
}

const MuiFabButtonSaveAs: React.FC<MuiFabButtonAddProps> = ({onClick, isDisabled = false}) => {
    return (
        <Zoom in={true} unmountOnExit>
            <Fab color="primary" aria-label="add" onClick={onClick} variant="extended" disabled={isDisabled}>
                <SaveAsIcon style={{color: 'white'}}/>
            </Fab>
        </Zoom>
    );
};

export default MuiFabButtonSaveAs;
