import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import LabelledMuiTypography from "../../../../../ui/mui/mui-display-data/LabelledMuiTypography";
import {IBank} from "../../../../../../utils/interfaces/my_documents/IBank";
import useApiGetBank from "./api-hooks/useApiGetBank";
import {useTranslation} from "react-i18next";

interface Props {
    obj: IBank | undefined,
}

const BankCard = ({
                      obj: initialObj,
                  }: Props) => {

    const theme = useTheme();
    const [obj, setObj] = useState<IBank | undefined>(initialObj);
    const {t} = useTranslation();

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj);
        }
    }, [initialObj]);

    const {
        data: detailedObj,
    } = useApiGetBank(obj?.id);


    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    return (
        <Box sx={{
            marginTop: 1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            border: '1px solid lightgrey',
            borderRadius: '4px',
            padding: '10px',
            backgroundColor: theme.palette.backgroundAppGarageColor.gray_50,

        }}>
            <Typography
                variant="subtitle2"
                sx={{
                    color: theme.palette.primary.main,
                    position: 'absolute',
                    top: -12,
                    left: 0,
                    backgroundColor: 'transparent',
                    paddingX: 2,
                }}
            >
                {t('Customer Bank')}
            </Typography>

            <LabelledMuiTypography label={'code'} value={obj?.code} upperCaseValue={true}/>
            <LabelledMuiTypography label={'name'} value={obj?.name} upperCaseValue={true}/>
            <LabelledMuiTypography label={'agency'} value={obj?.agency ? obj?.agency : ''} upperCaseValue={true}/>
            <LabelledMuiTypography label={'abi/cab'} value={`${obj?.abi}/${obj?.cab}`} upperCaseValue={true}/>
        </Box>
    );
}

export default BankCard;
