import React, {useState, useEffect} from 'react';
import {useQueryClient} from "@tanstack/react-query";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IMacroLinea} from "../../../../../../../utils/interfaces/tannery_production/articles/IMacroLinea";
import useApiUpdateMacroLinea from "./api-hooks/useApiUpdateMacroLinea";
import useApiCreateMacroLinea from "./api-hooks/useApiCreateMacroLinea";
import useApiGetMacroLineaDetails from "./api-hooks/useApiGetMacroLineaDetails";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import {Box, Grid} from "@mui/material";
import MuiInputTextField from "../../../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";


interface MacroLineaFormProps {
    macroLineaToEdit: number | null;
    onClose: () => void;
}

const MacroLineaForm: React.FC<MacroLineaFormProps> = ({macroLineaToEdit, onClose}) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();

    const [macroLinea, setMacroLinea] = useState<IMacroLinea>()
    const [name, setName] = useState('');

    const mutationHook = macroLineaToEdit ? useApiUpdateMacroLinea : useApiCreateMacroLinea;
    const mutation = mutationHook(selectedCompany?.company_token);

    const macroLineaDetailsQuery = useApiGetMacroLineaDetails(selectedCompany?.company_token, macroLineaToEdit);

    useEffect(() => {
        if (macroLineaDetailsQuery.isSuccess && macroLineaDetailsQuery.data) {
            const macroLineaDetails = macroLineaDetailsQuery.data;
            setName(macroLineaDetails.macro_linea_name);
            setMacroLinea(macroLineaDetails);
        }
    }, [macroLineaDetailsQuery.isSuccess, macroLineaDetailsQuery.data]);


    const handleSubmit = async () => {

        try {
            const macroLineaData = {
                macro_linea_name: name,
                // Add other fields as needed
            };

            if (macroLineaToEdit !== null) {
                await mutation.mutateAsync({id: macroLineaToEdit, data: macroLineaData});
                await queryClient.invalidateQueries({queryKey: ['getMacroLineeProduzione']});
                await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
                displayMessage('MacroLinea updated successfully');
            } else {
                // Adding a new person
                await mutation.mutateAsync(macroLineaData);
                await queryClient.invalidateQueries({queryKey: ['getMacroLineeProduzione']});
                await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
                displayMessage('MacroLinea added successfully');
            }

            onClose(); // Close the form
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while saving the MacroLinea');
            }
        }
    };


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setName(e.target.value)}
                    label='name'
                    type="text"
                    value={name}
                    required={true}
                />
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Box className={'mt-2 gap-x-2'}>
                        <MuiFabButtonBack onClick={onClose}/>
                        {macroLineaToEdit ?
                            <MuiFabButtonSaveAs onClick={handleSubmit}/>
                            :
                            <MuiFabButtonSave onClick={handleSubmit}/>
                        }
                    </Box>
                </Grid>
            </form>
        </Box>
    );
};

export default MacroLineaForm;
