import React, {useState, useEffect, useRef} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {CustomAxiosError} from "../../../../../logics/api_config";
import {useQueryClient} from "@tanstack/react-query";
import {useTranslation} from "react-i18next";
import InputField from "../../../../ui/InputField";
import useApiUpdateVerificationPeriod from "./api-hooks/useApiUpdateVerificationPeriod";
import useApiCreateVerificationPeriod from "./api-hooks/useApiCreateVerificationPeriod";
import {
    IVerificationPeriod
} from "../../../../../utils/interfaces/lwg_dashboard/verification_period/IVerificationPeriod";
import FilterMotionDiv from "../../../../ui/mui/mui-accordion/mui-filter-accordion/FilterMotionDiv";


interface Props {
    openAccordion: boolean,
    verificationPeriod: IVerificationPeriod | undefined
}

const VerificationPeriodForm: React.FC<Props> = ({
                                                     openAccordion = false,
                                                     verificationPeriod: initialVerificationPeriod
                                                 }) => {
    const {t} = useTranslation();
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();

    const [verificationPeriod, setVerificationPeriod] = useState<IVerificationPeriod | undefined>(initialVerificationPeriod)
    const [startingDate, setStartingDate] = useState<string | undefined>(verificationPeriod?.starting_date);
    const [endingDate, setEndingDate] = useState<string | undefined>(verificationPeriod?.ending_date);

    const updateMutation = useApiUpdateVerificationPeriod();
    const createMutation = useApiCreateVerificationPeriod();


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const payload = {
                starting_date: startingDate,
                ending_date: endingDate,
            };

            if (verificationPeriod?.id !== null && verificationPeriod?.id !== undefined) {
                await updateMutation.mutateAsync({id: verificationPeriod.id, data: payload});
                // displayMessage(t('update_successfully'));
            } else {
                await createMutation.mutateAsync({data: payload});
                // displayMessage(t('create_successfully'));
            }
            await queryClient.invalidateQueries({queryKey: ['useApiGetVerificationPeriod']});
            await queryClient.invalidateQueries({queryKey: ['useApiGetReportProduction']});
            await queryClient.invalidateQueries({queryKey: ['useApiGetPendingOrders']});
            await queryClient.invalidateQueries({queryKey: ['useApiGetProductionRecords']});
            await queryClient.invalidateQueries({queryKey: ['getLwgVerificationPeriodOrders']});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                // displayError(t('saving_failure'));
            }
        }
    };

    useEffect(() => {
        if (initialVerificationPeriod) {
            setVerificationPeriod(initialVerificationPeriod);
        }
    }, [initialVerificationPeriod]);


    const labelStyles = {
        fontSize: "24px",
        color: "#60b8c3",
        textTransform: "capitalize" as "capitalize",
    };

    return (
        <FilterMotionDiv isOpen={openAccordion} classname={'filters-modal-content'}>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-4"
                     onBlur={handleSubmit}>
                    <InputField onChange={(e) => setStartingDate(e.target.value)}
                                value={startingDate}
                                label='starting_date'
                                type="date"
                                className='mb-2'
                                required={true}
                                onBlur={handleSubmit}
                    />

                    <InputField onChange={(e) => setEndingDate(e.target.value)}
                                value={endingDate}
                                label='ending_date'
                                type="date"
                                className='mb-2'
                                required={true}
                                onBlur={handleSubmit}
                    />
                </div>
            </form>
        </FilterMotionDiv>
    );
};

export default VerificationPeriodForm;
