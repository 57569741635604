import React, {useState} from 'react';
import MaxWidthDiv from "../../../ui/MaxWitdthDiv";
import useApiGetMyCompanyRegistry from "./my_company_registry/api-hooks/useApiGetMyCompanyRegistry";
import MyCompanyRegistryCard from "./my_company_registry/MyCompanyRegistryCard";
import {Box} from "@mui/material";
import MyCompanyBankCard from "./my_company_registry/my_company_bank/MyCompanyBankCard";
import MuiFabButtonBase from "../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBase";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import MuiModal from "../../../ui/mui/mui_modal/MuiModal";
import MyCompanyWarehouseTable from "./my_company_warehouse/MyCompanyWarehouseTable";


const MyCompanyScreen = () => {

    const {data} = useApiGetMyCompanyRegistry();
    const [isMyCompanyWarehouseTableOpen, setIsMyCompanyWarehouseTableOpen] = useState<boolean>(false)

    return (
        <>

            <MaxWidthDiv maxWidth='1024px'>
                <Box sx={{marginTop: 2}}>
                    <Box sx={{py: 1}}>
                        <MyCompanyRegistryCard obj={data}/>
                    </Box>
                    <Box sx={{py: 1}}>
                        <MyCompanyBankCard obj={data?.my_company_bank}/>
                    </Box>
                    <Box sx={{py: 1}}>
                        <MuiFabButtonBase icon={<WarehouseIcon/>} label={'warehouses'}
                                          onClick={() => setIsMyCompanyWarehouseTableOpen(true)}/>
                    </Box>
                </Box>

                <MuiModal top="50%" left="50%" width={'120vh'} open={isMyCompanyWarehouseTableOpen}
                          onClose={() => setIsMyCompanyWarehouseTableOpen(false)}
                          disableBackdropClick={true}
                >
                    <MyCompanyWarehouseTable
                        alreadySelectedObj={undefined}
                        onSelect={undefined}
                        onClose={() => setIsMyCompanyWarehouseTableOpen(false)}
                    />
                </MuiModal>

            </MaxWidthDiv>


        </>
    );
};

export default MyCompanyScreen;
