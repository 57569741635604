import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";


const useApiCreateArticle = (companyToken: string | undefined, people_id: number | undefined): UseMutationResult<void, CustomAxiosError, any, unknown> => {

    const {post} = useApiCalls()

    async function doCreate({companyToken, people_id, data}: {
        companyToken?: string,
        people_id: number | undefined,
        data: any
    }): Promise<void> {
        try {
            await post(`/api/company/${companyToken}/tannery_production/articles/${people_id}`, data);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, any>(
        {
            mutationFn: (data) => doCreate({companyToken, people_id, data})
        }
    );
};


export default useApiCreateArticle;