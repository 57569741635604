import React, {useEffect, useState} from 'react';
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import Loading from "../../../../../ui/Loading";
import {Box} from "@mui/material";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {Add, Edit} from "@mui/icons-material";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import TextField from "@mui/material/TextField";
import {IStatementOfIntent} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IStatementOfIntent";
import useApiGetStatementOfIntents from "./api-hooks/useApiGetStatementOfIntents";
import useApiGetStatementOfIntent from "./api-hooks/useApiGetStatementOfIntent";
import {formatDateTime} from "../../../../../../logics/helpers";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import StatementOfIntentForm from "./StatementOfIntentForm";
import StatementOfIntentTable from "./StatementOfIntentTable";
import {FaSkull} from "react-icons/fa";


interface Props {
    obj: IStatementOfIntent | undefined,
    onChange: (obj: IStatementOfIntent | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
    fiscal_registry: IFiscalRegistry | undefined,
}

const StatementOfIntentSelect = ({
                                     obj: initialObj,
                                     label,
                                     onChange,
                                     required,
                                     fiscal_registry,
                                     ...props
                                 }: Props) => {

    const {displayError} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)

    const [objs, setObjs] = useState<IStatementOfIntent[]>([]);
    const [obj, setObj] = useState<IStatementOfIntent | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
        error,
    } = useApiGetStatementOfIntents(fiscal_registry?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);


    const {
        data: detailedObj, error: detailedObjError
    } = useApiGetStatementOfIntent(fiscal_registry?.id, obj?.id);


    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);


    const handleOnChange = (event: React.SyntheticEvent, newValue: IStatementOfIntent | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);

    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="statement_of_intent-select"
                            options={objs}
                            getOptionLabel={(option) => option.year + ' - ' + formatDateTime(option.registration_date) + ' - ' + option.fiscal_registry_reference_number}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.fiscal_registry_reference_number === value?.fiscal_registry_reference_number}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('statement of intent')}
                                    required={required}
                                />
                            )}
                        >
                            {detailedObj && !detailedObj?.is_valid &&
                                <MRTMuiButton
                                    small={true}
                                    icon={<FaSkull className='animate-pulse' color={theme.palette.warning.main}/>}
                                    onClick={() => {
                                        setIsListTableOpen(true)
                                    }}
                                    label={'statement of intent out of validity'}/>}
                            <MRTMuiButton
                                small={true}
                                icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => {
                                    setIsListTableOpen(true)
                                }} label={'show_list'}/>

                            <MRTMuiButton
                                small={true}
                                icon={obj ?
                                    <Edit sx={{color: theme.palette.primary.main}}/>
                                    :
                                    <Add sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => {
                                    setIsEditFormOpen(true)
                                }} label={obj ? 'edit statement of intent' : 'add statement of intent'}/>
                        </MuiAutocomplete>
                    </Box>

                    <MuiModal
                        top="50%"
                        left="50%"
                        width="50vh"
                        open={isEditFormOpen}
                        onClose={() => setIsEditFormOpen(false)}
                        disableBackdropClick={true}
                    >
                        <StatementOfIntentForm
                            objToEdit={obj}
                            fiscal_registry={fiscal_registry}
                            onSelect={onChange}
                            onClose={() => setIsEditFormOpen(false)}
                        />
                    </MuiModal>

                    <MuiModal
                        top="50%"
                        left="50%"
                        width="150vh"
                        open={isListTableOpen}
                        onClose={() => setIsListTableOpen(false)}
                        disableBackdropClick={true}
                    >
                        <StatementOfIntentTable
                            fiscal_registry={fiscal_registry}
                            alreadySelectedObj={obj}
                            onSelect={onChange}
                            onClose={() => setIsListTableOpen(false)}
                        />
                    </MuiModal>

                </>
            )}
        </>
    );
}

export default StatementOfIntentSelect;