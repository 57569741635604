import React, {useState, useEffect} from 'react';
import {useQueryClient} from "@tanstack/react-query";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {IRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IRegistry";
import UseApiUpdateRegistry from "./api-hooks/useApiUpdateRegistry";
import useApiCreateRegistry from "./api-hooks/useApiCreateRegistry";
import {formatDateTimeForInput, getCurrentDate} from "../../../../../../logics/helpers";
import {useService} from "../../../../../../utils/contexts/service/service-context";
import NationSelect from "../../NationSelect";
import {INation} from "../../../../../../utils/interfaces/my_documents/INation";
import {Box} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import useApiGetRegistry from "./api-hooks/useApiGetRegistry";

interface Props {
    registryToEdit: IRegistry | undefined;
    onClose: () => void;
}

const RegistryForm: React.FC<Props> = ({registryToEdit, onClose}) => {
    const {displayError, displayMessage} = useToast();
    const queryClient = useQueryClient();
    const {selectedFiscalRegistry} = useService()

    const updateMutation = UseApiUpdateRegistry();
    const createMutation = useApiCreateRegistry();

    const [fiscalRegistry, setFiscalRegistry] = useState<number>()
    const [businessName, setBusinessName] = useState<string | undefined>()
    const [address, setAddress] = useState<string | undefined>()
    const [city, setCity] = useState<string | undefined>()
    const [cap, setCap] = useState<string | undefined>()
    const [province, setProvince] = useState<string | undefined>()
    const [validityStartDate, setValidityStartDate] = useState<string | undefined>(getCurrentDate)
    const [nation, setNation] = useState<INation>()

    const handleNationChange = (obj: INation | undefined) => {
        setNation(obj);
    };


    const {
        data: detailedObj,
    } = useApiGetRegistry(selectedFiscalRegistry?.id, registryToEdit?.id);

    useEffect(() => {
        if (detailedObj) {
            setFiscalRegistry(detailedObj.fiscal_registry);
            setBusinessName(detailedObj.business_name);
            setAddress(detailedObj.address);
            setCity(detailedObj.city);
            setCap(detailedObj.cap);
            setProvince(detailedObj.province);
            setNation(detailedObj.nation);
            setValidityStartDate(detailedObj.validity_start_date);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {

        try {
            const upd_data = {
                fiscal_registry: registryToEdit?.fiscal_registry,
                business_name: businessName,
                address: address,
                city: city,
                cap: cap,
                province: province,
                nation: nation?.id,
                validity_start_date: validityStartDate,
            };

            if (registryToEdit !== undefined && registryToEdit !== null) {
                await updateMutation.mutateAsync({
                    id: registryToEdit.id,
                    fiscal_registry_id: registryToEdit.fiscal_registry,
                    data: upd_data
                });

                displayMessage('Registry updated successfully');
            } else {
                await createMutation.mutateAsync({data: upd_data, fiscal_registry_id: selectedFiscalRegistry?.id});
                displayMessage('Registry added successfully');
            }

            onClose();
            await queryClient.invalidateQueries({queryKey: ['getFiscalRegistry']});
            await queryClient.invalidateQueries({queryKey: ['getRegistry']});
            await queryClient.invalidateQueries({queryKey: ['getRegistry', registryToEdit?.id]});


        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while saving the Registry');
                console.log(error)
            }
        }
    };

    const [allRequirementsSatisfied, setAllRequirementsSatisfied] = useState<boolean>(false)
    useEffect(() => {
        setAllRequirementsSatisfied(false);
        if (businessName && address && city && cap && province && nation) {
            setAllRequirementsSatisfied(true);
        }
    }, [address, businessName, cap, city, province, nation]);

    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setBusinessName(e.target.value)}
                    label='business_name'
                    type="text"
                    value={businessName}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setAddress(e.target.value)}
                    label='address'
                    type="text"
                    value={address}
                    required={true}
                />

                <Box className="grid grid-cols-2 gap-4">
                    <MuiInputTextField
                        onChange={(e) => setCity(e.target.value)}
                        label='city'
                        type="text"
                        value={city}
                        required={true}
                    />

                    <MuiInputTextField
                        onChange={(e) => setCap(e.target.value)}
                        label='cap'
                        type="text"
                        value={cap}
                        required={true}
                    />
                </Box>

                <Box className="grid grid-cols-2 gap-4">
                    <MuiInputTextField
                        onChange={(e) => setProvince(e.target.value)}
                        label='province'
                        type="text"
                        value={province}
                        required={true}
                    />

                    <NationSelect
                        label={'nation'}
                        obj={nation}
                        onChange={handleNationChange}
                        className={''}
                        required={true}
                    ></NationSelect>
                </Box>


                <MuiInputTextField
                    onChange={(e) => setValidityStartDate(e.target.value)}
                    label='validity_start_date'
                    type="date"
                    value={formatDateTimeForInput(validityStartDate)}
                    required={true}
                />

                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*<MuiFabButtonDelete onClick={() => {*/}
                    {/*    setDeleteConfirmationOpen(true)*/}
                    {/*}}/>*/}
                    {registryToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementsSatisfied}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementsSatisfied}/>}
                </Box>
            </form>
        </Box>
    );
};

export default RegistryForm;
