import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";


const useApiUpdateMacroLinea = (companyToken: string | undefined): UseMutationResult<void, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {

    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined, macro_linea_id: number, macroLineaData: any): Promise<void> {
        try {
            await put(`/api/company/${company_token}/tannery_production/articles/macro_linee_produzione/${macro_linea_id}`, macroLineaData);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        id: number;
        data: any;
    }>({mutationFn: ({id, data}) => doUpdate(companyToken, id, data)});
};

export default useApiUpdateMacroLinea;