import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {
    IOrderScheduleTanneryDyeing
} from "../../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleTanneryDyeing";


const useApiGetDyeByOrder = (
    companyToken: string | undefined, order_id: number | undefined
): UseQueryResult<IOrderScheduleTanneryDyeing[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, order_id: number | undefined): Promise<IOrderScheduleTanneryDyeing[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/order_schedule/tannery_dyeing/${order_id}/order_id`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getDyeByOrder', order_id],
        queryFn: () => doGet(companyToken, order_id),
    })
};

export default useApiGetDyeByOrder