import {CustomAxiosError, useApiCalls} from "../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {ICurrency} from "../../../../../utils/interfaces/my_documents/ICurrency";


const useApiGetCurrencyDetailed = (pk: number | undefined): UseQueryResult<ICurrency, CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, pk: number | undefined): Promise<ICurrency> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/currency/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetCurrency", pk],
        queryFn: () => doGet(selectedCompany?.company_token, pk),
        enabled: !!pk,
    })
};

export default useApiGetCurrencyDetailed