import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {formatDateTimeForInput} from "../../logics/helpers";

type Props = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur?: (e: React.FormEvent) => void,
    children?: React.ReactNode,
    className?: string,
    value: string | number | undefined,
    label?: string,
    type: string,
    inputRef?: React.RefObject<HTMLInputElement>;
    required?: boolean;
    step?: string
    style?: React.CSSProperties;
}

const InputField = ({
                        value,
                        label,
                        className = '',
                        onChange,
                        type,
                        inputRef,
                        required = false,
                        onBlur,
                        step,
                        style
                    }: Props) => {
    const {t} = useTranslation();

    useEffect(() => {
        if (inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, [inputRef]);

    const [isFocused, setIsFocused] = useState(false);
    const inputStyles = {
        width: '100%',
        padding: '0.5rem 0.5rem 0.5rem 0.5rem',
        border: '1px solid #ccc',
        borderRadius: '0.25rem',
        outline: 'none',
        transition: 'border-color 0.2s',
        boxShadow: isFocused ? '0 0 0 2px #60b8c3' : 'none',
        ...style,
    };

    const labelStyles = {
        fontSize: "16px",
        color: "#4b5563", // grey-600
        textTransform: "capitalize" as "capitalize",
    };

    const handleOnBLur = (e: React.FormEvent) => {
        onBlur && onBlur(e);
        setIsFocused(false);
    }

    const _value = (v: string | number | readonly string[] | undefined) => {
        if (type === 'date') {
            return formatDateTimeForInput(v)
        }
        return v
    }


    return (
        <div className={`flex flex-row items-center ${className}`}>
            {label && <label
                className={`mr-2 ${required && !value ? "font-bold" : ""}`}
                style={labelStyles}
            >
                {t(label)}:{required && !value && <span style={{color: 'red'}}> *</span>}
            </label>}
            <input
                style={inputStyles}
                type={type}
                value={_value(value)}
                onChange={(e) => onChange(e)}
                className="bg-aGgreen-lighter font-semibold text-gray-700 text-sm "
                onFocus={() => setIsFocused(true)}
                onBlur={handleOnBLur}
                ref={inputRef && inputRef}
                step={step}
            />
        </div>

    );
};

export default InputField;