import React from 'react';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import {keyframes} from '@mui/system';
import {useTranslation} from 'react-i18next';
import {useTheme} from "@mui/material/styles";

interface MuiFabButtonBaseProps {
    onClick?: () => void;
    label?: string;
    icon?: React.ReactNode;
    animated?: boolean;
    bg_color?: string
    bg_hover_color?: string
    font_size?: string
    font_weight?: string,
}

const pulseAnimation = keyframes({
    '0%': {
        transform: 'scale(1)',
    },
    '50%': {
        transform: 'scale(1.1)',
    },
    '100%': {
        transform: 'scale(1)',
    },
});

const MuiFabButtonBase: React.FC<MuiFabButtonBaseProps> = ({
                                                               onClick,
                                                               label,
                                                               icon,
                                                               animated = false,
                                                               bg_color,
                                                               bg_hover_color,
                                                               font_size = '0.8rem',
                                                               font_weight = 'normal',
                                                           }) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const _bg_color = bg_color ? bg_color : theme.palette.primary.main
    const _bg_hover_color = bg_hover_color ? bg_hover_color : theme.palette.primary.dark

    return (
        <Zoom in={true} unmountOnExit>
            <Fab
                color="primary"
                aria-label="add"
                onClick={onClick}
                variant="extended"
                sx={{
                    backgroundColor: _bg_color,
                    '&:hover': {
                        backgroundColor: _bg_hover_color,
                    },
                    ...(animated ? {animation: `${pulseAnimation} 1s infinite`} : {}),
                }}
            >
                {icon && <span style={{color: 'white', fontSize: '1.3rem'}}>{icon}</span>}
                {label && (
                    <span
                        style={{
                            textTransform: 'uppercase',
                            color: 'white',
                            marginLeft: '8px',
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            marginTop: '1px',
                            fontSize: font_size,
                            fontWeight: font_weight,
                        }}
                    >
            {t(label)}
          </span>
                )}
            </Fab>
        </Zoom>
    );
};

export default MuiFabButtonBase;
