import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {
    IStatementOfIntent
} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/IStatementOfIntent";


const useApiUpdateOtherDestination = (): UseMutationResult<IStatementOfIntent, CustomAxiosError, {
    fiscal_registry_id: number | undefined,
    id: number;
    data: any
}, unknown> => {
    const {selectedCompany} = useAuth()

    const {put} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            fiscal_registry_id: number | undefined,
                            pk: number | undefined,
                            data: any): Promise<IStatementOfIntent> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/fiscal_registry/${fiscal_registry_id}/statement_of_intent/${pk}/`, data);
            return response.data as IStatementOfIntent;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IStatementOfIntent, CustomAxiosError, {
        fiscal_registry_id: number | undefined,
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({fiscal_registry_id, id, data}) => {
            return await doUpdate(selectedCompany?.company_token, fiscal_registry_id, id, data);
        },
        onSuccess: async (data) => {
            // await queryClient.invalidateQueries({queryKey: ["useApiGetOtherDestination", fiscal_registry_id, data.id]});
            // await queryClient.invalidateQueries({queryKey: ["useApiGetOtherDestinations", fiscal_registry_id]});
        },
    });
};


export default useApiUpdateOtherDestination;
