import React, {useEffect, useState} from 'react';
import AgDiv from "../../../../../ui/divs/AgDiv";
import {GiBarrel, GiCow} from "react-icons/gi";
import {IRecord} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IRecord";
import {formatDate} from "../../../../../../logics/helpers";
import {FaSkull} from "react-icons/fa";
import {useTranslation} from "react-i18next";


interface Props {
    record: IRecord,
}

const RecordRow = ({record: initialRecord}: Props) => {
    const {t} = useTranslation()
    const [record, setRecord] = useState<IRecord>(initialRecord);
    const [selectedRecord, setSelectedRecord] = useState<IRecord | undefined>();

    const handleSelection = () => {
        if (selectedRecord?.tannery_dyeing_id === record.tannery_dyeing_id) {
            setSelectedRecord(undefined);
        } else {
            setSelectedRecord(record)
        }
    }

    useEffect(() => {
        setRecord(initialRecord)
    }, [initialRecord]);


    return (
        <>
            <div
                className={`flex flex-row ${selectedRecord?.tannery_dyeing_id === record.tannery_dyeing_id ? 'font-bold' : ''}`}
                style={{flex: 1}}
            >
                <div
                    onClick={handleSelection}
                    className={`flex flex-row ${selectedRecord?.tannery_dyeing_id === record.tannery_dyeing_id ? 'font-bold' : ''}`}
                    // style={{flex: 1}}
                >
                    <AgDiv value={record.tannery_dyeing_id}
                           minWidth="320px" maxWidth="320px"
                           icons={[
                               record.sqm_produced === 0 &&
                               <FaSkull className='animate-pulse text-red-600' title={t("has_record_zero")}/>,
                               <GiBarrel color={'#60b8c3'}/>]}
                    />
                    <AgDiv value={formatDate(record.tannery_dyeing_date)}
                           minWidth="150px" maxWidth="150px"

                    />
                    <AgDiv value={record.pcs_produced}
                           minWidth="150px" maxWidth="150px"

                    />

                    <AgDiv value={record.avg_sqm.toFixed(2)}
                           minWidth="100px" maxWidth="100px"

                    />

                    <AgDiv value={record.sqm_produced.toFixed(2)}
                           minWidth="150px" maxWidth="150px"

                    />
                    <AgDiv value={record.lwg_sqm_produced.toFixed(2)}
                           minWidth="150px" maxWidth="150px"
                    />
                    <AgDiv value={record.coefficient_sqm_produced.toFixed(2)}
                           minWidth="150px" maxWidth="150px"
                    />
                    <AgDiv value={record.order.order_id}
                           minWidth="250px" maxWidth="250px"
                    />

                    <AgDiv value={record.order.article_name}
                           minWidth="250px" maxWidth="250px"
                    />
                    <AgDiv value={record.order.color.color_name}
                           minWidth="250px" maxWidth="250px"
                    />


                </div>


            </div>


        </>
    );
};

export default RecordRow;
