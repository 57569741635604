import React from 'react';
import {Box, Button, Grid, Typography} from '@mui/material';
import MRTMuiButtonFilter from "./MRTMuiButtonFilter";
import {useTheme} from "@mui/material/styles";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";

type MuiFilterAccordionProps = {
    onToggle: () => void;
    children: React.ReactNode;
};

const MRTFiltersBox: React.FC<MuiFilterAccordionProps> = ({onToggle, children}) => {
    const theme = useTheme();
    return (

        <Box
            display=""
            flexDirection="column"
            alignItems="center"
            p={0}
            sx={{
                backgroundColor: theme.palette.backgroundAppGarageColor.main,
                color: theme.palette.filterColor.main,
                maxWidth: 220,
                borderRadius: 2,
                boxShadow: theme.shadows[3],
            }}
        >
            <Box mb={0} mt={0} display="flex" justifyContent="center" alignItems="center">
                <MRTMuiButtonFilter
                    icon={<FilterAltOffIcon
                        sx={{
                            marginTop: 2,
                            color: theme.palette.filterColor.main,
                            '&:hover': {
                                color: theme.palette.filterColor.main,
                            },
                        }}
                    />}
                    label={'clear_additional_filters'}
                    onClick={onToggle}
                    hasActiveFilters={true}/>
            </Box>


            <Box
                sx={{
                    padding: 2,
                }}
            >
                {React.Children.map(children, (child, index) => (
                    <Grid item xs={12} md={4} key={index}>
                        {child}
                    </Grid>
                ))}
            </Box>


        </Box>

    );
};

export default MRTFiltersBox;
