import React, {useEffect, useState} from 'react';
import {IMacroLinea} from "../../../../../../../utils/interfaces/tannery_production/articles/IMacroLinea";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import useApiGetMacroLinea from "./api-hooks/useApiGetMacroLinea";
import useApiGetMacroLineeProduzione from "./api-hooks/useGetMacroLinee";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import Loading from "../../../../../../ui/Loading";
import {Box, Popover} from "@mui/material";
import MRTMuiButton from "../../../../../../ui/mui/mui_react_table/MRTMuiButton";
import {Add, Edit} from "@mui/icons-material";
import MuiAutocomplete from "../../../../../../ui/mui/mui-input/MuiAutoComplete";
import TextField from "@mui/material/TextField";
import MacroLineaForm from "./MacroLineaForm";

interface MacroLineeProduzioneSelectProps {
    linea: IMacroLinea | undefined,
    onChange: (linea: IMacroLinea | null) => void,
    label?: string,
    required?: boolean
}

const MacroLineeProduzioneSelect = ({
                                        linea: initialObj,
                                        onChange,
                                        required, label,
                                    }: MacroLineeProduzioneSelectProps) => {
    const {displayError, displayMessage} = useToast()
    const {selectedCompany} = useAuth()
    const theme = useTheme();
    const {t} = useTranslation();

    const [objs, setObjs] = useState<IMacroLinea[]>([]);
    const [obj, setObj] = useState<IMacroLinea | null | undefined>(initialObj);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const {
        data: detailedObj,
    } = useApiGetMacroLinea(selectedCompany?.company_token, obj?.id);

    const {
        data,
        isLoading,
        error,
    } = useApiGetMacroLineeProduzione(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        setObj(initialObj);
    }, [initialObj]);

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IMacroLinea | null) => {
        setObj(newValue);
        onChange(newValue);
    };


    return (
        isLoading ? (
            <Loading/>
        ) : (
            <>
                <Box sx={{alignItems: 'center', width: '100%'}}>
                    <MuiAutocomplete
                        id="macro_linea-select"
                        options={objs}
                        getOptionLabel={(option: any) => option.macro_linea_name}
                        value={detailedObj ? detailedObj : null}
                        onChange={handleOnChange}
                        isOptionEqualToValue={(option: any, value: any) => option.id === value?.id && option.macro_linea_name === value?.macro_linea_name}
                        renderInput={(params: any) => (
                            <TextField
                                sx={{textTransform: 'capitalize'}}
                                {...params}
                                label={label || t('macro_linea')}
                                required={required}
                            />
                        )}
                    >
                        <MRTMuiButton
                            small={true}
                            icon={obj ?
                                <Edit sx={{color: theme.palette.primary.main}}/>
                                :
                                <Add sx={{color: theme.palette.primary.main}}/>}
                            onClick={() => {
                                setIsEditFormOpen(true)
                            }} label={obj ? 'edit_macrolinea' : 'add_macrolinea'}/>
                    </MuiAutocomplete>
                </Box>

                <Popover
                    id={'edit_popover'}
                    open={isEditFormOpen}
                    anchorEl={anchorEl}
                    onClose={() => setIsEditFormOpen(false)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <MacroLineaForm macroLineaToEdit={obj ? obj.id : null}
                                    onClose={() => setIsEditFormOpen(false)}/>
                </Popover>
            </>
        )
    );
}

export default MacroLineeProduzioneSelect;