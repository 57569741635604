import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {
    IOrderScheduleDetail
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail";


const useApiGetHistoricalCompanyOrderSchedule = (
    companyToken: string | undefined
): UseQueryResult<IOrderScheduleDetail[], CustomAxiosError> => {

    const {get} = useApiCalls();
    
    async function doGet(company_token: string | undefined): Promise<IOrderScheduleDetail[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/order_schedule/historical`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['useApiGetHistoricalCompanyOrderSchedule'],
        queryFn: () => doGet(companyToken),
    })

};

export default useApiGetHistoricalCompanyOrderSchedule