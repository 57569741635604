import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";
import {IDocRow} from "../../../../../../../../utils/interfaces/my_documents/doc/IDocRow";
import {useMyDocuments} from "../../../../../../../../utils/contexts/my_documents/my_documents-context";


const useApiMoveDocRow = (): UseMutationResult<IDocRow, CustomAxiosError, {
    row_position: number | undefined,
    id: number;
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()
    const {selectedDocDetail} = useMyDocuments();

    const {put} = useApiCalls()


    async function doUpdate(company_token: string | undefined,
                            row_position: number | undefined,
                            pk: number | undefined): Promise<IDocRow> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/doc/doc_row/${pk}/move_to/${row_position}`);
            return response.data as IDocRow;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IDocRow, CustomAxiosError, {
        row_position: number | undefined,
        id: number
    }, unknown>({
        mutationFn: async ({row_position, id}) => {
            return await doUpdate(selectedCompany?.company_token, row_position, id);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetDoc', selectedDocDetail?.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetDocRows", selectedDocDetail?.id]});

        },
    });
};

export default useApiMoveDocRow;
