import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import useApiGetLwgSites from "./api-hooks/useApiGetLwgSites";
import {CustomAxiosError} from "../../../../../logics/api_config";
import Loading from "../../../../ui/Loading";
import {Box, Popover, TextField} from "@mui/material";
import {ILwgSite} from "../../../../../utils/interfaces/tannery_raw_material/ILwgSite";
import MuiAutocomplete from "../../../../ui/mui/mui-input/MuiAutoComplete";
import LwgSitesForm from "./LwgSitesForm";
import {Add, Edit} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import useApiGetLwgSite from "./api-hooks/useApiGetLwgSite";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";


interface Props {
    lwg_site: any;
    onChange: (lwg_site: any) => void;
    className: string;
    label?: string;
    required?: boolean
}

const LwgSitesSelect = ({
                            lwg_site: initialObj,
                            onChange,
                            label,
                            required,
                        }: Props) => {
    const {displayError} = useToast();
    const {t} = useTranslation();
    const theme = useTheme();
    const {selectedCompany} = useAuth();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [obj, setObj] = useState<ILwgSite | null>(initialObj);
    const [objs, setObjs] = useState<ILwgSite[]>([]);

    const {data, isLoading, error} = useApiGetLwgSites(selectedCompany?.company_token);

    useEffect(() => {
        setObj(initialObj);
    }, [initialObj]);

    useEffect(() => {
        if (error) {
            const axiosError = error as CustomAxiosError;
            displayError(axiosError.response.data.detail);
        }
    }, [error, displayError]);

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: ILwgSite | null) => {
        onChange(newValue);
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);

    const {
        data: detailedObj,
    } = useApiGetLwgSite(obj?.id);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="lwg_site-select"
                            options={objs}
                            getOptionLabel={(option) => option.lwg_site_name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.lwg_site_name === value?.lwg_site_name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label || t('lwg_site')}
                                    required={required}
                                />
                            )}
                        >
                            <MRTMuiButton
                                small={true}
                                icon={obj ?
                                    <Edit sx={{color: theme.palette.primary.main}}/>
                                    :
                                    <Add sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => {
                                    setIsEditFormOpen(true)
                                }} label={obj ? 'edit_lwg_site' : 'add_lwg_site'}/>
                        </MuiAutocomplete>
                    </Box>

                    <Popover
                        id={'edit_popover'}
                        open={isEditFormOpen}
                        anchorEl={anchorEl}
                        onClose={() => setIsEditFormOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <LwgSitesForm lwgSiteToEdit={obj ? obj : null}
                                      onClose={() => setIsEditFormOpen(false)}/>
                    </Popover>
                </>
            )}
        </>
    );
};

export default LwgSitesSelect;
