import React, {useState} from 'react';
import './AgDiv.css';
import {useTranslation} from "react-i18next";

interface Props {
    value: number | string | undefined;
    minWidth: string;
    maxWidth: string;
    icon?: React.ReactNode;
    icons?: React.ReactNode[];
    className?: string; // Add className prop
    children?: React.ReactNode; // Add children prop
    style?: React.CSSProperties;
    label?: string;
    isEur?: boolean;
    toFixed?: number;
    title?: string;
    bg_color?: string;
    hover_color?: string;
}

const AgDivDiv: React.FC<Props> = ({
                                       value,
                                       minWidth,
                                       maxWidth,
                                       icon,
                                       icons,
                                       className, // Add className prop
                                       children,   // Add children prop
                                       title,
                                       bg_color = 'white',
                                       hover_color = '',
                                       label,
                                       isEur,
                                       toFixed = 2
                                   }) => {
    const {t} = useTranslation();

    const formattedTitle = value !== null ? value?.toString() : '';
    const numberClass = typeof formattedTitle === 'string' && !isNaN(Number(formattedTitle))
        ? (Number(formattedTitle) < 0 ? 'negative' : 'number')
        : '';

    const transformedTitle = (formattedTitle: string | undefined, isEur?: boolean): number | string | undefined => {
        if (formattedTitle !== undefined && !isNaN(Number(formattedTitle))) {
            const numberValue = Number(formattedTitle);

            if (Number.isFinite(numberValue) && numberValue % 1 !== 0 && formattedTitle.indexOf('.') !== -1) {
                formattedTitle = numberValue.toFixed(toFixed);
            }

            if (isEur) {
                return numberValue.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                });
            } else {
                return formattedTitle;
            }
        } else {
            // Return the original string or empty string for NaN or undefined
            return formattedTitle || '';
        }
    };


    const containerClass = `${className || 'AgLotDiv'} bg-${bg_color} hover:bg-${hover_color}`;

    const iconContainerClass = 'icon-container-right';

    return (
        !icon ? (
            <div className={containerClass} style={{minWidth, maxWidth}} title={title}>
                {label && <h1 className={`capitalize ${numberClass}`}>{t(label)}:</h1>}
                {label ? (
                    <>
                        <div className={iconContainerClass}>
                            {icons &&
                                icons.map((icon, index) => (
                                    <div key={index} className="icon-item">
                                        {icon}
                                    </div>
                                ))}
                        </div>
                        <h1 className={numberClass}>{transformedTitle(t(formattedTitle ? formattedTitle : ''), isEur)}</h1>
                    </>
                ) : (
                    <>
                        <h1 className={numberClass}>{transformedTitle(t(formattedTitle ? formattedTitle : ''), isEur)}</h1>
                        <div className={iconContainerClass}>
                            {icons &&
                                icons.map((icon, index) => (
                                    <div key={index} className="icon-item">
                                        {icon}
                                    </div>
                                ))}
                        </div>
                    </>
                )}
            </div>
        ) : (
            <div className={containerClass} style={{minWidth, maxWidth}} title={title}>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div className={`icon-item ${numberClass}`}>
                        {icon}
                    </div>
                </div>
            </div>
        )
    );

};

export default AgDivDiv;
