import React, {ReactNode} from 'react';
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {motion} from "framer-motion";

interface ReusablePaperProps {
    children: ReactNode;
    maxHeight: number | string;
}

const MuiScrollableBox: React.FC<ReusablePaperProps> = ({children, maxHeight}) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                width: '100%',
                // bgcolor: 'background.paper',
                maxHeight: maxHeight,
                overflowY: 'auto',
                overflowX: 'hidden',
                scrollbarColor: `${theme.palette.primary.main} transparent`,  // Updated color
                marginTop: '8px'
            }}
        >
            {children}
        </Box>
    );
};


export default MuiScrollableBox;