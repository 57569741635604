import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from '@tanstack/react-query';


const useApiDeleteChoice = (company_token: string | undefined, choice_id: number | undefined): UseMutationResult<void, CustomAxiosError, {
    company_token?: string,
    choice_id?: number
}, unknown> => {

    const {DELETE} = useApiCalls()

    const doDelete = async ({company_token, choice_id}: {
        company_token?: string,
        choice_id?: number
    }): Promise<void> => {
        try {
            await DELETE(`/api/company/${company_token}/tannery_raw_material/lots/choices/${choice_id}/delete`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, { company_token?: string, choice_id?: number }>(
        {mutationFn: () => doDelete({company_token, choice_id})});
};

export default useApiDeleteChoice