import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {IWarehouseProduct} from "../../../../../../utils/interfaces/my_warehouse/IWarehouseProduct";


const useApiGetWarehouseProducts = (warehouse_id: number): UseQueryResult<IWarehouseProduct[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(warehouse_id: number): Promise<IWarehouseProduct[]> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/my_warehouse/${warehouse_id}/products`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetWarehouseProducts", warehouse_id],
        queryFn: () => doGet(warehouse_id),
        enabled: !!warehouse_id,
    })
};

export default useApiGetWarehouseProducts