import React from 'react';
import LoginForm from "./LoginForm";
import {motion} from 'framer-motion';
import ToastLayout from "../ui/layout/ToastLayout";

const LoginScreen = () => {

    return (
        <>
            <div>
                <nav className="bg-aGpetrol">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <motion.div
                            className="flex items-center justify-between"
                            initial={{height: 64}}
                            animate={{height: 32}}
                            transition={{duration: 0.5}}
                        >
                        </motion.div>
                    </div>
                </nav>
                <LoginForm/>
            </div>
        </>
    );
};

export default LoginScreen;