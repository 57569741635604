import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from "@tanstack/react-query";


const useApiUpdateRawChoiceOrder = (
    companyToken: string | undefined
): UseMutationResult<void, CustomAxiosError, { pk: number; data: any }, unknown> => {

    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined,
                            pk: number,
                            data: any): Promise<void> {
        try {
            await put(`/api/company/${company_token}/tannery_production/order_schedule/raw_material/${pk}/update`, data);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        pk: number;
        data: any;
    }>({
        mutationFn: ({pk, data}) => doUpdate(companyToken, pk, data)
    });
};

export default useApiUpdateRawChoiceOrder;