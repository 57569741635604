import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {
    IMyCompanyWarehouse
} from "../../../../../../utils/interfaces/my_company/my_company_warehouse/IMyCompanyWarehouse";


const useApiGetMyCompanyWarehouse = (pk: number | undefined): UseQueryResult<IMyCompanyWarehouse, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, pk: number | undefined): Promise<IMyCompanyWarehouse> {
        try {
            const response = await get(`/api/company/${company_token}/my_company/my_company_warehouse/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetMyCompanyWarehouse", pk],
        queryFn: () => doGet(selectedCompany?.company_token, pk),
        enabled: !!pk,
    })

};

export default useApiGetMyCompanyWarehouse