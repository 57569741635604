import React from 'react';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

interface Props {
    label: string | undefined;
    value: string | number | undefined;
    upperCaseValue?: boolean,
    italic?: boolean;
}

const LabelledMuiTypography: React.FC<Props> = ({label, value, italic, upperCaseValue = false}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', letterSpacing: 'widest'}}>
            {label && <Typography variant="body1"
                                  sx={{
                                      textTransform: 'capitalize',
                                      color: theme.palette.textAppGarageColor.tw500
                                  }}>{t(label)}: </Typography>}
            <Typography
                sx={{
                    color: theme.palette.textAppGarageColor.tw700,
                    paddingX: 1,
                    fontStyle: italic ? 'italic' : 'normal',
                    textTransform:
                        upperCaseValue ? 'uppercase' : '',
                }}
            >
                {value !== undefined ? t(value.toString()) : null}
            </Typography>
        </div>
    );
};

export default LabelledMuiTypography;
