import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";


const useApiUpdateLot = (companyToken: string | undefined): UseMutationResult<void, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {

    const {putWithFile} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            id: number,
                            data: any): Promise<void> {

        try {
            await putWithFile(`/api/company/${company_token}/tannery_raw_material/lots/${id}/update`, data);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        id: number;
        data: any;
    }>({
        mutationFn: ({id, data}) => doUpdate(companyToken, id, data)
    });
};

export default useApiUpdateLot;