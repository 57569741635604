import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IDocCompanyDocType} from "../../../../../../../utils/interfaces/my_documents/doc_type/IDocCompanyDocType";


const useApiGetCompanyDocType = (pk: number | undefined): UseQueryResult<IDocCompanyDocType, CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, pk: number | undefined): Promise<IDocCompanyDocType> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/doc/company_doc_type/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetCompanyDocType", pk],
        queryFn: () => doGet(selectedCompany?.company_token, pk),
        enabled: !!pk,
    })
};

export default useApiGetCompanyDocType