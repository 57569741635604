import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from '@tanstack/react-query';


const useApiDeletePrice = (companyToken: string | undefined, people_id: number, product_id: number, pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    companyToken?: string,
    people_id: number,
    product_id: number,
    pk?: number
}, unknown> => {
    const {DELETE} = useApiCalls();

    async function doDelete({companyToken, people_id, product_id, pk}: {
        companyToken?: string,
        people_id: number,
        product_id: number | undefined,
        pk?: number
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/service/people/${people_id}/product/${product_id}/price/${pk}/delete`);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, {
        companyToken?: string,
        people_id: number,
        product_id: number,
        pk?: number
    }>(
        {
            mutationFn: () => doDelete({
                companyToken: companyToken,
                people_id: people_id,
                product_id: product_id,
                pk: pk
            })
        });
};

export default useApiDeletePrice