import {ILot} from "../../../../../utils/interfaces/tannery_raw_material/ILot";

export const filterLotList = (
    data: ILot[],
    filterLwgSiteType: string,
    filterChoiceName: string,
) => {
    function filterByChoiceName(data: ILot[], filterChoiceName: string) {
        return data
            .filter((lot) => {
                if (filterChoiceName.trim() === "") {
                    return true; // Return all data if the filter is empty
                }

                const matchingChoices = lot.choices.filter((choice) =>
                    choice.lot_id.toLowerCase().includes(filterChoiceName.toLowerCase())
                );

                return matchingChoices.length > 0;
            })
            .map((lot) => {
                if (filterChoiceName.trim() === "") {
                    return lot;
                }

                const matchingChoices = lot.choices.filter((choice) =>
                    choice.lot_id.toLowerCase().includes(filterChoiceName.toLowerCase())
                );

                return {
                    ...lot,
                    choices: matchingChoices,
                };
            });
    }

    function filterByLwgSiteType(data: ILot[], filter: string) {
        if (!filter) {
            return data
        }
        return data.filter((obj) =>
            obj.lwg_site.type?.toLowerCase().includes(filter.toLowerCase())
        );
    }

    const filteredByLwgSiteType = filterByLwgSiteType(data, filterLwgSiteType);
    const filteredByChoiceName = filterByChoiceName(filteredByLwgSiteType, filterChoiceName);

    return filteredByChoiceName

};
