import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";


const useApiUpdateCompanyOrderSchedule = (
    companyToken: string | undefined
): UseMutationResult<void, CustomAxiosError, { color_id: number; id: number; data: any }, unknown> => {


    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined,
                            color_id: number,
                            pk: number,
                            data: any): Promise<void> {
        try {
            await put(`/api/company/${company_token}/tannery_production/order_schedule/${color_id}/${pk}/update`, data);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        color_id: number;
        id: number;
        data: any;
    }>({
        mutationFn: ({id, color_id, data}) => doUpdate(companyToken, color_id, id, data)
    });
};

export default useApiUpdateCompanyOrderSchedule;