import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {
    IMyCompanyWarehouse
} from "../../../../../../utils/interfaces/my_company/my_company_warehouse/IMyCompanyWarehouse";


const useApiUpdateMyCompanyWarehouse = (): UseMutationResult<IMyCompanyWarehouse, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()

    const {put} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            pk: number | undefined,
                            data: any): Promise<IMyCompanyWarehouse> {
        try {
            const response = await put(`/api/company/${company_token}/my_company/my_company_warehouse/${pk}/`, data);
            return response.data as IMyCompanyWarehouse;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IMyCompanyWarehouse, CustomAxiosError, {
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doUpdate(selectedCompany?.company_token, id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetMyCompanyWarehouse', data.id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetMyCompanyWarehouses"]});
        },
    });
};


export default useApiUpdateMyCompanyWarehouse;