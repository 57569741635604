import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import Loading from "../../../../../ui/Loading";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {IPaymentType} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IPaymentType";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import {IPaymentDayType} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IPaymentDayType";
import useApiGetPaymentDayTypes from "./api-hooks/useApiGetPaymentDayTypes";
import useApiGetPaymentDayType from "./api-hooks/useApiGetPaymentDayType";


interface Props {
    obj: IPaymentDayType | undefined,
    onChange: (obj: IPaymentType | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
}

const PaymentDayTypeSelect = ({
                                  obj: initialObj,
                                  label,
                                  onChange,
                                  required,
                              }: Props) => {

    const {displayError} = useToast()
    const {t} = useTranslation()


    const [objs, setObjs] = useState<IPaymentDayType[]>([]);
    const [obj, setObj] = useState<IPaymentDayType | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
        error,
    } = useApiGetPaymentDayTypes();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetPaymentDayType(obj?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IPaymentDayType | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="payment_day_type-select"
                            options={objs}
                            getOptionLabel={(option) => option.description}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                            disableClearable={true}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('payment_day_type')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>
                </>
            )}
        </>
    );
}

export default PaymentDayTypeSelect;