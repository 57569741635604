import React from 'react';
import {ProvideAuth} from "../../utils/contexts/auth-context";
import {ToastProvider} from "../../utils/contexts/toast-context";


import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

import {AppGarageProvider} from "../../utils/contexts/appGarage-context";
import {I18nextProvider} from "react-i18next";
import i18n from "../../utils/translation/i18n";
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {ServiceProvider} from "../../utils/contexts/service/service-context";
import {ThemeProvider} from '@mui/material/styles';
import responsiveAppGarageTheme from '../ui/mui/appGarage-theme';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'


const queryClient = new QueryClient()


type Props = {
    children: React.ReactNode;
}

const MainProviders = ({children}: Props) => {
    return (
        <ThemeProvider theme={responsiveAppGarageTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DndProvider backend={HTML5Backend}>
                    <I18nextProvider i18n={i18n}>
                        <QueryClientProvider client={queryClient}>
                            <AppGarageProvider>
                                <ProvideAuth>
                                    <ToastProvider>
                                        <ServiceProvider>
                                            {children}
                                        </ServiceProvider>
                                    </ToastProvider>
                                </ProvideAuth>
                            </AppGarageProvider>
                        </QueryClientProvider>
                    </I18nextProvider>
                </DndProvider>
            </LocalizationProvider>
        </ThemeProvider>

    );
};

export default MainProviders;