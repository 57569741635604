import React from 'react';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';

interface Props {
    value: string | number | undefined;
    italic?: boolean;
}

const MuiTypography: React.FC<Props> = ({value, italic}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Typography
            sx={{
                color: theme.palette.textAppGarageColor.tw600,
                paddingX: 1,
                fontStyle: italic ? 'italic' : 'normal',
            }}
        >
            {value !== undefined ? t(value.toString()) : null}
        </Typography>
    );
};

export default MuiTypography;
