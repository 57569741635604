import React, {useEffect, useMemo, useState} from 'react';
import {IPrice} from "../../../../../../../utils/interfaces/service/people/product_list/price/IPrice";
import {IPeopleProduct} from "../../../../../../../utils/interfaces/service/people/product_list/IPeopleProduct";
import {IProduct} from "../../../../../../../utils/interfaces/service/people/product_list/IProduct";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import useApiDeletePrice from "./api-hooks/useApiDeletePrice";
import customPriceCellRenderer from "./customPriceCellRenderer";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import MuiReadOnlyBoxBody from "../../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import MuiAlertDialog from "../../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiModal from "../../../../../../ui/mui/mui_modal/MuiModal";
import PriceForm from "./PriceForm";
import {useLocation} from "react-router-dom";
import {FaLink} from "react-icons/fa";
import WorkingsForm from "../../../../tannery_production/order_schedule/workings/WorkingsForm";
import {IoPricetagsOutline} from "react-icons/io5";


interface IPriceWithCustomField extends IPrice {
    custom_field: JSX.Element;
}


interface Props {
    person: IPeopleProduct;
    product: IProduct;
    prices: IPrice[];
    scope?: string
}


const PriceTable = ({prices, person, product}: Props) => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const icon_color = theme.palette.primary.main
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const location = useLocation();
    const isInTanneryProduction = location.pathname.startsWith('/apps/tannery_production');

    const [selectedRow, setSelectedRow] = useState<IPrice | undefined>()
    const [rows, setRows] = useState<IPrice[] | undefined>()
    const [workingForm, setWorkingForm] = useState<boolean>(false)

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeletePrice(selectedCompany?.company_token, person?.id, product?.id, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({
                companyToken: selectedCompany?.company_token,
                people_id: person.id,
                product_id: product.id,
                pk: selectedRow?.id
            });
        } catch (error) {
        }
        await queryClient.invalidateQueries({queryKey: ['getPeopleProducts']});
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    useEffect(() => {
        if (prices) {
            setRows(prices);
        }
    }, [prices]);


    const [peopleArticlesWithCustomField, setPeopleArticlesWithCustomField] = useState<IPriceWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IPriceWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customPriceCellRenderer(row, theme, t),
        }));
        setPeopleArticlesWithCustomField(dataWithCustomField);
    }, [rows]);


    const columns = useMemo<MRT_ColumnDef<IPrice>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'price_description',
                header: t('price_description'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'um.um',
                header: t('um'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'price',
                header: t('price'),
                filterFn: 'contains',
            },
        ],
        [rows]
    );


    const table = useMaterialReactTable<IPrice>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: peopleArticlesWithCustomField ? peopleArticlesWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableGlobalFilter: false,
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: true,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),

        muiTableBodyRowProps: ({row, staticRowIndex, table}) => ({
            onClick: (event) => setSelectedRow(row.original),
            ...getMuiTablePaperProps(),
        }),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            isInTanneryProduction && (
                <MRT_ActionMenuItem
                    icon={<FaLink color={icon_color}/>}
                    key="link"
                    label={t("link_product/service")}
                    onClick={() => {
                        setSelectedRow(row.original);
                        setWorkingForm(true);
                        closeMenu();
                    }}
                    table={table}
                />
            )
        ],

        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 0
                    }}>
                        <IoPricetagsOutline color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'prices'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_price'}/>
                </>
            </Box>
        ),
    });


    return (
        <>
            <MaterialReactTable table={table}/>

            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />

            <MuiModal
                top="50%"
                left="50%"
                width={'50vh'}
                open={isEditFormOpen}
                onClose={() => setIsEditFormOpen(false)}
            >
                <PriceForm
                    product={product}
                    person={person}
                    priceToEdit={selectedRow ? selectedRow : null}
                    onClose={() => setIsEditFormOpen(false)}
                />
            </MuiModal>

            <MuiModal
                top="50%"
                left="50%"
                width={'50vh'}
                open={workingForm}
                onClose={() => setWorkingForm(false)}
            >
                <WorkingsForm
                    price_id={selectedRow?.id}
                    workingToEdit={null}
                    onClose={() => setWorkingForm(false)}
                />
            </MuiModal>
        </>
    );


};

export default PriceTable;

