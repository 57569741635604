import React, {useEffect, useState} from 'react';
import {ITransportCompany} from "../../../../../../utils/interfaces/my_documents/ITransportCompany";
import useApiGetTransportCompany from "./api-hooks/useApiGetTransportCompany";
import {useTheme} from "@mui/material/styles";
import {Box} from "@mui/material";
import LabelledMuiTypography from "../../../../../ui/mui/mui-display-data/LabelledMuiTypography";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";

interface Props {
    obj: ITransportCompany | undefined,
}

const TransportCompanyCard = ({
                                  obj: initialObj,
                              }: Props) => {


    const [obj, setObj] = useState<ITransportCompany | undefined>(initialObj)
    const theme = useTheme();
    const {t} = useTranslation();

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data: detailedObj,
    } = useApiGetTransportCompany(obj?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);


    return (
        <>
            <Box sx={{
                marginTop: 1,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                border: '1px solid lightgrey',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: theme.palette.backgroundAppGarageColor.gray_50,

            }}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.primary.main,
                        position: 'absolute',
                        top: -12,
                        left: 0,
                        backgroundColor: 'transparent',
                        paddingX: 2,
                    }}
                >
                    {t('Transport Company')}
                </Typography>
                <LabelledMuiTypography label={'business_name'} value={obj?.business_name} upperCaseValue={true}/>
                <LabelledMuiTypography label={'address'} value={obj?.address} upperCaseValue={true}/>
                <LabelledMuiTypography label={'cap'} value={obj?.cap} upperCaseValue={true}/>
                <LabelledMuiTypography label={'city'} value={obj?.city} upperCaseValue={true}/>
                <LabelledMuiTypography label={'state'} value={obj?.nation.name} upperCaseValue={true}/>
            </Box>
        </>

    );
}

export default TransportCompanyCard;