import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from '@tanstack/react-query';


const useApiDeleteWorkingOrder = (companyToken: string | undefined, pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    companyToken?: string,
    pk?: number
}, unknown> => {

    const {DELETE} = useApiCalls();

    async function doDelete({companyToken, pk}: {
        companyToken?: string,
        pk?: number
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/tannery_production/workings/${pk}/delete`);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, { companyToken?: string, pk?: number }>(
        {mutationFn: () => doDelete({companyToken: companyToken, pk: pk})});
};

export default useApiDeleteWorkingOrder