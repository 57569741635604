import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";
import {IDocTransport} from "../../../../../../../../utils/interfaces/my_documents/doc/doc_trasnport/IDocTransport";


const useApiUpdateDocTransport = (): UseMutationResult<IDocTransport, CustomAxiosError, {
    doc_year: number | undefined,
    doc_id: number | undefined,
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()
    let _doc_year: number | undefined;
    let _doc_id: number | undefined;

    const {put} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            doc_year: number | undefined,
                            doc_id: number | undefined,
                            pk: number | undefined,
                            data: any): Promise<IDocTransport> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/doc/${doc_year}/${doc_id}/doc_transport/${pk}/`, data);
            return response.data as IDocTransport;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IDocTransport, CustomAxiosError, {
        doc_year: number | undefined,
        doc_id: number | undefined,
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({doc_year, doc_id, id, data}) => {
            _doc_year = doc_year;
            _doc_id = doc_id
            return await doUpdate(selectedCompany?.company_token, doc_year, doc_id, id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ["useApiGetDocTransport", _doc_year, _doc_id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetDoc", _doc_id]});
        },
    });
};

export default useApiUpdateDocTransport;