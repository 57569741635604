import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";
import {IDocRow} from "../../../../../../../../utils/interfaces/my_documents/doc/IDocRow";
import {useMyDocuments} from "../../../../../../../../utils/contexts/my_documents/my_documents-context";


const useApiCreateDocRow = (): UseMutationResult<IDocRow, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {selectedDocDetail} = useMyDocuments();
    const {post} = useApiCalls()


    async function doCreate({companyToken, doc_year, doc_id, data}: {
        companyToken?: string,
        doc_year: number | undefined,
        doc_id: number | undefined,
        data: any
    }): Promise<IDocRow> {
        try {
            const response = await post(`/api/company/${companyToken}/my_documents/doc/${doc_year}/${doc_id}/doc_row/`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IDocRow, CustomAxiosError, any>(
        {
            mutationFn: ({doc_year, doc_id, data}: {
                doc_year: number | undefined,
                doc_id: number | undefined,
                data: any
            }) => {
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    doc_year,
                    doc_id,
                    data
                })
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetDocRows", selectedDocDetail?.id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetDoc", selectedDocDetail?.id]});
            },
        }
    );
};


export default useApiCreateDocRow;
