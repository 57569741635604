import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IPeriod} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IPeriod";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiGetReportProduction = (): UseQueryResult<IPeriod, CustomAxiosError> => {
    const {selectedCompany} = useAuth()
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IPeriod> {
        try {
            const response = await get(`/api/company/${company_token}/lwg_dashboard/lwg_reports/production`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetReportProduction"],
        queryFn: () => doGet(selectedCompany?.company_token),
    })


};

export default useApiGetReportProduction