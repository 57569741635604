import React from 'react';
import {
    IOrderScheduleDetail
} from '../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail';
import {GiBabyBottle, GiBarrel, GiCow} from "react-icons/gi";
import {FaBalanceScale, FaSitemap, FaTimes} from "react-icons/fa";
import {MdWorkspaces} from "react-icons/md";
import {PiRuler} from "react-icons/pi";
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {ILot} from "../../../../../utils/interfaces/tannery_raw_material/ILot";
import {BiLeaf} from "react-icons/bi";
import {FiDownloadCloud} from "react-icons/fi";


const getIconsForCustomField = (row: ILot, theme: any) => {
    const icons = [];

    const icon_color = theme.palette.primary.main
    const disabled_color = theme.palette.disabledColor.main


    icons.push(
        (row.lwg_site.coefficient > 0 && row.lwg_coefficient > 0) ?
            <BiLeaf title='LWG' color={icon_color}/> :
            <BiLeaf title='NO LWG' color='red'/>,
        row.choices.length > 0 ? <FaSitemap
                title={row.total_choices_pcs - row.total_choices_used_pcs <= 0 ? 'sold out of pieces' : 'choices inside'}
                color={row.total_choices_pcs - row.total_choices_used_pcs <= 0 ? 'red' : icon_color}/> :
            <FaSitemap title='' color='#f3f4f6'/>,
        row.pdf_doc ? <FiDownloadCloud title='PDF DOC INSIDE' color={icon_color}/> :
            <FiDownloadCloud title='' color={disabled_color}/>,
    );


    return icons;
};

export const customCellRenderer = (rowData: ILot, theme: any) => {
    const icons = getIconsForCustomField(rowData, theme);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customCellRenderer;
