import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from '@tanstack/react-query';


const useApiDeleteLot = (companyToken: string | undefined, lot_id: number | undefined): UseMutationResult<void, CustomAxiosError, {
    companyToken?: string,
    lot_id?: number
}, unknown> => {

    const {DELETE} = useApiCalls()

    async function doDelete({companyToken, lot_id}: {
        companyToken?: string,
        lot_id?: number
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/tannery_raw_material/lots/${lot_id}/delete`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, { companyToken?: string, lwg_site_id?: number }>(
        {mutationFn: () => doDelete({companyToken, lot_id})});
};

export default useApiDeleteLot