import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {Add, Edit} from "@mui/icons-material";
import {
    IMyCompanyBank
} from "../../../../../../utils/interfaces/my_company/my_company_registry/my_company_bank/IMyCompanyBank";
import useApiGetMyCompanyBank from "./api-hooks/useApiGetMyCompanyBank";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import LabelledMuiTypography from "../../../../../ui/mui/mui-display-data/LabelledMuiTypography";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import MyCompanyBankForm from "./MyCompanyBankForm";

interface Props {
    obj: IMyCompanyBank | undefined,
}

const MyCompanyBankCard = ({
                               obj: initialObj,
                           }: Props) => {


    const theme = useTheme();
    const [obj, setObj] = useState<IMyCompanyBank | undefined>(initialObj)
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data: detailedObj,
    } = useApiGetMyCompanyBank(obj?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);


    return (
        <>
            <Box sx={{position: 'relative', width: '100%'}}>

                <Box sx={{position: 'absolute', top: -10, right: 0, display: 'flex', alignItems: 'center', gap: 2}}
                >
                    <MRTMuiButton
                        small={true}
                        icon={obj ?
                            <Edit sx={{color: theme.palette.primary.main}}/>
                            :
                            <Add sx={{color: theme.palette.primary.main}}/>}
                        onClick={() => {
                            setIsEditFormOpen(true)
                        }} label={obj ? 'edit bank' : 'add bank'}/>
                </Box>

                <Box sx={{
                    display: 'flex-1',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    border: '1px solid lightgrey',
                    borderRadius: '4px',
                    padding: '10px',
                    backgroundColor: theme.palette.backgroundAppGarageColor.gray_50
                }}>
                    <LabelledMuiTypography label={'iban'} value={detailedObj?.iban}
                                           upperCaseValue={true}/>
                    <LabelledMuiTypography label={'bank'} value={detailedObj?.bank.name}
                                           upperCaseValue={true}/>
                    <LabelledMuiTypography label={'agency'} value={detailedObj?.bank.agency}
                                           upperCaseValue={true}/>
                </Box>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'120vh'}
                    open={isEditFormOpen}
                    disableBackdropClick={true}
                    onClose={() => setIsEditFormOpen(false)}
                >
                    <MyCompanyBankForm
                        objToEdit={obj}
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </MuiModal>

            </Box>
        </>

    );
}

export default MyCompanyBankCard;