import {ICategory} from "../../../../../../utils/interfaces/service/people/ICategory";
import {IProduct} from "../../../../../../utils/interfaces/service/people/product_list/IProduct";
import {IPeopleProduct} from "../../../../../../utils/interfaces/service/people/product_list/IPeopleProduct";

export const filterProductList = (
    data: IPeopleProduct[],
    filterPerson: string,
    filterProduct: string,
    category_person?: string,
    selected_categories?: string[] | [],
) => {
    const filteredPeople = data.filter((peopleProduct) =>
        peopleProduct.people_name.toLowerCase().includes(filterPerson.toLowerCase())
    );

    let filteredDataByProduct = filteredPeople.map((peopleProduct) => {
        const matchingChoices = peopleProduct.products.filter((product: IProduct) =>
            product.product_name.toLowerCase().includes(filterProduct.toLowerCase())
        );

        return {
            ...peopleProduct,
            products: matchingChoices,
        };
    }).filter((filteredLot) =>
        filterProduct.length === 0 || filteredLot.products.length > 0
    );

    if (selected_categories && selected_categories.length > 0) {
        filteredDataByProduct = filteredDataByProduct.filter((people) =>
            selected_categories.some(category =>
                people.peoplecategory_set.some((peopleCategory: ICategory) =>
                    peopleCategory.category_name === category
                )
            )
        );
    }

    if (category_person) {
        return filteredDataByProduct.filter(item =>
            item.peoplecategory_set.some((category: ICategory) => category.category_name === category_person)
        );
    } else {
        return filteredDataByProduct;
    }
};
