import React from 'react';

type Props = {
    maxWidth?: string,
    children: React.ReactNode,
    className?: string,
}

const MaxWidthDiv = ({maxWidth, children, className = ""}: Props) => {
    const divStyle = {
        maxWidth: maxWidth || '1280px', // Default to '1280px' if maxWidth is not provided
        width: '100%',
        margin: '0 auto'
    };

    return (
        <div style={divStyle} className={className}>
            {children}
        </div>
    );
};

export default MaxWidthDiv;
