import {useState} from 'react';
import {useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useFetchPdf = () => {
    const {get} = useApiCalls();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    const {selectedCompany} = useAuth();

    const fetchPdf = async (tannery_dyeing_id: number | undefined) => {
        setLoading(true);
        setError(null);

        const url = `/api/company/${selectedCompany?.company_token}/tannery_dyeing/${tannery_dyeing_id}/get_pdf`

        try {
            const response = await get(url, {}, {responseType: 'blob'});
            const blob = new Blob([response.data], {type: 'application/pdf'});
            const blobUrl = window.URL.createObjectURL(blob);
            
            window.open(blobUrl, '_blank');
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    return {fetchPdf, loading, error};
};

export default useFetchPdf;
