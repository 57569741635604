import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IPaymentDetailedWithString
} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IPaymentDetailedWithString";


const useApiGetDocPaymentDetailed = (doc_payment: string | undefined): UseQueryResult<IPaymentDetailedWithString, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, doc_payment: string | undefined): Promise<IPaymentDetailedWithString> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/payment/${doc_payment}/doc_payment_detailed`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetDocPaymentDetailed", doc_payment],
        queryFn: () => doGet(selectedCompany?.company_token, doc_payment),
        enabled: !!doc_payment && doc_payment.length === 8
    })
};

export default useApiGetDocPaymentDetailed