import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IMacroLinea} from "../../../../../../../../utils/interfaces/tannery_production/articles/IMacroLinea";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";


const useApiGetMacroLinea = (
    companyToken: string | undefined,
    pk: number | undefined,
): UseQueryResult<IMacroLinea, CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, pk: number | undefined,): Promise<IMacroLinea> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/articles/macro_linee_produzione/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["getMacroLineeProduzione", pk],
        queryFn: () => doGet(companyToken, pk),
        enabled: !!pk,
    })
};

export default useApiGetMacroLinea