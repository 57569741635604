import {motion} from "framer-motion";
import React from "react";

type Props = {

    children?: React.ReactNode,
    isOpen: boolean,
}


const AccordionMotionDiv = ({isOpen, children}: Props) => {
    const variants = {
        hidden: {
            y: -100, // Moves the element 100 pixels above its original position
            opacity: 0,
        },
        visible: {
            y: 0, // Restores the original position
            opacity: 1,
            transition: {
                type: "spring", // You can adjust the animation type
                damping: 10, // You can adjust the damping for the spring animation
                stiffness: 80, // You can adjust the stiffness for the spring animation
            },
        },
    };

    return (
        <motion.div
            initial="hidden"
            animate={isOpen ? "visible" : "hidden"}
            variants={variants}
        >
            {children}
            {/*<div className="filter_motion_div-divider-bar"></div>*/}
        </motion.div>
    );
};

export default AccordionMotionDiv;
