import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IPaymentsNumber
} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IPaymentsNumber";


const useApiGetPaymentsNumbers = (): UseQueryResult<IPaymentsNumber[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IPaymentsNumber[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/payment/payments_number`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetPaymentsNumbers"],
        queryFn: () => doGet(selectedCompany?.company_token,),
    })
};

export default useApiGetPaymentsNumbers