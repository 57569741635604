import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {IColor} from "../../../../../../../utils/interfaces/tannery_production/articles/IColor";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import useApiGetColors from "./api-hooks/useApiGetColors";
import useApiGetColor from "./api-hooks/useApiGetColor";
import Loading from "../../../../../../ui/Loading";
import {Box, TextField} from "@mui/material";
import MuiAutocomplete from "../../../../../../ui/mui/mui-input/MuiAutoComplete";


interface Props {
    article_id: number,
    obj: IColor | undefined,
    onChange: (obj: IColor | undefined) => void,
    className: string,
    label?: string,
    clearable?: boolean
    required?: boolean
}

const ColorSelect = ({obj: initialObj, onChange, label, clearable, required, article_id, ...props}: Props) => {
    const {displayError, displayMessage} = useToast()
    const {selectedCompany} = useAuth()
    const {t} = useTranslation();
    const {className = ''} = props;
    const [objs, setObjs] = useState<IColor[]>([]);
    const [obj, setObj] = useState<IColor | undefined>(initialObj)

    const {
        data,
        isLoading,
        error,
    } = useApiGetColors(selectedCompany?.company_token, article_id);
    if (error) {
    }

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }

    }, [initialObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IColor | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }

    };

    const {
        data: detailedObj,
    } = useApiGetColor(selectedCompany?.company_token, article_id, obj?.id);


    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>

                        <MuiAutocomplete
                            id="color-select"
                            options={objs}
                            getOptionLabel={(option) => option.color_name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.color_name === value?.color_name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('color')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>

                </>
            )}
        </>
    );
}

export default ColorSelect;