import React, {useEffect, useMemo, useState} from 'react';
import {IDocList} from "../../../../../../utils/interfaces/my_documents/doc/IDocList";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import useApiDeleteDoc from "./api-hooks/useApiDeleteDoc";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import useApiGetDocs from "./api-hooks/useApiGetDocs";
import customCellRenderer from "./customCellRenderer";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_Row,
    useMaterialReactTable
} from "material-react-table";
import {convertToCsv} from "../../../../../../logics/csv_converter";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Delete, Edit, Print} from "@mui/icons-material";
import {Avatar, Box, Button} from "@mui/material";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {CSVLink} from "react-csv";
import Loading from "../../../../../ui/Loading";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import DocForm from "./DocForm";
import MuiFetchingSpinner from "../../../../../ui/mui/MuiFetchingSpinner";
import {IoDocuments} from "react-icons/io5";
import {useMyDocuments} from "../../../../../../utils/contexts/my_documents/my_documents-context";
import {useService} from "../../../../../../utils/contexts/service/service-context";
import {LiaFileInvoiceDollarSolid} from "react-icons/lia";
import DocsInvoicingForm from "./docs_invoicing/DocsInvoicingForm";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";
import useFetchPdfDoc from "./api-hooks/useFetchPdfDoc";

interface IDocWithCustomField extends IDocList {
    custom_field: JSX.Element;
}


const DocTable = () => {
    const {selectedSystemYear} = useAppGarage();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const {selectPerson} = useService()
    const {
        selectDocDetail,
    } = useMyDocuments()

    const [selectedRow, setSelectedRow] = useState<IDocList | undefined>()
    const [rows, setRows] = useState<IDocList[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isDocInvoicingFormOpen, setIsDocInvoicingFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteDoc(selectedRow?.doc_year, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {

            await apiDelete({doc_year: selectedRow?.doc_year, pk: selectedRow?.id});
            await queryClient.invalidateQueries({queryKey: ['useApiGetDocs']});
            displayMessage('Doc deleted successfully');

        } catch (error) {
        }
        setSelectedRow(undefined);
        selectDocDetail(undefined);
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {
        data,
        error,
        isFetching,
        isLoading,
        refetch
    } = useApiGetDocs();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setRows(data);
            handleSetCsvData(data);
        }
    }, [data]);

    const [docsWithCustomField, setDocsWithCustomField] = useState<IDocWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IDocWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customCellRenderer(row, theme, t),
        }));
        setDocsWithCustomField(dataWithCustomField);
    }, [rows]);


    const keysToInclude = [
        "doc_number",
        "doc_prefix",
        "doc_type_name",
        "doc_year",
        "doc_date",
        "doc_reason_name",
        "doc_reason_extended_name",
        "people_name",
    ];

    const customLabels = [
        t("doc_number"),
        t("doc_prefix"),
        t("doc_type_name"),
        t("doc_year"),
        t("doc_date"),
        t("doc_reason_name"),
        t("doc_reason_extended_name"),
        t("people_name"),
    ];

    const handleExportRows = (rows: MRT_Row<IDocList>[]) => {
        const rowData = rows.map((row) => row.original);
        handleSetCsvData(rowData);

    };

    const [csvData, setCsvData] = useState<string | undefined>();
    const handleSetCsvData = (jsonData: IDocList[]) => {
        const csvData: string = convertToCsv(jsonData, keysToInclude, customLabels);
        setCsvData(csvData);
    };

    const {fetchPdf} = useFetchPdfDoc();
    const handleGetPdfFile = async (doc: IDocList) => {
        await fetchPdf(doc.doc_year, doc.id);
    };


    const columns = useMemo<MRT_ColumnDef<IDocList>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'doc_number',
                header: t('doc_number'),
                filterFn: 'contains',
                headerTooltip: 'Tooltip text for custom_field',
            },
            {
                accessorKey: 'doc_prefix',
                header: t('doc_prefix'),
                filterFn: 'contains',


            },
            {
                accessorKey: 'doc_type_name',
                header: t('doc_type_name'),
                filterFn: 'contains',
                accessorFn: (row) => {
                    return row.company_doc_type.doc_type.name
                },

                // title={doc.doc_type_description} todo

            },
            {
                accessorKey: 'doc_year',
                header: t('doc_year'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'doc_date',
                header: t('doc_date'),
                filterFn: 'contains',
            },
            {
                accessorKey: 'doc_reason_name',
                header: t('doc_reason_name'),
                filterFn: 'contains',
                accessorFn: (row) => {
                    return row.company_doc_reason.doc_reason.name
                },
                // title={doc.doc_reason_description} todo
            },
            {
                accessorKey: 'doc_reason_extended_name',
                header: t('doc_reason_extended_name'),
                filterFn: 'contains',
                accessorFn: (row) => {
                    return row.company_doc_reason.doc_reason.extended_name
                },
            },
            {
                accessorKey: 'people_name',
                header: t('people_name'),
                filterFn: 'contains',
                accessorFn: (row) => {
                    return row.fiscal_registry.people.people_name
                },
            },

        ],
        [rows]
    );


    const table = useMaterialReactTable<IDocList>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: docsWithCustomField ? docsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: true,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true);
                    closeMenu();
                }}
                table={table}
                disabled={!!row.original.invoiced_at}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu();
                }}
                table={table}
                disabled={!!row.original.invoiced_at}
            />,
            <MRT_ActionMenuItem
                icon={<Print color="primary"/>}
                key="print"
                label={t("print")}
                onClick={() => {
                    handleGetPdfFile(row.original)
                    closeMenu();
                }}
                table={table}
                disabled={false}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <IoDocuments color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'docs'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            selectPerson(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_doc'}/>
                    <MRTMuiButton
                        icon={<LiaFileInvoiceDollarSolid
                            style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setIsDocInvoicingFormOpen(true)
                        }} label={'invoice docs'}/>
                    {csvData &&
                        <Button
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                            onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                            }
                            startIcon={<FileDownloadIcon/>}
                        >

                            <CSVLink data={csvData} filename={'all_rows.csv'}>
                                {t('export_to_csv')}
                            </CSVLink>
                        </Button>}
                </>
            </Box>
        ),
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {!isEditFormOpen && (
                    <>
                        <MaterialReactTable table={table}/>

                        <MuiAlertDialog
                            open={isDeleteConfirmationOpen}
                            title="delete_confirmation"
                            content="ask_for_delete"
                            onClose={handleCancelDelete}
                            onConfirm={handleConfirmDelete}
                        />
                    </>

                )}

                <DocForm docNotDetailed={selectedRow}
                         isOpen={isEditFormOpen}
                         onClose={
                             () => {
                                 setSelectedRow(undefined);
                                 selectDocDetail(undefined);
                                 setIsEditFormOpen(false);
                                 refetch()
                             }
                         }/>

                <MuiModal top="50%" left="50%" width={'120vh'} open={isDocInvoicingFormOpen}
                          onClose={() => setIsDocInvoicingFormOpen(false)}>
                    <DocsInvoicingForm
                        isOpen={isDocInvoicingFormOpen}
                        onClose={() => setIsDocInvoicingFormOpen(false)}
                    />
                </MuiModal>


                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default DocTable;
