import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IPrice} from "../../../../../../../../utils/interfaces/service/people/product_list/price/IPrice";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";


const useApiGetPrices = (person_id: number | undefined,
                         product_id: number | undefined): UseQueryResult<IPrice[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined,
                         person_id: number | undefined,
                         product_id: number | undefined): Promise<IPrice[]> {
        try {
            const response = await get(`/api/company/${company_token}/service/people/${person_id}/product/${product_id}/price/`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }


    return useQuery({
        queryKey: ["useApiGetPrices", person_id, product_id],
        queryFn: () => doGet(selectedCompany?.company_token, person_id, product_id),
        enabled: !!person_id && !!product_id,
    })

};

export default useApiGetPrices

