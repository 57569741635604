import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {
    IStatementOfIntent
} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/IStatementOfIntent";


const useApiCreateStatementOfIntent = (): UseMutationResult<IStatementOfIntent, CustomAxiosError, {
    fiscal_registry_id: number | undefined,
    data: any
}> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();

    const {post} = useApiCalls()

    let _fiscal_registry_id: number | undefined;

    async function doCreate({companyToken, fiscal_registry_id, data}: {
        companyToken?: string,
        fiscal_registry_id: number | undefined
        data: any
    }): Promise<IStatementOfIntent> {
        try {
            const response = await post(`/api/company/${companyToken}/my_documents/fiscal_registry/${fiscal_registry_id}/statement_of_intent`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IStatementOfIntent, CustomAxiosError, any>(
        {
            mutationFn: ({fiscal_registry_id, data}: {
                fiscal_registry_id: number | undefined,
                data: any
            }) => {
                _fiscal_registry_id = fiscal_registry_id;

                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    fiscal_registry_id,
                    data
                })
            },
            onSuccess: async (obj) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetStatementOfIntents", _fiscal_registry_id]});

            },
        }
    );
};


export default useApiCreateStatementOfIntent;
