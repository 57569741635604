import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";

interface MuiReadOnlyBoxProps {
    label: string | undefined;
    value?: string | number;
    borderColor?: string;
}

const MuiReadOnlyBoxBody: React.FC<MuiReadOnlyBoxProps> = ({
                                                               label,
                                                               value,
                                                               borderColor,
                                                           }) => {
    const theme = useTheme();
    const _borderColor = borderColor ? borderColor : theme.palette.primary.light;
    const {t} = useTranslation()


    return (
        <Box
            sx={{
                border: `2px solid ${_borderColor}`,
                borderRadius: 4,
                padding: 1,
                bgcolor: 'white',
                position: 'relative',
            }}
        >
            <Typography
                variant="subtitle2"
                sx={{
                    color: _borderColor,
                    position: 'absolute',
                    top: -20,
                    left: 4,
                    bgcolor: 'transparent',
                    paddingX: 1,
                }}
            >
                {label ? t(label) : ''}
            </Typography>
            <Typography
                sx={{
                    color: theme.palette.textAppGarageColor.tw600,
                    paddingX: 1,
                }}
            >{typeof value === 'string' ? t(value) : value}

            </Typography>
        </Box>
    );
};

export default MuiReadOnlyBoxBody;
