import React, {ReactNode} from 'react';
import {motion} from 'framer-motion';

interface AnimatedRowProps {
    children: ReactNode;
    index?: number; // Make the index prop optional
}

const AnimatedRow: React.FC<AnimatedRowProps> = ({children, index}) => {
    return (
        <motion.div
            initial={{opacity: 0, x: 100}}
            animate={{opacity: 1, x: 0}}
            exit={{opacity: 0, x: 100}}
            transition={{duration: 0.15, delay: index !== undefined ? index * 0.1 : 0}}
        >
            {children}
        </motion.div>
    );
};

export default AnimatedRow;
