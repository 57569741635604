import {CustomAxiosError, useApiCalls} from "../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../contexts/auth-context";

interface IUserLevel {
    user_level: number;
}


const useApiGetUserLevel = (): UseQueryResult<IUserLevel, CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    const getData = async (company_token: string | undefined): Promise<IUserLevel> => {
        try {
            const response = await get(`/api/company_user/${company_token}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['userLevel', selectedCompany?.company_token],
        enabled: !!selectedCompany?.company_token,
        queryFn: () => getData(selectedCompany?.company_token)
    })
};

export default useApiGetUserLevel;
