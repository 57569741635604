import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {AiOutlineBorderlessTable} from "react-icons/ai";
import {IColor} from "../../../../../../../utils/interfaces/tannery_production/articles/IColor";


const getIconsForCustomField = (row: IColor, theme: any, t: any) => {
    const icons = [];
    const icon_color = theme.palette.primary.main

    icons.push(
        row.orders.length > 0 &&
        <AiOutlineBorderlessTable color={icon_color} title='ORDERS INSIDE'></AiOutlineBorderlessTable>
    );


    return icons;
};

export const customCellRenderer = (rowData: IColor, theme: any, t: any) => {
    const icons = getIconsForCustomField(rowData, theme, t);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customCellRenderer;
