import {CustomAxiosError, useApiCalls} from "../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {IVatCode} from "../../../../../utils/interfaces/my_documents/IVatCode";


const useApiGetVatCodes = (): UseQueryResult<IVatCode[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IVatCode[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/vat_code`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetVatCodes"],
        queryFn: () => doGet(selectedCompany?.company_token),
    })
};

export default useApiGetVatCodes