import {AxiosError, AxiosResponse} from 'axios';
import useApiAxiosInstance from "../utils/custom_hooks/useApiAxiosInstance";


export function useApiCalls() {
    const {interceptedAxiosInstance} = useApiAxiosInstance()

    const get = async (url: string, params?: any, config = {}) => {
        try {
            if (params) {
                return await interceptedAxiosInstance.get(url, {params: {...params}});
            }
            return await interceptedAxiosInstance.get(url);
        } catch (error) {
            throw error;
        }
    }

    const post = async (url: string, params?: any, config = {}) => {
        try {
            return await interceptedAxiosInstance.post(url, {...params});
        } catch (error) {
            throw error;
        }
    }

    const postEncoded = async (url: string, params?: any, config = {}) => {
        try {
            return await interceptedAxiosInstance.post(url, new URLSearchParams({...params}));
        } catch (error) {
            throw error;
        }
    }

    const postWithFile = async (url: string, formData: FormData, config = {}) => {
        try {
            return await interceptedAxiosInstance.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                ...config
            });
        } catch (error) {
            throw error;
        }
    }

    const put = async (url: string, data?: any, config = {}) => {
        try {
            return await interceptedAxiosInstance.put(url, {...data});
        } catch (error) {
            throw error;
        }
    }

    const putWithFile = async (url: string, formData: FormData, config = {}) => {
        try {
            return await interceptedAxiosInstance.put(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                ...config
            });
        } catch (error) {
            throw error;
        }
    }

    const DELETE = async (url: string, data?: any, config = {}) => {
        try {
            return await interceptedAxiosInstance.delete(url, {...data});
        } catch (error) {
            throw error;
        }
    }

    return {
        get,
        post,
        postEncoded,
        postWithFile,
        put,
        putWithFile,
        DELETE,
    };
};

export interface CustomAxiosError extends AxiosError<unknown, AxiosResponse<unknown>> {
    code: string,
    message: string,
    response: {
        data: {
            non_field_errors: [];
            detail: string;
            code: string
            [key: string]: string | string[] | undefined;
        };
        status: number;
        statusText: string;
        headers: any;
        config: any;
    };
}


