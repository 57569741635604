import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IWarehouseProductMovement} from "../../../../../../utils/interfaces/my_warehouse/IWarehouseProductMovement";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import BackHandIcon from '@mui/icons-material/BackHand';


const useMyWarehouseProductCustomCellRenderer = (row: IWarehouseProductMovement, theme: any) => {


    const getIconsForCustomField = (row: IWarehouseProductMovement, theme: any) => {
        const icons = [];


        if (row.is_manual) {
            icons.push(
                <MRTMuiButton
                    small={true}
                    icon={<BackHandIcon color={theme.palette.secondary.main}/>}
                    onClick={() => {
                    }}
                    label={''}/>
            )
        }


        return icons;
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" sx={{gap: 0.5}}>
                    <span>{icons}</span>
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useMyWarehouseProductCustomCellRenderer;