import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import Loading from "../../../ui/Loading";
import MuiAutocomplete from "../../../ui/mui/mui-input/MuiAutoComplete";
import {useToast} from "../../../../utils/contexts/toast-context";
import {IUm} from "../../../../utils/interfaces/service/IUm";
import useApiGetUm from "./api-hooks/useApiGetUm";
import useApiGetUms from "./api-hooks/useApiGetUms";


interface Props {
    obj: IUm | undefined,
    onChange: (obj: IUm | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
    clearable?: boolean
}

const UmSelect = ({
                      obj: initialObj,
                      label,
                      onChange,
                      required,
                      clearable,
                      ...props
                  }: Props) => {

    const {displayError} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)


    const [objs, setObjs] = useState<IUm[]>([]);
    const [obj, setObj] = useState<IUm | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
        error,
    } = useApiGetUms();


    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetUm(obj?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IUm | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            disableClearable={!clearable}
                            id="um-select"
                            options={objs}
                            getOptionLabel={(option: IUm) => option.um}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.um === value?.um}
                            renderInput={(params: any) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('um')}
                                    required={required}
                                />
                            )}
                            borderColor={theme.palette.primary.light}
                            onFocusBorderColor={theme.palette.primary.dark}
                            minWidth={300}
                        >
                        </MuiAutocomplete>
                    </Box>
                </>
            )}
        </>
    );
}

export default UmSelect;