import React, {useEffect, useState} from 'react';
import {ILwgSite} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/ILwgSite";
import AgDiv from "../../../../../ui/divs/AgDiv";
import {SiTestcafe} from "react-icons/si";
import LotList from "./LotList";
import {cutString} from "../../../../../../logics/helpers";
import {BiLeaf} from "react-icons/bi";
import {FaSkull} from "react-icons/fa";
import {useTranslation} from "react-i18next";


interface Props {
    lwgSite: ILwgSite,
}

const LwgSiteRow = ({lwgSite: initialLwgSite}: Props) => {
    const {t} = useTranslation()
    const [lwgSite, setLwgSite] = useState<ILwgSite>(initialLwgSite);
    const [selectedLwgSite, setSelectedLwgSite] = useState<ILwgSite | undefined>();

    const handleSelection = () => {
        if (selectedLwgSite?.id === lwgSite.id) {
            setSelectedLwgSite(undefined);
        } else {
            setSelectedLwgSite(lwgSite)
        }
    }

    useEffect(() => {
        setLwgSite(initialLwgSite)
    }, [initialLwgSite]);


    return (
        <>
            <div
                className={`flex flex-row ${selectedLwgSite?.id === lwgSite.id ? 'font-bold' : ''}`}
                style={{flex: 1}}
            >
                <div
                    onClick={handleSelection}
                    className={`flex flex-row ${selectedLwgSite?.id === lwgSite.id ? 'font-bold' : ''}`}
                    // style={{flex: 1}}
                >
                    <AgDiv value={cutString(lwgSite.lwg_site_name, 25)}
                           minWidth="320px" maxWidth="320px"
                           icons={[lwgSite.has_zero_records &&
                           <FaSkull className='animate-pulse text-red-600' title={t("has_record_zero")}/>
                               , <BiLeaf
                                   title={t(lwgSite.coefficient > 0 ? lwgSite.has_zero_coefficient_lots ? 'is_lwg_with_no_lwg_lots' : 'is_lwg' : 'no_lwg')}
                                   className={lwgSite.has_zero_coefficient_lots ? 'animate-pulse' : ''}
                                   color={lwgSite.coefficient > 0 ? '#60b8c3' : 'red'}
                               />,
                               <SiTestcafe color={'#60b8c3'}/>]}
                    />
                    <AgDiv label='' value={lwgSite.coefficient.toString()}
                           minWidth="100px" maxWidth="100px"/>
                    <AgDiv label='' value={lwgSite.pcs_produced.toFixed(2)}
                           minWidth="260px" maxWidth="260px"/>
                    <AgDiv label='' value={lwgSite.sqm_produced.toFixed(2)}
                           minWidth="260px" maxWidth="260px"/>
                    <AgDiv label='' value={lwgSite.lwg_sqm_produced.toFixed(2)}
                           minWidth="260px" maxWidth="260px"/>
                    <AgDiv label='' value={lwgSite.coefficient_sqm_produced.toFixed(2)}
                           minWidth="260px" maxWidth="260px"/>
                </div>

            </div>
            {selectedLwgSite?.id === lwgSite.id && <LotList lots={lwgSite.lots}/>}


        </>
    );
};

export default LwgSiteRow;
