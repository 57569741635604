import React, {useEffect, useMemo, useState} from 'react';
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import {IRawChoice} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IRawChoice";
import useApiGetRawChoiceOrders from "./api-hooks/useApiGetRawChoiceOrders";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {useTanneryProduction} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";
import useApiDeleteRawChoiceOrder from "./api-hooks/useApiDeleteRawChoiceOrder";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    getMuiTableBodyCellProps, getMuiTableBodyRowProps,
    getMuiTableHeadCellProps, getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import {Avatar, Box, Popover} from "@mui/material";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import {Delete, Edit} from "@mui/icons-material";
import RawMaterialForm from "./RawMaterialForm";
import {FaLink, FaUnlink} from "react-icons/fa";


const RawMaterialLinkedTable = () => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();
    const {selectedCompanyOrderSchedule} = useTanneryProduction()
    const {availableCompanyUserApplications} = useAppGarage()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [selectedRow, setSelectedRow] = useState<IRawChoice | undefined>()


    const {
        data,
        isLoading,
        error,
        refetch,
    } = useApiGetRawChoiceOrders(selectedCompany?.company_token, selectedCompanyOrderSchedule?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteRawChoiceOrder(selectedCompany?.company_token, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({companyToken: selectedCompany?.company_token, pk: selectedRow?.id});
            displayMessage('ChoiceOrder deleted successfully');
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the ChoiceOrder');
            }
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['getChoicesByLot']});
        await queryClient.invalidateQueries({queryKey: ['getDyeByOrder']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
        availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetReportProduction']});
        availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetPendingOrders']});
        availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetReportWorkings']});
        availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['getLwgVerificationPeriodOrders']});
        refetch();
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const totalPcs = useMemo(() => data?.reduce((acc, curr) => acc + curr.quantity, 0), [data]);

    const columns = useMemo<MRT_ColumnDef<IRawChoice>[]>(
        () => [
            {
                accessorKey: 'parent_lot.lot_id',
                header: t('lot'),

            },
            {
                accessorKey: 'parent_lot.people.people_name',
                header: t('supplier'),

            },
            {
                accessorKey: 'parent_lot.lwg_site.lwg_site_name',
                header: t('lwg_site'),
            },
            {
                accessorKey: 'choice.lot_id',
                header: t('choice_name'),
            },
            {
                accessorKey: 'quantity',
                header: t('pcs'),
                aggregationFn: 'sum',
                Footer: () => <div>{totalPcs}</div>,
            },


        ],
        [totalPcs]
    );


    const table = useMaterialReactTable<IRawChoice>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: data ? data : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enablePagination: false,
        enableFilters: false,
        enableColumnActions: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        initialState: {density: 'compact', pagination: {pageSize: 25, pageIndex: 0}},
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<FaUnlink color={theme.palette.warning.main}/>}
                key="unlink"
                label={t("unlink")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <FaLink color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'raw_material_linked_choices'}/>
                </>
            </Box>
        ),
    });


    return <>

        <>
            <MaterialReactTable table={table}/>

            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />

            <Popover
                id={'edit_popover'}
                open={isEditFormOpen}
                anchorEl={anchorEl}
                onClose={() => setIsEditFormOpen(false)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
            >
                <RawMaterialForm choiceOrderToEdit={selectedRow?.id}
                                 onClose={() => setIsEditFormOpen(false)}/>
            </Popover>
        </>
    </>;
};

export default RawMaterialLinkedTable;
