import React, {useEffect, useRef, useState} from 'react';
import AnimatedRow from "../../../../../ui/AnimatedRow";
import {ILot} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/ILot";
import LotRow from "./LotRow";
import AgDiv from "../../../../../ui/divs/AgDiv";
import {ILwgSite} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/ILwgSite";

interface Props {
    scrollableHeight?: number;
    lots: ILot[]
}

const LotList = ({scrollableHeight = 100, lots: initialLots}: Props) => {
    const [lots, setLots] = useState<ILot[]>(initialLots);
    const [filteredData, setFilteredData] = useState<ILot[] | undefined>(lots);
    const [totalPcs, setTotalPcs] = useState<number>()
    const [totalSqm, setTotalSqm] = useState<number>()
    const [totalLwgSqm, setTotalLwgSqm] = useState<number>()
    const [totalCoefficientSqm, setTotalCoefficientSqm] = useState<number>()

    useEffect(() => {
        setLots(initialLots)
    }, [initialLots]);

    useEffect(() => {
        if (lots) {
            const total_pcs = lots.reduce((accumulator: any, object: ILot) => {
                return accumulator + object.pcs_produced;
            }, 0);
            const total_sqm = lots.reduce((accumulator: any, object: ILot) => {
                return accumulator + object.sqm_produced;
            }, 0);
            const total_lwg_sqm = lots.reduce((accumulator: any, object: ILot) => {
                return accumulator + object.lwg_sqm_produced;
            }, 0);
            const total_coefficient_sqm = lots.reduce((accumulator: any, object: ILot) => {
                return accumulator + object.coefficient_sqm_produced;
            }, 0);

            setTotalPcs(total_pcs);
            setTotalSqm(total_sqm);
            setTotalLwgSqm(total_lwg_sqm);
            setTotalCoefficientSqm(total_coefficient_sqm)
            setFilteredData(lots);
        }

    }, [lots]);


    const mapped_data =
        filteredData?.map((obj: ILot, index: any) =>
            <AnimatedRow key={obj.id} index={index / 3}>
                <LotRow lot={obj}/>
            </AnimatedRow>
        )


    return (

        <>
            <div className="p-4 ml-8">
                <div>
                    <div className='flex flex-row capitalize font-bold'>
                        <AgDiv label={'lots'} value={''}
                               minWidth="320px" maxWidth="320px" className={'AgDivHeader'}

                        />
                        <AgDiv label='pcs_produced' value={''}
                               minWidth="260px" maxWidth="260px" className={'AgDivHeader'}/>
                        <AgDiv label='sqm_produced' value={''}
                               minWidth="260px" maxWidth="260px" className={'AgDivHeader'}/>
                        <AgDiv label='lwg_sqm_produced' value={''}
                               minWidth="260px" maxWidth="260px" className={'AgDivHeader'}/>
                        <AgDiv label='coefficient_sqm_produced' value={''}
                               minWidth="260px" maxWidth="260px" className={'AgDivHeader'}/>
                    </div>
                    {mapped_data}
                    <div className='flex flex-row'>
                        <AgDiv value={'Totals:'}
                               minWidth="320px" maxWidth="320px"
                               className={'agDiv_transparent_div ml-1'}

                        />
                        <AgDiv label='' value={totalPcs && totalPcs.toFixed(2)}
                               minWidth="260px" maxWidth="260px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalSqm && totalSqm.toFixed(2)}
                               minWidth="260px" maxWidth="260px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalLwgSqm && totalLwgSqm.toFixed(2)}
                               minWidth="260px" maxWidth="260px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalCoefficientSqm && totalCoefficientSqm.toFixed(2)}
                               minWidth="260px" maxWidth="260px" className={'AgDivFooter'}/>
                    </div>
                </div>


            </div>
        </>)


};

export default LotList;