import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {Box} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {useTranslation} from "react-i18next";
import {IWarehouseProductMovement} from "../../../../../../utils/interfaces/my_warehouse/IWarehouseProductMovement";
import useApiCreateManualWarehouseProductMovement from "./api-hooks/useApiCreateManualWarehouseProductMovement";
import useApiUpdateManualWarehouseProductMovement from "./api-hooks/useApiUpdateManualWarehouseProductMovement";
import {
    IMyCompanyWarehouse
} from "../../../../../../utils/interfaces/my_company/my_company_warehouse/IMyCompanyWarehouse";
import {IWarehouseProduct} from "../../../../../../utils/interfaces/my_warehouse/IWarehouseProduct";
import {IUm} from "../../../../../../utils/interfaces/service/IUm";
import useApiGetManualWarehouseProductMovement from "./api-hooks/useApiGetManualWarehouseProductMovement";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import {formatDateTimeForInput, getCurrentDate} from "../../../../../../logics/helpers";
import UmSelect from "../../../service/UmSelect";
import useApiGetUm from "../../../service/api-hooks/useApiGetUm";


interface Props {
    warehouse: IMyCompanyWarehouse,
    product: IWarehouseProduct,
    objToEdit: IWarehouseProductMovement | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IWarehouseProductMovement | undefined) => void,
}

const MyWarehouseManualProductMovementForm: React.FC<Props> = ({objToEdit, onClose, onSelect, warehouse, product}) => {
    const {displayError, displayMessage} = useToast();
    const {t} = useTranslation()

    const [um, setUm] = useState<IUm | undefined | null>();
    const [quantity, setQuantity] = useState<number | undefined>();
    const [description, setDescription] = useState<string>();
    const [movementDate, setMovementDate] = useState<string>();


    const createMutation = useApiCreateManualWarehouseProductMovement(warehouse.id, product.id);
    const updateMutation = useApiUpdateManualWarehouseProductMovement(warehouse.id, product.id);


    const handleUmChange = (um: IUm | null | undefined) => {
        setUm(um);
    }

    const {
        data: detailedObj,
    } = useApiGetManualWarehouseProductMovement(warehouse.id, product.id, objToEdit?.id);

    const {
        data: sqm_um,
    } = useApiGetUm(2);


    useEffect(() => {
        if (detailedObj) {
            setUm(detailedObj.um);
            setQuantity(detailedObj.quantity);
            setDescription(detailedObj.description);
            setMovementDate(detailedObj.movement_date);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                um: um?.id,
                quantity: quantity,
                description: description,
                movement_date: movementDate,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({pk: detailedObj.id, data: payload});
                displayMessage(t('Updated successfully'));
            } else {
                newData = await createMutation.mutateAsync(payload);
                displayMessage(t('Added successfully'));
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (um) {
            setAllRequirementAreSatisfied(true);
        }
    }, [um]);


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setMovementDate(e.target.value)}
                    label='movement_date'
                    type="date"
                    value={formatDateTimeForInput(movementDate) || getCurrentDate()}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setQuantity(parseFloat(e.target.value))}
                    label='quantity'
                    type="number"
                    value={quantity}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setDescription(e.target.value)}
                    label='description'
                    type="text"
                    value={description}
                    required={false}
                />

                <UmSelect obj={um ? um : sqm_um} onChange={handleUmChange} className={''}/>


                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*{objToEdit &&*/}
                    {/*    <MuiFabButtonDelete onClick={() => {*/}
                    {/*        setConfirmationOpen(true)*/}
                    {/*    }}/>}*/}
                    {objToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>}
                </Box>
            </form>
        </Box>
    );
};

export default MyWarehouseManualProductMovementForm;