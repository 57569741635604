import React from 'react';
import Select from 'react-select';
import {useTranslation} from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';

interface LanguageOption {
    value: string;
    label: React.ReactNode;
    icon: string;
}

const VALUES: LanguageOption[] = [
    {value: 'en', label: 'English', icon: 'gb'},
    {value: 'it', label: 'Italiano', icon: 'it'},
    // {value: 'es', label: 'Español', icon: 'es'},
    // {value: 'de', label: 'Deutsch', icon: 'de'},
];

const LanguageSelect: React.FC = () => {
    const {i18n} = useTranslation();

    const browserLanguage = (navigator.language).split('-')[0];
    const defaultLanguage = VALUES.find((lang) => lang.value === browserLanguage) ? browserLanguage : 'en';
    // i18n.changeLanguage(defaultLanguage);

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            border: '1px solid #02959c',
            borderRadius: '4px',
            height: '32px',
            width: '135px',
        }),
        option: (provided: any) => ({
            ...provided,
        }),
        menu: (provided: any) => ({
            ...provided,
            width: 'auto', // Set width to auto for dynamic sizing
            minWidth: '135px', // Minimum width to match the control
        }),
    };

    const options: LanguageOption[] = VALUES.map((option) => ({
        value: option.value,
        label: (
            <div className="flex items-center">
                <ReactCountryFlag
                    countryCode={option.icon}
                    style={{
                        width: '2em',
                        minWidth: '2em',
                        height: 'auto',
                        marginRight: '8px', // Adjust margin as needed
                    }}
                    svg
                />
                <span>{option.label}</span>
            </div>
        ),
        icon: option.icon,
    }));

    const changeLanguageHandler = (lang: string) => {
        i18n.changeLanguage(lang);
    };

    const handleChange = (selectedOption: LanguageOption | null) => {
        if (selectedOption) {
            changeLanguageHandler(selectedOption.value);
        }
    };

    const defaultSelectedValue = options.find((option) => option.value === defaultLanguage);


    return (
        <div className="flex flex-row items-center gap-1 justify-end text-sm">
            <Select
                options={options}
                defaultValue={defaultSelectedValue}
                className="bg-transparent font-medium text-xs italic"
                onChange={handleChange}
                isSearchable={false}
                styles={customStyles}
                components={{
                    IndicatorSeparator: () => null,
                }}
            />
        </div>
    );
};

export default LanguageSelect;
