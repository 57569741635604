import React, {useEffect, useState} from 'react';
import {ILwgSite} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/ILwgSite";
import AgDiv from "../../../../../ui/divs/AgDiv";
import {SiTestcafe} from "react-icons/si";
import LotList from "./LotList";
import {cutString} from "../../../../../../logics/helpers";
import {BiLeaf} from "react-icons/bi";
import {IMacroSpessore} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IMacroSpessore";


interface Props {
    macro_spessore: IMacroSpessore,
}

const LwgSiteRow = ({macro_spessore: initialMacroSpessore}: Props) => {
    const [macroSpessore, setMacroSpessore] = useState<IMacroSpessore>(initialMacroSpessore);
    const [selectedMacroSpessore, setSelectedMacroSpessore] = useState<IMacroSpessore | undefined>();

    const handleSelection = () => {
        if (selectedMacroSpessore?.id === macroSpessore.id) {
            setSelectedMacroSpessore(undefined);
        } else {
            setSelectedMacroSpessore(macroSpessore)
        }
    }

    useEffect(() => {
        setMacroSpessore(initialMacroSpessore)
    }, [initialMacroSpessore]);


    return (
        <>
            <div
                className={`flex flex-row ${selectedMacroSpessore?.id === macroSpessore.id ? '' : ''}`}
                style={{flex: 1}}
            >
                <div
                    onClick={handleSelection}
                    className={`flex flex-row ${selectedMacroSpessore?.id === macroSpessore.id ? '' : ''}`}
                    // style={{flex: 1}}
                >
                    <AgDiv value={cutString(macroSpessore.macro_spessore_name, 25)}
                           minWidth="320px" maxWidth="320px"

                    />
                    <AgDiv label='' value={macroSpessore.pcs_produced.toFixed(2)}
                           minWidth="180px" maxWidth="180px"/>
                    <AgDiv label='' value={macroSpessore.sqm_produced.toFixed(2)}
                           minWidth="180px" maxWidth="180px"/>
                    <AgDiv label='' value={macroSpessore.lwg_sqm_produced.toFixed(2)}
                           minWidth="180px" maxWidth="180px"/>
                    <AgDiv label='' value={macroSpessore.coefficient_sqm_produced.toFixed(2)}
                           minWidth="180px" maxWidth="180px"/>
                </div>
            </div>


        </>
    );
};

export default LwgSiteRow;
