import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {formatDateTimeForInput, getCurrentDate} from "../../../../../../logics/helpers";
import {useTanneryProduction} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import {
    IOrderScheduleTanneryDyeing
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleTanneryDyeing";
import useApiUpdateDye from "./api-hooks/useApiUpdateDye";
import useApiCreateDye from "./api-hooks/useApiCreateDye";
import {Box, Grid} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonDelete from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonDelete";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import MuiFabButtonBase from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBase";
import useApiDeleteDye from "./api-hooks/useApiDeleteDye";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import {useTanneryDyeing} from "../../../../../../utils/contexts/tannery_dyeing/tannery_dyeing-context";
import useApiGetDye from "./api-hooks/useApiGetDye";
import {useLocation} from "react-router-dom";


interface Props {
    dyeToEdit: IOrderScheduleTanneryDyeing | null;
    onClose: () => void;
    scope?: string
}

const DyeForm: React.FC<Props> = ({dyeToEdit, onClose, scope}) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();
    const {selectedTanneryDyeing} = useTanneryDyeing()

    const [listDataType, setListDataType] = useState<string>()
    const location = useLocation();
    useEffect(() => {
        if (location.pathname.startsWith('/apps/tannery_dyeing/dyeing_schedule/history')) {
            setListDataType('historical');
        } else if (location.pathname.startsWith('/apps/tannery_dyeing/dyeing_schedule/current')) {
            setListDataType('current');
        } else if (location.pathname.startsWith('/apps/tannery_dyeing/dyeing_schedule/drafts')) {
            setListDataType('drafts');
        } else {
            setListDataType(undefined);
        }
    }, [location.pathname]);


    const {
        data: detailedObj,
    } = useApiGetDye(dyeToEdit?.id);

    const {selectedPrice, selectedCompanyOrderSchedule} = useTanneryProduction()


    const updateMutation = useApiUpdateDye(selectedCompany?.company_token);
    const createMutation = useApiCreateDye(selectedCompany?.company_token);


    const [weight, setWeight] = useState<number>();
    const [ready_to_dye_at, setReady_to_dye_at] = useState<string>(getCurrentDate);
    const [closed_at, setClosed_at] = useState<string>();
    const [orderId, setOrderId] = useState<number>();

    const putMutation = useApiUpdateDye(selectedCompany?.company_token);
    const handleReleaseDye = async () => {
        if (dyeToEdit) {
            const _tannery_dyeing_id = dyeToEdit.tannery_dyeing ? undefined : selectedTanneryDyeing?.id

            try {
                const data = {
                    tannery_dyeing_id: _tannery_dyeing_id || null,
                    order_id: dyeToEdit.order.id,
                    weight: dyeToEdit.weight,
                    ready_to_dye_at: dyeToEdit.ready_to_dye_at,
                };

                await putMutation.mutateAsync({
                    pk: dyeToEdit.id,
                    data: data,
                });
                displayMessage('DyeList updated successfully');
                await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleCurrent', _tannery_dyeing_id]});


            } catch (error) {
                const axiosError = error as CustomAxiosError;
                if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                    displayError(axiosError.response.data.detail);
                } else {
                    displayError('An error occurred while saving the TanneryDyeing');
                }
            }
        }
        onClose();
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe']})
        await queryClient.invalidateQueries({queryKey: ['getDyeToDye']})
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleCurrent']});
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleHistorical']});
        await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleDrafts']});
        await queryClient.invalidateQueries({queryKey: ['getDyeByOrder']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
        await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
    };

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteDye(selectedCompany?.company_token, detailedObj?.id);
    const {mutateAsync: apiDelete} = deleteMutation;
    const handleConfirmDelete = async () => {
        if (detailedObj) {
            try {
                await apiDelete({companyToken: selectedCompany?.company_token, pk: detailedObj.id});
                displayMessage('DyeList deleted successfully');

            } catch (error) {
                const axiosError = error as CustomAxiosError;
                if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                    displayError(axiosError.response.data.detail);
                } else {
                    displayError('An error occurred while deleting the DyeList');
                }
            }
            onClose()
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe']})
            await queryClient.invalidateQueries({queryKey: ['getDyeToDye']})
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleCurrent']});
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleHistorical']});
            await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleDrafts']});
            await queryClient.invalidateQueries({queryKey: ['getDyeByOrder']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
        }

    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    // console.log('selectedCompanyOrderSchedule', selectedCompanyOrderSchedule)
    // console.log(orderId)


    const [isFormDisabled, setIsFormDisabled] = useState(false)
    useEffect(() => {
        setOrderId(selectedCompanyOrderSchedule?.id)
        if (detailedObj) {
            setWeight(detailedObj.weight)
            setReady_to_dye_at(detailedObj.ready_to_dye_at)
            setClosed_at(detailedObj.closed_at)
            setOrderId(detailedObj.order.id)
            setIsFormDisabled((!(!listDataType || listDataType === 'current')))
        }
    }, [detailedObj, dyeToEdit]);


    const handleSubmit = async () => {
        try {
            const data = {
                weight: weight,
                ready_to_dye_at: ready_to_dye_at,
                closed_at: closed_at || null,
                order_id: orderId,
                tannery_dyeing_id: null,
            };

            if (dyeToEdit !== null) {
                await updateMutation.mutateAsync({
                    pk: dyeToEdit.id,
                    data: data,
                });
                displayMessage('DyeList updated successfully');
            } else {
                await createMutation.mutateAsync(
                    data
                );
                displayMessage('DyeList added successfully');
            }

            onClose();
            await queryClient.invalidateQueries({queryKey: ['getDyeByOrder']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while saving the Working');
            }
        }
    };


    return (
        <><Box>
            <form>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <MuiInputTextField
                            label="ready_to_dye_at"
                            type="date"
                            value={formatDateTimeForInput(ready_to_dye_at) || 0}
                            onChange={(e) => setReady_to_dye_at(e.target.value)}
                            required={true}
                            disabled={!(!listDataType || listDataType === 'current')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MuiInputTextField
                            label="closed_at"
                            type="date"
                            value={formatDateTimeForInput(closed_at) || ' '}
                            onChange={(e) => setClosed_at(e.target.value)}
                            required={false}
                            disabled={!(!listDataType || listDataType === 'current')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <MuiInputTextField
                            label="weight"
                            type="number"
                            value={weight || ''}
                            onChange={(e) => setWeight(parseFloat(e.target.value))}
                            required={true}
                            disabled={!(!listDataType || listDataType === 'current')}
                        />
                    </Grid>

                </Grid>

                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {!isFormDisabled &&
                        <MuiFabButtonDelete onClick={() => {
                            setDeleteConfirmationOpen(true)
                        }}/>}

                    {!isFormDisabled &&
                        <>{dyeToEdit ?
                            <MuiFabButtonSaveAs onClick={handleSubmit}/>
                            :
                            <MuiFabButtonSave onClick={handleSubmit}/>}
                        </>
                    }

                    {!isFormDisabled &&
                        <>
                            {scope === 'link_dye_to_tannery_dyeing' &&
                                <MuiFabButtonBase label={'link_dye_to_tannery_dyeing'} icon={<LinkIcon/>}
                                                  onClick={handleReleaseDye}/>
                            }
                            {scope === 'unlink_dye_to_tannery_dyeing' &&
                                <MuiFabButtonBase label={'unlink_dye_to_tannery_dyeing'} icon={<LinkOffIcon/>}
                                                  onClick={handleReleaseDye}/>
                            }
                        </>

                    }


                </Box>
            </form>
        </Box>
            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </>

    );
};

export default DyeForm;
