import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";

interface HeaderTableCellProps {
    textAlign?: 'left' | 'center' | 'right'; // Adjust the values based on your requirements
}

export const HeaderTableCell = styled(TableCell)<HeaderTableCellProps>(({theme, textAlign}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        textTransform: 'uppercase',
        textAlign: textAlign || 'left', // Use the passed textAlign or default to 'left'
    },
}));

export const BodyTableNumberCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Courier New, monospace', // Set the desired font-family here
        textAlign: 'right', // Align content to the right
        borderBottom: `1px solid ${theme.palette.primary.light}`, // Add the bottom border
    },
}));


export const BodyTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
        borderBottom: `1px solid ${theme.palette.primary.light}`, // Add the bottom border
    },
}));


// TANNERY DYEING RECIPE

export const HeaderTableCellTDRecipe = styled(TableCell)<HeaderTableCellProps>(({theme, textAlign}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        textTransform: 'uppercase',
        textAlign: textAlign || 'left',
        fontWeight: 'bold',
    },
}));

export const BodyTableCellTDRecipe = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
        color: theme.palette.textAppGarageColor.tw700,
        backgroundColor: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.primary.light}`, // Add the bottom border
    },
}));

export const BodyTableNumberCellTDRecipe = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.body}`]: {
        color: theme.palette.textAppGarageColor.tw700,
        fontFamily: 'Courier New, monospace', // Set the desired font-family here
        textAlign: 'right', // Align content to the right
        borderBottom: `1px solid ${theme.palette.primary.light}`, // Add the bottom border
    },
}));