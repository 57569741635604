import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {IRawChoiceOrder} from "../../../../../../../utils/interfaces/tannery_production/order_schedule/IRawChoiceOrder";


const useApiGetRawChoiceOrderDetail = (
    companyToken: string | undefined,
    id: number | undefined
): UseQueryResult<IRawChoiceOrder, CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, id: number | undefined): Promise<IRawChoiceOrder> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/order_schedule/raw_material/${id}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getRawChoiceDetails', id],
        queryFn: () => doGet(companyToken, id),
        enabled: !!id
    })
};

export default useApiGetRawChoiceOrderDetail;
