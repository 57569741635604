import React, {useEffect, useRef, useState} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {CustomAxiosError} from "../../../../../logics/api_config";
import Loading from "../../../../ui/Loading";
import useApiGetCompanyOrderSchedule from "./api-hooks/useApiGetCompanyOrderSchedule";
import {
    IOrderScheduleDetail
} from "../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail";
import useApiGetHistoricalCompanyOrderSchedule from "./api-hooks/useApiGetHistoricalCompanyOrderSchedule";
import OrderScheduleTable from "./OrderScheduleTable";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";


interface Props {
}

const OrderSchedule = ({}: Props) => {
    const {displayError} = useToast()
    const {selectedCompany} = useAuth()

    const [ordersSchedule, setOrdersSchedule] = useState<IOrderScheduleDetail[]>();
    const [scope, setScope] = useState<'current' | 'historical'>('current')

    const {
        data: currentOrderScheduleData,
        isLoading: isLoadingCurrentOrderSchedule,
        error: errorCurrentOrderSchedule,
        isFetching: isFetchingCurrentOrderSchedule
    } = useApiGetCompanyOrderSchedule(selectedCompany?.company_token);
    if (errorCurrentOrderSchedule) {
        const axiosError = errorCurrentOrderSchedule as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const {
        data: historicalCurrentScheduleData,
        isLoading: isLoadingHistoricalCurrentSchedule,
        error: errorHistoricalCurrentSchedule,
        isFetching: isFetchingHistoricalCurrentSchedule
    } = useApiGetHistoricalCompanyOrderSchedule(selectedCompany?.company_token);
    if (errorHistoricalCurrentSchedule) {
        const axiosError = errorHistoricalCurrentSchedule as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (scope === 'current') {
            setOrdersSchedule(currentOrderScheduleData);
        }
        if (scope === 'historical') {
            setOrdersSchedule(historicalCurrentScheduleData);
        }
    }, [scope, historicalCurrentScheduleData, currentOrderScheduleData]);

    const handleScopeToggle = () => {
        if (scope === 'current') {
            setScope('historical')
        }
        if (scope === 'historical') {
            setScope('current')
        }
    }


    return (

        <>{(isLoadingCurrentOrderSchedule) ?
            <Loading/>
            :
            <>
                {/*<OrderScheduleList current_orders_schedule={currentOrdersSchedule}*/}
                {/*                   historical_orders_schedule={historiclOrdersSchedule}/>*/}
                <OrderScheduleTable orders_schedule={ordersSchedule} handleScopeToggle={handleScopeToggle}
                                    scope={scope}/>
            </>


        }
            {isFetchingCurrentOrderSchedule &&
                <MuiFetchingSpinner text="Fetching Current data..."/>
            }
            {isFetchingHistoricalCurrentSchedule &&
                <MuiFetchingSpinner text="Fetching Historical data..."/>
            }
        </>
    );
};

export default OrderSchedule;