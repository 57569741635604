// MuiFabButtonSave.tsx
import React from 'react';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';
import Zoom from "@mui/material/Zoom";

interface MuiFabButtonAddProps {
    onClick: () => void;
}

const MuiFabButtonSave: React.FC<MuiFabButtonAddProps> = ({onClick}) => {
    return (
        <Zoom in={true} unmountOnExit>
            <Fab color="warning" aria-label="delete" onClick={onClick} variant="extended">
                <DeleteIcon style={{color: 'white'}}/>
            </Fab>
        </Zoom>
    );
};

export default MuiFabButtonSave;
