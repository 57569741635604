import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {
    IOrderScheduleDetail
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiGetPendingOrders = (): UseQueryResult<IOrderScheduleDetail[], CustomAxiosError> => {
    const {selectedCompany} = useAuth()
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IOrderScheduleDetail[]> {
        try {
            const response = await get(`/api/company/${company_token}/lwg_dashboard/lwg_reports/pending_orders`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetPendingOrders"],
        queryFn: () => doGet(selectedCompany?.company_token),
    })

};

export default useApiGetPendingOrders