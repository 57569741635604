import React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Zoom from "@mui/material/Zoom";
import {useTranslation} from "react-i18next";
import {MdWorkspaces} from "react-icons/md";

interface MuiFabButtonAddProps {
    onClick: () => void;
    label?: string;
}

const MuiFabButtonAddWorking: React.FC<MuiFabButtonAddProps> = ({onClick, label = 'add_working'}) => {
    const {t} = useTranslation()

    return (
        <Zoom in={true} unmountOnExit>
            <Fab color="primary" aria-label="add" onClick={onClick} variant="extended">
                <AddIcon style={{color: 'white'}}/>
                <MdWorkspaces style={{color: 'white', fontSize: '1.5rem'}}/>
                {label && (
                    <span style={{textTransform: 'uppercase', color: 'white', marginLeft: '8px'}}>
                        {t(label)}
                    </span>
                )}
            </Fab>
        </Zoom>
    );
};

export default MuiFabButtonAddWorking;
