import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {ILwgSite} from "../../../../../../utils/interfaces/tannery_raw_material/ILwgSite";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiCreateLwgSite = (): UseMutationResult<ILwgSite, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {post} = useApiCalls()

    const doCreate = async ({companyToken, data}: { companyToken?: string, data: any }): Promise<ILwgSite> => {
        try {
            const response = await post(`/api/company/${companyToken}/tannery_raw_material/lwg_sites/`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<ILwgSite, CustomAxiosError, any>(
        {
            mutationFn: (data: {
                data: any
            }) => {

                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    data
                })
            },

            onError: (err, newData, previousData) => {
                const previous = queryClient.getQueryData<ILwgSite[]>(['getCompanyLwgSites']);
                queryClient.setQueryData(['getCompanyLwgSites'], previous)
            },

            onSettled: async (newData) => {
                const previous = queryClient.getQueryData<ILwgSite[]>(['getCompanyLwgSites']);
                if (previous && newData) {
                    const updated = [newData, ...previous]; //directly on top!

                    queryClient.setQueryData(['getCompanyLwgSites'], updated);
                }
            }

        }
    );
};

export default useApiCreateLwgSite;

// note_marco ['doCreate con aggiornamento immediata (invalidare nel componente per evitare tempi di attesa del reftech ']