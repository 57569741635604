import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import {useTheme} from '@mui/material/styles';


interface StyledTablePaginationProps {
    count: number;
    page: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowsPerPage: number;
}

const StyledTablePagination: React.FC<StyledTablePaginationProps> = ({
                                                                         page,
                                                                         onPageChange,
                                                                         onRowsPerPageChange,
                                                                         rowsPerPage,
                                                                         count,
                                                                         ...props
                                                                     }) => {
    const theme = useTheme();

    return (
        <TablePagination
            sx={{
                position: 'sticky',
                bottom: 0,
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                zIndex: 1,
                borderRadius: '0 0 0px 0px',
                '& .MuiTablePagination-spacer': {
                    display: 'none',
                },
                '& .MuiTablePagination-actions': {
                    marginRight: 0,
                },
                '& .MuiTablePagination-toolbar': {
                    minHeight: 'unset',
                },
            }}
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            {...props}
        />
    );
};

export default StyledTablePagination;
