import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from "@tanstack/react-query";


const useApiCreatePrice = (companyToken: string | undefined, people_id: number | undefined, product_id: number | undefined): UseMutationResult<void, CustomAxiosError, any, unknown> => {

    const {post} = useApiCalls();
    
    async function doCreate({companyToken, people_id, product_id, data}: {
        companyToken?: string,
        people_id: number | undefined,
        product_id: number | undefined,
        data: any
    }): Promise<void> {
        try {
            await post(`/api/company/${companyToken}/service/people/${people_id}/product/${product_id}/price/`, data);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, any>(
        {
            mutationFn: (data) => doCreate({companyToken, people_id, product_id, data})
        }
    );
};


export default useApiCreatePrice;