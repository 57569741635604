import React, {useState, useEffect} from 'react';
import {useQueryClient} from "@tanstack/react-query";
import {IChoice} from "../../../../../../utils/interfaces/tannery_raw_material/IChoice";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import UseApiUpdateChoice from "./api-hooks/useApiUpdateChoice";
import useApiCreateChoice from "./api-hooks/useApiCreateChoice";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {
    useTanneryRawMaterial
} from "../../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";
import {Box, Grid} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";


interface Props {
    choiceToEdit: IChoice | null;
    onClose: () => void;
}

const ChoicesForm: React.FC<Props> = ({choiceToEdit, onClose}) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();
    const {selectedLot} = useTanneryRawMaterial()

    const mutationHook = choiceToEdit ? UseApiUpdateChoice : useApiCreateChoice;
    const mutation = mutationHook(selectedCompany?.company_token);

    const [people_id, setPeopleId] = useState<number | undefined>()
    const [lwg_site_id, setLwgSiteId] = useState<number | undefined>()
    const [pcs, setPcs] = useState<number>()
    const [sqm, setSqm] = useState<number>()
    const [total_value_eur, setTotalValuwEur] = useState<number>()
    const [lot_id, setLotId] = useState<string>('')

    const handlePeople = (peopleId: number) => {
        setPeopleId(peopleId);
    }

    const handleLwgSite = (lwg_site: number) => {
        setLwgSiteId(lwg_site);
    }


    useEffect(() => {
        if (choiceToEdit) {
            setPeopleId(choiceToEdit.people);
            setLwgSiteId(choiceToEdit.lwg_site);
            setPcs(choiceToEdit.pcs);
            setSqm(choiceToEdit.sqm);
            setTotalValuwEur(choiceToEdit.total_value_eur)
            setLotId(choiceToEdit.lot_id)
        }
    }, [choiceToEdit]);


    const handleSubmit = async () => {

        try {
            const upd_data = {
                lot_id: lot_id,
                people_id: selectedLot?.people?.id,
                lwg_site_id: selectedLot?.lwg_site?.id,
                pcs: pcs,
                sqm: sqm,
                total_value_eur: total_value_eur,
                parent_id: selectedLot?.id
            };

            if (choiceToEdit !== null) {
                await mutation.mutateAsync({id: choiceToEdit.id, data: upd_data});

                displayMessage('Choice updated successfully');
            } else {
                await mutation.mutateAsync(upd_data);
                displayMessage('Choice added successfully');
            }

            onClose();
            await queryClient.invalidateQueries({queryKey: ['getChoicesByLot']});
            await queryClient.invalidateQueries({queryKey: ['getLots']});
            await queryClient.invalidateQueries({queryKey: ['getOrderRawChoicesOrders']});

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while saving the Choice');
            }
        }
    };

    return (
        <Box sx={{padding: 1}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setLotId(e.target.value)}
                    label='choice_name'
                    type="text"
                    value={lot_id || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setPcs(parseFloat(e.target.value))}
                    label='pcs'
                    type="number"
                    value={pcs || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setSqm(parseFloat(e.target.value))}
                    label='sqm'
                    type="number"
                    value={sqm || ''}
                    required={true}
                />

                <MuiInputTextField
                    onChange={(e) => setTotalValuwEur(parseFloat(e.target.value))}
                    label='total_value_eur'
                    type="number"
                    value={total_value_eur || ''}
                    required={true}
                />


                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Box className={'mt-2 gap-x-2'}>
                        <MuiFabButtonBack onClick={onClose}/>
                        {choiceToEdit ?
                            <MuiFabButtonSaveAs onClick={handleSubmit}/>
                            :
                            <MuiFabButtonSave onClick={handleSubmit}/>
                        }
                    </Box>
                </Grid>
            </form>

        </Box>
    );
};

export default ChoicesForm;
