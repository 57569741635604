import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {
    IFinalMeasurement
} from "../../../../../../../utils/interfaces/tannery_production/order_schedule/IFinalMeasurement";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiGetFinalMeasurement = (pk: number | undefined): UseQueryResult<IFinalMeasurement, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls();

    async function doGet(
        company_token: string | undefined,
        pk: number | undefined,
    ): Promise<IFinalMeasurement> {
        try {
            const url = `/api/company/${company_token}/tannery_production/order_schedule/final_measurement/${pk}`;
            const response = await get(url);
            return response.data[0];
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getFinalMeasurement', pk],
        queryFn: () => doGet(selectedCompany?.company_token, pk),
        enabled: !!pk,
    })
};

export default useApiGetFinalMeasurement;