import React from 'react';

type Props = {
    className?: string,
    label: string,
    name: string,
    type?: string,
    required?: boolean
}

const LabelledInput = ({className, label, name, type = "text", required = false}: Props) => {
    return (
        <div className={className}>
            <label className="text-xs leading-none block" htmlFor={name}>{label}</label>
            <input className="p-1 border-2 border-eBLUE rounded"
                   id={name}
                   name={name}
                   type={type}
                   required={required}
                   placeholder={label}/>
        </div>
    );
};

export default LabelledInput;