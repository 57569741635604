import React from 'react';
import AccordionMotionDiv from "../../../../ui/accordions/AccordionMotionDiv";
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import {ServiceProvider} from "../../../../../utils/contexts/service/service-context";
import PeopleTable from "./PeopleTable";
import {MyDocumentsProvider} from "../../../../../utils/contexts/my_documents/my_documents-context";

interface Props {
    category_person?: string;
    isService?: boolean;
}

const PeopleScreen = ({category_person}: Props) => {


    return (
        <>

            <MaxWidthDiv maxWidth='1024px'>
                <AccordionMotionDiv isOpen={true}>
                    <ServiceProvider>
                        <MyDocumentsProvider>
                            <PeopleTable category_person={category_person}/>
                        </MyDocumentsProvider>
                    </ServiceProvider>
                </AccordionMotionDiv>
            </MaxWidthDiv>


        </>
    );
};

export default PeopleScreen;
