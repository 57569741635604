import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {IMacroLinea} from "../../../../../../../../utils/interfaces/tannery_production/articles/IMacroLinea";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";


const useApiGetMacroLineaDetails = (
    companyToken: string | undefined,
    macroLineaId: number | null
): UseQueryResult<IMacroLinea, CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, macro_linea_id: number | null): Promise<IMacroLinea> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/articles/macro_linee_produzione/${macro_linea_id}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getPersonDetails', macroLineaId],
        queryFn: () => doGet(companyToken, macroLineaId),
        enabled: !!macroLineaId,
    })
};

export default useApiGetMacroLineaDetails;
