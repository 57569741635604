import {useQuery} from "@tanstack/react-query";
import {IUser} from "../interfaces/accounts/IUser";
import {useApiCalls} from "../../logics/api_config";

function useApiUser() {

    const {get} = useApiCalls();

    const whoAmI = async (): Promise<IUser> => {
        try {
            const response = await get(`/api/whoami`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({queryKey: ['whoAmI'], queryFn: whoAmI})
}

export default useApiUser;