import React, {useEffect} from 'react';
import MaxWidthDiv from "../../../../../ui/MaxWitdthDiv";
import LwgProductionReports from "./LwgProductionReports";
import {
    TanneryProductionProvider
} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import {
    TanneryRawMaterialProvider
} from "../../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";


const LwgProductionReportScreen = () => {


    return (
        <>

            <MaxWidthDiv maxWidth='1980px'>
                <TanneryRawMaterialProvider>
                    <TanneryProductionProvider>
                        <LwgProductionReports/>
                    </TanneryProductionProvider>
                </TanneryRawMaterialProvider>
            </MaxWidthDiv>


        </>
    );
};

export default LwgProductionReportScreen;
