import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IColor} from "../../../../../../../../utils/interfaces/tannery_production/articles/IColor";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";

const useApiGetColors = (
    companyToken: string | undefined,
    article_id: number,
): UseQueryResult<IColor[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined): Promise<IColor[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/colors/${article_id}/article_colors`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['useApiGetColors', article_id],
        queryFn: () => doGet(companyToken)
    })
};

export default useApiGetColors