import React, {useState} from "react";
import {ILot} from "../../interfaces/tannery_raw_material/ILot";
import {IChoice} from "../../interfaces/tannery_raw_material/IChoice";
import {ILwgSite} from "../../interfaces/tannery_raw_material/ILwgSite";


type TanneryRawMaterialContextType = {
    selectedLot: ILot | undefined,
    selectLot: (selectedLot: ILot | undefined) => void,

    selectedLwgSite: ILwgSite | undefined,
    selectLwgSite: (selectedLwgSite: ILwgSite | undefined) => void,

    selectedChoice: IChoice | undefined,
    selectChoice: (selectedChoice: IChoice | undefined) => void,
}

const TanneryRawMaterialContext = React.createContext<TanneryRawMaterialContextType | undefined>(undefined);

function useTanneryRawMaterialContextValue() {
    const [selectedLot, setSelectedLot] = useState<ILot>();
    const [selectedLwgSite, setSelectedLwgSite] = useState<ILwgSite>();
    const [selectedChoice, setSelectedChoice] = useState<IChoice>();

    const selectLot = (selectedLot: ILot | undefined) => {
        setSelectedLot(selectedLot)
    }

    const selectLwgSite = (selectedLwgSite: ILwgSite | undefined) => {
        setSelectedLwgSite(selectedLwgSite)
    }
    const selectChoice = (selectedChoice: IChoice | undefined) => {
        setSelectedChoice(selectedChoice)
    }


    return {
        selectedLot, selectLot,
        selectedLwgSite, selectLwgSite,
        selectedChoice, selectChoice,

    }
}

function TanneryRawMaterialProvider({children}: { children: React.ReactNode }) {
    const value = useTanneryRawMaterialContextValue();
    return <TanneryRawMaterialContext.Provider value={value}>{children}</TanneryRawMaterialContext.Provider>
}

function useTanneryRawMaterial() {
    const context = React.useContext(TanneryRawMaterialContext);
    if (!context) {
        throw new Error('useTanneryRawMaterial must be used within a TanneryRawMaterialProvider');
    }
    return context;
}

export {
    TanneryRawMaterialProvider,
    useTanneryRawMaterial,
}
