import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAppGarage} from '../../../utils/contexts/appGarage-context';
import {Box, CardContent, Typography} from "@mui/material";
import MuiFabButtonSave from "../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../ui/mui/mui-input/MuiInputTextField";
import {useTheme} from "@mui/material/styles";

interface YearSelectionProps {
    onClose: () => void;
}

const SystemDateSelector: React.FC<YearSelectionProps> = ({onClose}: YearSelectionProps) => {
    const {selectedSystemDate, selectSystemDate, selectSystemYear} = useAppGarage();
    const {t} = useTranslation();
    const theme = useTheme();

    const [systemDate, setSystemDate] = useState<string | undefined>(selectedSystemDate);

    const handleChangeYear = (e: React.ChangeEvent<HTMLInputElement>) => {
        const yearValue = e.target.value;
        setSystemDate(yearValue);
    };

    const handleChangeSelectedYear = () => {
        selectSystemDate(systemDate);
        onClose()
    };

    useEffect(() => {
        setSystemDate(selectedSystemDate);
    }, [selectedSystemDate]);

    useEffect(() => {
        if (selectedSystemDate === undefined) {
            const today = new Date();
            const formattedToday = today.toISOString().split('T')[0];
            setSystemDate(formattedToday);
            selectSystemYear(today.getFullYear())
        } else {
            setSystemDate(selectedSystemDate);
        }
    }, [selectedSystemDate]);

    useEffect(() => {
        if (systemDate !== undefined) {
            const year = new Date(systemDate).getFullYear();
            selectSystemYear(year);
        }
    }, [systemDate, selectSystemYear]);

    return (
        <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} className={'mt-2 gap-x-2'}>
            <CardContent>
                <Typography style={{textTransform: 'uppercase', color: theme.palette.primary.main, fontWeight: 'bold'}}>
                    {t('select_system_date')}
                </Typography>


            </CardContent>

            <MuiInputTextField
                label="select_system_date"
                type="date"
                value={systemDate}
                onChange={handleChangeYear}
                required={true}
            />

            <MuiFabButtonSave onClick={() => handleChangeSelectedYear()}/>
        </Box>
    );
};

export default SystemDateSelector;
