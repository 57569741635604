import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {IRegistry} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/IRegistry";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiUpdateRegistry = (): UseMutationResult<IRegistry, CustomAxiosError, {
    fiscal_registry_id: number | undefined,
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()

    const {put} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            fiscal_registry_id: number | undefined,
                            pk: number | undefined,
                            data: any): Promise<IRegistry> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/fiscal_registry/${fiscal_registry_id}/registry/${pk}/`, data);
            return response.data as IRegistry;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IRegistry, CustomAxiosError, {
        fiscal_registry_id: number | undefined,
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({fiscal_registry_id, id, data}) => {
            return await doUpdate(selectedCompany?.company_token, fiscal_registry_id, id, data);
        },
        onSuccess: async (obj) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetRegistry', obj.id]});
            await queryClient.invalidateQueries({queryKey: ['useApiGetRegistries']});
        },
    });
};


export default useApiUpdateRegistry;