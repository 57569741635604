import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {IArticle} from "../../../../../../../utils/interfaces/tannery_production/articles/IArticle";


const useApiGetArticle = (
    companyToken: string | undefined,
    customerId: number,
    pk: number | undefined,
): UseQueryResult<IArticle, CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined): Promise<IArticle> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/articles/${customerId}/${pk}`);
            return response?.data[0];
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['useApiGetArticle', customerId, pk],
        queryFn: () => doGet(companyToken),
        enabled: !!pk,
    })
};

export default useApiGetArticle