import React, {useEffect, useState} from 'react';
import {IDocTransport} from "../../../../../../../utils/interfaces/my_documents/doc/doc_trasnport/IDocTransport";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {IIncoterm} from "../../../../../../../utils/interfaces/my_documents/IIncoterm";
import {ITransportCompany} from "../../../../../../../utils/interfaces/my_documents/ITransportCompany";
import {IOtherDestination} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/IOtherDestination";
import useApiCreateDocTransport from "./api-hooks/useApiCreateDocTransport";
import useApiUpdateDocTransport from "./api-hooks/useApiUpdateDocTransport";
import useApiGetDocTransport from "./api-hooks/useApiGetDocTransport";
import IncotermSelect from "../../../fiscal_registry/incoterm/IncotermSelect";
import IncotermCard from "../../../fiscal_registry/incoterm/IncotermCard";
import TransportCompanySelect from "../../../fiscal_registry/transport_company/TransportCompanySelect";
import TransportCompanyCard from "../../../fiscal_registry/transport_company/TransportCompanyCard";
import OtherDestinationSelect from "../../../fiscal_registry/other_destination/OtherDestinationSelect";
import OtherDestinationCard from "../../../fiscal_registry/other_destination/OtherDestinationCard";
import useApiGetDoc from "../api-hooks/useApiGetDoc";
import {useTranslation} from "react-i18next";
import {Box, Grid} from "@mui/material";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {IDocDetail} from "../../../../../../../utils/interfaces/my_documents/doc/IDocDetail";
import MuiInputTextField from "../../../../../../ui/mui/mui-input/MuiInputTextField";
import {formatDateTimeForInput, getCurrentDate} from "../../../../../../../logics/helpers";


interface Props {
    objToEdit: IDocTransport | null | undefined;
    onClose: () => void;
    doc: IDocDetail | undefined,
}

const DocTransportForm: React.FC<Props> = ({objToEdit, onClose, doc}) => {
    const {displayError, displayMessage} = useToast();
    const {t} = useTranslation()

    const [obj, setObj] = useState<IDocTransport | undefined | null>(objToEdit)
    const [packagesAppearance, setPackagesAppearance] = useState<string | undefined>()
    const [packagesNumber, setPackagesNumber] = useState<number | undefined>()
    const [shipmentDatetime, setShipmentDatetime] = useState<string | undefined>(getCurrentDate())
    const [shipmentNotes, setShipmentNotes] = useState<string | undefined>()
    const [incoterm, setIncoterm] = useState<IIncoterm | undefined>()
    const [transportCompany, setTransportCompany] = useState<ITransportCompany | undefined>()
    const [otherDestination, setOtherDestination] = useState<IOtherDestination | undefined>()

    const createMutation = useApiCreateDocTransport();
    const updateMutation = useApiUpdateDocTransport();

    const handleIncotermChange = (obj: IIncoterm | undefined) => {
        // console.log(obj)
        setIncoterm(obj);
    };

    const handleTransportCompanyChange = (obj: ITransportCompany | undefined) => {
        setTransportCompany(obj);
    };

    const handleOtherDestinationChange = (obj: IOtherDestination | undefined) => {
        setOtherDestination(obj);
    };

    const {
        data: detailedObj,
    } = useApiGetDocTransport(doc?.doc_year, doc?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    const {
        data: detailedDoc,
    } = useApiGetDoc(doc?.id);


    useEffect(() => {
        if (obj) {
            setPackagesAppearance(obj.packages_appearance);
            setPackagesNumber(obj.number_of_packages)
            setShipmentDatetime(obj.shipment_datetime);
            setShipmentNotes(obj.shipment_notes);
            setIncoterm(obj.incoterm);
            setTransportCompany(obj.transport_company);
            setOtherDestination(obj.other_destination);
        } else {
            setIncoterm(detailedDoc?.fiscal_registry.incoterm);
            setTransportCompany(detailedDoc?.fiscal_registry.transport_company);
            setOtherDestination(detailedDoc?.fiscal_registry.other_destination);
        }
    }, [obj]);


    const handleSubmit = async () => {
        // console.log(obj)

        try {
            const payload = {
                other_destination: otherDestination ? otherDestination.id : null,
                transport_company: transportCompany ? transportCompany.id : null,
                incoterm: incoterm ? incoterm.id : null,
                packages_appearance: packagesAppearance,
                number_of_packages: packagesNumber,
                shipment_datetime: shipmentDatetime,
                shipment_notes: shipmentNotes,
            };

            if (obj?.id !== undefined && detailedDoc) {
                await updateMutation.mutateAsync({
                    doc_year: detailedDoc.doc_year,
                    doc_id: detailedDoc.id,
                    id: obj.id,
                    data: payload
                });
                // displayMessage('Updated successfully');
            } else {
                // displayMessage('Added successfully');
                await createMutation.mutateAsync({
                    doc_year: detailedDoc?.doc_year,
                    doc_id: detailedDoc?.id,
                    data: payload
                });
            }
            onClose();

        } catch (error) {
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (true) {
            setAllRequirementAreSatisfied(true);
        }
    }, []);

    //note_marco: form in grid

    return (
        <Box sx={{padding: 2}}>
            <span className={'form_title'}>{t('doc_transport')}</span>

            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <MuiInputTextField
                        onChange={(e) => setShipmentDatetime(e.target.value)}
                        label='date of shipment'
                        type="date"
                        value={formatDateTimeForInput(shipmentDatetime) || getCurrentDate()}
                        required={false}
                    />
                    <MuiInputTextField
                        onChange={(e) => setPackagesNumber(parseInt(e.target.value))}
                        label='number of packages'
                        type="number"
                        value={packagesNumber || ''}
                        required={false}
                    />
                    <MuiInputTextField
                        onChange={(e) => setPackagesAppearance(e.target.value)}
                        label='packages appearance'
                        type="text"
                        value={packagesAppearance || ''}
                        required={false}
                    />
                    <MuiInputTextField
                        onChange={(e) => setShipmentNotes(e.target.value)}
                        label='shipment notes'
                        type="text"
                        value={shipmentNotes || ''}
                        required={false}
                        rows={5}
                    />
                </Grid>
                <Grid item xs={3}>
                    <IncotermSelect
                        label={'select_incoterm'}
                        onChange={handleIncotermChange}
                        className={''}
                        obj={incoterm}
                    />
                    {incoterm && <IncotermCard obj={incoterm}/>}
                </Grid>

                <Grid item xs={3}>
                    <TransportCompanySelect
                        label={'select_transport_company'}
                        onChange={handleTransportCompanyChange}
                        className={''}
                        obj={transportCompany}
                    />
                    {transportCompany && <TransportCompanyCard obj={transportCompany}/>}
                </Grid>
                <Grid item xs={3}>
                    <OtherDestinationSelect
                        label={'select_other_destination'}
                        onChange={handleOtherDestinationChange}
                        className={''}
                        obj={otherDestination}
                        fiscal_registry={detailedDoc?.fiscal_registry}
                    />
                    {otherDestination && <OtherDestinationCard fiscal_registry={detailedDoc?.fiscal_registry}
                                                               obj={otherDestination}/>}
                </Grid>
            </Grid>


            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={onClose}/>
                {objToEdit ?
                    <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                    :
                    <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>}
            </Box>
        </Box>
    );
};

export default DocTransportForm;