import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {ITanneryDyeingProduct} from "../../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingProduct";


const useApiGetTanneryDyeingProducts = (
    company_token: string | undefined,
): UseQueryResult<ITanneryDyeingProduct[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined): Promise<ITanneryDyeingProduct[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_dyeing/products`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getTanneryDyeingProducts'],
        queryFn: () => doGet(company_token)
    })
};

export default useApiGetTanneryDyeingProducts