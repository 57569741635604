import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IDocDetail} from "../../../../../../../utils/interfaces/my_documents/doc/IDocDetail";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";


const useApiGetDoc = (pk: number | undefined): UseQueryResult<IDocDetail, CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {selectedSystemYear} = useAppGarage();
    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, doc_year: number | undefined, pk: number | undefined): Promise<IDocDetail> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/doc/${doc_year}/${pk}`);
            return response.data;
        } catch (error) {
            console.log('error')
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetDoc", pk],
        enabled: !!selectedSystemYear && !!pk,
        queryFn: () => doGet(selectedCompany?.company_token, selectedSystemYear, pk),
        refetchInterval: 1000,
        retry: false,
    })


};

export default useApiGetDoc;