import {IPeopleArticle} from "../../../../../utils/interfaces/tannery_production/articles/IPeopleArticle";

export const filterPeopleArticles = (
    data: IPeopleArticle[],
    filterPerson: string,
    filterArticle: string,
    filterThickness: string,
    filterSize: string,
    filterColor: string,
    filterOrderId: string,
) => {
    function filterByPeople(data: IPeopleArticle[], filterPerson: string) {
        return data.filter((peopleArticle) =>
            peopleArticle.people_name.toLowerCase().includes(filterPerson.toLowerCase())
        );
    }

    function filterByArticles(data: IPeopleArticle[], filterArticle: string) {
        return data.filter((peopleArticle) => {
            const filteredArticles = filterArticle.trim() === ''
                ? peopleArticle.articles
                : peopleArticle.articles.filter((article) =>
                    article.product_name.toLowerCase().includes(filterArticle.toLowerCase())
                );

            // Only return the peopleArticle if it has matching articles or if filterArticle is empty.
            return filterArticle.trim() === '' || filteredArticles.length > 0;
        }).map((filteredPeopleArticle) => ({
            ...filteredPeopleArticle,
            articles: filterArticle.trim() === '' ? filteredPeopleArticle.articles : filteredPeopleArticle.articles.filter((article) =>
                article.product_name.toLowerCase().includes(filterArticle.toLowerCase())
            ),
        }));
    }

    function filterByThicknessArticles(data: IPeopleArticle[], filterThickness: string) {
        return data
            .filter((peopleArticle) => {
                const filteredArticles = filterThickness.trim() === ''
                    ? peopleArticle.articles
                    : peopleArticle.articles.filter((article) =>
                        (article.thickness?.toLowerCase() || '').includes(filterThickness.toLowerCase())
                    );

                // Only return the peopleArticle if it has matching articles or if filterThickness is empty.
                return filterThickness.trim() === '' || filteredArticles.length > 0;
            })
            .map((filteredPeopleArticle) => ({
                ...filteredPeopleArticle,
                articles: filterThickness.trim() === ''
                    ? filteredPeopleArticle.articles
                    : filteredPeopleArticle.articles.filter((article) =>
                        (article.thickness?.toLowerCase() || '').includes(filterThickness.toLowerCase())
                    ),
            }));
    }

    function filterBySizeArticles(data: IPeopleArticle[], filterSize: string) {
        return data
            .filter((peopleArticle) => {
                const filteredArticles = filterSize.trim() === ''
                    ? peopleArticle.articles
                    : peopleArticle.articles.filter((article) =>
                        (article.size?.toLowerCase() || '').includes(filterSize.toLowerCase())
                    );

                // Only return the peopleArticle if it has matching articles or if filterThickness is empty.
                return filterSize.trim() === '' || filteredArticles.length > 0;
            })
            .map((filteredPeopleArticle) => ({
                ...filteredPeopleArticle,
                articles: filterSize.trim() === ''
                    ? filteredPeopleArticle.articles
                    : filteredPeopleArticle.articles.filter((article) =>
                        (article.size?.toLowerCase() || '').includes(filterSize.toLowerCase())
                    ),
            }));
    }

    function filterByArticlesByColor(data: IPeopleArticle[], filterColor: string) {
        return data.map((peopleArticle) => {
            const filteredArticles = peopleArticle.articles
                .map((article) => ({
                    ...article,
                    colors: article.colors.filter((color) =>
                        color.color_name.toLowerCase().includes(filterColor.toLowerCase())
                    ),
                }))
                .filter((article) =>
                    filterColor.trim() === '' || article.colors.length > 0
                );

            // Only return the peopleArticle if it has matching articles or if filterColor is empty.
            return {
                ...peopleArticle,
                articles: filteredArticles,
            };
        }).filter((filteredPeopleArticle) => {
            if (filterColor.trim() === '') {
                return true; // Include all people when filterColor is empty.
            }
            return filteredPeopleArticle.articles.length > 0;
        });
    }


    function filterByArticlesByOrderId(data: IPeopleArticle[], filterColor: string) {
        if (filterColor === '') {
            return data;
        }

        return data.map((peopleArticle) => {
            const filteredArticles = peopleArticle.articles
                .map((article) => ({
                    ...article,
                    colors: article.colors
                        .map((color) => ({
                            ...color,
                            orders: filterColor === ''
                                ? color.orders
                                : color.orders.filter((order) =>
                                    order.order_id.toLowerCase().includes(filterColor.toLowerCase())
                                ),
                        }))
                        .filter((color) => filterColor === '' || color.orders.length > 0),
                }))
                .filter((article) => article.colors.length > 0 || filterColor === '');

            return {
                ...peopleArticle,
                articles: filteredArticles.length > 0 ? filteredArticles : [],
            };
        }).filter((filteredPeopleArticle) => filteredPeopleArticle.articles.length > 0);
    }

    const filteredPeople = filterByPeople(data, filterPerson);
    const filteredDataByArticle = filterByArticles(filteredPeople, filterArticle);
    const filteredDataByThickness = filterByThicknessArticles(filteredDataByArticle, filterThickness);
    const filteredDataBySize = filterBySizeArticles(filteredDataByThickness, filterSize);
    const filteredDataByColor = filterByArticlesByColor(filteredDataBySize, filterColor);
    const filteredDataByOrderId = filterByArticlesByOrderId(filteredDataByColor, filterOrderId);

    return filteredDataByOrderId

};
