import React from 'react';
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import {
    TanneryRawMaterialProvider
} from "../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";
import LwgSiteTable from "./LwgSiteTable";


const LwgSitesScreen = () => {


    return (
        <>
            <MaxWidthDiv maxWidth='1980px'>
                <TanneryRawMaterialProvider>
                    <LwgSiteTable/>
                </TanneryRawMaterialProvider>
            </MaxWidthDiv>
        </>
    );
};

export default LwgSitesScreen;
