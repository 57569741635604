import React from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {useTheme} from '@mui/material/styles';
import {Box, Button, Tooltip, tooltipClasses, TooltipProps, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {styled} from '@mui/system';
import {motion} from 'framer-motion';

interface MuiButtonFilterProps {
    onClick: () => void;
    hasActiveFilters: boolean;
    label: string;
    icon: React.ReactNode;
}

const MRTMuiButtonFilter: React.FC<MuiButtonFilterProps> = ({onClick, hasActiveFilters, label, icon}) => {
    const theme = useTheme();
    const {t} = useTranslation();


    return (
        <Tooltip
            placement="top"
            disableFocusListener
            title={t(label)}
            sx={{fontWeight: 'bold'}}
        >
            <Button
                sx={{
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
                aria-label="filter"
                onClick={onClick}
            >
                <Box
                    sx={{
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.palette.filterColor.main,
                        },
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >{icon}
                </Box>
            </Button>
        </Tooltip>

    );
};

export default MRTMuiButtonFilter;
