import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import useApiGetArticle from "./api-hooks/useApiGetArticle";
import Loading from "../../../../../ui/Loading";
import {Box, TextField} from "@mui/material";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import {IArticle} from "../../../../../../utils/interfaces/tannery_production/articles/IArticle";
import useApiGetArticles from "./api-hooks/useApiGetArticles";


interface Props {
    customer_id: number,
    obj: IArticle | undefined,
    onChange: (obj: IArticle | undefined) => void,
    className: string,
    label?: string,
    clearable?: boolean
    required?: boolean
}

const ArticleSelect = ({obj: initialObj, onChange, label, clearable, required, customer_id, ...props}: Props) => {
    const {displayError, displayMessage} = useToast()
    const {selectedCompany} = useAuth()
    const {t} = useTranslation();
    const {className = ''} = props;
    const [objs, setObjs] = useState<IArticle[]>([]);
    const [obj, setObj] = useState<IArticle | undefined>(initialObj)

    const {
        data,
        isLoading,
        error,
    } = useApiGetArticles(selectedCompany?.company_token, customer_id);
    if (error) {
    }

    useEffect(() => {
        if (data) {
            setObjs(data);
        }
    }, [data]);

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }

    }, [initialObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IArticle | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }

    };

    const {
        data: detailedObj,
    } = useApiGetArticle(selectedCompany?.company_token, customer_id, obj?.id);

    // console.log(detailedObj, customer_id, obj?.id)
    //

    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>

                        <MuiAutocomplete
                            id="article-select"
                            options={objs}
                            getOptionLabel={(option) => option.product_name + ' - ' + option.thickness + ' - ' + option.size}
                            value={detailedObj ? detailedObj : null}
                            // value={null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.product_name === value?.product_name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('article')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>

                </>
            )}
        </>
    );
}

export default ArticleSelect;