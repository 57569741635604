import React, {FC} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import MuiFabButtonBack from '../mui_buttons/fab/extended/MuiFabButtonBack';
import MuiFabButtonDelete from '../mui_buttons/fab/extended/MuiFabButtonDelete';
import {useTranslation} from 'react-i18next';
import MuiFabButtonConfirm from "../mui_buttons/fab/extended/MuiFabButtonConfirm";

interface AlertDialogProps {
    open: boolean;
    title: string;
    content: string;
    onClose: () => void;
    onConfirm?: () => void;
    ask_for_delete?: boolean
}

const MuiAlertDialog: FC<AlertDialogProps> = ({open, title, content, onClose, onConfirm, ask_for_delete = true}) => {
    const {t} = useTranslation();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle
                style={{textAlign: 'center', padding: '16px', textTransform: 'uppercase'}}>{t(title)}</DialogTitle>
            <DialogContent style={{textAlign: 'center', paddingInline: '16px'}}>{t(content)}</DialogContent>
            <DialogActions style={{justifyContent: 'center', paddingBottom: '16px'}}>
                <MuiFabButtonBack onClick={onClose}/>
                {(onConfirm) &&
                    <> {ask_for_delete ? <MuiFabButtonDelete onClick={onConfirm}/> :
                        <MuiFabButtonConfirm onClick={onConfirm}/>}

                    </>}

            </DialogActions>
        </Dialog>
    );
};

export default MuiAlertDialog;
