import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";


const useApiCompanyOrderScheduleProductChange = (
    companyToken: string | undefined
): UseMutationResult<void, CustomAxiosError, { product_id: number; id: number }, unknown> => {


    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined,
                            product_id: number,
                            pk: number,
    ): Promise<void> {
        try {
            await put(`/api/company/${company_token}/tannery_production/order_schedule/${pk}/change_product/${product_id}`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        product_id: number;
        id: number;
    }>({
        mutationFn: ({id, product_id}) => doUpdate(companyToken, product_id, id)
    });
};

export default useApiCompanyOrderScheduleProductChange;