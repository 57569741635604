import React, {useEffect, useState} from 'react';
import {CustomAxiosError} from "../../../../../../logics/api_config";
import Loading from "../../../../../ui/Loading";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import {Box, Popover, TextField} from "@mui/material";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import {Add, Edit} from "@mui/icons-material";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import {useTheme} from "@mui/material/styles";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {IBank} from "../../../../../../utils/interfaces/my_documents/IBank";
import useApiGetBanks from "./api-hooks/useApiGetBanks";
import useApiGetBank from "./api-hooks/useApiGetBank";
import BankTable from "./BankTable";
import BankForm from "./BankForm";


interface Props {
    obj: IBank | undefined,
    onChange: (obj: IBank | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
}

const BankSelect = ({
                        obj: initialObj,
                        label,
                        onChange,
                        required,
                        ...props
                    }: Props) => {

    const {displayError} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)


    const [objs, setObjs] = useState<IBank[]>([]);
    const [obj, setObj] = useState<IBank | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
        error,
    } = useApiGetBanks();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetBank(obj?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        } else {
            setObj(undefined);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IBank | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="bank-select"
                            options={objs}
                            getOptionLabel={(option) => option.name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('bank')}
                                    required={required}
                                />
                            )}
                            borderColor={theme.palette.primary.light}
                            onFocusBorderColor={theme.palette.primary.dark}
                            minWidth={300}
                        >
                            <MRTMuiButton
                                icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => setIsListTableOpen(true)}
                                small={true}
                                label={'show_list'}
                            />
                            <MRTMuiButton
                                icon={obj ? <Edit sx={{color: theme.palette.primary.main}}/> :
                                    <Add sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => setIsEditFormOpen(true)}
                                small={true}
                                label={obj ? 'edit_bank' : 'add_bank'}
                            />
                        </MuiAutocomplete>
                    </Box>

                    <Popover
                        id={'edit_popover'}
                        open={isEditFormOpen}
                        anchorEl={anchorEl}
                        onClose={() => setIsEditFormOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <BankForm objToEdit={obj ? obj : null}
                                  onSelect={onChange}
                                  onClose={() => setIsEditFormOpen(false)}
                        />
                    </Popover>

                    <Popover
                        id={'show_list'}
                        open={isListTableOpen}
                        anchorEl={anchorEl}
                        onClose={() => setIsListTableOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <Box sx={{padding: 2}}>
                            <BankTable
                                alreadySelectedObj={obj}
                                onSelect={onChange}
                                onClose={() => setIsListTableOpen(false)}
                            />
                        </Box>
                    </Popover>
                </>
            )}
        </>
    );
}

export default BankSelect;