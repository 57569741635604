import React from 'react';
import {useAuth} from "../../../utils/contexts/auth-context";
import {ICompany} from "../../../utils/interfaces/accounts/ICompany";
import MuiButton from "../../ui/mui/mui_buttons/MuiButton";
import {useNavigate} from 'react-router-dom';
import {motion} from "framer-motion";
import usePostLogin from "../../Login/api-hooks/usePostLogin";
import {CustomAxiosError} from "../../../logics/api_config";

const ENDPOINT = process.env.REACT_APP_ENDPOINT;


const CompanySelection = () => {
    const {authenticatedUser, loadSelectedCompany, availableCompanies, selectedCompany} = useAuth()
    const navigate = useNavigate();
    const {user, password} = useAuth()

    const handleNoCompanyClick = () => {
        navigate('/login');
    };

    // silent re-sign in with the new endpoint
    const loginMutation = usePostLogin();
    const signin = async (username: string, password: string) => {
        try {
            const response = await loginMutation.mutateAsync({
                username: username,
                password: password,
            });
            if (response) {
                localStorage.setItem('@access_token', response.access);
                localStorage.setItem('@refresh_token', response.refresh);
            }
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response.data.detail) {
            }
        }
    }


    const mapped_companies = () => {
        const handleButtonClick = (company: ICompany) => {
            localStorage.setItem('@company_token', company ? company.company_token : '');
            loadSelectedCompany(company);
            if (user && password) {
                signin(user, password)
            }
        };


        if (availableCompanies) {

            return <div className="flex flex-wrap justify-center">
                {
                    availableCompanies.map((company: ICompany, index: number) => (
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 m-5"
                             key={company.id}>
                            <motion.div
                                className="flex items-center justify-between"
                                initial={{opacity: 0, y: -50}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.5, delay: index * 0.1}}
                                key={company.id}
                            >
                                <button
                                    key={company.id}
                                    className="bg-gray-400 text-white hover:bg-gray-500 px-2 py-1 rounded font-medium text-xs uppercase tracking-wider flex items-center shadow-2xl"
                                    type="submit"
                                    onClick={() => handleButtonClick(company)}
                                    style={{
                                        maxWidth: "300px",
                                        maxHeight: "110px",
                                        minWidth: "300px",
                                        minHeight: "110px"
                                    }}

                                >
                                    {company.company_logo && (
                                        <img
                                            src={ENDPOINT + company.company_logo}
                                            alt={company.company_name}
                                            className="p-4 rounded-3xl"
                                            style={{maxWidth: "100px", maxHeight: "100px"}}
                                        />
                                    )}
                                    <div className='p-2'>
                                        {company.company_name}
                                    </div>

                                </button>
                            </motion.div>
                        </div>

                    ))
                }
            </div>
        }

        return null;
    };


    return (
        <>
            {authenticatedUser && availableCompanies ? (
                <div className="flex flex-col justify-center mt-5 items-center">
                    <div
                        className="text-aGpetrol font-medium text-xs uppercase tracking-wider flex flex-col justify-center mt-5 text-center mb-5 p-3">
                        Please choose the company you want to work for:
                    </div>
                    {availableCompanies && availableCompanies?.length > 0 ? (
                        mapped_companies()
                    ) : (
                        <MuiButton
                            className="rounded font-medium text-xs uppercase tracking-wider"
                            type="submit"
                            onClick={handleNoCompanyClick}
                        >
                            No Company Assigned / Please Contact Admin
                        </MuiButton>
                    )}
                </div>
            ) : (
                ''
            )}
        </>
    );

};

export default CompanySelection;
