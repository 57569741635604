import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IPeople} from "../../../../../../utils/interfaces/service/people/IPeople";


const useApiGetPeople = (
    companyToken: string | undefined
): UseQueryResult<IPeople[], CustomAxiosError> => {

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IPeople[]> {
        try {
            const response = await get(`/api/company/${company_token}/service/people`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getPeople'],
        queryFn: () => doGet(companyToken),
    })
};

export default useApiGetPeople