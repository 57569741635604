import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useToast} from '../../../../../utils/contexts/toast-context';
import useApiGetChemicalProducts from './api-hooks/useApiGetChemicalProducts';
import {CustomAxiosError} from '../../../../../logics/api_config';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {BodyTableCell, BodyTableNumberCell, HeaderTableCell} from "../../../../ui/mui/mui_table/TableCells";
import {CustomTableRow} from "../../../../ui/mui/mui_table/CustomTableRow";
import StyledTablePagination from "../../../../ui/mui/mui_table/StyledTablePagination";
import ChemicalProductsMovementsTable from "./ChemicalProductsMovementsTable";
import {convertDateTimeToInteger, getCurrentDate} from "../../../../../logics/helpers";
import Loading from "../../../../ui/Loading";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFilterAccordion from "../../../../ui/mui/mui-accordion/mui-filter-accordion/MuiFilterAccordion";
import {useTheme} from "@mui/material/styles";
import {filterChemicalProductsList} from "./filterUtils";
import MuiScrollableBox from "../../../../ui/mui/MuiScrollableBox";
import {useService} from "../../../../../utils/contexts/service/service-context";
import {IChemicalProductList} from "../../../../../utils/interfaces/chemicals_products/IChemicalProductList";
import {Box} from "@mui/material";


const ChemicalProductsTable = () => {
    const {t} = useTranslation();
    const {displayError} = useToast();
    const theme = useTheme();
    const {selectPerson} = useService()

    const [openFilters, setOpenFilter] = useState<boolean>(true);
    const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [untilTimestamp, setUntilTimestamp] = useState<string | undefined>();
    const [fromTimestamp, setFromTimestamp] = useState<string | undefined>();
    const [toTimestamp, setToTimestamp] = useState<string | undefined>();


    const handleRowClick = (id: number, row: IChemicalProductList) => {
        selectPerson(row.people);
        setSelectedRowId(id);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const shouldFocusInput = true;
    const inputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const {
        data,
        isLoading,
        error,
        refetch: refetchChemicalProducts
    } = useApiGetChemicalProducts(
        convertDateTimeToInteger(untilTimestamp),
        convertDateTimeToInteger(fromTimestamp),
        convertDateTimeToInteger(toTimestamp));
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    const [personFilter, setPersonFilter] = useState<string>('');
    const [productFilter, setProductFilter] = useState<string>('');
    const handleToggleFilters = () => {
        setPersonFilter('');
        setProductFilter('');
        setOpenFilter(!openFilters);
    };

    const [filteredData, setFilteredData] = useState(data);
    useEffect(() => {
        if (data) {
            setFilteredData(filterChemicalProductsList(data, personFilter, productFilter));
        }
    }, [data, personFilter, productFilter, untilTimestamp, fromTimestamp, toTimestamp]);


    useEffect(() => {
        refetchChemicalProducts();
    }, [untilTimestamp, fromTimestamp, toTimestamp]);

    const dynamicMaxHeight = openFilters ? '60vh' : '80vh';


    return (
        isLoading ? <Loading/> :

            <div className={'p-2'}>
                <MuiFilterAccordion isOpen={openFilters} onToggle={handleToggleFilters}>
                    <MuiInputTextField label={t('chemical_supplier')}
                                       borderColor={theme.palette.filterColor.main}
                                       onChange={(e) => setPersonFilter(e.target.value)}
                        // inputRef={shouldFocusInput ? inputRef : undefined}
                    />
                    <MuiInputTextField label={t('chemical_product')}
                                       borderColor={theme.palette.filterColor.main}
                                       onChange={(e) => setProductFilter(e.target.value)}
                                       inputRef={shouldFocusInput ? inputRef : undefined}
                    />
                    <MuiInputTextField label={t('from_date_use')}
                                       borderColor={theme.palette.filterColor.main}
                                       onChange={(e) => setFromTimestamp(e.target.value)}
                                       type="date"
                                       value={'2020-01-01'}

                    />
                    <MuiInputTextField label={t('to_date_use')}
                                       borderColor={theme.palette.filterColor.main}
                                       onChange={(e) => setToTimestamp(e.target.value)}
                                       type="date"
                                       value={getCurrentDate()}
                    />
                    <MuiInputTextField label={t('balance_at')}
                                       borderColor={theme.palette.filterColor.main}
                                       onChange={(e) => setUntilTimestamp(e.target.value)}
                                       type="date"
                                       value={getCurrentDate()}
                    />
                </MuiFilterAccordion>

                {selectedRowId ?

                    <ChemicalProductsMovementsTable
                        product_id={selectedRowId}
                        onRefetch={refetchChemicalProducts}
                        onClose={() => setSelectedRowId(null)}
                        until_timestamp={convertDateTimeToInteger(untilTimestamp)}
                        from_timestamp={convertDateTimeToInteger(fromTimestamp)}
                        to_timestamp={convertDateTimeToInteger(toTimestamp)}
                    />
                    :

                    <Box>
                        <TableContainer sx={{
                            width: '100%',
                            maxHeight: dynamicMaxHeight,
                            overflowY: 'auto',
                            scrollbarColor: `${theme.palette.primary.main} transparent`,
                            marginTop: '8px'
                        }}>
                            <Table stickyHeader aria-label="sticky table" size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <HeaderTableCell>{t('chemical_supplier')}</HeaderTableCell>
                                        <HeaderTableCell>{t('chemical_product')}</HeaderTableCell>
                                        <HeaderTableCell textAlign={'right'}>{t('used_in_period')}</HeaderTableCell>
                                        <HeaderTableCell textAlign={'right'}>{t('balance')}</HeaderTableCell>
                                        <HeaderTableCell textAlign={'right'}>{t('value_balance')}</HeaderTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData
                                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <CustomTableRow
                                                key={index}
                                                onClick={() => handleRowClick(row.id, row)}
                                            >
                                                <BodyTableCell>{row.people.people_name}</BodyTableCell>
                                                <BodyTableCell>{row.product_name}</BodyTableCell>
                                                <BodyTableNumberCell>{row.used_in_period.toFixed(2)} kgs</BodyTableNumberCell>
                                                <BodyTableNumberCell>{row.balance.balance.toFixed(2)} kgs</BodyTableNumberCell>
                                                <BodyTableNumberCell>{row.balance.total_value_balance.toFixed(2)} €</BodyTableNumberCell>
                                            </CustomTableRow>
                                        ))}
                                    <CustomTableRow>
                                        <BodyTableCell/>
                                        <BodyTableCell>TOTALS:</BodyTableCell>
                                        <BodyTableNumberCell>{filteredData?.reduce((acc, row) => (acc + (row.used_in_period || 0)), 0).toFixed(2)} kgs</BodyTableNumberCell>
                                        <BodyTableNumberCell>{filteredData?.reduce((acc, row) => (acc + (row.balance.balance || 0)), 0).toFixed(2)} kgs</BodyTableNumberCell>
                                        <BodyTableNumberCell>{filteredData?.reduce((acc, row) => (acc + (row.balance.total_value_balance || 0)), 0).toFixed(2)} €</BodyTableNumberCell>
                                    </CustomTableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <StyledTablePagination
                            count={filteredData ? filteredData.length : 0}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                }


            </div>

    );
};

export default ChemicalProductsTable;
