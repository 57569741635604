import React from 'react';
import ButtonMaterial from '@mui/material/Button';
import {styled} from '@mui/material/styles';

type Props = {
    onClick?: () => void;
    children?: React.ReactNode;
    type?: 'button' | 'submit';
    className?: string;
};

// Styled Material-UI MuiButton
const StyledButton = styled(ButtonMaterial)(({theme}) => ({
    padding: theme.spacing(0.7, 2), // Adjust padding based on your preference
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    '&:hover': {
        backgroundColor: theme.palette.backgroundAppGarageColor.dark,
    },
}));

const MuiAppBarButton = ({children, type = 'button', className = '', onClick}: Props) => {
    const handleClick = () => {
        onClick && onClick();
    };

    return (
        <StyledButton type={type} className={className} onClick={handleClick}>
            {children}
        </StyledButton>
    );
};

export default MuiAppBarButton;
