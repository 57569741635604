import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import Loading from "../../../../../ui/Loading";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import {
    IFirstPaymentDays
} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IFirstPaymentDays";
import useApiGetFirstPaymentDayss from "./api-hooks/useApiGetFirstPaymentDayss";
import useApiGetFirstPaymentDays from "./api-hooks/useApiGetFirstPaymentDays";


interface Props {
    obj: IFirstPaymentDays | undefined,
    onChange: (obj: IFirstPaymentDays | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
}

const FirstPaymentDaysSelect = ({
                                    obj: initialObj,
                                    label,
                                    onChange,
                                    required,
                                }: Props) => {

    const {displayError} = useToast()
    const {t} = useTranslation()


    const [objs, setObjs] = useState<IFirstPaymentDays[]>([]);
    const [obj, setObj] = useState<IFirstPaymentDays | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
        error,
    } = useApiGetFirstPaymentDayss();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetFirstPaymentDays(obj?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IFirstPaymentDays | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="first_payment_days_type-select"
                            options={objs}
                            getOptionLabel={(option) => option.number_of_days.toString()}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.code === value?.code}
                            disableClearable={true}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('first_payment_days_type')}
                                    required={required}
                                />
                            )}
                        />
                    </Box>
                </>
            )}
        </>
    );
}

export default FirstPaymentDaysSelect;