import React, {useState, useEffect} from 'react';
import {IChemicalProductMovement} from "../../../../../utils/interfaces/chemicals_products/IChemicalProductMovement";
import {useToast} from "../../../../../utils/contexts/toast-context";
import {CustomAxiosError} from "../../../../../logics/api_config";
import useApiCreateManualChemicalProductsMovement
    from "./api-hooks/manual-movements/useApiCreateManualChemicalProductsMovement";
import useApiUpdateManualChemicalProductsMovement
    from "./api-hooks/manual-movements/useApiUpdateManualChemicalProductsMovement";
import useApiGetManualChemicalProductMovement
    from "./api-hooks/manual-movements/useApiGetManualChemicalProductsMovement";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import {Autocomplete, Box, TextField} from "@mui/material";
import {formatDateTimeForInput, getCurrentDate} from "../../../../../logics/helpers";
import MuiFabButtonDelete from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonDelete";
import {useTranslation} from "react-i18next";
import useApiDeleteManualChemicalProductsMovement
    from "./api-hooks/manual-movements/useApiDeleteManualChemicalProductsMovement";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import {useService} from "../../../../../utils/contexts/service/service-context";
import {IPrice} from "../../../../../utils/interfaces/service/people/product_list/price/IPrice";
import PriceSelect from "../../service/people/product/price/PriceSelect";
import {IChemicalProductDetail} from "../../../../../utils/interfaces/chemicals_products/IChemicalProductDetail";


interface Props {
    objToEdit: IChemicalProductMovement | null | undefined;
    chemicalProductDetail: IChemicalProductDetail | undefined,
    onClose: () => void;
}

const ManualChemicalProductsMovementForm: React.FC<Props> = ({objToEdit, chemicalProductDetail, onClose}) => {
    const {displayError, displayMessage} = useToast();
    const {t} = useTranslation()
    const {selectedPerson} = useService()

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [kgs, setKgs] = useState<number>();
    const [description, setDescription] = useState<string>();
    const [movementDate, setMovementDate] = useState<string>(getCurrentDate);
    const [price, setPrice] = useState<IPrice | null>(null);

    const {
        data: detailedObj,
    } = useApiGetManualChemicalProductMovement(chemicalProductDetail?.id, objToEdit?.movement_identifier);

    useEffect(() => {
        if (detailedObj) {
            setMovementDate(detailedObj.movement_date);
            setDescription(detailedObj.description);
            setKgs(detailedObj.kgs);
            setPrice(detailedObj.price);
        } else {
            setPrice(chemicalProductDetail?.valid_price ? chemicalProductDetail?.valid_price : null)
        }
    }, [detailedObj]);

    const createMutation = useApiCreateManualChemicalProductsMovement();
    const updateMutation = useApiUpdateManualChemicalProductsMovement();
    const deleteMutation = useApiDeleteManualChemicalProductsMovement(chemicalProductDetail?.id, detailedObj?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleConfirmDelete = async () => {
        if (detailedObj) {
            try {
                await apiDelete({product_id: detailedObj.product, pk: detailedObj.id});
            } catch (error) {
                const axiosError = error as CustomAxiosError;
                if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                    displayError(axiosError.response.data.detail);
                } else {
                    displayError('An error occurred while deleting the DocRow');
                }
            }
            setDeleteConfirmationOpen(false);
            onClose();
        }

    };

    const handleSubmit = async () => {
        if (kgs === undefined || !description || !movementDate) {
            displayError(t('please_fill_in_all_required_fields'));
            return;
        }
        try {
            const payload = {
                product: chemicalProductDetail?.id,
                description: description,
                kgs: kgs,
                movement_date: movementDate,
                price: price?.id
            };

            if (detailedObj !== null && detailedObj !== undefined) {
                await updateMutation.mutateAsync({id: detailedObj.id, product_id: detailedObj.product, data: payload});
                displayMessage('Updated successfully');
            } else {
                await createMutation.mutateAsync({product_id: chemicalProductDetail?.id, data: payload});
                displayMessage('Added successfully');
            }
            onClose();

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data) {
                if (axiosError.response.data.detail) {
                    const detailError = axiosError.response.data.detail;
                    displayError(detailError);
                } else {
                    displayError('An error occurred while saving the object');
                }
            } else {
                displayError('An error occurred while saving the object');
            }
        }
    }


    return (
        <Box>
            <form>
                <MuiInputTextField
                    label="Kgs"
                    type="number"
                    value={kgs || ''}
                    onChange={(e) => setKgs(Number(e.target.value))}
                    required={true}
                />
                <MuiInputTextField
                    label="Description"
                    value={description || ''}
                    onChange={(e) => setDescription(e.target.value)}
                    required={true}
                />
                <MuiInputTextField
                    label="Movement Date"
                    type="date"
                    value={formatDateTimeForInput(movementDate) || ''}
                    onChange={(e) => setMovementDate(e.target.value)}
                    required={true}
                />

                <PriceSelect person_id={selectedPerson?.id}
                             product_id={chemicalProductDetail?.id}
                             obj={price} onChange={setPrice} className={''}></PriceSelect>


                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    <MuiFabButtonDelete onClick={() => {
                        setDeleteConfirmationOpen(true)
                    }}/>
                    {objToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit}/>}
                </Box>
            </form>

            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </Box>
    );
};

export default ManualChemicalProductsMovementForm;