// MuiBorderedBox.tsx

import React, {ReactNode} from 'react';
import {Box, BoxProps, Typography, useTheme} from '@mui/material';
import {useTranslation} from "react-i18next";

interface MuiBorderedBoxProps extends BoxProps {
    label?: string,
    children: ReactNode;
}

const MuiBorderedBox: React.FC<MuiBorderedBoxProps> = ({children, label, ...rest}) => {
    const theme = useTheme();
    const {t} = useTranslation()

    return (
        <>
            <Box
                className="mt-2 mb-2 bg-gray-50"
                sx={{
                    border: theme => `1px solid ${theme.palette.primary.main}`,
                    borderRadius: '10px',
                    boxShadow: theme => `2px 2px 12px ${theme.palette.primary.light}`,
                    padding: '10px',
                    position: 'relative',
                    width: '100%'
                }}
                {...rest}
            >
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.primary.main,
                        position: 'absolute',
                        top: '-18px',
                        left: '0px',
                        // backgroundColor: 'white', // ensure the label stands out
                        paddingX: 1,
                    }}
                >
                    {label ? t(label) : ''}
                </Typography>
                {children}
            </Box>
        </>

    );
};

export default MuiBorderedBox;
