import React, {useEffect, useState} from 'react';
import AgDiv from "../../../../../ui/divs/AgDiv";
import {cutString} from "../../../../../../logics/helpers";
import {ISubcontractor} from "../../../../../../utils/interfaces/lwg_dashboard/reports/workings/ISubcontractor";
import RecordList from "./RecordList";
import {BiLeaf} from "react-icons/bi";


interface Props {
    subcontractor: ISubcontractor,
}

const SubcontractorRow = ({subcontractor: initialObj}: Props) => {
    const [obj, setObj] = useState<ISubcontractor>(initialObj);
    const [selectedObj, setSelectedObj] = useState<ISubcontractor | undefined>();

    const handleSelection = () => {
        if (selectedObj?.id === obj.id) {
            setSelectedObj(undefined);
        } else {
            setSelectedObj(obj)
        }
    }

    useEffect(() => {
        setObj(initialObj)
    }, [initialObj]);


    return (
        <>
            <div
                className={`flex flex-row ${selectedObj?.id === obj.id ? 'font-bold' : ''}`}
                style={{flex: 1}}
            >
                <div
                    onClick={handleSelection}
                    className={`flex flex-row ${selectedObj?.id === obj.id ? 'font-bold' : ''}`}
                    // style={{flex: 1}}
                >
                    <AgDiv value={cutString(obj.subcontractor_name, 25)}
                           minWidth="320px" maxWidth="320px"

                    />
                    <AgDiv value={cutString(obj.subcontractor_lwg_site_name, 25)}
                           minWidth="320px" maxWidth="320px"
                           icons={[<BiLeaf className=''
                                           color={obj.subcontractor_lwg_coefficient > 0 ? '#60b8c3' : 'red'}
                                           title={obj.subcontractor_lwg_coefficient > 0 ? 'is lwg' : 'is not lwg'}
                           />,
                           ]}
                    />
                    <AgDiv value={cutString(obj.worked_sqm.toFixed(2), 25)}
                           minWidth="320px" maxWidth="320px"
                           icons={[]}
                    />
                    <AgDiv value={cutString(obj.lwg_workings_description, 25)}
                           minWidth="320px" maxWidth="320px"
                           icons={[]}
                    />
                    <AgDiv value={obj.subcontractor_lwg_coefficient}
                           minWidth="80px" maxWidth="80px"
                           icons={[]}
                    />
                </div>

            </div>
            {selectedObj?.id === obj.id && <RecordList records={obj.records}/>}


        </>
    );
};

export default SubcontractorRow;
