import {styled, useTheme} from "@mui/system";
import TableRow from "@mui/material/TableRow";
import {keyframes} from "@emotion/react";

interface CustomTableRowProps {
    onClick?: () => void;
    index?: number;
}

const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: translateX(0px)
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const CustomTableRow = styled(TableRow)<CustomTableRowProps>(
    ({onClick, theme}) => ({
        position: 'relative',
        animation: `${fadeIn} 1s ease`,
        '&:hover': {
            backgroundColor: theme.palette.tableHoverColor.main,
            cursor: onClick ? 'pointer' : 'default',
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    })
);
