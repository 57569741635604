import React from 'react';
import {CardContent, Chip, Typography, useTheme} from '@mui/material';
import {styled} from '@mui/system';
import {useTranslation} from "react-i18next";

interface MuiCardContentProps {
    value: string | undefined | null;
    label?: string; // Optional label prop
    onClick?: () => void; // onClick prop is optional
}


const MuiCardContent: React.FC<MuiCardContentProps> = ({value, label, onClick}) => {
    const {t} = useTranslation()
    const theme = useTheme();
    return (
        <CardContent sx={{border: `1px solid ${theme.palette.primary.main}`,}}>
            <Typography
                variant="subtitle2"
                sx={{
                    color: theme.palette.primary.main,
                    position: 'absolute',
                    top: 12,
                    left: 24,
                    backgroundColor: 'transparent',
                    paddingX: 2,
                }}
            >
                {t(label ? label : '')}
            </Typography>
            <Typography sx={{color: theme.palette.textAppGarageColor.tw700}}>
                {t(value ? value : '')}
            </Typography>
        </CardContent>
    );
};

export default MuiCardContent;
