import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IDocPayment} from "../../../../../../../../utils/interfaces/my_documents/doc_payment/IDocPayment";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";


const useApiGetDocPayment = (doc_id: number | undefined, pk: number | undefined): UseQueryResult<IDocPayment, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(pk: number | undefined): Promise<IDocPayment> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/my_documents/doc/${doc_id}/doc_payment/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetDocPayment", doc_id, pk],
        queryFn: () => doGet(pk),
        enabled: !!doc_id && !!pk,
    })

};

export default useApiGetDocPayment