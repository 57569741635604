// MuiFabButtonBack.tsx
import React from 'react';
import Fab from '@mui/material/Fab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Zoom from '@mui/material/Zoom';
import {useTranslation} from "react-i18next";

interface MuiFabButtonBackProps {
    onClick: () => void;
    label?: string;
}

const MuiFabButtonBack: React.FC<MuiFabButtonBackProps> = ({onClick, label}) => {
    const {t} = useTranslation()

    return (
        <Zoom in={true} unmountOnExit>
            <Fab color="secondary" aria-label="back" onClick={onClick} variant="extended">
                <ArrowBackIcon style={{color: 'white'}}/>
                {label && (
                    <span style={{textTransform: 'uppercase', color: 'white', marginLeft: '8px'}}>
                        {t(label)}
                    </span>
                )}
            </Fab>
        </Zoom>
    );
};

export default MuiFabButtonBack;
