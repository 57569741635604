import React, {useEffect} from 'react';
import {motion} from 'framer-motion';
import {useAuth} from "../../../utils/contexts/auth-context";
import useApiPrefetchHistoricalCompanyOrderSchedule
    from "../apps/tannery_production/order_schedule/api-hooks/useApiPrefetchHistoricalCompanyOrderSchedule";


const ENDPOINT = process.env.REACT_APP_ENDPOINT;

const HomePage = () => {
    const {selectedCompany} = useAuth();

    // const {data, isLoading, error, isFetching} = useApiGetReportProduction(selectedCompany?.company_token);
    //

    // const {prefetchHistoricalOrderSchedule} = useApiPrefetchHistoricalCompanyOrderSchedule(selectedCompany?.company_token);
    // useEffect(() => {
    //     prefetchHistoricalOrderSchedule();
    // }, [prefetchHistoricalOrderSchedule]);

    return (
        <>
            <div>
                {/* Your other content goes here */}
            </div>

            {/* Centered div with opacity and animation */}
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0}}
                style={{
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    padding: '20px',
                }}
            >
                {selectedCompany?.company_logo && (
                    <motion.img
                        src={ENDPOINT + selectedCompany.company_logo}
                        alt={selectedCompany.company_name}
                        className="p-4 rounded-3xl"
                        style={{maxWidth: '250px', maxHeight: '250px'}}
                        initial={{opacity: 0}} // Optional initial opacity for the image
                        animate={{opacity: 1}} // Animate the opacity of the image
                        transition={{duration: 3}} // Set the duration of the animation (in seconds)
                    />
                )}
            </motion.div>

        </>
    );
};

export default HomePage;
