import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IRegistry} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/IRegistry";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiGetRegistries = (fiscal_registry_id: number | undefined
): UseQueryResult<IRegistry[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, fiscal_registry_id: number | undefined): Promise<IRegistry[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/fiscal_registry/${fiscal_registry_id}/registry`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetRegistries"],
        queryFn: () => doGet(selectedCompany?.company_token, fiscal_registry_id),
        enabled: !!fiscal_registry_id
    })

};

export default useApiGetRegistries