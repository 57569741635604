import React, {useEffect, useState} from 'react';
import Loading from "../../../../ui/Loading";
import {useTranslation} from "react-i18next";
import {Box, TextField} from "@mui/material";
import MuiAutocomplete from "../../../../ui/mui/mui-input/MuiAutoComplete";
import {useTheme} from "@mui/material/styles";
import {IMyCompanyWarehouse} from "../../../../../utils/interfaces/my_company/my_company_warehouse/IMyCompanyWarehouse";
import {IWarehouseProduct} from "../../../../../utils/interfaces/my_warehouse/IWarehouseProduct";
import useApiGetWarehouseProducts from "./api-hooks/useApiGetWarehouseProducts";
import useApiGetWarehouseProduct from "./api-hooks/useApiGetWarehouseProduct";


interface Props {
    obj: IWarehouseProduct | undefined,
    onChange: (obj: IWarehouseProduct | undefined) => void,
    warehouse: IMyCompanyWarehouse,
    className?: string,
    label?: string,
    required?: boolean
    withDefaultValueIfEmpty?: boolean
}

const MyWarehouseProductSelect = ({
                                      obj: initialObj,
                                      label,
                                      onChange,
                                      required,
                                      withDefaultValueIfEmpty = false,
                                      warehouse,
                                      ...props
                                  }: Props) => {


    const theme = useTheme();
    const {t} = useTranslation()

    const [objs, setObjs] = useState<IWarehouseProduct[]>([]);
    const [obj, setObj] = useState<IWarehouseProduct | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
    } = useApiGetWarehouseProducts(warehouse.id);


    useEffect(() => {
        if (data) {
            setObjs(data)
            // note_marco Automatically select the first record if obj is undefined
            if (!initialObj && data.length > 0 && withDefaultValueIfEmpty) {
                setObj(data[0]);
                onChange(data[0]);
            }
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetWarehouseProduct(warehouse.id, obj?.id);


    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IWarehouseProduct | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    const handleChange = (obj: IWarehouseProduct | undefined) => {
        setObj(obj);
        onChange(obj)
    }

    useEffect(() => {
        onChange(obj);

    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="warehouse_product-select"
                            options={objs}
                            getOptionLabel={(option) => option.product_name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.product_name === value?.product_name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('product')}
                                    required={required}
                                />
                            )}
                            borderColor={theme.palette.primary.light}
                            onFocusBorderColor={theme.palette.primary.dark}
                            minWidth={300}
                        >
                        </MuiAutocomplete>
                    </Box>
                </>
            )}
        </>
    );
}

export default MyWarehouseProductSelect;