import React from 'react';
import {TanneryProductionProvider} from "../../../../../utils/contexts/tannery_production/tannery_production-context";
import {
    TanneryRawMaterialProvider
} from "../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import {TanneryDyeingProvider} from "../../../../../utils/contexts/tannery_dyeing/tannery_dyeing-context";
import TanneryDyeingScheduleTable from "./TanneryDyeingScheduleTable";

interface Props {
    listDataType: 'current' | 'historical' | 'drafts';
}

const TanneryDyeingScheduleScreen = ({listDataType}: Props) => {


    return (
        <>

            <MaxWidthDiv maxWidth='1250px'>
                <TanneryProductionProvider>
                    <TanneryRawMaterialProvider>
                        <TanneryDyeingProvider>
                            {/*<TanneryDyeingScheduleList listDataType={listDataType}/>*/}
                            <TanneryDyeingScheduleTable/>
                        </TanneryDyeingProvider>
                    </TanneryRawMaterialProvider>
                </TanneryProductionProvider>
            </MaxWidthDiv>


        </>
    );
};

export default TanneryDyeingScheduleScreen;
