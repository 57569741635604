import React, {useEffect, useState} from 'react';
import {ITransportCompany} from "../../../../../../utils/interfaces/my_documents/ITransportCompany";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import useApiGetTransportCompanies from "./api-hooks/useApiGetTransportCompanies";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import useApiGetTransportCompany from "./api-hooks/useApiGetTransportCompany";
import Loading from "../../../../../ui/Loading";
import {Box, Popover, TextField} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import TransportCompanyForm from "./TransportCompanyForm";
import TransportCompanyTable from "./TransportCompanyTable";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {Add, Edit} from "@mui/icons-material";


interface Props {
    obj: ITransportCompany | undefined,
    onChange: (obj: ITransportCompany | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
}

const TransportCompanySelect = ({
                                    obj: initialObj,
                                    label,
                                    onChange,
                                    required,
                                    ...props
                                }: Props) => {

    const {displayError} = useToast()
    const {t} = useTranslation()
    const theme = useTheme();

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [objs, setObjs] = useState<ITransportCompany[]>([]);
    const [obj, setObj] = useState<ITransportCompany | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
        error,
    } = useApiGetTransportCompanies();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetTransportCompany(obj?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        } else {
            setObj(undefined);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: ITransportCompany | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="trasnport_company-select"
                            options={objs}
                            getOptionLabel={(option) => option.business_name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.business_name === value?.business_name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('incoterm')}
                                    required={required}
                                />
                            )}
                        >
                            <MRTMuiButton
                                icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                                small={true}
                                onClick={() => {
                                    setIsListTableOpen(true)
                                }} label={'show_list'}/>

                            <MRTMuiButton
                                small={true}
                                icon={obj ?
                                    <Edit sx={{color: theme.palette.primary.main}}/>
                                    :
                                    <Add sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => {
                                    setIsEditFormOpen(true)
                                }} label={obj ? 'edit_transport_company' : 'add_transport_company'}/>
                        </MuiAutocomplete>
                    </Box>

                    <Popover
                        id={'edit_popover'}
                        open={isEditFormOpen}
                        anchorEl={anchorEl}
                        onClose={() => setIsEditFormOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <TransportCompanyForm objToEdit={obj ? obj : null}
                                              onSelect={onChange}
                                              onClose={() => setIsEditFormOpen(false)}
                        />
                    </Popover>

                    <Popover
                        id={'show_list'}
                        open={isListTableOpen}
                        anchorEl={anchorEl}
                        onClose={() => setIsListTableOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <Box sx={{padding: 2}}>
                            <TransportCompanyTable
                                alreadySelectedObj={obj}
                                onSelect={onChange}
                                onClose={() => setIsListTableOpen(false)}
                            />
                        </Box>
                    </Popover>
                </>
            )}
        </>
    );
}

export default TransportCompanySelect;