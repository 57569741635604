import React, {useEffect, useState} from 'react';
import {IRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IRegistry";
import {useTranslation} from "react-i18next";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import useApiGetRegistries from "./api-hooks/useApiGetRegistries";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import RegistryForm from "./RegistryForm";
import Loading from "../../../../../ui/Loading";
import {Box, Popover, TextField} from "@mui/material";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import {Add, Edit} from "@mui/icons-material";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import {useTheme} from "@mui/material/styles";
import useApiGetRegistry from "./api-hooks/useApiGetRegistry";

interface Props {
    fiscal_registry: IFiscalRegistry | undefined,
    registry: IRegistry | undefined,
    onChange: (registry: IRegistry | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
}

const RegistrySelect = ({
                            registry: initialRegistry,
                            fiscal_registry: initialFiscalRegistry,
                            label,
                            onChange,
                            required,
                            ...props
                        }: Props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {selectedCompany} = useAuth()
    const {displayError} = useToast()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [objs, setObjs] = useState<IRegistry[]>([]);
    const [obj, setObj] = useState<IRegistry | undefined>(initialRegistry)
    const [fiscalRegistry, setFiscalRegistry] = useState<IFiscalRegistry | undefined>(initialFiscalRegistry)


    const {
        data,
        isLoading,
        error,
    } = useApiGetRegistries(fiscalRegistry?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (initialRegistry) {
            setObj(initialRegistry)
        }
    }, [initialRegistry]);

    useEffect(() => {
        if (initialFiscalRegistry) {
            setFiscalRegistry(initialFiscalRegistry)
        }
    }, [initialFiscalRegistry]);

    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IRegistry | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);

    const {
        data: detailedObj,
    } = useApiGetRegistry(fiscalRegistry?.id, obj?.id);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="registry-select"
                            options={objs}
                            getOptionLabel={(option) => option.business_name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.business_name === value?.business_name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={t(label ? label : '') || t('registry')}
                                    required={required}
                                />
                            )}
                        >
                            <MRTMuiButton
                                small={true}
                                icon={obj ?
                                    <Edit sx={{color: theme.palette.primary.main}}/>
                                    :
                                    <Add sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => {
                                    setIsEditFormOpen(true)
                                }} label={obj ? 'edit registry' : 'add registry'}/>
                        </MuiAutocomplete>
                    </Box>

                    <Popover
                        id={'edit_popover'}
                        open={isEditFormOpen}
                        anchorEl={anchorEl}
                        onClose={() => setIsEditFormOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <RegistryForm registryToEdit={obj}
                                      onClose={() => setIsEditFormOpen(false)}/>
                    </Popover>
                </>
            )}
        </>
    );
}

export default RegistrySelect;