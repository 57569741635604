import React, {ReactNode, useState} from "react";
import {ITanneryDyeingSchedule} from "../../interfaces/tannery_dyeing/ITanneryDyeingSchedule";
import {ITanneryDyeingRecipe} from "../../interfaces/tannery_dyeing/ITanneryDyeingRecipe";

type TanneryDyeingContextType = {
    selectedTanneryDyeing: ITanneryDyeingSchedule | undefined,
    selectTanneryDyeing: (selectedTanneryDyeing: ITanneryDyeingSchedule | undefined) => void,

    selectedTanneryDyeingRecipe: ITanneryDyeingRecipe | undefined,
    selectTanneryDyeingRecipe: (selectedTanneryDyeingRecipe: ITanneryDyeingRecipe | undefined) => void,

    selectedTanneryDyeingRecipes: ITanneryDyeingRecipe[] | undefined,
    selectTanneryDyeingRecipes: (selectedTanneryDyeingRecipes: ITanneryDyeingRecipe[] | undefined) => void,

    selectTanneryDyeingRecipeByRowPosition: (rowPosition: number | undefined) => void,

    isEditable: boolean,
    setIsEditable: React.Dispatch<React.SetStateAction<boolean>>,

    avatarImage: ReactNode | undefined,
    setAvatarImage: React.Dispatch<React.SetStateAction<ReactNode | undefined>>,
}

const TanneryDyeingContext = React.createContext<TanneryDyeingContextType | undefined>(undefined);

function useTanneryDyeingContextValue() {
    const [selectedTanneryDyeing, setSelectedTanneryDyeing] = useState<ITanneryDyeingSchedule>();
    const [selectedTanneryDyeingRecipe, setSelectedTanneryDyeingRecipe] = useState<ITanneryDyeingRecipe>();
    const [selectedTanneryDyeingRecipes, setSelectedTanneryDyeingRecipes] = useState<ITanneryDyeingRecipe[]>();
    const [isEditable, setIsEditable] = useState(false);
    const [avatarImage, setAvatarImage] = useState<ReactNode>();

    const selectTanneryDyeing = (selectedObj: ITanneryDyeingSchedule | undefined) => {
        setSelectedTanneryDyeing(selectedObj);
    }

    const selectTanneryDyeingRecipe = (selectedObj: ITanneryDyeingRecipe | undefined) => {
        setSelectedTanneryDyeingRecipe(selectedObj);
    }

    const selectTanneryDyeingRecipes = (recipes: ITanneryDyeingRecipe[] | undefined) => {
        setSelectedTanneryDyeingRecipes(recipes);
    };

    const selectTanneryDyeingRecipeByRowPosition = (rowPosition: number | undefined) => {
        const tanneryDyeingRecipes = selectedTanneryDyeingRecipes || [];
        const selectedRecipe = tanneryDyeingRecipes.find(recipe => recipe.row_position === rowPosition);
        selectTanneryDyeingRecipe(selectedRecipe);
    };

    return {
        selectedTanneryDyeing, selectTanneryDyeing,
        selectedTanneryDyeingRecipe, selectTanneryDyeingRecipe,
        selectedTanneryDyeingRecipes, selectTanneryDyeingRecipes,
        selectTanneryDyeingRecipeByRowPosition,
        isEditable, setIsEditable,
        avatarImage, setAvatarImage
    };
}

function TanneryDyeingProvider({children}: { children: React.ReactNode }) {
    const value = useTanneryDyeingContextValue();
    return <TanneryDyeingContext.Provider value={value}>{children}</TanneryDyeingContext.Provider>;
}

function useTanneryDyeing() {
    const context = React.useContext(TanneryDyeingContext);
    if (!context) {
        throw new Error('useTanneryDyeing must be used within a TanneryDyeingProvider');
    }
    return context;
}

export {
    TanneryDyeingProvider,
    useTanneryDyeing,
};
