import {ITanneryDyeingSchedule} from "../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingSchedule";

export const filterTanneryDyeingList = (
    data: ITanneryDyeingSchedule[],
    filterTanneryDyeingId: string,
    filterOrderId: string,
    filterPerson: string,
    filterArticle: string,
    filterThickness: string,
    filterColor: string,
    filterTanneryDyeingDescription: string,
    showOnlyWithRecipe: boolean,
) => {
    if (!data) {
        return [];
    }

    function filterByOrderId(data: ITanneryDyeingSchedule[], filter: string) {
        return data.filter((tanneryDyeing) => {
            if (filter.trim() === '') {
                return true;
            } else {
                const matchingOrders = tanneryDyeing.orders_schedule.filter((dyeOrder) => {
                    return dyeOrder.order.order_id.toLowerCase().includes(filter.toLowerCase());
                });

                return matchingOrders.length > 0;
            }
        });
    }

    function filterByTanneryDyeingId(data: ITanneryDyeingSchedule[], filter: string) {
        if (filter === '') {
            return data
        }
        return data.filter((tanneryDyeing) => {
            return tanneryDyeing.tannery_dyeing_id?.toLowerCase().includes(filter.toLowerCase());
        });
    }

    function filterByTanneryDyeingDescription(data: ITanneryDyeingSchedule[], filter: string) {
        if (filter === '') {
            return data
        }
        return data.filter((tanneryDyeing) => {
            return tanneryDyeing.description?.toLowerCase().includes(filter.toLowerCase());
        });
    }

    function filterByPerson(data: ITanneryDyeingSchedule[], filter: string) {
        return data.filter((tanneryDyeing) => {
            if (filter.trim() === '') {
                return true;
            } else {
                // Check if the customer of the tanneryDyeing matches the filter
                const tanneryDyeingCustomerMatches = tanneryDyeing.customer?.toLowerCase().includes(filter.toLowerCase());

                // Check if any orders' people_name matches the filter
                const matchingOrders = tanneryDyeing.orders_schedule.filter((dyeOrder) => {
                    return dyeOrder.order.people_name.toLowerCase().includes(filter.toLowerCase());
                });

                return tanneryDyeingCustomerMatches || matchingOrders.length > 0;
            }
        });
    }


    function filterByArticle(data: ITanneryDyeingSchedule[], filter: string) {
        return data.filter((tanneryDyeing) => {
            if (filter.trim() === '') {
                return true;
            } else {
                // Check if the article_name of the tanneryDyeing matches the filter
                const tanneryDyeingArticleMatches = tanneryDyeing.article?.toLowerCase().includes(filter.toLowerCase());

                // Check if any orders' article_name matches the filter
                const matchingOrders = tanneryDyeing.orders_schedule.filter((dyeOrder) => {
                    return dyeOrder.order.article_name.toLowerCase().includes(filter.toLowerCase());
                });

                return tanneryDyeingArticleMatches || matchingOrders.length > 0;
            }
        });
    }


    function filterByThickness(data: ITanneryDyeingSchedule[], filter: string) {
        return data.filter((tanneryDyeing) => {
            if (filter.trim() === '') {
                return true;
            } else {
                const matchingOrders = tanneryDyeing.orders_schedule.filter((dyeOrder) => {
                    return dyeOrder.order.thickness.toLowerCase().includes(filter.toLowerCase());
                });

                return matchingOrders.length > 0;
            }
        });
    }

    function filterByColor(data: ITanneryDyeingSchedule[], filter: string) {
        return data.filter((tanneryDyeing) => {
            if (filter.trim() === '') {
                return true;
            } else {
                // Check if the color of the tanneryDyeing matches the filter
                const tanneryDyeingColorMatches = tanneryDyeing.color?.toLowerCase().includes(filter.toLowerCase());

                // Check if any orders' color matches the filter
                const matchingOrders = tanneryDyeing.orders_schedule.filter((dyeOrder) => {
                    return dyeOrder.order.color.color_name.toLowerCase().includes(filter.toLowerCase());
                });

                return tanneryDyeingColorMatches || matchingOrders.length > 0;
            }
        });
    }

    function filterOnlyWithRecipe(data: ITanneryDyeingSchedule[], showOnlyWithRecipe: boolean): ITanneryDyeingSchedule[] {
        return data.filter((tanneryDyeing) => {
            // Return items with recipe if showOnlyWithRecipe is true
            return showOnlyWithRecipe ? tanneryDyeing.has_recipe : true;
        });
    };

    const filteredByOrderId = filterByOrderId(data, filterOrderId);
    const filteredByTanneryDyeingId = filterByTanneryDyeingId(filteredByOrderId, filterTanneryDyeingId);
    const filteredByPeople = filterByPerson(filteredByTanneryDyeingId, filterPerson);
    const filteredByArticle = filterByArticle(filteredByPeople, filterArticle);
    const filteredByThickness = filterByThickness(filteredByArticle, filterThickness);
    const filteredByColor = filterByColor(filteredByThickness, filterColor);
    const filteredByDescription = filterByTanneryDyeingDescription(filteredByColor, filterTanneryDyeingDescription);
    const filteredOnlyWithRecipe = filterOnlyWithRecipe(filteredByDescription, showOnlyWithRecipe)


    return filteredOnlyWithRecipe

};
