import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IMyCompanyRegistry} from "../../../../../../utils/interfaces/my_company/my_company_registry/IMyCompanyRegistry";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiGetMyCompanyRegistry = (): UseQueryResult<IMyCompanyRegistry, CustomAxiosError> => {
    const {selectedCompany} = useAuth()
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IMyCompanyRegistry> {
        try {
            const response = await get(`/api/company/${company_token}/my_company/my_company_registry/detail/`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetMyCompanyRegistry"],
        queryFn: () => doGet(selectedCompany?.company_token),
        // retry: 2,
        // retryDelay: (attemptIndex) => Math.min(1000000 * 2 ** attemptIndex),
    })
};

export default useApiGetMyCompanyRegistry