import React from 'react';
import {Route, Routes} from "react-router-dom";
import LoginScreen from '../../components/Login/LoginScreen';
import LogOutScreen from '../../components/Login/LogOutScreen';
import ProtectedRoute from './ProtectedRoute';
import HomeScreen from "../../components/app/Home/HomeScreen";
import PeopleScreen from "../../components/app/apps/service/people/PeopleScreen";
import OrderScheduleScreen from "../../components/app/apps/tannery_production/order_schedule/OrderScheduleScreen";
import LwgSitesScreen from "../../components/app/apps/tannery_raw_material/lwg_sites/LwgSitesScreen";
import LotsScreen from "../../components/app/apps/tannery_raw_material/lots/LotsScreen";
import WorkingsScreen from "../../components/app/apps/tannery_production/order_schedule/workings/WorkingsScreen";
import TanneryDyeingScheduleScreen
    from "../../components/app/apps/tannery_dyeing/tannery_dyeing_schedule/TanneryDyeingScheduleScreen";
import LwgProductionReportScreen
    from "../../components/app/apps/lwg_dashboard/lwg_reports/production/LwgProductionReportScreen";
import LwgWorkingsReportScreen
    from "../../components/app/apps/lwg_dashboard/lwg_reports/workings/LwgWorkingsReportScreen";
import DocScreen from "../../components/app/apps/my_documents/doc/DocScreen";
import ChemicalProductsScreen
    from "../../components/app/apps/chemicals_management/chemical_products/ChemicalProductsScreen";
import BasePage from "../../components/ui/layout/BasePage/BasePage";
import PeopleArticlesScreen from "../../components/app/apps/tannery_production/people_articles/PeopleArticlesScreen";
import MyCompanyScreen from "../../components/app/apps/my_company/MyCompanyScreen";
import MyWarehouseScreen from "../../components/app/apps/my_warehouse/MyWarehouseScreen";

const Navigation = () => {

    return (
        <Routes>
            <Route path="/login" element={<LoginScreen/>}/>
            <Route path="/logout" element={<LogOutScreen/>}/>
            <Route path="/" element={<LoginScreen/>}/>
            {/*<Route path="/" element={<WelcomeScreen/>}/>*/}

            <Route path="/" element={<BasePage/>}>
                <Route path="/apps/my_company/registry" element={
                    <ProtectedRoute>
                        <MyCompanyScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/lwg_dashboard/production_report" element={
                    <ProtectedRoute>
                        <LwgProductionReportScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/lwg_dashboard/workings_report" element={
                    <ProtectedRoute>
                        <LwgWorkingsReportScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/tannery_raw_material/lwg_sites" element={
                    <ProtectedRoute>
                        <LwgSitesScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/service/people" element={
                    <ProtectedRoute>
                        <PeopleScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/my_documents/doc" element={
                    <ProtectedRoute>
                        <DocScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/my_documents/people" element={
                    <ProtectedRoute>
                        <PeopleScreen category_person={'customer'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_raw_material/people" element={
                    <ProtectedRoute>
                        <PeopleScreen category_person={'supplier'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_raw_material/lots" element={
                    <ProtectedRoute>
                        <LotsScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/tannery_production/articles" element={
                    <ProtectedRoute>
                        <PeopleArticlesScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_production/order_schedule" element={
                    <ProtectedRoute>
                        <OrderScheduleScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_production/people" element={
                    <ProtectedRoute>
                        <PeopleScreen category_person={'subcontractor'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_production/workings" element={
                    <ProtectedRoute>
                        <WorkingsScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/tannery_dyeing/dyeing_schedule/people" element={
                    <ProtectedRoute>
                        <PeopleScreen isService={false} category_person={'chemical_supplier'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_dyeing/dyeing_schedule/current" element={
                    <ProtectedRoute>
                        <TanneryDyeingScheduleScreen listDataType={'current'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_dyeing/dyeing_schedule/history" element={
                    <ProtectedRoute>
                        <TanneryDyeingScheduleScreen listDataType={'historical'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_dyeing/dyeing_schedule/drafts" element={
                    <ProtectedRoute>
                        <TanneryDyeingScheduleScreen listDataType={'drafts'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/tannery_raw_material/lwg_sites" element={
                    <ProtectedRoute>
                        <LwgSitesScreen/>
                    </ProtectedRoute>
                }/>
                <Route path="/apps/chemicals_management/products" element={
                    <ProtectedRoute>
                        <ChemicalProductsScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/chemicals_management/people" element={
                    <ProtectedRoute>
                        <PeopleScreen isService={false} category_person={'chemical_supplier'}/>
                    </ProtectedRoute>
                }/>

                <Route path="/apps/my_warehouse" element={
                    <ProtectedRoute>
                        <MyWarehouseScreen/>
                    </ProtectedRoute>
                }/>

                <Route path="/home" element={
                    <ProtectedRoute>
                        <HomeScreen/>
                    </ProtectedRoute>
                }/>

            </Route>
            {/*<Route path="/home" element={*/}
            {/*    <ProtectedRoute>*/}
            {/*        <HomeScreen/>*/}
            {/*    </ProtectedRoute>*/}
            {/*}/>*/}

        </Routes>
    );
};

export default Navigation;