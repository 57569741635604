import React, {useState, useEffect} from 'react';
import {useQueryClient} from "@tanstack/react-query";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useTanneryProduction} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import useApiUpdateRawChoiceOrder from "./api-hooks/useApiUpdateRawChoiceOrder";
import useApiCreateRawChoiceOrder from "./api-hooks/useApiCreateRawChoiceOrder";
import {
    IOrderScheduleList
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleList";
import {
    useTanneryRawMaterial
} from "../../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import useApiGetRawChoiceOrderDetail from "./api-hooks/useApiGetRawChoiceOrderDetail";
import {IChoice} from "../../../../../../utils/interfaces/tannery_raw_material/IChoice";
import {useAppGarage} from "../../../../../../utils/contexts/appGarage-context";
import {Box, Grid} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";


interface Props {
    choiceOrderToEdit: number | undefined;
    onClose: () => void;
}

const RawMaterialForm: React.FC<Props> = ({choiceOrderToEdit, onClose}) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();
    const {selectedCompanyOrderSchedule} = useTanneryProduction()
    const {selectedChoice} = useTanneryRawMaterial()
    const {availableCompanyUserApplications} = useAppGarage()


    const [quantity, setQuantity] = useState<number | undefined>()
    const [choice, setChoice] = useState<IChoice | undefined>()
    const [order, setOrder] = useState<IOrderScheduleList | undefined>()

    const mutationHook = choiceOrderToEdit ? useApiUpdateRawChoiceOrder : useApiCreateRawChoiceOrder;
    const mutation = mutationHook(selectedCompany?.company_token);


    const {data, error} = useApiGetRawChoiceOrderDetail(selectedCompany?.company_token, choiceOrderToEdit);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        setOrder(selectedCompanyOrderSchedule);
        setChoice(selectedChoice);
        if (choiceOrderToEdit) {
            if (data) {
                if (Array.isArray(data) && data.length > 0) {
                    const quantity = data[0].quantity;
                    setQuantity(quantity);
                    setChoice(data[0].choice)
                }
            }
        }
    }, [data, selectedChoice, selectedCompanyOrderSchedule, choiceOrderToEdit]);


    const handleSubmit = async () => {
        try {
            const mutationData = {
                quantity: quantity,
                order_id: order?.id,
                choice_id: choice?.id,
            };

            if (choiceOrderToEdit !== undefined) {
                await mutation.mutateAsync({
                    pk: choiceOrderToEdit,
                    data: mutationData
                });

                displayMessage('ChoiceOrder updated successfully');
            } else {
                await mutation.mutateAsync(
                    mutationData
                );
                displayMessage('ChoiceOrder added successfully');
            }

            onClose();

            await queryClient.invalidateQueries({queryKey: ['getChoicesByLot']});
            await queryClient.invalidateQueries({queryKey: ['getOrderRawChoicesOrders']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
            await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetReportProduction']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetPendingOrders']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['useApiGetReportWorkings']});
            availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && await queryClient.invalidateQueries({queryKey: ['getLwgVerificationPeriodOrders']});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while saving the ChoiceOrder');
            }
        }
    };


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
                    label='quantity'
                    type="quantity"
                    value={quantity || ''}
                    required={true}
                />

                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Box className={'mt-2 gap-x-2'}>
                        <MuiFabButtonBack onClick={onClose}/>
                        {choiceOrderToEdit ?
                            <MuiFabButtonSaveAs onClick={handleSubmit}/>
                            :
                            <MuiFabButtonSave onClick={handleSubmit}/>
                        }
                    </Box>
                </Grid>
            </form>
        </Box>
    );
};

export default RawMaterialForm;
