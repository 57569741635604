import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IFurtherPaymentDays
} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/payment/IFurtherPaymentDays";


const useApiGetFurtherPaymentDayss = (): UseQueryResult<IFurtherPaymentDays[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IFurtherPaymentDays[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/payment/further_payment_days`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetFurtherPaymentDayss"],
        queryFn: () => doGet(selectedCompany?.company_token,),
    })
};

export default useApiGetFurtherPaymentDayss