import React, {useEffect, useMemo, useState} from 'react';
import {IOtherDestination} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IOtherDestination";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import useApiDeleteOtherDestination from "./api-hooks/useApiDeleteOtherDestination";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import useApiGetOtherDestinations from "./api-hooks/useApiGetOtherDestinations";
import customCellRenderer from "./customCellRenderer";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box, Popover} from "@mui/material";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import Loading from "../../../../../ui/Loading";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import OtherDestinationForm from "./OtherDestinationForm";
import MuiFetchingSpinner from "../../../../../ui/mui/MuiFetchingSpinner";
import AdsClickIcon from '@mui/icons-material/AdsClick';


interface Props {
    fiscal_registry: IFiscalRegistry | undefined,
    onSelect?: (obj: IOtherDestination | undefined) => void,
    onClose?: () => void;
    alreadySelectedObj?: IOtherDestination | undefined,
}

interface IOtherDestinationWithCustomField extends IOtherDestination {
    custom_field: JSX.Element;
}


const OtherDestinationTable = ({fiscal_registry, onSelect, onClose, alreadySelectedObj}: Props) => {
    const {displayError} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()

    const [selectedRow, setSelectedRow] = useState<IOtherDestination | undefined>()
    const [rows, setRows] = useState<IOtherDestination[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteOtherDestination(fiscal_registry?.id, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            if (onSelect && (alreadySelectedObj?.id === selectedRow?.id)) {
                onSelect(undefined);
            }
            await apiDelete({fiscal_registry_id: fiscal_registry?.id, pk: selectedRow?.id});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the obj');
            }
        }
        setDeleteConfirmationOpen(false);

    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    const {
        data,
        isLoading,
        error,
        isFetching
    } = useApiGetOtherDestinations(fiscal_registry?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);


    const [lwgSitesWithCustomField, setLwgSitesWithCustomField] = useState<IOtherDestinationWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IOtherDestinationWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customCellRenderer(row, theme, t),
        }));
        setLwgSitesWithCustomField(dataWithCustomField);
    }, [rows]);


    const columns = useMemo<MRT_ColumnDef<IOtherDestination>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'business_name',
                header: t('business_name'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'address',
                header: t('address'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'city',
                header: t('city'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'cap',
                header: t('cap'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'province',
                header: t('province'),
                filterFn: 'contains',

            },
            {
                accessorKey: 'nation.name',
                header: t('state'),
                filterFn: 'contains',

            },

        ],
        [rows]
    );


    const table = useMaterialReactTable<IOtherDestination>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: lwgSitesWithCustomField ? lwgSitesWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: true,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu();
                }}
                table={table}
            />,

        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <AssistantDirectionIcon color={'primary'}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'other_destinations'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_other_destination'}/>
                </>
            </Box>
        ),
    });


    return <>
        {isLoading ? <Loading/> :
            <>

                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <Popover
                    id={'edit_popover'}
                    open={isEditFormOpen}
                    anchorEl={anchorEl}
                    onClose={() => setIsEditFormOpen(false)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <OtherDestinationForm objToEdit={selectedRow ? selectedRow : null}
                                          fiscal_registry={fiscal_registry}
                                          onClose={() => setIsEditFormOpen(false)}/>
                </Popover>

                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default OtherDestinationTable;
