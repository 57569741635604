import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from "@tanstack/react-query";


const useApiUpdatePrice = (
    companyToken: string | undefined
): UseMutationResult<void, CustomAxiosError, {
    people_id: number;
    product_id: number;
    id: number;
    data: any
}, unknown> => {

    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined,
                            people_id: number,
                            product_id: number,
                            pk: number,
                            data: any): Promise<void> {
        try {
            await put(`/api/company/${company_token}/service/people/${people_id}/product/${product_id}/price/${pk}/update`, data);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, {
        people_id: number;
        product_id: number;
        id: number;
        data: any;
    }>({
        mutationFn: ({id, people_id, product_id, data}) => doUpdate(companyToken, people_id, product_id, id, data)
    });
};


export default useApiUpdatePrice;

