import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {IArticle} from "../../../../../../../utils/interfaces/tannery_production/articles/IArticle";


const useApiGetArticles = (
    companyToken: string | undefined,
    customerId: number,
): UseQueryResult<IArticle[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined): Promise<IArticle[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/articles/${customerId}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['useApiGetArticles', customerId],
        queryFn: () => doGet(companyToken)
    })
};

export default useApiGetArticles