import React, {useEffect, useRef, useState} from 'react';
import {useAuth} from "../../../../../utils/contexts/auth-context";
import Loading from "../../../../ui/Loading";
import useApiGetCompanyOrderScheduleByColor from "./api-hooks/useApiGetCompanyOrderScheduleByColor";
import {CustomAxiosError} from "../../../../../logics/api_config";
import {useToast} from "../../../../../utils/contexts/toast-context";
import OrderScheduleTable from "./OrderScheduleTable";
import {IColor} from "../../../../../utils/interfaces/tannery_production/articles/IColor";

interface Props {
    color?: IColor,
}

const OrderScheduleLisByColor = ({
                                     color,
                                 }: Props) => {

    const {selectedCompany} = useAuth()
    const {displayError} = useToast()

    const inputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);


    const {
        data,
        isLoading,
        error,
    } = useApiGetCompanyOrderScheduleByColor(selectedCompany?.company_token, color?.id);

    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    return (

        <>{isLoading ?
            <Loading/>
            :
            <>
                {/*<OrderScheduleList current_orders_schedule={data}*/}
                {/*                   filteredByColor={true}*/}
                {/*                   scrollableHeight={scrollableHeight}*/}
                {/*                   withFilters={false}*/}
                {/*/>*/}
                <OrderScheduleTable orders_schedule={data} color={color}/>
            </>


        }
        </>
    );
};

export default OrderScheduleLisByColor;