import {ICategory} from "../../../../../../utils/interfaces/service/people/ICategory";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";


const useApiGetCategories = (
    companyToken: string | undefined
): UseQueryResult<ICategory[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined,): Promise<ICategory[]> {
        try {
            const response = await get(`/api/company/${company_token}/service/people/categories`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getCategories'],
        queryFn: () => doGet(companyToken),
    })
};

export default useApiGetCategories;