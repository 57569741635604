import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import {contentNavbarEntries} from "./content-navbar";
import {useNavigate,} from "react-router-dom";
import {IconContext} from "react-icons";
import {DrawerHeader} from "./DrawerHeader";
import {MdArrowBackIos} from "react-icons/md";
import {useTheme} from "@mui/material/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import {useAppGarage} from "../../../../utils/contexts/appGarage-context";
import {useAuth} from "../../../../utils/contexts/auth-context";
import UseApiGetAvailableUserCompanyApplications
    from "../../../../utils/custom_hooks/useApiGetAvailableUserCompanyApplications";
import {useTranslation} from "react-i18next";
import UseApiGetAvailableCompanyApplications
    from "../../../../utils/custom_hooks/useApiGetAvailableCompanyApplications";


interface Props {
    isOpen?: boolean,
    handleOpen?: () => void,
    handleClose?: () => void,
    handleToggle?: () => void,
}


const NavBar = ({isOpen = false, handleOpen, handleClose, handleToggle}: Props) => {
    const navigate = useNavigate();
    const {t} = useTranslation()
    const theme = useTheme();
    const {selectedCompany} = useAuth()
    const {selectAvailableCompanyUserApplications, selectAvailableCompanyApplications} = useAppGarage()

    const {data: availableCompanyUserApplications} = UseApiGetAvailableUserCompanyApplications(selectedCompany?.company_token)

    const {data: availableCompanyApplications} = UseApiGetAvailableCompanyApplications(selectedCompany?.company_token)

    const filteredContentNavbarEntries = contentNavbarEntries
        .filter(entry => {
            const nameMatches = availableCompanyUserApplications?.some(app => app.in_project_application_name === entry.name);
            const isLogoutOrHome = entry.name === "logout" || entry.name === "home";

            return nameMatches || isLogoutOrHome;
        })
        .map(entry => {
            const matchingApp = availableCompanyUserApplications?.find(app => app.in_project_application_name === entry.name);
            return {
                ...entry,
                is_valid: matchingApp ? matchingApp.is_valid : true
            };
        });


    useEffect(() => {
        selectAvailableCompanyUserApplications(availableCompanyUserApplications)
    }, [availableCompanyUserApplications]);

    useEffect(() => {
        selectAvailableCompanyApplications(availableCompanyApplications)
    }, [availableCompanyApplications]);


    const handleNavbarNavigation = (to: string, i: number) => {
        setSelectedIndex(i);
        if (to[0] !== "/") {
            window.location.href = to;
        } else {
            navigate(to)
        }
        handleClose && handleClose()
    }

    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [open, setOpen] = React.useState<number | null>(null);
    const handleClick = (index: number) => {
        setOpen((prevIndex) => (prevIndex === index ? null : index));
    };


    return (
        <>
            <Drawer
                variant={"persistent"}
                open={isOpen}
                PaperProps={{
                    sx: {
                        backgroundColor: theme.palette.primary.main, // Set your desired background color
                        boxShadow: theme.shadows[3],
                    },
                }}
                // sx={{
                //     position: "sticky",
                //     top: 64,
                // }}
            >
                <IconContext.Provider
                    value={{color: "white", size: "20px"}}
                >
                    <DrawerHeader>
                        <IconButton
                            onClick={handleToggle}
                            onMouseOver={handleClose}
                            sx={{
                                transition: 'transform 0.2s ease',
                                transform: isOpen ? 'rotate(-180deg)' : 'rotate(0)',
                                padding: "4px",
                            }}
                        >
                            <MdArrowBackIos/>
                        </IconButton>
                    </DrawerHeader>
                    <List>
                        {filteredContentNavbarEntries.map((entryObj: any, index: number) => (
                            <React.Fragment key={index}>
                                {entryObj.id === 0 && <Divider/>}
                                <ListItem
                                    // dense
                                    disablePadding
                                    sx={{
                                        backgroundColor: theme.palette.primary.main
                                    }}>
                                    <ListItemButton
                                        disabled={!entryObj.is_valid}
                                        sx={{color: 'white'}}
                                        onClick={() => entryObj.to ?
                                            handleNavbarNavigation(entryObj.to, index)
                                            :
                                            handleClick(index)
                                        }>
                                        <ListItemIcon>
                                            <Avatar sx={{
                                                backgroundColor: `${entryObj.to ? theme.palette.secondary.main : theme.palette.primary.light}`,
                                                border: `2px solid white`,
                                                marginRight: 2,
                                            }}>
                                                {entryObj.icon}
                                            </Avatar>
                                        </ListItemIcon>
                                        <ListItemText primary={t(entryObj.name)} sx={{textTransform: 'capitalize'}}/>

                                        {entryObj.submenu ? open === index ? <ExpandLess/> :
                                            <ExpandMore/> : null}
                                    </ListItemButton>
                                    <Collapse in={open === index} timeout="auto" unmountOnExit>
                                        {entryObj.submenu &&
                                            <List component="div" disablePadding>
                                                {entryObj.submenu.map((submenuItem: any, subIndex: number) => (
                                                    <React.Fragment key={`${index}-${subIndex}`}>
                                                        <ListItemButton key={subIndex} sx={{pl: 2, color: 'white'}}
                                                                        onClick={() => handleNavbarNavigation(submenuItem.to, index)}>
                                                            <ListItemIcon>
                                                                <Avatar sx={{
                                                                    backgroundColor: `${submenuItem.to ? theme.palette.secondary.main : theme.palette.primary.light}`,
                                                                    border: `2px solid white`,
                                                                    marginRight: 2,
                                                                }}>
                                                                    {submenuItem.icon}
                                                                </Avatar>
                                                            </ListItemIcon>
                                                            <ListItemText primary={t(submenuItem.name)}
                                                                          sx={{textTransform: 'capitalize'}}/>
                                                        </ListItemButton>
                                                        {subIndex !== entryObj.submenu.length - 1 &&
                                                            <Divider/>}
                                                    </React.Fragment>
                                                ))}
                                            </List>
                                        }
                                    </Collapse>
                                </ListItem>
                                <Divider/>
                            </React.Fragment>
                        ))}
                    </List>
                </IconContext.Provider>
            </Drawer>

        </>
    );
};

export default NavBar;