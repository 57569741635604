import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IStatementOfIntent
} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/IStatementOfIntent";


const useApiGetStatementOfIntents = (fiscal_registry_id: number | undefined): UseQueryResult<IStatementOfIntent[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, fiscal_registry_id: number | undefined): Promise<IStatementOfIntent[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/fiscal_registry/${fiscal_registry_id}/statement_of_intent`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetStatementOfIntents", fiscal_registry_id],
        enabled: !!fiscal_registry_id,
        queryFn: () => doGet(selectedCompany?.company_token, fiscal_registry_id),
    })

};

export default useApiGetStatementOfIntents