import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";
import {IDocRow} from "../../../../../../../utils/interfaces/my_documents/doc/IDocRow";


const useApiUpdateDocRowsVatCode = (): UseMutationResult<IDocRow[], CustomAxiosError, {
    doc_year: number | undefined,
    id: number;
    vat_code_id: number | undefined
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth();
    const {selectedSystemYear} = useAppGarage();
    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined,
                            doc_year: number | undefined,
                            pk: number | undefined,
                            vat_code_id: number | undefined): Promise<IDocRow[]> {
        try {
            const response = await put(`/api/company/${company_token}/my_documents/doc/${doc_year}/${pk}/change_doc_rows_vat_code/${vat_code_id}`);
            return response.data as IDocRow[];
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IDocRow[], CustomAxiosError, {
        doc_year: number | undefined,
        id: number;
        vat_code_id: number | undefined
    }, unknown>({
        mutationFn: async ({doc_year, id, vat_code_id}) => {
            return await doUpdate(selectedCompany?.company_token, doc_year, id, vat_code_id);
        },
        onSuccess: async (data) => {
            // await queryClient.invalidateQueries({queryKey: ['useApiGetDoc', id]});
            // await queryClient.invalidateQueries({queryKey: ['useApiGetDocRows', id]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetDocs", selectedSystemYear]});
        },
    });
};


export default useApiUpdateDocRowsVatCode;