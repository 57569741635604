// MuiFabButtonFilter.tsx
import React from 'react';
import Fab from '@mui/material/Fab';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Zoom from "@mui/material/Zoom";
import {useTheme} from "@mui/material/styles";

interface MuiFabButtonAddProps {
    onClick: () => void;
    isOpen: boolean;
}

const MuiFabButtonFilter: React.FC<MuiFabButtonAddProps> = ({onClick, isOpen = false}) => {
    const theme = useTheme();

    return (
        <Zoom in={true} unmountOnExit>
            <Fab
                sx={{
                    backgroundColor: theme.palette.filterColor.main,
                    '&:hover': {
                        backgroundColor: theme.palette.filterColor.dark,
                    },
                }}
                aria-label="add"
                onClick={onClick}
            >
                {!isOpen ?
                    <FilterAltIcon style={{color: 'white'}}/>
                    :
                    <FilterAltOffIcon style={{color: 'white'}}/>
                }
            </Fab>
        </Zoom>
    );
};

export default MuiFabButtonFilter;
