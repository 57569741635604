import React from 'react';
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi"
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";

type Props = {
    onClick?: () => void,
    children?: React.ReactNode,
    type?: 'button' | 'submit',
    className?: string,
    isOpen: boolean,
    title: string,
}

const FilterAccordionButton = ({isOpen, children, type = 'button', className = '', onClick, title}: Props) => {
    const {t} = useTranslation();

    const handleClick = () => {
        onClick && onClick();
    }

    let defaultClassName: string = 'flex text-white hover:bg-aGacidGreen px-5 py-2.5';

    if (isOpen) {
        defaultClassName += ' bg-aGacidGreenSelected';
    } else {
        defaultClassName += ' bg-aGacidLightGreen';
    }

    return (
        <button
            type={type}
            className={`${defaultClassName} ${className}`}
            onClick={handleClick}
        >
            {isOpen ? `${t('close')} ${t(title)}` : `${t('open')} ${t(title)}`
            }
            <div className="ml-2">
                {isOpen ? <BiSolidDownArrow/> : <BiSolidUpArrow/>}
            </div>
        </button>
    );
};

export default FilterAccordionButton;