import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";
import {IFiscalRegistry} from "../../../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";


const useApiCreateDocTransport = (): UseMutationResult<IFiscalRegistry, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    let _doc_year: number | undefined;
    let _doc_id: number | undefined;

    const {post} = useApiCalls()

    async function doCreate({companyToken, doc_year, doc_id, data}: {
        companyToken?: string,
        doc_year: number | undefined,
        doc_id: number | undefined,
        data: any
    }): Promise<IFiscalRegistry> {
        console.log(doc_year, doc_id, data)
        try {
            const response = await post(`/api/company/${companyToken}/my_documents/doc/${doc_year}/${doc_id}/doc_transport/`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IFiscalRegistry, CustomAxiosError, any>(
        {
            mutationFn: ({doc_year, doc_id, data}: {
                doc_year: number | undefined,
                doc_id: number | undefined,
                data: any
            }) => {
                _doc_year = doc_year;
                _doc_id = doc_id;
                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    doc_year,
                    doc_id,
                    data
                });
            },
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetDocTransport", _doc_year, _doc_id]});
                await queryClient.invalidateQueries({queryKey: ["useApiGetDoc", _doc_id]});
            },
        }
    );
};


export default useApiCreateDocTransport;