import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IWarehouseProductMovement} from "../../../../../../../utils/interfaces/my_warehouse/IWarehouseProductMovement";


const useApiGetWarehouseProductMovements = (warehouse_id: number, product_id: number): UseQueryResult<IWarehouseProductMovement[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(warehouse_id: number): Promise<IWarehouseProductMovement[]> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/my_warehouse/${warehouse_id}/products/${product_id}/movements`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetWarehouseProductMovements", warehouse_id, product_id],
        queryFn: () => doGet(warehouse_id),
    })
};

export default useApiGetWarehouseProductMovements