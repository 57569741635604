import React, {useEffect, useMemo, useState} from 'react';
import {IArticle} from "../../../../../../../utils/interfaces/tannery_production/articles/IArticle";
import {IColor} from "../../../../../../../utils/interfaces/tannery_production/articles/IColor";
import {IPeopleArticle} from "../../../../../../../utils/interfaces/tannery_production/articles/IPeopleArticle";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import useApiDeleteColor from "./api-hooks/useApiDeleteColor";
import {CustomAxiosError} from "../../../../../../../logics/api_config";
import customCellRenderer from "./customCellRenderer";
import {
    MaterialReactTable,
    MRT_ActionMenuItem,
    MRT_ColumnDef,
    MRT_RowSelectionState,
    useMaterialReactTable
} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box, Popover} from "@mui/material";
import {IoColorPaletteOutline} from "react-icons/io5";
import MuiReadOnlyBoxBody from "../../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import MuiBorderedBox from "../../../../../../ui/mui/MuiBorderedBox";
import OrderScheduleLisByColor from "../../../order_schedule/OrderScheduleByColor";
import MuiAlertDialog from "../../../../../../ui/mui/mui_modal/MuiAlertDialog";
import ColorForm from "./ColorForm";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import PeopleSelect from "../../../../service/people/PeopleSelect";
import MuiModal from "../../../../../../ui/mui/mui_modal/MuiModal";
import {IPeople} from "../../../../../../../utils/interfaces/service/people/IPeople";
import ArticleSelect from "../ArticleSelect";
import useApiUpdateColor from "./api-hooks/useApiUpdateColor";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";


interface Props {
    article: IArticle;
    colors: IColor[]
    person: IPeopleArticle
}

interface IColorWithCustomField extends IColor {
    custom_field: JSX.Element;
}


const ColorTable = ({colors: initialColors, person: initialPerson, article: initialArticle}: Props) => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [isArticleSelectorOpen, setIsArticleSelectorOpen] = useState(false);
    const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const [newSelectedPerson, setNewSelectedPerson] = useState<IPeople | null | undefined>()
    const handleNewPersonChange = async (obj: IPeople | null) => {
        setNewSelectedPerson(obj);
    }
    const updateMutation = useApiUpdateColor(selectedCompany?.company_token)
    const handleNewArticleChange = async (newArticle: IArticle | undefined) => {
        if (newArticle === undefined) {
            return
        }

        const updatePromises = table.getSelectedRowModel().rows.map(obj => updateMutation.mutateAsync({
            people_id: initialPerson.id,
            id: obj.original.id,
            data: {
                color_name: obj.original.color_name,
                parent_id: newArticle.id,
                people_id: newSelectedPerson?.id
            },
        }));

        try {
            await Promise.all(updatePromises);
        } catch (error) {
        }

        setIsArticleSelectorOpen(false)
        await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
    }

    const [selectedRow, setSelectedRow] = useState<IColor | undefined>()
    const [rows, setRows] = useState<IColor[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteColor(selectedCompany?.company_token, initialPerson.id, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({
                companyToken: selectedCompany?.company_token,
                people_id: initialPerson.id,
                pk: selectedRow?.id
            });
            await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
            displayMessage('Color deleted successfully');

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting the Color');
            }
        }
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };


    useEffect(() => {
        if (initialColors) {
            setRows(initialColors);
        }
    }, [initialColors]);

    const [colorsWithCustomField, setColorsWithCustomField] = useState<IColorWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IColorWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customCellRenderer(row, theme, t),
        }));
        setColorsWithCustomField(dataWithCustomField);
    }, [rows]);


    const columns = useMemo<MRT_ColumnDef<IColor>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'color_name',
                header: t('color_name'),
                filterFn: 'contains',

            },
        ],
        [rows]
    );


    const table = useMaterialReactTable<IColor>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: colorsWithCustomField ? colorsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),

        muiTableBodyRowProps: ({row, staticRowIndex, table}) => ({
            onClick: (event) => setSelectedRow(row.original),
            ...getMuiTablePaperProps(),
        }),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
                disabled={row.original.orders.length > 0}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 0
                    }}>
                        <IoColorPaletteOutline color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'colors'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_color'}/>
                    {table.getSelectedRowModel().rows.length > 0 &&
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                            <MRTMuiButton
                                bigAtRender={true}
                                icon={<NorthEastIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                                onClick={() => {
                                    setIsArticleSelectorOpen(true)
                                }} label={t('move to another article')}/>
                        </Box>
                    }
                </>
            </Box>
        ),

        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('orders'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            <MuiBorderedBox>
                <OrderScheduleLisByColor color={row.original}/>
            </MuiBorderedBox>,

        enableRowSelection: true,
        positionToolbarAlertBanner: 'none',
        onRowSelectionChange: setRowSelection,
        state: {rowSelection},
    });

    return <>

        <MaterialReactTable table={table}/>

        <MuiAlertDialog
            open={isDeleteConfirmationOpen}
            title="delete_confirmation"
            content="ask_for_delete"
            onClose={handleCancelDelete}
            onConfirm={handleConfirmDelete}
        />

        <Popover
            id={'edit_popover'}
            open={isEditFormOpen}
            anchorEl={anchorEl}
            onClose={() => setIsEditFormOpen(false)}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
        >
            <ColorForm colorToEdit={selectedRow ? selectedRow : null}
                       article={initialArticle}
                       person={initialPerson}
                       onClose={() => setIsEditFormOpen(false)}/>
        </Popover>

        <MuiModal top="50%" left="50%" width={'50vh'} open={isArticleSelectorOpen} disableBackdropClick={true}
                  onClose={() => setIsArticleSelectorOpen(false)}>
            <PeopleSelect people={initialPerson} onChange={handleNewPersonChange} className={''}
                          category_person={'customer'}/>
            {newSelectedPerson &&
                <ArticleSelect customer_id={newSelectedPerson.id} obj={undefined} onChange={handleNewArticleChange}
                               className={''}/>
            }
            <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                <MuiFabButtonBack onClick={() => setIsArticleSelectorOpen(false)}/>
            </Box>
        </MuiModal>
    </>;
};

export default ColorTable;

