import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {useLocation} from "react-router-dom";


const useApiUpdateDye = (
    companyToken: string | undefined
): UseMutationResult<void, CustomAxiosError, { pk: number; data: any }, unknown> => {

    const {put} = useApiCalls();
    const location = useLocation();
    const is_tannery_dyeing = location.pathname.startsWith('/apps/tannery_dyeing')

    async function doUpdate(company_token: string | undefined,
                            pk: number,
                            data: any): Promise<void> {
        try {
            const url = is_tannery_dyeing ?
                `/api/company/${company_token}/tannery_dyeing/order_schedule/${pk}/toggle`
                :
                `/api/company/${company_token}/tannery_production/order_schedule/tannery_dyeing/${pk}/update`
            await put(url, data);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, {
        pk: number;
        data: any;
    }>({
        mutationFn: ({pk, data}) => doUpdate(companyToken, pk, data)
    });
};

export default useApiUpdateDye;

