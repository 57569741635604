import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiDeleteManualChemicalProductsMovement = (
    product_id: number | undefined,
    pk: number | undefined,
): UseMutationResult<void, CustomAxiosError, {
    product_id: number | undefined,
    pk: number | undefined;
}, unknown> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {DELETE} = useApiCalls()

    async function doDelete({companyToken, product_id, pk}: {
        companyToken?: string,
        product_id: number | undefined,
        pk?: number,
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/chemicals_management/chemical_product/${product_id}/manual_movement/${pk}/`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        product_id: number | undefined,
        pk: number | undefined,
    }>(
        {
            mutationFn: () => doDelete({
                companyToken: selectedCompany?.company_token,
                product_id: product_id,
                pk: pk,
            }),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ['useApiGetChemicalProductMovements', product_id]});
            },
        })
}

// const v = (product_id: number | undefined, pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
//     pk: number;
// }, unknown> => {
//     const queryClient = useQueryClient();
//     const {selectedCompany} = useAuth()
//
//     return useMutation<void, CustomAxiosError, { pk: number; }>(({
//                                                                      pk,
//                                                                  }) => doMutation(selectedCompany?.company_token, product_id, pk),
//         {
//             onSuccess: () => {
//                 queryClient.invalidateQueries(["useApiGetChemicalProductMovements", product_id]);
//             }
//         }
//     );
// };

export default useApiDeleteManualChemicalProductsMovement;