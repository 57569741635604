import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IChemicalProductManualMovement
} from "../../../../../../../utils/interfaces/chemicals_products/IChemicalProductManualMovement";


const useApiUpdateManualChemicalProductsMovement = (): UseMutationResult<IChemicalProductManualMovement, CustomAxiosError, {
    product_id: number | undefined,
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()
    const {put} = useApiCalls()

    async function doUpdate(company_token: string | undefined,
                            product_id: number | undefined,
                            pk: number | undefined,
                            data: any): Promise<IChemicalProductManualMovement> {
        try {
            const response = await put(`/api/company/${company_token}/chemicals_management/chemical_product/${product_id}/manual_movement/${pk}/`, data);
            return response.data as IChemicalProductManualMovement;
        } catch (error) {
            throw error;
        }
    }

    return useMutation<IChemicalProductManualMovement, CustomAxiosError, {
        product_id: number | undefined,
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({product_id, id, data}) => {
            return await doUpdate(selectedCompany?.company_token, product_id, id, data);
        },
        onSuccess: async (data) => {
            await queryClient.invalidateQueries({queryKey: ['useApiGetChemicalProductMovements', data.product]});
            await queryClient.invalidateQueries({queryKey: ["useApiGetManualChemicalProductMovement", data.id]});
        },
    });
};


export default useApiUpdateManualChemicalProductsMovement;