import React, {useEffect, useMemo, useState} from 'react';
import {IProduct} from "../../../../../../utils/interfaces/service/people/product_list/IProduct";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import useApiDeleteProduct from "./api-hooks/useApiDeleteProduct";

import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";

import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import MuiBorderedBox from "../../../../../ui/mui/MuiBorderedBox";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import ProductForm from "./ProductForm";
import customProductCellRenderer from "./customProductCellRenderer";
import {TiThList} from "react-icons/ti";
import {IPeopleProduct} from "../../../../../../utils/interfaces/service/people/product_list/IPeopleProduct";
import PriceTable from "./price/PriceTable";


interface IProductWithCustomField extends IProduct {
    custom_field: JSX.Element;
}


interface Props {
    person: IPeopleProduct;
    products: IProduct[];
    scope?: string
}


const ProductTable = ({products, person}: Props) => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IProduct | undefined>()
    const [rows, setRows] = useState<IProduct[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteProduct(selectedCompany?.company_token, person?.id, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({
                companyToken: selectedCompany?.company_token,
                people_id: person.id,
                pk: selectedRow?.id
            });
        } catch (error) {
        }
        await queryClient.invalidateQueries({queryKey: ['getPeopleProducts']});
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    useEffect(() => {
        if (products) {
            setRows(products);
        }
    }, [products]);


    const [peopleArticlesWithCustomField, setPeopleArticlesWithCustomField] = useState<IProductWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IProductWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customProductCellRenderer(row, theme, t),
        }));
        setPeopleArticlesWithCustomField(dataWithCustomField);
    }, [rows]);


    const columns = useMemo<MRT_ColumnDef<IProduct>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'product_name',
                header: t('product_name'),
                filterFn: 'contains',

            },
        ],
        [rows]
    );


    const table = useMaterialReactTable<IProduct>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: peopleArticlesWithCustomField ? peopleArticlesWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableGlobalFilter: false,
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: true,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),

        muiTableBodyRowProps: ({row, staticRowIndex, table}) => ({
            onClick: (event) => setSelectedRow(row.original),
            ...getMuiTablePaperProps(),
        }),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 0
                    }}>
                        <TiThList color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'products/services'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_product/service'}/>
                </>
            </Box>
        ),
        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('prices'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            <MuiBorderedBox>
                {/*<ArticleTable articles={row.original.articles} person={row.original}/>*/}
                {/*<ProductTable products={row.original}*/}
                <PriceTable person={person} product={row.original} prices={row.original.prices}/>
            </MuiBorderedBox>
    });


    return (
        <>
            <MaterialReactTable table={table}/>

            <MuiAlertDialog
                open={isDeleteConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />

            <MuiModal
                top="50%"
                left="50%"
                width={'50vh'}
                open={isEditFormOpen}
                onClose={() => setIsEditFormOpen(false)}
            >
                <ProductForm
                    person={person}
                    productToEdit={selectedRow ? selectedRow : null}
                    onClose={() => setIsEditFormOpen(false)}
                />
            </MuiModal>
        </>
    );


};

export default ProductTable;

