import {CustomAxiosError, useApiCalls} from "../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {INation} from "../../../../../utils/interfaces/my_documents/INation";
import {useAuth} from "../../../../../utils/contexts/auth-context";


const useApiGetNationDetailed = (pk: number | undefined): UseQueryResult<INation, CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, pk: number | undefined): Promise<INation> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/nation/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetNation", pk],
        queryFn: () => doGet(selectedCompany?.company_token, pk),
        enabled: !!pk,
    })
};

export default useApiGetNationDetailed