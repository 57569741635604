import React, {useState, useEffect} from 'react';
import {useQueryClient} from "@tanstack/react-query";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useTanneryProduction} from "../../../../../../utils/contexts/tannery_production/tannery_production-context";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import Loading from "../../../../../ui/Loading";
import useApiGetWorkingsOrdersByOrder from "./api-hooks/useApiGetWorkingsOrdersByOrder";
import {
    IOrderScheduleWorking
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleWorking";
import {Box, Grid} from "@mui/material";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiReadOnlyBoxBody from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import WorkingsTable from "./WorkingsTable";
import MuiBorderedBox from "../../../../../ui/mui/MuiBorderedBox";
import PeopleTable from "../../../service/people/PeopleTable";

interface Props {
    onClose: () => void;
    isOpen: boolean,
}

const WorkingsLinker: React.FC<Props> = ({
                                             onClose,
                                             isOpen,
                                         }) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();
    const {selectedCompanyOrderSchedule, selectIsWorkingsOpen} = useTanneryProduction()
    const [openFilters, setOpenFilters] = useState<boolean>(false)


    const {
        data,
        isLoading,
        error
    } = useApiGetWorkingsOrdersByOrder(selectedCompany?.company_token, selectedCompanyOrderSchedule?.id);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const [filterOrderId, setFilterOrder] = useState<string>('');


    function filterByOrderId(data: IOrderScheduleWorking[], filter: string) {
        return data.filter((obj) =>
            obj.order_code.toLowerCase().includes(filter.toLowerCase())
        );
    }

    const [filterSubContractor, setFilterSubContractor] = useState<string>('');

    function filterBySubContractor(data: IOrderScheduleWorking[], filter: string) {
        return data.filter((obj) =>
            obj.subcontractor.toLowerCase().includes(filter.toLowerCase())
        );
    }

    const [onlyOpenWorkings, setOnlyOpenWorkings] = useState<boolean>(false)


    const [filteredData, setFilteredData] = useState(data);
    useEffect(() => {
        if (data) {
            const filteredByOrderId = filterByOrderId(data, filterOrderId);
            const filteredBySubContractor = filterBySubContractor(filteredByOrderId, filterSubContractor);
            const filteredByOnlyOpenWorkings = filteredBySubContractor.filter((obj: IOrderScheduleWorking | undefined) => {
                return onlyOpenWorkings ? obj?.returning_date === null : true;
            });

            const filteredDataWithoutUndefined = filteredByOnlyOpenWorkings.filter((obj) => obj !== undefined);

            setFilteredData(filteredDataWithoutUndefined);
        }
    }, [data, filterOrderId, filterSubContractor, onlyOpenWorkings]);


    return (
        <>
            {isOpen &&

                <Box sx={{padding: 2}}>
                    {isLoading ? (
                        <Loading/>
                    ) : (
                        <>
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <Box className={'mt-2 gap-x-2 mb-2'}>
                                    <MuiFabButtonBack onClick={onClose}/>
                                </Box>
                            </Grid>

                            <Box sx={{flexDirection: 'column'}}>
                                <MuiReadOnlyBoxBody label={'order_info'}
                                                    value={`${selectedCompanyOrderSchedule?.order_id} - 
                                    ${selectedCompanyOrderSchedule?.article_name} - 
                                    ${selectedCompanyOrderSchedule?.color.color_name} 
                                    `}/>
                            </Box>

                            <MuiBorderedBox>
                                <WorkingsTable workings={data}/>
                            </MuiBorderedBox>

                            <MuiBorderedBox>
                                {/*<PeopleProductList category_person='subcontractor' scope={'add_working'}/>*/}
                                <PeopleTable category_person='subcontractor'/>

                            </MuiBorderedBox>


                            <div className="absolute top-0 right-0 mt-2 mr-6">
                                <button
                                    onClick={onClose}
                                    className="mt-2 p-4 py-2 bg-red-400 text-white rounded-md hover:bg-aGazure focus:outline-none rounded font-medium text-xs uppercase tracking-wider"
                                >
                                    CLOSE
                                </button>
                            </div>

                        </>

                    )}
                </Box>}
        </>

    );
};

export default WorkingsLinker;
