import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IDocList} from "../../../../../../utils/interfaces/my_documents/doc/IDocList";
import {FaLock} from "react-icons/fa6";
import {LiaFileInvoiceDollarSolid} from "react-icons/lia";


const getIconsForCustomField = (row: IDocList, theme: any, t: any) => {
    const icons = [];


    const icon_color = theme.palette.primary.main


    icons.push(
        row.lock_duration > 0 && !row.locked_by_me &&
        <FaLock
            color={theme.palette.primary.warning}
            title={t('dock_locked_by')}
        />
    );

    icons.push(
        row.is_going_to_be_invoiced && !row.invoiced_at &&
        <LiaFileInvoiceDollarSolid
            className='animate-pulse text-red-600'
            title={t('dock_locked_by')}
        />
    );

    icons.push(
        row.invoiced_at &&
        <LiaFileInvoiceDollarSolid
            color={theme.palette.primary.main}
            title={t('dock_locked')}
        />
    );


    return icons;
};

export const customCellRenderer = (rowData: IDocList, theme: any, t: any) => {
    const icons = getIconsForCustomField(rowData, theme, t);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customCellRenderer;
