import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import useApiGetPeopleArticles from "./articles/api-hooks/useApiGetPeopleArticles";
import customPeopleArticleCellRenderer from "./customPeopleArticleCellRenderer";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import {FaSitemap} from "react-icons/fa";
import {SlPeople} from "react-icons/sl";

import AddIcon from "@mui/icons-material/Add";

import ArticleTable from "./articles/ArticleTable";

import MRTPeopleArticlesFiltersPopOver from "./MRTPeopleArticlesFiltersPopOver";

import {filterPeopleArticles} from "./peopleArticleFilterUtils";

import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import {IPeopleArticle} from "../../../../../utils/interfaces/tannery_production/articles/IPeopleArticle";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../utils/contexts/toast-context";
import useApiDeletePerson from "../../service/people/api-hooks/useApiDeletePerson";
import {CustomAxiosError} from "../../../../../logics/api_config";
import {
    getMuiTableBodyCellProps,
    getMuiTableHeadCellProps, getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import MuiBorderedBox from "../../../../ui/mui/MuiBorderedBox";
import Loading from "../../../../ui/Loading";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import PeopleForm from "../../service/people/PeopleForm";


interface IPeopleArticleWithCustomField extends IPeopleArticle {
    custom_field: JSX.Element;
}


const PeopleArticleTable = () => {
    const {selectedCompany} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IPeopleArticle | undefined>()
    const [rows, setRows] = useState<IPeopleArticle[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeletePerson(selectedCompany?.company_token, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({companyToken: selectedCompany?.company_token, pk: selectedRow?.id});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);
            } else {
                displayError('An error occurred while deleting...');
            }
        }
        await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const {data, isLoading, error, isFetching} = useApiGetPeopleArticles(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const [openFilters, setOpenFilter] = useState<boolean>(true)
    const [filterPerson, setFilterPerson] = useState<string>('');
    const [filterArticle, setFilterArticle] = useState('');
    const [filterThickness, setFilterThickness] = useState('');
    const [filterSize, setFilterSize] = useState('');
    const [filterColor, setFilterColor] = useState('');
    const [filterOrderId, setFilterOrderId] = useState('');

    const handleToggleFilters = () => {
        setFilterPerson('');
        setFilterArticle('');
        setFilterColor('');
        setFilterThickness('');
        setFilterOrderId('');
        setFilterSize('');
        setOpenFilter(!openFilters);
    };

    useEffect(() => {
        if (data) {
            const filteredData = filterPeopleArticles(data, filterPerson, filterArticle, filterThickness, filterSize, filterColor, filterOrderId);
            setRows(filteredData);
        }
    }, [data, filterArticle, filterColor, filterOrderId, filterPerson, filterSize, filterThickness]);


    const [peopleArticlesWithCustomField, setPeopleArticlesWithCustomField] = useState<IPeopleArticleWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IPeopleArticleWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customPeopleArticleCellRenderer(row, theme, t),
        }));
        setPeopleArticlesWithCustomField(dataWithCustomField);
    }, [rows]);


    const columns = useMemo<MRT_ColumnDef<IPeopleArticle>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'people_name',
                header: t('people_name'),
                filterFn: 'contains',

            },
        ],
        [rows]
    );


    const table = useMaterialReactTable<IPeopleArticle>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: peopleArticlesWithCustomField ? peopleArticlesWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableGlobalFilter: false,
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: true,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: true,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),

        muiTableBodyRowProps: ({row, staticRowIndex, table}) => ({
            onClick: (event) => setSelectedRow(row.original),
            ...getMuiTablePaperProps(),
        }),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
                disabled={row.original.articles.length > 0}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 0
                    }}>
                        <SlPeople color={theme.palette.primary.main}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'customers'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_customer'}/>
                    <MRTPeopleArticlesFiltersPopOver
                        onToggle={handleToggleFilters}
                        filterPerson={filterPerson}
                        setFilterPerson={setFilterPerson}
                        filterArticle={filterArticle}
                        setFilterArticle={setFilterArticle}
                        filterThickness={filterThickness}
                        setFilterThickness={setFilterThickness}
                        filterSize={filterSize}
                        setFilterSize={setFilterSize}
                        filterColor={filterColor}
                        setFilterColor={setFilterColor}
                        filterOrderId={filterOrderId}
                        setFilterOrderId={setFilterOrderId}
                    />
                </>
            </Box>
        ),
        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('articles'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            <MuiBorderedBox>
                <ArticleTable articles={row.original.articles} person={row.original}/>
            </MuiBorderedBox>
    });


    return (
        <>
            {isLoading ? (
                <Loading
                />
            ) : (
                <>
                    <MaterialReactTable table={table}/>

                    <MuiAlertDialog
                        open={isDeleteConfirmationOpen}
                        title="delete_confirmation"
                        content="ask_for_delete"
                        onClose={handleCancelDelete}
                        onConfirm={handleConfirmDelete}
                    />

                    <MuiModal
                        top="50%"
                        left="50%"
                        width={'50vh'}
                        open={isEditFormOpen}
                        onClose={() => setIsEditFormOpen(false)}
                    >
                        <PeopleForm
                            personToEdit={selectedRow ? selectedRow : null}
                            category_person="customer"
                            onClose={() => setIsEditFormOpen(false)}
                        />
                    </MuiModal>

                    {isFetching && <MuiFetchingSpinner text="Fetching data..."/>}
                </>
            )}
        </>
    );


};

export default PeopleArticleTable;

