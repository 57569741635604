import React, {useEffect, useMemo, useState} from 'react';
import {IPeopleProduct} from "../../../../../utils/interfaces/service/people/product_list/IPeopleProduct";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "@tanstack/react-query";
import useApiDeletePerson from "./api-hooks/useApiDeletePerson";
import {CustomAxiosError} from "../../../../../logics/api_config";
import useApiGetPeopleProducts from "./product/api-hooks/useApiGetPeopleProducts";
import {filterProductList} from "./product/filterUtils";
import customPeopleCellRenderer from "./customPeopleCellRenderer";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {
    getMuiTableBodyCellProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import {useTheme} from "@mui/material/styles";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box} from "@mui/material";
import {SlPeople} from "react-icons/sl";
import MuiReadOnlyBoxBody from "../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import AddIcon from "@mui/icons-material/Add";
import MRTPeopleProductsFiltersPopOver from "./product/MRTPeopleProductsFiltersPopOver";
import MuiBorderedBox from "../../../../ui/mui/MuiBorderedBox";
import Loading from "../../../../ui/Loading";
import MuiAlertDialog from "../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import PeopleForm from "./PeopleForm";
import MuiFetchingSpinner from "../../../../ui/mui/MuiFetchingSpinner";
import ProductTable from "./product/ProductTable";
import {IoIosFlask} from "react-icons/io";
import {MdWorkspaces} from "react-icons/md";
import {useLocation} from "react-router-dom";
import {IoDocumentSharp} from "react-icons/io5";
import FiscalRegistryForm from "../../my_documents/fiscal_registry/FiscalRegistryForm";

interface IPeopleProductWithCustomField extends IPeopleProduct {
    custom_field: JSX.Element;
}


interface Props {
    category_person?: string;
    scope?: string
}


const PeopleTable = ({category_person}: Props) => {
    const {selectedCompany, userLevel} = useAuth();
    const {displayError, displayMessage} = useToast()
    const theme = useTheme();
    const icon_color = theme.palette.primary.main
    const {t} = useTranslation()
    const queryClient = useQueryClient();

    const [selectedRow, setSelectedRow] = useState<IPeopleProduct | undefined>()
    const [rows, setRows] = useState<IPeopleProduct[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isFiscalRegistryFormOpen, setIsFiscalRegistryFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeletePerson(selectedCompany?.company_token, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;

    const location = useLocation();
    const isInTanneryRawMaterial = location.pathname.startsWith('/apps/tannery_raw_material');
    const isInTanneryProduction = location.pathname.startsWith('/apps/tannery_production');
    const isInMyDocuments = location.pathname.startsWith('/apps/my_documents');

    const handleConfirmDelete = async () => {
        try {
            await apiDelete({companyToken: selectedCompany?.company_token, pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
        await queryClient.invalidateQueries({queryKey: ['getPeopleProducts']});
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const {data, isLoading, error, isFetching} = useApiGetPeopleProducts(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const [openFilters, setOpenFilters] = useState<boolean>(true)
    const [filterPerson, setFilterPerson] = useState<string>('');
    const [filterProduct, setFilterProduct] = useState('');
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const handleCategoryToggle = (categoryName: string) => {
        if (selectedCategories.includes(categoryName)) {
            setSelectedCategories((prevCategories) =>
                prevCategories.filter((id) => id !== categoryName)
            );
        } else {
            setSelectedCategories((prevCategories) => [...prevCategories, categoryName]);
        }
    };


    const handleToggleFilters = () => {
        setFilterPerson('');
        setFilterProduct('');
        setSelectedCategories([]);
        setOpenFilters(!openFilters);
    };

    useEffect(() => {
        if (data) {
            const filteredData = filterProductList(data, filterPerson, filterProduct, category_person, selectedCategories);
            setRows(filteredData);
        }
    }, [category_person, data, filterPerson, filterProduct, selectedCategories]);


    const [peopleArticlesWithCustomField, setPeopleArticlesWithCustomField] = useState<IPeopleProductWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IPeopleProductWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: customPeopleCellRenderer(row, theme, t),
        }));
        setPeopleArticlesWithCustomField(dataWithCustomField);
    }, [rows]);


    const columns = useMemo<MRT_ColumnDef<IPeopleProduct>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'people_name',
                header: t('people_name'),
                filterFn: 'contains',

            },
        ],
        [rows]
    );

    const table_label = (() => {
        switch (true) {
            case category_person === 'subcontractor':
                return 'subcontractors';

            case category_person === 'chemical_supplier':
                return 'chemical_suppliers';

            default:
                return 'people';
        }
    })()

    const table_avatar = (() => {
        switch (true) {
            case category_person === "chemical_supplier":
                return <IoIosFlask
                    style={{color: theme.palette.primary.main}}/>;
            case category_person === "subcontractor":
                return <MdWorkspaces
                    style={{color: theme.palette.primary.main}}/>;
            default:
                return <SlPeople style={{color: theme.palette.primary.main}}/>;
        }
    })()


    const table = useMaterialReactTable<IPeopleProduct>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: peopleArticlesWithCustomField ? peopleArticlesWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableGlobalFilter: false,
        enableFullScreenToggle: false,
        enableFilters: true,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: true,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),

        muiTableBodyRowProps: ({row, staticRowIndex, table}) => ({
            onClick: (event) => setSelectedRow(row.original),
            ...getMuiTablePaperProps(),
        }),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true);
                    closeMenu();
                }}
                table={table}
            />,
            isInMyDocuments && (
                <MRT_ActionMenuItem
                    icon={<IoDocumentSharp color={icon_color}/>}
                    key="link"
                    label={t("fiscal_registry")}
                    onClick={() => {
                        setSelectedRow(row.original);
                        setIsFiscalRegistryFormOpen(true);
                        closeMenu();
                    }}
                    table={table}
                />
            )
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 0
                    }}>
                        {table_avatar}
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={table_label}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_people'}/>
                    <MRTPeopleProductsFiltersPopOver onToggle={handleToggleFilters}
                                                     filterPerson={filterPerson}
                                                     setFilterPerson={setFilterPerson}
                                                     filterProduct={filterProduct}
                                                     setFilterProduct={setFilterProduct}
                                                     category_person={category_person}
                                                     selectedCategories={selectedCategories}
                                                     setSelectedCategories={setSelectedCategories}
                                                     handleCategoryToggle={handleCategoryToggle}
                    />
                </>
            </Box>
        ),
        enableExpandAll: false,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                header: t('products_services'),
            },
        },
        muiDetailPanelProps: () => ({
            sx: (theme) => ({
                backgroundColor: theme.palette.backgroundAppGarageColor.dark
            }),
        }),
        muiExpandButtonProps: ({row, table}) => ({
            onClick: () => table.setExpanded({[row.id]: !row.getIsExpanded()}),
            sx: {
                transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s',
                color: theme.palette.primary.main,
            },
        }),
        renderDetailPanel: ({row}) =>
            <MuiBorderedBox>
                {/*<ArticleTable articles={row.original.articles} person={row.original}/>*/}
                <ProductTable products={row.original.products} person={row.original}/>
            </MuiBorderedBox>
    });


    return (
        <>
            {isLoading ? (
                <Loading
                />
            ) : (
                <>
                    <MaterialReactTable table={table}/>

                    <MuiAlertDialog
                        open={isDeleteConfirmationOpen}
                        title="delete_confirmation"
                        content="ask_for_delete"
                        onClose={handleCancelDelete}
                        onConfirm={handleConfirmDelete}
                    />

                    <MuiModal
                        top="50%"
                        left="50%"
                        width={'50vh'}
                        open={isEditFormOpen}
                        onClose={() => setIsEditFormOpen(false)}
                    >
                        <PeopleForm
                            category_person={category_person}
                            personToEdit={selectedRow ? selectedRow : null}
                            onClose={() => setIsEditFormOpen(false)}
                        />
                    </MuiModal>

                    <MuiModal
                        top="50%"
                        left="50%"
                        width={'120vh'}
                        open={isFiscalRegistryFormOpen}
                        disableBackdropClick={true}
                        onClose={() => setIsFiscalRegistryFormOpen(false)}
                    >
                        <FiscalRegistryForm
                            person={selectedRow}
                            onClose={() => setIsFiscalRegistryFormOpen(false)}
                        />
                    </MuiModal>


                    {isFetching && <MuiFetchingSpinner text="Fetching data..."/>}
                </>
            )}
        </>
    );


};

export default PeopleTable;

