import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ILwgSite} from "../../../../../../utils/interfaces/tannery_raw_material/ILwgSite";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiGetLwgSite = (
    pk: number | undefined,
): UseQueryResult<ILwgSite, CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    const doGetLwgSites = async (company_token: string | undefined, pk: number | undefined): Promise<ILwgSite> => {
        try {
            const response = await get(`/api/company/${company_token}/tannery_raw_material/lwg_sites/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["getCompanyLwgSite", pk],
        enabled: !!pk,
        queryFn: () => doGetLwgSites(selectedCompany?.company_token, pk)
    })
};

export default useApiGetLwgSite