import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IMacroSpessore} from "../../../../../../../../utils/interfaces/tannery_production/articles/IMacroSpessore";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";


const useApiGetMacroSpessori = (
    companyToken: string | undefined
): UseQueryResult<IMacroSpessore[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGetMacroSpessori(company_token: string | undefined): Promise<IMacroSpessore[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/articles/macro_spessori`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getMacroSpessori'],
        queryFn: () => doGetMacroSpessori(companyToken)
    })
};

export default useApiGetMacroSpessori