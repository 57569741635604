import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IPeopleProduct} from "../../../../../../../utils/interfaces/service/people/product_list/IPeopleProduct";
import {useToast} from "../../../../../../../utils/contexts/toast-context";


const useApiGetPeopleProducts = (
    companyToken: string | undefined
): UseQueryResult<IPeopleProduct[], CustomAxiosError> => {

    const {displayError, displayMessage} = useToast()
    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IPeopleProduct[]> {
        try {
            const response = await get(`/api/company/${company_token}/service/people/product`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }


    return useQuery({
        queryKey: ['getPeopleProducts'],
        queryFn: () => doGet(companyToken)
    })
};

export default useApiGetPeopleProducts