import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";


const useApiCreateLot = (companyToken: string | undefined): UseMutationResult<void, CustomAxiosError, any, unknown> => {

    const {postWithFile} = useApiCalls();
    
    async function doCreate({companyToken, data}: { companyToken?: string, data: any }): Promise<void> {
        try {
            await postWithFile(`/api/company/${companyToken}/tannery_raw_material/lots/`, data);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, any>(
        {
            mutationFn: (data) => doCreate({companyToken, data})
        }
    );
};

export default useApiCreateLot;