import React, {useEffect, useState} from 'react';
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {IMyCompanyRegistry} from "../../../../../utils/interfaces/my_company/my_company_registry/IMyCompanyRegistry";
import LabelledMuiTypography from "../../../../ui/mui/mui-display-data/LabelledMuiTypography";
import useApiGetMyCompanyRegistry from "./api-hooks/useApiGetMyCompanyRegistry";
import {Add, Edit} from "@mui/icons-material";
import MRTMuiButton from "../../../../ui/mui/mui_react_table/MRTMuiButton";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import MyCompanyRegistryForm from "./MyCompanyRegistryForm";

interface Props {
    obj: IMyCompanyRegistry | undefined,
}

const MyCompanyRegistryCard = ({
                                   obj: initialObj,
                               }: Props) => {


    const theme = useTheme();
    const [obj, setObj] = useState<IMyCompanyRegistry | undefined>(initialObj)
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data: detailedObj,
    } = useApiGetMyCompanyRegistry();

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);


    return (
        <>
            <Box sx={{position: 'relative', width: '100%'}}>

                <Box sx={{position: 'absolute', top: -10, right: 0, display: 'flex', alignItems: 'center', gap: 2}}
                >
                    <MRTMuiButton
                        small={true}
                        icon={obj ?
                            <Edit sx={{color: theme.palette.primary.main}}/>
                            :
                            <Add sx={{color: theme.palette.primary.main}}/>}
                        onClick={() => {
                            setIsEditFormOpen(true)
                        }} label={obj ? 'edit fiscal registry' : 'add fiscal registry'}/>
                </Box>

                <Box sx={{
                    display: 'flex-1',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    border: '1px solid lightgrey',
                    borderRadius: '4px',
                    padding: '10px',
                    backgroundColor: theme.palette.backgroundAppGarageColor.gray_50
                }}>
                    <LabelledMuiTypography label={'business_name'} value={detailedObj?.business_name}
                                           upperCaseValue={true}/>
                    <LabelledMuiTypography label={'address'} value={detailedObj?.address} upperCaseValue={true}/>
                    <LabelledMuiTypography label={'cap'} value={detailedObj?.cap} upperCaseValue={true}/>
                    <LabelledMuiTypography label={'city'} value={detailedObj?.city} upperCaseValue={true}/>
                    <LabelledMuiTypography label={'vat_number'} value={detailedObj?.vat_number} upperCaseValue={true}/>
                    <LabelledMuiTypography label={'nation'} value={detailedObj?.nation.iso_code} upperCaseValue={true}/>
                </Box>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'120vh'}
                    open={isEditFormOpen}
                    disableBackdropClick={true}
                    onClose={() => setIsEditFormOpen(false)}
                >
                    <MyCompanyRegistryForm
                        onClose={() => setIsEditFormOpen(false)}
                    />
                </MuiModal>

            </Box>
        </>

    );
}

export default MyCompanyRegistryCard;