import React, {useEffect, useState} from 'react';
import VerificationPeriodForm from "../../verification_period/VerificationPeriodForm";
import FilterAccordionButton from "../../../../../ui/mui/mui-accordion/mui-filter-accordion/FilterAccordionButton";
import LwgSiteList from "./LwgSiteList";
import useApiGetVerificationPeriod from "../../verification_period/api-hooks/useApiGetVerificationPeriod";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {
    IVerificationPeriod
} from "../../../../../../utils/interfaces/lwg_dashboard/verification_period/IVerificationPeriod";
import useApiGetReportProduction from "../api_hooks/useApiGetReportProduction";
import useApiGetPendingOrders from "../api_hooks/useApiGetPendingOrders";
import Loading from "../../../../../ui/Loading";
import AgDiv from "../../../../../ui/divs/AgDiv";
import {FaSkull} from "react-icons/fa";
import PercentageChart from "../../../../../ui/victory/PercentageChart";
import MacroSpessoreList from "./MacroSpessoreList";
import {IPeriod} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IPeriod";
import OpenerButton from "../../../../../ui/OpenerButton";
import MacroCategoryList from "./MacroCategoryList";
import MacroLineaList from "./MacroLineaList";
import CsvButton from "../../../../../ui/CsvButton";
import useApiGetProductionRecords from "../api_hooks/useApiGetProductionRecords";
import {convertToCsv} from "../../../../../../logics/csv_converter";
import {useTranslation} from "react-i18next";
import MuiFetchingSpinner from "../../../../../ui/mui/MuiFetchingSpinner";
import OrderScheduleTable from "../../../tannery_production/order_schedule/OrderScheduleTable";
import useApiGetVerificationPeriodOrders from "../api_hooks/useApiGetVerificationPeriodOrders";


interface Props {
    scrollableHeight?: number;
}

const LwgProductionReports = ({scrollableHeight = 100,}: Props) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();
    const {t} = useTranslation()

    const [isReportByLwgSiteOpen, setIsReportByLwgSiteOpen] = useState<boolean>(true)
    const [isReportByMacroSpessore, setIsReportByMacroSpessore] = useState<boolean>(false)
    const [isReportByMacroCategory, setIsReportByMacroCategory] = useState<boolean>(false)
    const [isReportByMacroLinea, setIsReportByMacroLinea] = useState<boolean>(false)
    const [isPendingOrders, setIsPendingOrders] = useState<boolean>(false)
    const [isVerificationPeriodOrders, setIsVerificationPeriodOrders] = useState<boolean>(false)
    const [openAccordion, setOpenAccordion] = useState<boolean>(false)

    const [verificationPeriod, setVerificationPeriod] = useState<IVerificationPeriod>()
    const {
        data: verificationPeriodData,
        isLoading: isLoadingVerificationPeriodData,
        error: errorVerificationPeriodData
    } = useApiGetVerificationPeriod();

    if (errorVerificationPeriodData) {
        const axiosError = errorVerificationPeriodData as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    const [missingVerificationPeriod, setMissingVerificationPeriod] = useState<boolean>(false)
    const [startingDate, setStartingDate] = useState<string | undefined>(verificationPeriod?.starting_date);
    const [endingDate, setEndingDate] = useState<string | undefined>(verificationPeriod?.ending_date);
    const [totPendingOrders, setTotPendingOrders] = useState<number | undefined>(0)
    const [period, setPeriod] = useState<IPeriod>()

    const {data, isLoading, error, isFetching} = useApiGetReportProduction();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    // const data = queryClient.getQueryData<IPeriod>('getLwgReportProduction');

    const {
        data: pendingOrders,
        error: errorPendingOrders,
    } = useApiGetPendingOrders();

    const {
        data: verifcationPeriodOrders,
        error: errorVerificationPeriodOrders,
    } = useApiGetVerificationPeriodOrders();

    if (errorPendingOrders) {
        const axiosError = errorPendingOrders as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    const openReportByLwgSite = () => {
        setIsReportByLwgSiteOpen(true);
        setIsReportByMacroSpessore(false);
        setIsReportByMacroCategory(false);
        setIsReportByMacroLinea(false);
        setIsPendingOrders(false);
        setIsVerificationPeriodOrders(false);
    };

    const openReportByMacroSpessore = () => {
        setIsReportByLwgSiteOpen(false);
        setIsReportByMacroSpessore(true);
        setIsReportByMacroCategory(false);
        setIsReportByMacroLinea(false);
        setIsPendingOrders(false);
        setIsVerificationPeriodOrders(false);
    };

    const openReportByMacroCategory = () => {
        setIsReportByLwgSiteOpen(false);
        setIsReportByMacroSpessore(false);
        setIsReportByMacroCategory(true);
        setIsReportByMacroLinea(false);
        setIsPendingOrders(false);
        setIsVerificationPeriodOrders(false);
    };

    const openReportByMacroLinea = () => {
        setIsReportByLwgSiteOpen(false);
        setIsReportByMacroSpessore(false);
        setIsReportByMacroCategory(false);
        setIsReportByMacroLinea(true);
        setIsPendingOrders(false);
        setIsVerificationPeriodOrders(false);
    };

    const openPendingOrders = () => {
        setIsReportByLwgSiteOpen(false);
        setIsReportByMacroSpessore(false);
        setIsReportByMacroCategory(false);
        setIsReportByMacroLinea(false);
        setIsPendingOrders(true);
        setIsVerificationPeriodOrders(false);
    };

    const openVerificationPeriodOrders = () => {
        setIsReportByLwgSiteOpen(false);
        setIsReportByMacroSpessore(false);
        setIsReportByMacroCategory(false);
        setIsReportByMacroLinea(false);
        setIsPendingOrders(false);
        setIsVerificationPeriodOrders(true);
    };

    useEffect(() => {
        if (data) {
            setPeriod(data);
        }
    }, [data]);

    useEffect(() => {
        if (verificationPeriodData) {
            setVerificationPeriod(verificationPeriodData);
            setStartingDate(verificationPeriodData.starting_date);
            setEndingDate(verificationPeriodData.ending_date);
        }
    }, [verificationPeriodData]);

    useEffect(() => {
        if (pendingOrders) {
            setTotPendingOrders(pendingOrders?.length)
        }
    }, [pendingOrders]);

    useEffect(() => {
        setMissingVerificationPeriod(false);
        if (!startingDate || !endingDate) {
            setMissingVerificationPeriod(true);
        }
    }, [startingDate, endingDate]);


    const {
        data: productionRecords,
        isFetching: isProductionRecordsFetching
    } = useApiGetProductionRecords();
    const keysToInclude = [
        "tannery_dyeing_id",
        "tannery_dyeing_date",
        "tannery_dyeing_month",
        "tannery_dyeing_year",
        "avg_sqm",
        "sqm_produced",
        "pcs_produced",
        "coefficient_sqm_produced",
        "lwg_sqm_produced",
        "choice",
        "order",
        "lot",
        "supplier",
        "lwg_site",
        "coefficient",
        "macro_spessore_name",
        "macro_category_name",
        "macro_linea_name",
    ];

    const customLabels = [
        t("tannery_dyeing_id"),
        t("tannery_dyeing_date"),
        t("tannery_dyeing_month"),
        t("tannery_dyeing_year"),
        t("avg_sqm"),
        t("sqm_produced"),
        t("pcs_produced"),
        t("coefficient_sqm_produced"),
        t("lwg_sqm_produced"),
        t("choice"),
        t("order"),
        t("lot"),
        t("supplier"),
        t("lwg_site"),
        t("coefficient"),
        t("macro_spessore"),
        t("macro_category"),
        t("macro_linea")

    ];


    let csvData: string | Array<Array<any>> | Array<object> = [];
    if (productionRecords) {
        csvData = convertToCsv(productionRecords, keysToInclude, customLabels);
    }

    return (
        <>{!data ?
            <Loading/>
            :
            <>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='p-2'>
                    <FilterAccordionButton
                        className={`rounded font-medium text-xs uppercase tracking-wider mr-2 ${
                            missingVerificationPeriod ? 'bg-red-600 animate-pulse' : ''
                        }`}
                        onClick={() => setOpenAccordion(!openAccordion)}
                        isOpen={openAccordion}
                        title='verification_period'
                    >
                    </FilterAccordionButton>
                    <CsvButton csvData={csvData} isFetching={isProductionRecordsFetching}/>
                </div>
                {openAccordion &&
                    <VerificationPeriodForm openAccordion={openAccordion} verificationPeriod={verificationPeriodData}/>}

                {isFetching && <MuiFetchingSpinner text="Fetching data..."/>}

                <div className="p-4">
                    <div>
                        <div className="grid grid-cols-2 gap-4"> {/* Use a CSS Grid with two columns */}
                            <div className="flex flex-col font-bold mb-5 mt-0">
                                <AgDiv label='pcs_produced' value={data && data?.pcs_produced.toFixed(2)}
                                       minWidth="560px" maxWidth="560px"/>
                                <AgDiv label='sqm_produced' value={data && data?.sqm_produced.toFixed(2)}
                                       minWidth="560px" maxWidth="560px"/>
                                <AgDiv label='lwg_sqm_produced'
                                       value={data && data?.lwg_sqm_produced.toFixed(2)}
                                       minWidth="560px" maxWidth="560px"/>
                                <AgDiv label='coefficient_sqm_produced'
                                       value={data && data?.coefficient_sqm_produced.toFixed(2)}
                                       minWidth="560px" maxWidth="560px"/>
                                <AgDiv label='evaluated_tannery_dyeings_count'
                                       value={
                                           data && data.evaluated_tannery_dyeings_count
                                               ? `${data.evaluated_tannery_dyeings_count}`
                                               : `0`
                                       }
                                       minWidth="560px" maxWidth="560px"/>
                                <AgDiv label='period_tannery_dyeings_count'
                                       value={
                                           data && data.period_tannery_dyeings_count
                                       }
                                       minWidth="560px" maxWidth="560px"/>
                                <AgDiv label='lwg_pending_orders'
                                       icons={[totPendingOrders && totPendingOrders > 0 ?
                                           <FaSkull className='animate-pulse text-red-600'/> :
                                           '']}
                                       value={totPendingOrders && totPendingOrders.toString()}
                                       minWidth="560px" maxWidth="560px"/>
                            </div>
                            {data &&
                                <div className={'flex flex-rowù'}>
                                    <PercentageChart title={"lwg_sqm_produced/sqm"}
                                                     targetPercentage={data.lwg_sqm_produced * 100 / data.sqm_produced}/>
                                    <PercentageChart title={"coefficient_sqm_produced/sqm"}
                                                     targetPercentage={data.coefficient_sqm_produced * 100 / data.sqm_produced}/>

                                </div>

                            }
                        </div>
                    </div>


                    <div className='flex flex-row'>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='p-2'>
                            <OpenerButton
                                className="rounded font-medium text-xs uppercase tracking-wider"
                                onClick={openReportByLwgSite}
                                isOpen={isReportByLwgSiteOpen}
                                title='by_lwg_site'
                                width={'200px'}
                            >
                            </OpenerButton>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='p-2'>
                            <OpenerButton
                                className="rounded font-medium text-xs uppercase tracking-wider"
                                onClick={openReportByMacroSpessore}
                                isOpen={isReportByMacroSpessore}
                                title='by_macro_spessore'
                                width={'200px'}
                            >
                            </OpenerButton>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='p-2'>
                            <OpenerButton
                                className="rounded font-medium text-xs uppercase tracking-wider"
                                onClick={openReportByMacroCategory}
                                isOpen={isReportByMacroCategory}
                                title='by_macro_category'
                                width={'200px'}
                            >
                            </OpenerButton>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='p-2'>
                            <OpenerButton
                                className="rounded font-medium text-xs uppercase tracking-wider"
                                onClick={openReportByMacroLinea}
                                isOpen={isReportByMacroLinea}
                                title='by_macro_linea'
                                width={'200px'}
                            >
                            </OpenerButton>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='p-2'>
                            <OpenerButton
                                className="rounded font-medium text-xs uppercase tracking-wider"
                                onClick={openPendingOrders}
                                isOpen={isPendingOrders}
                                title='show_pending_orders'
                                width={'200px'}
                            >
                            </OpenerButton>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} className='p-2'>
                            <OpenerButton
                                className="rounded font-medium text-xs uppercase tracking-wider"
                                onClick={openVerificationPeriodOrders}
                                isOpen={isPendingOrders}
                                title='show_verification_period_orders'
                                width={'200px'}
                            >
                            </OpenerButton>
                        </div>

                    </div>
                    {isReportByLwgSiteOpen && <LwgSiteList lwg_sites={period?.lwg_sites}/>}
                    {isReportByMacroSpessore && <MacroSpessoreList objs={data?.macro_spessori}/>}
                    {isReportByMacroCategory && <MacroCategoryList objs={data?.macro_categories}/>}
                    {isReportByMacroLinea && <MacroLineaList objs={data?.macro_lines}/>}
                    {isPendingOrders && <OrderScheduleTable orders_schedule={pendingOrders}/>}
                    {isVerificationPeriodOrders && <OrderScheduleTable orders_schedule={verifcationPeriodOrders}/>}
                </div>
            </>}
        </>
    );
};

export default LwgProductionReports;
