import React, {useEffect} from 'react';
import {
    TanneryRawMaterialProvider
} from "../../../../../utils/contexts/tannery_raw_material/tannery_raw_material-context";
import {TanneryProductionProvider} from "../../../../../utils/contexts/tannery_production/tannery_production-context";
import MaxWidthDiv from "../../../../ui/MaxWitdthDiv";
import LotsTable from "./LotsTable";


const LotsScreen = () => {


    return (
        <>

            <MaxWidthDiv maxWidth='1980px'>
                <TanneryProductionProvider>
                    <TanneryRawMaterialProvider>
                        {/*<LotsList/>*/}
                        <LotsTable/>
                    </TanneryRawMaterialProvider>
                </TanneryProductionProvider>

            </MaxWidthDiv>


        </>
    );
};

export default LotsScreen;
