import React from 'react';
import {FaSkull} from "react-icons/fa";
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {AiOutlineBorderlessTable} from "react-icons/ai";
import {IChoiceWithUsedPcs} from "../../../../../../utils/interfaces/tannery_raw_material/IChoiceWithUsedPcs";


const getIconsForCustomField = (row: IChoiceWithUsedPcs, theme: any) => {
    const icons = [];


    icons.push(
        (row.pcs - row.used_pcs < 0) ? (
            <FaSkull
                // color='60b8c3'
                color={theme.palette.warning.main}
                title="USED MORE PCS THAN AVAILABLE"
            />
        ) : (
            <FaSkull
                color='transparent'
                title=''
            />
        ),
        (row.order_schedules && row.order_schedules.length > 0) ? (
            <AiOutlineBorderlessTable
                // color='60b8c3'
                color={theme.palette.primary.main}
                title="ORDERS INSIDE"
            />
        ) : (
            <AiOutlineBorderlessTable
                color='transparent'
                title=''
            />
        ),
    );


    return icons;
};

export const customCellRenderer = (rowData: IChoiceWithUsedPcs, theme: any) => {
    const icons = getIconsForCustomField(rowData, theme);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customCellRenderer;
