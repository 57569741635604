import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {
    IOrderScheduleDetail
} from "../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail";


const useApiGetCompanyOrderScheduleDetail = (
    companyToken: string | undefined,
    id: number | undefined | null
): UseQueryResult<IOrderScheduleDetail, CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, id: number | undefined | null): Promise<IOrderScheduleDetail> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/order_schedule/${id}/detail`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getCompanyOrderDetails', id],
        queryFn: () => doGet(companyToken, id),
        enabled: !!id,
    })
};


export default useApiGetCompanyOrderScheduleDetail

