import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ILwgSite} from "../../../../../../utils/interfaces/tannery_raw_material/ILwgSite";


const useApiGetLwgSites = (
    companyToken: string | undefined
): UseQueryResult<ILwgSite[], CustomAxiosError> => {

    const {get} = useApiCalls()

    const doGetLwgSites = async (company_token: string | undefined): Promise<ILwgSite[]> => {
        try {
            const response = await get(`/api/company/${company_token}/tannery_raw_material/lwg_sites`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getCompanyLwgSites'],
        queryFn: () => doGetLwgSites(companyToken)
    })

};

export default useApiGetLwgSites