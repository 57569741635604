import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IPeopleArticle} from "../../../../../../../utils/interfaces/tannery_production/articles/IPeopleArticle";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";


const useApiGetPeopleArticles = (
    companyToken: string | undefined
): UseQueryResult<IPeopleArticle[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined): Promise<IPeopleArticle[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/articles/`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getPeopleArticles'],
        queryFn: () => doGet(companyToken)
    })
};

export default useApiGetPeopleArticles