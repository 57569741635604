import React from 'react';
import Navigation from './utils/navigation/Navigation';
import ToastLayout from "./components/ui/layout/ToastLayout";


function App() {
    return (
        <ToastLayout>
            <Navigation/>
        </ToastLayout>
    );
}

export default App;
