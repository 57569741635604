import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {FaLock} from "react-icons/fa6";
import {IDocRow} from "../../../../../../../utils/interfaces/my_documents/doc/IDocRow";


const getIconsForCustomField = (row: IDocRow, theme: any, t: any) => {
    const icons = [];


    const icon_color = theme.palette.primary.main


    icons.push(
        null
        // (row.lock_duration > 0 && !row.locked_by_me) ? (
        //         <FaLock
        //             color={'#60b8c3'}
        //             title={t('dock_locked')}
        //         />
        //     )
        //     :
        //     (
        //         <FaLock
        //             color={'transparent'}
        //             title={t('dock_unlocked')}
        //         />
        //     )
    );


    return icons;
};

export const customCellRenderer = (rowData: IDocRow, theme: any, t: any) => {
    const icons = getIconsForCustomField(rowData, theme, t);
    return (
        <IconContext.Provider
            value={{size: "1.3rem"}}
        >
            <Box display="flex" sx={{gap: 0.5}}>
                {icons.map((icon, index) => (
                    <span key={index}>{icon}</span>
                ))}
            </Box>
        </IconContext.Provider>

    );
};

export default customCellRenderer;
