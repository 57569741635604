import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {IPeople} from "../../../../../../utils/interfaces/service/people/IPeople";


const useApiGetPersonDetails = (
    companyToken: string | undefined,
    personId: number | null | undefined
): UseQueryResult<IPeople, CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined, person_id: number | null | undefined): Promise<IPeople> {
        try {
            const response = await get(`/api/company/${company_token}/service/people/${person_id}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getPersonDetails', personId],
        queryFn: () => doGet(companyToken, personId),
        enabled: !!personId
    })
};

export default useApiGetPersonDetails;
