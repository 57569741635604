import {CustomAxiosError, useApiCalls} from "../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IUm} from "../../../../../utils/interfaces/service/IUm";
import {useAuth} from "../../../../../utils/contexts/auth-context";


const useApiGetUms = (): UseQueryResult<IUm[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();
    const {get} = useApiCalls()

    async function doGet(): Promise<IUm[]> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/service/ums`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getUms'],
        queryFn: () => doGet()
    })
};

export default useApiGetUms