import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {useAppGarage} from "../../../../../../../utils/contexts/appGarage-context";


const useApiDeleteDoc = (
    doc_year: number | undefined,
    pk: number | undefined,
): UseMutationResult<void, CustomAxiosError, {
    doc_year: number | undefined,
    pk?: number | undefined
}, unknown> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {DELETE} = useApiCalls()
    const {selectedSystemYear} = useAppGarage();

    async function doDelete({companyToken, doc_year, pk}: {
        companyToken?: string,
        doc_year: number | undefined,
        pk?: number,
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/my_documents/doc/${doc_year}/${pk}/`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, {
        doc_year: number | undefined,
        pk?: number | undefined,
    }>(
        {
            mutationFn: () => doDelete({
                companyToken: selectedCompany?.company_token,
                doc_year: doc_year,
                pk: pk,
            }),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ['useApiGetDocs', selectedSystemYear]});
            },
        })
}


export default useApiDeleteDoc