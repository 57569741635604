import React, {useEffect, useState} from 'react';
import {IIncoterm} from "../../../../../../utils/interfaces/my_documents/IIncoterm";
import useApiGetIncoterm from "./api_hooks/useApiGetIncoterm";
import {Box, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import LabelledMuiTypography from "../../../../../ui/mui/mui-display-data/LabelledMuiTypography";
import {useTranslation} from "react-i18next";

interface Props {
    obj: IIncoterm | undefined,
}

const IncotermCard = ({
                          obj: initialObj,
                      }: Props) => {


    const theme = useTheme();
    const {t} = useTranslation();
    const [obj, setObj] = useState<IIncoterm | undefined>(initialObj)

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data: detailedObj,
    } = useApiGetIncoterm(obj?.id);


    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        }
    }, [detailedObj]);


    return (
        <>
            <Box sx={{
                marginTop: 1,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                border: '1px solid lightgrey',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: theme.palette.backgroundAppGarageColor.gray_50,

            }}>
                <Typography
                    variant="subtitle2"
                    sx={{
                        color: theme.palette.primary.main,
                        position: 'absolute',
                        top: -12,
                        left: 0,
                        backgroundColor: 'transparent',
                        paddingX: 2,
                    }}
                >
                    {t('Incoterm')}
                </Typography>
                <LabelledMuiTypography label={'name'} value={obj?.name} upperCaseValue={true}/>
                <LabelledMuiTypography label={'description'} value={obj?.description} upperCaseValue={true}/>
                <LabelledMuiTypography label={'iso_code'} value={obj?.iso_code} upperCaseValue={true}/>
            </Box>
        </>

    );
}

export default IncotermCard;