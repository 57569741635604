import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import useApiUpdatePerson from "./api-hooks/useApiUpdatePerson";
import useApiCreatePerson from "./api-hooks/useApiCreatePerson";
import {useAuth} from "../../../../../utils/contexts/auth-context";
import {CustomAxiosError} from "../../../../../logics/api_config";
import {useQueryClient} from "@tanstack/react-query";
import useApiGetCategories from "./api-hooks/useApiGetPeopleCategories";
import {IPeople} from "../../../../../utils/interfaces/service/people/IPeople";
import {useTranslation} from "react-i18next";
import {useAppGarage} from "../../../../../utils/contexts/appGarage-context";
import LwgSitesSelect from "../../tannery_raw_material/lwg_sites/LwgSitesSelect";
import {ILwgSite} from "../../../../../utils/interfaces/tannery_raw_material/ILwgSite";
import LwgSitesForm from "../../tannery_raw_material/lwg_sites/LwgSitesForm";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {Box, List, ListItem} from "@mui/material";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import PeopleCategories from "./PeopleCategories";
import {IPeopleArticle} from "../../../../../utils/interfaces/tannery_production/articles/IPeopleArticle";
import useApiGetPeople from "./api-hooks/useApiGetPeople";
import ListItemText from "@mui/material/ListItemText";
import MuiTypography from "../../../../ui/mui/mui-display-data/MuiTypography";


interface PeopleFormProps {
    personToEdit: IPeople | IPeopleArticle | null | undefined;
    onClose: () => void;
    category_person?: string;
}

const PeopleForm: React.FC<PeopleFormProps> = ({personToEdit, onClose, category_person}) => {
    const {availableCompanyUserApplications} = useAppGarage()
    const {t} = useTranslation();
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();

    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [isLwgSitesFormOpen, setIsLwgSitesFormOpen] = useState<boolean>(false)
    const [personObj, setPersonObj] = useState<IPeople | IPeopleArticle>()
    const [name, setName] = useState('');
    const [lwgWorkingsDescription, setLwgWorkingsDescription] = useState('');
    const [lwg_site, setLwgSite] = useState<ILwgSite | undefined>();

    const [people, setPeople] = useState<IPeople[] | undefined>();

    const mutationHook = personToEdit ? useApiUpdatePerson : useApiCreatePerson;
    const mutation = mutationHook(selectedCompany?.company_token);

    const handleLwgSite = (lwg_site: ILwgSite) => {
        setLwgSite(lwg_site);

    };

    const {
        data,
        isLoading,
        error,
    } = useApiGetPeople(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        setPeople(data)
    }, [data]);


    const [filteredPeople, setFilteredPeople] = useState<IPeople[] | undefined>();
    useEffect(() => {
        setFilteredPeople(undefined);
        const filteredPeople = people?.filter((person) =>
            name.length >= 4 &&
            person.people_name.toLowerCase().includes(name.toLowerCase())
        );
        setFilteredPeople(filteredPeople);
    }, [name, people]);


    const {
        data: categories,
        isLoading: categoriesLoading,
        error: categoriesError
    } = useApiGetCategories(selectedCompany?.company_token);
    if (categoriesError) {
        const axiosError = categoriesError as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (personToEdit) {
            setName(personToEdit.people_name);
            setLwgWorkingsDescription(personToEdit.lwg_workings_description);
            setPersonObj(personToEdit);
            setLwgSite(personToEdit.lwg_site_data);
            setSelectedCategories(
                personToEdit.peoplecategory_set.map((category) => category.category_name)
            );
        } else if (category_person) {
            const selectedCategory = categories?.find(category => category.category_name === category_person);
            if (selectedCategory) {
                setSelectedCategories([selectedCategory.category_name]);
            }
        }
    }, [personToEdit, category_person, categories]);


    const handleCategoryToggle = (categoryName: string) => {
        if (selectedCategories.includes(categoryName)) {
            setSelectedCategories((prevCategories) =>
                prevCategories.filter((id) => id !== categoryName)
            );
        } else {
            setSelectedCategories((prevCategories) => [...prevCategories, categoryName]);
        }
    };

    const handleSubmit = async () => {
        try {
            const personData = {
                people_name: name,
                category_names: selectedCategories,
                lwg_workings_description: lwgWorkingsDescription || null,
                lwg_site_id: lwg_site?.id,
            };

            if (personToEdit !== null && personToEdit !== undefined) {
                await mutation.mutateAsync({id: personToEdit.id, data: personData});
                displayMessage('Person updated successfully');
            } else {
                await mutation.mutateAsync(personData);
                displayMessage('Person added successfully');
            }
            onClose();
            await queryClient.invalidateQueries({queryKey: ['getPeople']});
            await queryClient.invalidateQueries({queryKey: ['getPeopleProducts']});
            await queryClient.invalidateQueries({queryKey: ['getPeopleArticles']});
        } catch (error) {
            const axiosError = error as CustomAxiosError;
        }
    }


    return (
        <>
            <Box sx={{padding: 2}}>
                <form className="">
                    <MuiInputTextField
                        label="people_name"
                        type="text"
                        value={name || ''}
                        onChange={(e) => setName(e.target.value)}
                        required={true}
                    />
                    {filteredPeople && !personToEdit &&
                        <List dense>
                            <MuiTypography value={'names_already_present'}/>
                            {filteredPeople.map((person) => (
                                <ListItem key={person.id}>
                                    <ListItemText primary={person.people_name}/>
                                </ListItem>
                            ))}
                        </List>
                    }

                    {(selectedCategories.includes("subcontractor") || selectedCategories.includes("supplier")) &&
                        availableCompanyUserApplications?.some(app => app.in_project_application_name === 'lwg_dashboard') && (
                            <Box className="flex flex-col space-y-2">
                                <MuiInputTextField
                                    label="lwg_working_description"
                                    type="text"
                                    value={lwgWorkingsDescription || ''}
                                    onChange={(e) => setLwgWorkingsDescription(e.target.value)}
                                    required={false}
                                />
                                <Box className="flex flex-row space-x-2 items-center">
                                    <LwgSitesSelect
                                        label={'Lwg Site'}
                                        lwg_site={lwg_site}
                                        onChange={handleLwgSite}
                                        className={'text-black'}
                                    />
                                </Box>

                            </Box>
                        )}

                    {!category_person &&
                        <Box className={'mt-4'}>
                            <PeopleCategories
                                selectedCategories={selectedCategories}
                                handleCategoryToggle={handleCategoryToggle}
                            />
                        </Box>}
                    <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                        <MuiFabButtonBack onClick={onClose}/>
                        {personToEdit ?
                            <MuiFabButtonSaveAs onClick={handleSubmit}/>
                            :
                            <MuiFabButtonSave onClick={handleSubmit}/>}
                    </Box>
                </form>
            </Box>
            <MuiModal
                top="50%"
                left="50%"
                width={'70vh'}
                open={isLwgSitesFormOpen}
                onClose={() => {
                    setIsLwgSitesFormOpen(false)
                }}
            >
                <LwgSitesForm
                    lwgSiteToEdit={lwg_site}
                    onClose={() => setIsLwgSitesFormOpen(false)}
                />
            </MuiModal>
        </>
    );
};

export default PeopleForm;
