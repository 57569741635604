import React from 'react';
import {useToast} from "../../../utils/contexts/toast-context";
import {motion, AnimatePresence} from "framer-motion";

type Props = {
    children: React.ReactNode,
}

const ToastLayout = ({children}: Props) => {
    const {toastContent, toastOpen} = useToast();

    const toastAnimation = {
        initial: {opacity: 0, y: 0},
        animate: {opacity: 1, y: 0},
        exit: {opacity: 0, y: 0},
        transition: {duration: 0.55},
    };

    return (
        <>
            {children}
            <AnimatePresence>
                {toastOpen &&
                    <motion.div
                        style={{
                            zIndex: 9999
                        }}
                        className="pointer-events-none fixed bottom-10 w-full flex flex-row justify-center"
                        {...toastAnimation}
                    >
                        {toastContent}
                    </motion.div>
                }
            </AnimatePresence>
        </>
    );
};

export default ToastLayout;
