import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {
    IOrderScheduleWorking
} from "../../../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleWorking";


const useApiGetWorkingsOrders = (
    companyToken: string | undefined
): UseQueryResult<IOrderScheduleWorking[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined): Promise<IOrderScheduleWorking[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/workings`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["getWorkingOrders"],
        queryFn: () => doGet(companyToken),
    })
};

export default useApiGetWorkingsOrders