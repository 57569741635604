import {motion} from "framer-motion";
import React from "react";
import {useTheme} from "@mui/material/styles";

type Props = {

    children?: React.ReactNode,
    isOpen: boolean,
    classname?: string
}


const FilterMotionDiv = ({isOpen, children, classname}: Props) => {
    const theme = useTheme();

    const variants = {
        hidden: {
            y: -100,
            opacity: 0,
        },
        visible: {
            y: 0, // Restores the original position
            opacity: 1,
            transition: {
                type: "spring",
                damping: 10,
                stiffness: 80,
            },
        },
    };

    const combinedClassName = `${classname} mx-2`;

    return (
        <motion.div
            initial="hidden"
            animate={isOpen ? "visible" : "hidden"}
            variants={variants}
            className={combinedClassName}
        >
            <div className=''>
                {children}
            </div>

            <motion.div
                className="filter_motion_div-divider-bar"
                initial={{scaleX: 0, transformOrigin: "100% 50%"}}
                animate={{scaleX: 1}}
                transition={{duration: 0.6}}
                style={{
                    height: '3px',
                    backgroundColor: theme.palette.filterColor.main,
                    marginTop: '10px',
                    marginBottom: '20px',
                }}
            />
        </motion.div>
    );
};

export default FilterMotionDiv;
