import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IMacroCategory} from "../../../../../../../../utils/interfaces/tannery_production/articles/IMacroCategory";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";


const useApiGetMacroCategory = (
    companyToken: string | undefined
): UseQueryResult<IMacroCategory[], CustomAxiosError> => {

    const {get} = useApiCalls();

    async function doGet(company_token: string | undefined): Promise<IMacroCategory[]> {
        try {
            const response = await get(`/api/company/${company_token}/tannery_production/articles/macro_category`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['getMacroCategory'],
        queryFn: () => doGet(companyToken)
    })
};

export default useApiGetMacroCategory