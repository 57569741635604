import React, {useState} from 'react';
import {ITanneryDyeingSchedule} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingSchedule";
import {useTranslation} from "react-i18next";
import OrderScheduleTanneryDyeingTable
    from "../../../tannery_production/order_schedule/dye/OrderScheduleTanneryDyeingTable";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import Loading from "../../../../../ui/Loading";
import {Box} from "@mui/material";
import MuiBorderedBox from "../../../../../ui/mui/MuiBorderedBox";
import TanneryDyeingRecipeTable from "./TanneryDyeingRecipeTable";
import MuiFabButtonBase from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBase";
import {FiCopy, FiEdit2} from "react-icons/fi";
import TanneryDyeingScheduleForm from "../TanneryDyeingScheduleForm";
import MuiModal from "../../../../../ui/mui/mui_modal/MuiModal";
import {GiBarrel} from "react-icons/gi";
import {useLocation} from "react-router-dom";
import useFetchPdf from "./api-hooks/useFetchPdf";
import {useTheme} from "@mui/material/styles";
import {AiOutlineBorderlessTable} from "react-icons/ai";
import MuiFabButtonAdd from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonAdd";
import AccordionMotionDiv from "../../../../../ui/accordions/AccordionMotionDiv";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {useTanneryDyeing} from "../../../../../../utils/contexts/tannery_dyeing/tannery_dyeing-context";
import MuiReadOnlyBoxTitle from "../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxTitle";


interface Props {
    tannery_dyeing: ITanneryDyeingSchedule | undefined,
    onClose?: () => void;
}


const TanneryDyeingRecipe = ({onClose, tannery_dyeing}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState()
    const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
    const [isCopyFormOpen, setIsCopyFormOpen] = useState<boolean>(false)
    const [hideOrders, setHideOrders] = useState(true)
    const {isEditable} = useTanneryDyeing()

    const [isCreateFormOpen, setIsCreateFormOpen] = useState<boolean>(false)


    const {fetchPdf} = useFetchPdf();
    const handleGetPdfFile = async () => {
        if (tannery_dyeing !== undefined) {
            await fetchPdf(tannery_dyeing.id);
        } else {
            alert("Please enter a valid Tannery Dyeing ID.");
        }
    };


    return (
        isLoading ? <Loading/> :
            <>

                <Box sx={{
                    position: 'fixed',
                    marginTop: 0,
                    top: 64,
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    p: 0,
                    backgroundColor: theme.palette.backgroundAppGarageColor.main
                }}>

                    <Box
                        style={{display: 'flex', justifyContent: 'center'}}
                        className={'my-4 gap-x-2'}
                    >{tannery_dyeing &&
                        <>
                            <MuiFabButtonBase icon={<GiBarrel/>}
                                              label={tannery_dyeing.drum.toString()}
                                              font_size={'1.5rem'}
                                              font_weight={'bold'}
                            />
                            <MuiReadOnlyBoxTitle label={'tannery_dyeing_id'}
                                                 value={tannery_dyeing.tannery_dyeing_id}></MuiReadOnlyBoxTitle>

                            <MuiFabButtonBase
                                onClick={() => setIsFormOpen(true)}
                                icon={<FiEdit2/>}
                            ></MuiFabButtonBase>

                            {!isEditable &&
                                <MuiFabButtonBase
                                    onClick={() => setIsCopyFormOpen(true)}
                                    icon={<FiCopy/>}
                                    label={'copy_in_a_new_tannery_dyeing'}
                                />}

                            {tannery_dyeing.orders_schedule.length > 0 &&
                                <Box style={{display: 'flex', justifyContent: 'center'}} className={''}>
                                    {hideOrders ?
                                        <MuiFabButtonBase icon={<AiOutlineBorderlessTable/>}
                                                          bg_color={theme.palette.primary.main}
                                                          bg_hover_color={theme.palette.primary.dark}
                                                          label={'show_orders'}
                                                          onClick={() => setHideOrders(false)}/>
                                        :
                                        <MuiFabButtonBase icon={<VisibilityOffIcon/>}
                                                          bg_color={theme.palette.secondary.main}
                                                          bg_hover_color={theme.palette.secondary.dark}
                                                          onClick={() => setHideOrders(true)}
                                                          label={'hide_orders'}
                                        />}
                                </Box>}

                            <MuiFabButtonBase
                                onClick={handleGetPdfFile}
                                icon={<LocalPrintshopIcon/>}
                                label={''}
                            />
                            {!isCreateFormOpen && isEditable &&
                                <MuiFabButtonAdd onClick={() => setIsCreateFormOpen(true)}
                                                 label={'add_tannery_dyeing_recipe_row'}/>}
                        </>
                    }
                        {onClose &&
                            <MuiFabButtonBack onClick={onClose}/>
                        }
                    </Box>
                </Box>


                <Box sx={{marginTop: 12}}>
                    {tannery_dyeing && tannery_dyeing.orders_schedule.length > 0 && !hideOrders && (
                        <AccordionMotionDiv isOpen={true}>
                            <MuiBorderedBox>
                                <OrderScheduleTanneryDyeingTable
                                    tannery_dyeing={tannery_dyeing}
                                    orders={tannery_dyeing.orders_schedule}
                                    scope={'recipe'}
                                />
                            </MuiBorderedBox>
                        </AccordionMotionDiv>
                    )}

                    {tannery_dyeing && (
                        <MuiBorderedBox>
                            <TanneryDyeingRecipeTable tannery_dyeing={tannery_dyeing}
                                                      isCreateFormOpen={isCreateFormOpen}
                                                      onCloseCreateForm={() => setIsCreateFormOpen(false)}
                            />
                        </MuiBorderedBox>
                    )}
                </Box>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'70vh'}
                    open={isFormOpen}
                    onClose={() => setIsFormOpen(false)}
                >
                    <TanneryDyeingScheduleForm objToEdit={tannery_dyeing ? tannery_dyeing : null}
                                               onClose={() => setIsFormOpen(false)}/>
                </MuiModal>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'70vh'}
                    open={isCopyFormOpen}
                    onClose={() => setIsCopyFormOpen(false)}
                >
                    <TanneryDyeingScheduleForm objToEdit={null}

                                               oldTanneryDyeing={tannery_dyeing}
                                               onClose={() => setIsCopyFormOpen(false)}
                    />
                </MuiModal>

            </>
    );
};

export default TanneryDyeingRecipe;
