import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useTheme} from '@mui/material/styles';
import {useTranslation} from "react-i18next";

interface MuiReadOnlyBoxProps {
    label: string | undefined;
    value?: string | number;
    borderColor?: string;
    onClick?: () => void;
}

const MuiReadOnlyBoxTitle: React.FC<MuiReadOnlyBoxProps> = ({
                                                                label,
                                                                value,
                                                                borderColor,
                                                                onClick
                                                            }) => {
    const theme = useTheme();
    const _borderColor = borderColor ? borderColor : theme.palette.primary.light;
    const _bg_hover_color = onClick ? theme.palette.primary.dark : theme.palette.primary.main
    const {t} = useTranslation()


    return (
        <Box
            onClick={onClick}

            sx={{
                border: `2px solid ${_borderColor}`,
                borderRadius: 4,
                padding: 1,
                position: 'relative',
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: _bg_hover_color,
                },
            }}
        >
            <Typography
                variant="subtitle2"
                sx={{
                    color: _borderColor,
                    position: 'absolute',
                    top: -20,
                    left: 0,
                    bgcolor: 'transparent',
                    paddingX: 1,
                }}
            >
                {label ? t(label) : ''}
            </Typography>
            <Typography
                sx={{
                    fontWeight: 'bold',
                    color: 'white',
                    paddingX: 1,
                    fontSize: '1.2rem',
                }}
            >{value}</Typography>
        </Box>
    );
};

export default MuiReadOnlyBoxTitle;
