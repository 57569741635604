import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../utils/contexts/toast-context";
import {useQueryClient} from "@tanstack/react-query";
import useApiCreateFiscalRegistry from "./api-hooks/useApiCreateFiscalRegistry";
import useApiUpdateFiscalRegistry from "./api-hooks/useApiUpdateFiscalRegistry";
import RegistrySelect from "./registry/RegistrySelect";
import {IRegistry} from "../../../../../utils/interfaces/my_documents/fiscal_registry/IRegistry";
import IncotermSelect from "./incoterm/IncotermSelect";
import {IIncoterm} from "../../../../../utils/interfaces/my_documents/IIncoterm";
import TransportCompanySelect from "./transport_company/TransportCompanySelect";
import {ITransportCompany} from "../../../../../utils/interfaces/my_documents/ITransportCompany";
import OtherDestinationSelect from "./other_destination/OtherDestinationSelect";
import {IOtherDestination} from "../../../../../utils/interfaces/my_documents/fiscal_registry/IOtherDestination";
import OtherDestinationCard from "./other_destination/OtherDestinationCard";
import TransportCompanyCard from "./transport_company/TransportCompanyCard";
import IncotermCard from "./incoterm/IncotermCard";
import {IPeople} from "../../../../../utils/interfaces/service/people/IPeople";
import useApiGetFiscalRegistry from "./api-hooks/useApiGetFiscalRegistry";
import {Box} from "@mui/material";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import RegistryCard from "./registry/RegistryCard";
import {useService} from "../../../../../utils/contexts/service/service-context";
import PaymentForm from "./payment/PaymentForm";
import MuiModal from "../../../../ui/mui/mui_modal/MuiModal";
import MuiButton from "../../../../ui/mui/mui_buttons/MuiButton";
import {useTranslation} from "react-i18next";
import BankSelect from "./bank/BankSelect";
import {IBank} from "../../../../../utils/interfaces/my_documents/IBank";
import BankCard from "./bank/BankCard";
import PaymentCard from "./payment/PaymentCard";
import {useMyDocuments} from "../../../../../utils/contexts/my_documents/my_documents-context";
import {IStatementOfIntent} from "../../../../../utils/interfaces/my_documents/fiscal_registry/IStatementOfIntent";
import StatementOfIntentSelect from "./statement_of_intent/StatementOfIntentSelect";
import {IVatCode} from "../../../../../utils/interfaces/my_documents/IVatCode";
import VatCodeSelect from "../VatCodeSelect";
import useApiGetMyCompanyRegistry from "../../my_company/my_company_registry/api-hooks/useApiGetMyCompanyRegistry";


interface Props {
    person: IPeople | undefined,
    onClose: () => void;
}

const FiscalRegistryForm: React.FC<Props> = ({onClose, person}: Props) => {
    const {displayError, displayMessage} = useToast();
    const queryClient = useQueryClient();
    const {t} = useTranslation()
    const {selectFiscalRegistry} = useService()
    const {selectedDocDetail} = useMyDocuments()

    const [isDocPaymentFormOpen, setIsDocPaymentFormOpen] = useState(false);

    const [fiscalCode, setFiscalCode] = useState<string | undefined>();
    const [vatNumber, setVatNumber] = useState<string | undefined>();
    const [registry, setRegistry] = useState<IRegistry | undefined>()
    const [incoterm, setIncoterm] = useState<IIncoterm | undefined>()
    const [bank, setBank] = useState<IBank | undefined>()
    const [iban, setIBan] = useState<string | undefined>()
    const [transportCompany, setTransportCompany] = useState<ITransportCompany | undefined>()
    const [otherDestination, setOtherDestination] = useState<IOtherDestination | undefined>()
    const [statementOfIntent, setStatementOfIntent] = useState<IStatementOfIntent | undefined>()
    const [docPayment, setDocPayment] = useState<string | undefined>()
    const [detailedDocPayment, setDetailedDocPayment] = useState<string | undefined>()
    const [sdiCode, setSdiCode] = useState<string | undefined>()
    const [sdiPEcAddress, setSdiPecAddress] = useState<string | undefined>()
    const [fisicPersonName, setFisicPersonName] = useState<string | undefined>()
    const [fisicPersonSurname, setFisicPersonSurname] = useState<string | undefined>()
    const [defaultVatCode, setDefaultVatCode] = useState<IVatCode>()
    const [riferimentoPubblicaAmministrazione, setRiferimentoPubblicaAmministrazione] = useState<string | undefined>()

    const updateMutation = useApiUpdateFiscalRegistry();
    const createMutation = useApiCreateFiscalRegistry();

    const {
        data: detailedFiscalRegistry
    } = useApiGetFiscalRegistry(person?.id);

    useEffect(() => {
        selectFiscalRegistry(detailedFiscalRegistry)
        setRegistry(undefined);
        setIncoterm(undefined);
        setTransportCompany(undefined);
        setOtherDestination(undefined);
        setFiscalCode('');
        setVatNumber('');
        setDetailedDocPayment('');
        setDocPayment(undefined);
        setStatementOfIntent(undefined);
        setDefaultVatCode(undefined);
        if (detailedFiscalRegistry) {
            setFiscalCode(detailedFiscalRegistry.fiscal_code || '');
            setVatNumber(detailedFiscalRegistry.vat_number || '');
            setRegistry(detailedFiscalRegistry.registry);
            setIncoterm(detailedFiscalRegistry.incoterm);
            setTransportCompany(detailedFiscalRegistry.transport_company);
            setOtherDestination(detailedFiscalRegistry.other_destination);
            setDocPayment(detailedFiscalRegistry.doc_payment);
            setDetailedDocPayment(detailedFiscalRegistry.detailed_doc_payment);
            setBank(detailedFiscalRegistry.bank);
            setIBan(detailedFiscalRegistry.iban);
            setStatementOfIntent(detailedFiscalRegistry.statement_of_intent);
            setDefaultVatCode(detailedFiscalRegistry.default_vat_code);
            setSdiCode(detailedFiscalRegistry.sdi_code);
            setSdiPecAddress(detailedFiscalRegistry.sdi_pec_address);
            setFisicPersonSurname(detailedFiscalRegistry.fisic_person_surname);
            setFisicPersonName(detailedFiscalRegistry.fisic_person_name)
            setRiferimentoPubblicaAmministrazione(detailedFiscalRegistry.riferimento_pubblica_amministrazione)
        }
    }, [detailedFiscalRegistry]);


    const handleRegistryChange = (registry: IRegistry | undefined) => {
        setRegistry(registry);
    };

    const handleIncotermChange = (obj: IIncoterm | undefined) => {
        setIncoterm(obj);
    };

    const handleBankChange = (obj: IBank | undefined) => {
        setBank(obj);
    };

    const handleTransportCompanyChange = (obj: ITransportCompany | undefined) => {
        setTransportCompany(obj);
    };

    const handleOtherDestinationChange = (obj: IOtherDestination | undefined) => {
        setOtherDestination(obj);
    };

    const handleDocPaymentChange = (_docPayment: string | undefined, _detailedDocPayment: string | undefined) => {
        setDocPayment(_docPayment)
        setDetailedDocPayment(_detailedDocPayment)
    }


    const handleSubmit = async () => {
        try {
            const mutation_data = {
                fiscal_code: fiscalCode || null,
                vat_number: vatNumber || null,
                registry_id: registry?.id || null,
                incoterm: incoterm ? incoterm.id : null,
                transport_company: transportCompany ? transportCompany.id : null,
                other_destination: otherDestination ? otherDestination.id : null,
                doc_payment: docPayment,
                bank: bank ? bank.id : null,
                iban: iban,
                statement_of_intent: statementOfIntent ? statementOfIntent.id : null,
                sdi_code: sdiCode,
                sdi_pec_address: sdiPEcAddress,
                fisic_person_name: fisicPersonName,
                fisic_person_surname: fisicPersonSurname,
                riferimento_pubblica_amministrazione: riferimentoPubblicaAmministrazione

                // people: initialPeopleId,
            };

            if (detailedFiscalRegistry?.id) {
                await updateMutation.mutateAsync({
                    id: detailedFiscalRegistry.id,
                    people_id: person?.id,
                    data: mutation_data
                });
                await queryClient.invalidateQueries({queryKey: ['useApiGetFiscalRegistries']});
                await queryClient.invalidateQueries({queryKey: ["useApiGetDocPayments", selectedDocDetail?.id]});
                displayMessage('Fiscal Registry updated successfully');
            } else {
                await createMutation.mutateAsync({people_id: person?.id, data: mutation_data});
                await queryClient.invalidateQueries({queryKey: ['useApiGetFiscalRegistries']});
                displayMessage('Fiscal Registry added successfully');
            }
            onClose();

        } catch (error) {

        }
    }

    const {data: myCompanyRegistry} = useApiGetMyCompanyRegistry();


    return (
        <Box sx={{padding: 0}}>
            <form>
                <Box className="grid grid-cols-3 gap-4">
                    {/* First Column */}
                    <Box className="flex flex-col">
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                // alignItems: 'flex-end',
                                border: '1px solid lightgrey',
                                borderRadius: '4px',
                                padding: '10px',
                                width: '100%'
                            }}
                        >
                            <MuiInputTextField
                                label="vat_number"
                                type="text"
                                value={vatNumber}
                                onChange={(e) => setVatNumber(e.target.value)}
                                required={false}
                            />
                            <MuiInputTextField
                                label="fiscal_code"
                                type="text"
                                value={fiscalCode}
                                onChange={(e) => setFiscalCode(e.target.value)}
                                required={false}
                            />
                            <MuiInputTextField
                                label="sdi code"
                                type="text"
                                value={sdiCode}
                                onChange={(e) => setSdiCode(e.target.value)}
                                required={false}
                            />
                            <MuiInputTextField
                                label="sdi pec address"
                                type="text"
                                value={sdiPEcAddress}
                                onChange={(e) => setSdiPecAddress(e.target.value)}
                                required={false}
                            />
                            <MuiInputTextField
                                label="fisic person name"
                                type="text"
                                value={fisicPersonName}
                                onChange={(e) => setFisicPersonName(e.target.value)}
                                required={false}
                            />
                            <MuiInputTextField
                                label="fisic person surnname"
                                type="text"
                                value={fisicPersonSurname}
                                onChange={(e) => setFisicPersonSurname(e.target.value)}
                                required={false}
                            />
                            {myCompanyRegistry?.nation.iso_code === 'IT' &&
                                <MuiInputTextField
                                    label="riferimento pubblica amministrazione"
                                    type="text"
                                    value={riferimentoPubblicaAmministrazione}
                                    onChange={(e) => setRiferimentoPubblicaAmministrazione(e.target.value)}
                                    required={false}
                                    toolTipInfo="Se il cliente è Pubblica Amministrazione, qui va inserito un codice di 20 caratteri che poi andrà riportato in fattura elettronica"
                                />}
                        </Box>

                        {detailedFiscalRegistry && (
                            <Box className="flex flex-col gap-2 mt-2">
                                <IncotermSelect
                                    label="select_incoterm"
                                    onChange={handleIncotermChange}
                                    className=""
                                    obj={incoterm}
                                />
                                {incoterm && <IncotermCard obj={incoterm}/>}
                                <TransportCompanySelect
                                    label="select_transport_company"
                                    onChange={handleTransportCompanyChange}
                                    className=""
                                    obj={transportCompany}
                                />
                                {transportCompany && <TransportCompanyCard obj={transportCompany}/>}
                                <OtherDestinationSelect
                                    label="select_other_destination"
                                    onChange={handleOtherDestinationChange}
                                    className=""
                                    obj={otherDestination}
                                    fiscal_registry={detailedFiscalRegistry}
                                />
                                {otherDestination && (
                                    <OtherDestinationCard
                                        fiscal_registry={detailedFiscalRegistry}
                                        obj={otherDestination}
                                    />
                                )}
                            </Box>
                        )}
                    </Box>

                    {/* Second Column */}
                    {detailedFiscalRegistry && (
                        <Box className="flex flex-row">
                            <Box
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // alignItems: 'flex-end',
                                    border: '1px solid lightgrey',
                                    borderRadius: '4px',
                                    padding: '10px',
                                    width: '100%'
                                }}
                            >
                                <RegistryCard obj={registry}/>
                                <RegistrySelect
                                    label="select_registry"
                                    onChange={handleRegistryChange}
                                    className=""
                                    registry={registry}
                                    fiscal_registry={detailedFiscalRegistry}
                                />
                                <StatementOfIntentSelect
                                    label="select a statement of intent"
                                    onChange={(obj) => setStatementOfIntent(obj)}
                                    className=""
                                    obj={statementOfIntent}
                                    fiscal_registry={detailedFiscalRegistry}
                                />
                                <VatCodeSelect obj={defaultVatCode} onChange={(obj) => setDefaultVatCode(obj)}
                                               className={''}/>
                            </Box>
                        </Box>
                    )}

                    {/* Third Column */}
                    <Box className="flex flex-row ">
                        <Box
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                // alignItems: 'flex-end',
                                border: '1px solid lightgrey',
                                borderRadius: '4px',
                                padding: '10px',
                                width: '100%'
                            }}
                        >
                            <MuiInputTextField
                                label="iban"
                                type="text"
                                value={iban}
                                onChange={(e) => setIBan(e.target.value)}
                                required={false}
                            />
                            {bank && <BankCard obj={bank}/>}
                            <BankSelect
                                label="select bank"
                                onChange={handleBankChange}
                                className=""
                                obj={bank}
                            />


                            <Box className="mt-1.5 mb-2">
                                <MuiButton
                                    onClick={() => setIsDocPaymentFormOpen(true)}>{t('doc_payment')}</MuiButton>
                            </Box>
                            {detailedDocPayment && <PaymentCard obj={detailedDocPayment}/>}


                        </Box>
                    </Box>
                </Box>

                {/* Action Buttons */}
                <Box className="flex justify-center mt-2 gap-x-2">
                    <MuiFabButtonBack onClick={onClose}/>
                    {detailedFiscalRegistry ? (
                        <MuiFabButtonSaveAs onClick={handleSubmit}/>
                    ) : (
                        <MuiFabButtonSave onClick={handleSubmit}/>
                    )}
                </Box>
            </form>

            <MuiModal
                top="50%"
                left="50%"
                width="50vh"
                open={isDocPaymentFormOpen}
                onClose={() => setIsDocPaymentFormOpen(false)}
                disableBackdropClick={true}
            >
                <PaymentForm
                    doc_payment={docPayment}
                    onChange={handleDocPaymentChange}
                    onClose={() => setIsDocPaymentFormOpen(false)}
                />
            </MuiModal>
        </Box>
    );
};

export default FiscalRegistryForm;
