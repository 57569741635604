import React, {useEffect, useRef, useState} from 'react';

import {IChoice} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IChoice";
import ChoiceRow from "./ChoiceRow";
import AnimatedRow from "../../../../../ui/AnimatedRow";
import AgDiv from "../../../../../ui/divs/AgDiv";
import {IMacroSpessore} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IMacroSpessore";
import MacroSpessoreRow from "./MacroSpessoreRow";
import {IMacroCategory} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IMacroCategory";
import MacroCategoryRow from "./MacroCategoryRow";
import PercentagesChart from "../../../../../ui/victory/PercentagesChart";

interface Props {
    scrollableHeight?: number;
    objs: IMacroCategory[] | undefined
}

const MacroCategoryList = ({scrollableHeight = 100, objs: initialObjs}: Props) => {
    const [objs, setObjs] = useState<IMacroCategory[] | undefined>(initialObjs);
    const [filteredData, setFilteredData] = useState<IMacroCategory[] | undefined>(objs);
    const [totalPcs, setTotalPcs] = useState<number>()
    const [totalSqm, setTotalSqm] = useState<number>()
    const [totalLwgSqm, setTotalLwgSqm] = useState<number>()
    const [totalCoefficientSqm, setTotalCoefficientSqm] = useState<number>()

    useEffect(() => {
        setObjs(initialObjs)
    }, [initialObjs]);

    useEffect(() => {
        if (objs) {
            const total_pcs = objs.reduce((accumulator: any, object: IMacroCategory) => {
                return accumulator + object.pcs_produced;
            }, 0);
            const total_sqm = objs.reduce((accumulator: any, object: IMacroCategory) => {
                return accumulator + object.sqm_produced;
            }, 0);
            const total_lwg_sqm = objs.reduce((accumulator: any, object: IMacroCategory) => {
                return accumulator + object.lwg_sqm_produced;
            }, 0);
            const total_coefficient_sqm = objs.reduce((accumulator: any, object: IMacroCategory) => {
                return accumulator + object.coefficient_sqm_produced;
            }, 0);

            setTotalPcs(total_pcs);
            setTotalSqm(total_sqm);
            setTotalLwgSqm(total_lwg_sqm);
            setTotalCoefficientSqm(total_coefficient_sqm)
            setFilteredData(objs);
        }

    }, [objs]);


    const mapped_data =
        filteredData?.map((obj: IMacroCategory, index: any) =>
            <AnimatedRow key={obj.id} index={index / 3}>
                <MacroCategoryRow obj={obj}/>
            </AnimatedRow>
        )

    const percentages_sqm_data = filteredData?.map((obj: IMacroCategory, index: any) => {
        const label = obj.macro_category_name;
        const value = totalSqm ? (obj.sqm_produced * 100) / totalSqm : 0;

        return {
            label,
            value,
        };
    });


    return (

        <>
            <div style={{
                height: `calc(${scrollableHeight}vh - 500px)`,
                overflowY: 'auto',
                overflow: 'auto',
                whiteSpace: 'nowrap',
                minWidth: '100%',
                scrollbarWidth: 'auto',
                scrollbarColor: '#02959c transparent',
            }}
                 className='scroll-content no-scrollbar flex flex-row'>
                <div>
                    <div className='flex flex-row capitalize font-bold'>
                        <AgDiv label={'macro_category'} value={''}
                               minWidth="320px" maxWidth="320px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'pcs_produced'} value={''}
                               minWidth="180px" maxWidth="180px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'sqm_produced'} value={''}
                               minWidth="180px" maxWidth="180px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'lwg_sqm_produced'} value={''}
                               minWidth="180px" maxWidth="180px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'coefficient_sqm_produced'} value={''}
                               minWidth="180px" maxWidth="180px" className={'AgDivHeader'}

                        />


                    </div>
                    {mapped_data}
                    <div className='flex flex-row'>
                        <AgDiv value={'Totals:'}
                               minWidth="320px" maxWidth="320px" className={'agDiv_transparent_div ml-1'}

                        />
                        <AgDiv label='' value={totalPcs && totalPcs.toFixed(2)}
                               minWidth="180px" maxWidth="180px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalSqm && totalSqm.toFixed(2)}
                               minWidth="180px" maxWidth="180px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalLwgSqm && totalLwgSqm.toFixed(2)}
                               minWidth="180px" maxWidth="180px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalCoefficientSqm && totalCoefficientSqm.toFixed(2)}
                               minWidth="180px" maxWidth="180px" className={'AgDivFooter'}/>
                    </div>
                </div>
                <div className={'flex flex-row'}>
                    <PercentagesChart data={percentages_sqm_data} title="sqm_produced"/>
                </div>


            </div>
        </>)


};

export default MacroCategoryList;