import React, {useEffect, useState} from 'react';
import {IRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IRegistry";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import LabelledMuiTypography from "../../../../../ui/mui/mui-display-data/LabelledMuiTypography";

interface Props {
    obj: IRegistry | undefined,
}

const RegistryCard = ({
                          obj: initialObj,
                      }: Props) => {


    const theme = useTheme();
    const [obj, setObj] = useState<IRegistry | undefined>(initialObj)

    useEffect(() => {
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    // const {
    //     data: detailedObj,
    // } = useApiGetRegistry(obj?.id);
    //
    // useEffect(() => {
    //     if (detailedObj) {
    //         setObj(detailedObj);
    //     }
    // }, [detailedObj]);


    return (
        <>
            <Box sx={{
                display: 'flex-1',
                flexDirection: 'column',
                alignItems: 'flex-end',
                border: '1px solid lightgrey',
                borderRadius: '4px',
                padding: '10px',
                backgroundColor: theme.palette.backgroundAppGarageColor.gray_50
            }}>
                <LabelledMuiTypography label={'business_name'} value={obj?.business_name} upperCaseValue={true}/>
                <LabelledMuiTypography label={'address'} value={obj?.address} upperCaseValue={true}/>
                <LabelledMuiTypography label={'cap'} value={obj?.cap} upperCaseValue={true}/>
                <LabelledMuiTypography label={'city'} value={obj?.city} upperCaseValue={true}/>
                <LabelledMuiTypography label={'state'} value={obj?.nation.name} upperCaseValue={true}/>
            </Box>
        </>

    );
}

export default RegistryCard;