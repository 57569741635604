import React, {useEffect, useState} from 'react';
import {useToast} from '../../../../../utils/contexts/toast-context';
import {useAuth} from '../../../../../utils/contexts/auth-context';
import {CustomAxiosError} from '../../../../../logics/api_config';
import {ILot} from '../../../../../utils/interfaces/tannery_raw_material/ILot';
import useApiUpdateLot from './api-hooks/useApiUpdateLot';
import useApiCreateLot from './api-hooks/useApiCreateLot';
import PeopleSelect from '../../service/people/PeopleSelect';
import {IPeople} from '../../../../../utils/interfaces/service/people/IPeople';
import LwgSitesSelect from '../lwg_sites/LwgSitesSelect';
import {ILwgSite} from '../../../../../utils/interfaces/tannery_raw_material/ILwgSite';
import useGetNextLotCode from './useGetNextLotCode';
import {
    IOrderScheduleDetail
} from "../../../../../utils/interfaces/tannery_production/order_schedule/IOrderScheduleDetail";
import {useTranslation} from "react-i18next";
import {Box, Grid} from "@mui/material";
import MuiFabButtonBack from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import MuiInputTextField from "../../../../ui/mui/mui-input/MuiInputTextField";
import {useQueryClient} from "@tanstack/react-query";


interface Props {
    lotToEdit: ILot | null;
    onClose: () => void;
}

const LotsForm: React.FC<Props> = ({lotToEdit, onClose}) => {
    const {displayError, displayMessage} = useToast();
    const {selectedCompany} = useAuth();
    const queryClient = useQueryClient();
    const {t} = useTranslation();

    const mutationHook = lotToEdit ? useApiUpdateLot : useApiCreateLot;
    const mutation = mutationHook(selectedCompany?.company_token);

    const [people, setPeople] = useState<IPeople | undefined>();
    const [lwg_site, setLwgSite] = useState<ILwgSite | undefined>();
    const [pcs, setPcs] = useState<number>(0);
    const [sqm, setSqm] = useState<number>(0);
    const [kgs, setKgs] = useState<number>(0);
    const [total_value_eur, setTotalValuwEur] = useState<number>(0);
    const [lot_code, setLotCode] = useState<string>('');
    const [lot_note, setLotNote] = useState<string | null>('');
    const [pdfFile, setPdfFile] = useState<File | null>(null);
    const [pdfFileName, setPdfFileName] = useState<string | undefined>();
    const [coefficient, setCoefficient] = useState<number>()


    const handlePeople = (people: IPeople | null | undefined) => {
        if (people) {
            setPeople(people);
        } else {
            setPeople(undefined)
        }

    };

    const handleLwgSite = (lwg_site: ILwgSite) => {
        setLwgSite(lwg_site);

    };

    const {data, isLoading, error} = useGetNextLotCode(selectedCompany?.company_token);
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }

    useEffect(() => {
        if (!lotToEdit && data && data.suggested_id) {
            setLotCode(data.suggested_id);
        }
    }, [lotToEdit, data]);

    useEffect(() => {
        if (lotToEdit) {
            setLotCode(lotToEdit.lot_id);
            setPeople(lotToEdit.people);
            setLwgSite(lotToEdit.lwg_site);
            setPcs(lotToEdit.pcs);
            setSqm(lotToEdit.sqm);
            setTotalValuwEur(lotToEdit.total_value_eur);
            setLotNote(lotToEdit.lot_note);
            setKgs(lotToEdit.kgs)
            setCoefficient(lotToEdit.lwg_coefficient)

            const pdfDocUrl = lotToEdit.pdf_doc;
            if (pdfDocUrl) {
                const url = new URL(pdfDocUrl);
                const pdfFileName = decodeURIComponent(url.pathname.split('/').pop() as string);
                setPdfFileName(pdfFileName);
            }
        }
    }, [lotToEdit]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files[0];
        setPdfFile(file);
        if (file) {
            setPdfFileName(file.name);
        }
    };


    const handleSubmit = async () => {
        try {
            const upd_data = {
                lot_id: lot_code,
                people_id: people?.id,
                lwg_site_id: lwg_site?.id,
                pcs: pcs,
                sqm: sqm,
                kgs: kgs,
                total_value_eur: total_value_eur,
                pdf_doc: pdfFile || null,
                lot_note: lot_note || null,
                lwg_coefficient: coefficient || 0,
            };

            const {pdf_doc, ...updatedData} = upd_data;

            if (lotToEdit !== null) {
                await mutation.mutateAsync({id: lotToEdit.id, data: upd_data});
                displayMessage('Lot updated successfully');
                queryClient.setQueryData<ILot[]>(['getLots'], (old: any) => {
                    if (!old) return [];
                    return old.map((item: any) =>
                        item.id === lotToEdit.id ? {...item, ...updatedData} : item
                    );
                });
            } else {
                const newObj = await mutation.mutateAsync(upd_data);
                displayMessage('Lot added successfully');
                queryClient.setQueryData<IOrderScheduleDetail[]>(['getLots'], (old: any) => {
                    const updatedData = [...(old || []), newObj];
                    return updatedData.filter((item): item is IOrderScheduleDetail => item !== null && item !== undefined);
                });
            }
            onClose();
            await queryClient.invalidateQueries({queryKey: ['getLots']});
            await queryClient.invalidateQueries({queryKey: ['getOrderRawChoicesOrders']});

        } catch (error) {
            const axiosError = error as CustomAxiosError;
            if (axiosError.response && axiosError.response.data && axiosError.response.data.detail) {
                displayError(axiosError.response.data.detail);

            }
            if (axiosError.response && axiosError.response.data && axiosError.response.data.lwg_coefficient) {
                displayError(t(axiosError.response.data.lwg_coefficient));
            } else {
                console.log(axiosError.response);
                displayError('An error occurred while saving the Lot');
            }
        }
    };

    return (
        <Box className="space-y-6">

            <form className="">
                <Grid container spacing={2}>
                    {/* Column 1 */}
                    <Grid item md={4} xs={12}>
                        <MuiInputTextField
                            onChange={(e) => setLotCode(e.target.value)}
                            label='lot_code'
                            type="text"
                            value={lot_code || ''}
                            required={true}
                        />

                        <MuiInputTextField
                            onChange={(e) => setPcs(parseFloat(e.target.value) || 0)}
                            label='pcs'
                            type="number"
                            value={pcs || ''}
                            required={false}
                        />

                        <MuiInputTextField
                            onChange={(e) => setSqm(parseFloat(e.target.value) || 0)}
                            label='sqm'
                            type="number"
                            value={sqm || ''}
                            required={false}
                        />
                    </Grid>

                    {/* Column 2 */}
                    <Grid item md={4} xs={12}>
                        <MuiInputTextField
                            onChange={(e) => setTotalValuwEur(parseFloat(e.target.value) || 0)}
                            label='total_value_eur'
                            type="number"
                            value={total_value_eur || ''}
                            required={false}
                        />

                        <Box display="flex" flexDirection="row" alignItems="center">
                            <PeopleSelect
                                label={'Fornitore'}
                                people={people}
                                onChange={handlePeople}
                                className={'text-black mb-2 mr-5'}
                                category_person="supplier"
                            />
                        </Box>


                        <Box display="flex" flexDirection="row" alignItems="center">
                            <LwgSitesSelect
                                label={'Lwg Site'}
                                lwg_site={lwg_site}
                                onChange={handleLwgSite}
                                className={'text-black'}
                            />
                        </Box>
                    </Grid>

                    {/* Column 3 */}
                    <Grid item md={4} xs={12}>
                        <MuiInputTextField
                            onChange={(e) => setCoefficient(parseFloat(e.target.value) || 0)}
                            label='coefficient'
                            type="number"
                            value={coefficient || ''}
                            required={false}
                        />

                        <MuiInputTextField
                            onChange={(e) => setKgs(parseFloat(e.target.value) || 0)}
                            label='kgs'
                            type="number"
                            value={kgs || ''}
                            required={false}
                        />

                        <MuiInputTextField
                            onChange={(e) => setLotNote(e.target.value)}
                            label='lot_note'
                            type="text"
                            value={lot_note || ''}
                            required={false}
                            rows={4}
                        />

                        <Box className="flex flex-col mb-3">
                            <label className="input-label mb-1 ">PDF File:</label>
                            <input
                                type="file"
                                accept=".pdf"
                                onChange={handleFileChange}
                                className="input-field"
                            />
                            <Box className='text-aGgreen font-bold text-xs tracking-wider'>
                                {pdfFileName && (
                                    <p>Existing File: {pdfFileName}</p>
                                )}
                            </Box>
                        </Box>
                    </Grid>

                    {/* Buttons */}
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                            {lotToEdit ?
                                <MuiFabButtonSaveAs onClick={handleSubmit}/>
                                :
                                <MuiFabButtonSave onClick={handleSubmit}/>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default LotsForm;
