import React, {useEffect, useMemo, useState} from 'react';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MaterialReactTable, MRT_ActionMenuItem, MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {Delete, Edit} from "@mui/icons-material";
import {Avatar, Box, Grid, Popover} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import {CSVLink} from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {IDocPayment} from "../../../../../../../utils/interfaces/my_documents/doc_payment/IDocPayment";
import useApiDeleteDocPayment from "./api-hooks/useApiDeleteDocPayment";
import useApiGetDocPayments from "./api-hooks/useApiGetDocPayments";
import {IDocDetail} from "../../../../../../../utils/interfaces/my_documents/doc/IDocDetail";
import useCustomCellRenderer from "./useCustomCellRenderer";
import Papa from "papaparse";
import {
    getMuiTableBodyCellProps,
    getMuiTableBodyRowProps,
    getMuiTableHeadCellProps,
    getMuiTablePaperProps
} from "../../../../../../ui/mui/mui_react_table/MuiReactTableStyles";
import MuiReadOnlyBoxBody from "../../../../../../ui/mui/mui-display-data/MuiReadOnlyBoxBody";
import MRTMuiButton from "../../../../../../ui/mui/mui_react_table/MRTMuiButton";
import Loading from "../../../../../../ui/Loading";
import MuiAlertDialog from "../../../../../../ui/mui/mui_modal/MuiAlertDialog";
import MuiFetchingSpinner from "../../../../../../ui/mui/MuiFetchingSpinner";
import PaymentIcon from "@mui/icons-material/Payment";
import DocPaymentForm from "./DocPaymentForm";
import MuiFabButtonBack from "../../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import FiscalRegistryForm from "../../../fiscal_registry/FiscalRegistryForm";
import MuiModal from "../../../../../../ui/mui/mui_modal/MuiModal";
import {useQueryClient} from "@tanstack/react-query";


interface IDocPaymentWithCustomField extends IDocPayment {
    custom_field: JSX.Element;
}

interface Props {
    onSelect?: (obj: IDocPayment | undefined) => void,
    doc: IDocDetail,
    onClose?: () => void;
}


const DocPaymentTable = ({onSelect, onClose, doc}: Props) => {
    const theme = useTheme();
    const {t} = useTranslation()
    const queryClient = useQueryClient();


    const [selectedRow, setSelectedRow] = useState<IDocPayment | undefined>()
    const [rows, setRows] = useState<IDocPayment[] | undefined>()

    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteDocPayment(selectedRow?.doc, selectedRow?.id);
    const {mutateAsync: apiDelete} = deleteMutation;
    const [isFiscalRegistryFormOpen, setIsFiscalRegistryFormOpen] = useState<boolean>(false)

    const handleConfirmDelete = async () => {
        try {
            if (onSelect && (doc?.id === selectedRow?.id)) {
                onSelect(undefined);
            }
            await apiDelete({pk: selectedRow?.id});
        } catch (error) {
        }
        setDeleteConfirmationOpen(false);
    };
    const handleCancelDelete = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleToggleFiscalRegistryForm = async () => {
        setIsFiscalRegistryFormOpen(!isFiscalRegistryFormOpen);
    }


    const {
        data,
        isLoading,
        error,
        isFetching,
    } = useApiGetDocPayments(doc.id);


    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);

    // note_marco: customCellRenderer in hook example
    const CustomCellRenderer: React.FC<{ row: IDocPayment, theme: any }> = ({row, theme}) => {
        const {renderIcons} = useCustomCellRenderer(row, theme, handleToggleFiscalRegistryForm);
        return renderIcons();
    };


    const [objsWithCustomField, setObjsWithCustomField] = useState<IDocPaymentWithCustomField[] | undefined>();
    useEffect(() => {
        const dataWithCustomField: IDocPaymentWithCustomField[] | undefined = rows?.map((row) => ({
            ...row,
            custom_field: <CustomCellRenderer row={row} theme={theme}/>
        }));
        setObjsWithCustomField(dataWithCustomField);
    }, [rows, theme]);


    // note_marco: export csv example
    const convertArrayOfObjectsToCSV = (data: IDocPayment[]): string => {
        // Create new array of objects without the unnecessary fields
        const reducedData = data.map(({created_at, updated_at, doc, ...rest}) => rest);

        // Convert to CSV
        return Papa.unparse(reducedData, {
            delimiter: ';',
        });
    };


    const columns = useMemo<MRT_ColumnDef<IDocPayment>[]>(
        () => [
            {
                accessorKey: 'custom_field',
                header: '',
                enableColumnActions: false,
                enableColumnFilter: false,
                enableColumnOrdering: false,
                enableSorting: false,
            },
            {
                accessorKey: 'doc_type',
                header: t('payment type'),
                accessorFn: (row) => (row.payment.payment_type.name),

            },
            {
                accessorKey: 'value',
                header: t('value'),
            },
            {
                accessorKey: 'payment_date',
                header: t('payment_date'),
            },
            {
                accessorKey: 'description',
                header: t('description'),

            },
            {
                accessorKey: 'abi',
                header: t('abi'),
                accessorFn: (row) => (row.bank ? row.bank.abi : ''),

            },
            {
                accessorKey: 'cab',
                header: t('cab'),
                accessorFn: (row) => (row.bank ? row.bank.cab : ''),

            },
            // {
            //     accessorKey: 'locked_at',
            //     header: t('locked_at'),
            //     accessorFn: (row) => (row.locked_at && formatDateTime(row.locked_at)),
            //
            // },
            // {
            //     accessorKey: 'manually_modified_at',
            //     header: t('manually_modified_at'),
            //     accessorFn: (row) => (row.manually_modified_at && formatDateTime(row.manually_modified_at)),
            // },

        ],
        [rows]
    );


    const table = useMaterialReactTable<IDocPayment>({
        columns,
        defaultColumn: {
            maxSize: 400,
            minSize: 80,
            size: 80,
        },
        data: objsWithCustomField ? objsWithCustomField : [],
        columnFilterDisplayMode: 'popover',
        globalFilterFn: 'contains',
        enableFullScreenToggle: false,
        enableFilters: false,
        enableDensityToggle: false,
        muiTableHeadCellProps: getMuiTableHeadCellProps(theme.palette.primary.main),
        muiTableBodyCellProps: getMuiTableBodyCellProps(theme),
        muiTablePaperProps: getMuiTablePaperProps,
        enablePagination: false,
        initialState: {
            density: 'compact',
            pagination: {pageSize: 25, pageIndex: 0},
        },
        mrtTheme: (theme) => ({
            baseBackgroundColor: theme.palette.backgroundAppGarageColor.main,

        }),
        muiTableBodyRowProps: getMuiTableBodyRowProps(theme),

        enableRowActions: true,
        renderRowActionMenuItems: ({closeMenu, row}) => [
            onSelect && onClose && (
                <MRT_ActionMenuItem
                    icon={<AdsClickIcon color="primary"/>}
                    key="select"
                    label={t("select")}
                    onClick={() => {
                        onSelect(row.original);
                        onClose();
                    }}
                    table={table}
                />),
            <MRT_ActionMenuItem
                icon={<Edit color="primary"/>}
                key="edit"
                label={t("edit")}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setSelectedRow(row.original);
                    setIsEditFormOpen(true)
                    closeMenu()
                }}
                table={table}
            />,
            <MRT_ActionMenuItem
                icon={<Delete color="warning"/>}
                key="delete"
                label={t("delete")}
                onClick={() => {
                    setSelectedRow(row.original);
                    setDeleteConfirmationOpen(true)
                    closeMenu()
                }}
                table={table}
                disabled={!!row.original.locked_at}
            />,
        ],
        renderTopToolbarCustomActions: ({table}) => (
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <>
                    <Avatar sx={{
                        backgroundColor: 'white',
                        border: `2px solid ${theme.palette.primary.main}`,
                        marginRight: 1
                    }}>
                        <PaymentIcon color={'primary'}/>
                    </Avatar>
                    <MuiReadOnlyBoxBody label={''} value={'payments'}/>
                    <MRTMuiButton
                        icon={<AddIcon style={{color: theme.palette.primary.main, fontSize: '2rem'}}/>}
                        onClick={() => {
                            setSelectedRow(undefined);
                            setIsEditFormOpen(true)
                        }} label={'add_doc_payment'}/>
                    {rows &&
                        <CSVLink data={convertArrayOfObjectsToCSV(rows)} filename={'all_rows.csv'}>
                            {<FileDownloadIcon
                                style={{color: theme.palette.primary.main, fontSize: '2rem', marginTop: 8}}/>}
                        </CSVLink>
                    }

                </>
            </Box>
        ),
    });


    return <>
        {isLoading ? <Loading/> :
            <>
                {onClose &&
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <Box className={'mt-2 gap-x-2 mb-2'}>
                            <MuiFabButtonBack onClick={onClose}/>
                        </Box>
                    </Grid>}

                <MaterialReactTable table={table}/>

                <MuiAlertDialog
                    open={isDeleteConfirmationOpen}
                    title="delete_confirmation"
                    content="ask_for_delete"
                    onClose={handleCancelDelete}
                    onConfirm={handleConfirmDelete}
                />

                <Popover
                    id={'edit_popover'}
                    open={isEditFormOpen}
                    // anchorEl={anchorEl}
                    onClose={() => setIsEditFormOpen(false)}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <DocPaymentForm objToEdit={selectedRow ? selectedRow : null}
                                    onClose={() => setIsEditFormOpen(false)}
                                    doc={doc}
                    />
                </Popover>

                <MuiModal
                    top="50%"
                    left="50%"
                    width={'120vh'}
                    open={isFiscalRegistryFormOpen}
                    disableBackdropClick={true}
                    onClose={() => setIsFiscalRegistryFormOpen(false)}
                >
                    <FiscalRegistryForm
                        person={doc.fiscal_registry.people}
                        onClose={() => setIsFiscalRegistryFormOpen(false)}
                    />
                </MuiModal>

                {isFetching &&
                    <MuiFetchingSpinner text="Fetching data..."/>
                }
            </>}
    </>;
};

export default DocPaymentTable;
