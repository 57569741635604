import React, {useEffect, useState} from 'react';
import {IIncoterm} from "../../../../../../utils/interfaces/my_documents/IIncoterm";
import useApiGetIncoterms from "./api_hooks/useApiGetIncoterms";
import useApiGetIncoterm from "./api_hooks/useApiGetIncoterm";
import {CustomAxiosError} from "../../../../../../logics/api_config";
import Loading from "../../../../../ui/Loading";
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {useTranslation} from "react-i18next";
import IncotermForm from "./IncotermForm";
import {Box, Popover, TextField} from "@mui/material";
import MRTMuiButton from "../../../../../ui/mui/mui_react_table/MRTMuiButton";
import {Add, Edit} from "@mui/icons-material";
import MuiAutocomplete from "../../../../../ui/mui/mui-input/MuiAutoComplete";
import {useTheme} from "@mui/material/styles";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import IncotermTable from "./IncotermTable";


interface Props {
    obj: IIncoterm | undefined,
    onChange: (obj: IIncoterm | undefined) => void,
    className: string,
    label?: string,
    required?: boolean
}

const IncotermSelect = ({
                            obj: initialObj,
                            label,
                            onChange,
                            required,
                            ...props
                        }: Props) => {

    const {displayError} = useToast()
    const theme = useTheme();
    const {t} = useTranslation()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false)
    const [isListTableOpen, setIsListTableOpen] = useState<boolean>(false)


    const [objs, setObjs] = useState<IIncoterm[]>([]);
    const [obj, setObj] = useState<IIncoterm | undefined>(initialObj)


    useEffect(() => {
        setObj(undefined);
        if (initialObj) {
            setObj(initialObj)
        }
    }, [initialObj]);


    const {
        data,
        isLoading,
        error,
    } = useApiGetIncoterms();
    if (error) {
        const axiosError = error as CustomAxiosError;
        displayError(axiosError.response.data.detail);
    }


    useEffect(() => {
        if (data) {
            setObjs(data)
        }
    }, [data]);


    const {
        data: detailedObj,
    } = useApiGetIncoterm(obj?.id);

    useEffect(() => {
        if (detailedObj) {
            setObj(detailedObj);
        } else {
            setObj(undefined);
        }
    }, [detailedObj]);

    const handleOnChange = (event: React.SyntheticEvent, newValue: IIncoterm | null) => {
        if (newValue) {
            onChange(newValue);
        } else {
            onChange(undefined);
        }
    };

    useEffect(() => {
        onChange(obj);
    }, [obj]);


    return (
        <>
            {isLoading ? (
                <Loading/>
            ) : (
                <>
                    <Box sx={{alignItems: 'center', width: '100%'}}>
                        <MuiAutocomplete
                            id="incoterm_site-select"
                            options={objs}
                            getOptionLabel={(option) => option.name}
                            value={detailedObj ? detailedObj : null}
                            onChange={handleOnChange}
                            isOptionEqualToValue={(option, value) => option.id === value?.id && option.name === value?.name}
                            renderInput={(params) => (
                                <TextField
                                    sx={{textTransform: 'capitalize'}}
                                    {...params}
                                    label={label ? t(label) : '' || t('incoterm')}
                                    required={required}
                                />
                            )}
                        >
                            <MRTMuiButton
                                icon={<FormatListBulletedIcon sx={{color: theme.palette.primary.main}}/>}
                                small={true}
                                onClick={() => {
                                    setIsListTableOpen(true)
                                }} label={'show_list'}/>

                            <MRTMuiButton
                                small={true}
                                icon={obj ?
                                    <Edit sx={{color: theme.palette.primary.main}}/>
                                    :
                                    <Add sx={{color: theme.palette.primary.main}}/>}
                                onClick={() => {
                                    setIsEditFormOpen(true)
                                }} label={obj ? 'edit_incoterm' : 'add_incoterm'}/>
                        </MuiAutocomplete>
                    </Box>

                    <Popover
                        id={'edit_popover'}
                        open={isEditFormOpen}
                        anchorEl={anchorEl}
                        onClose={() => setIsEditFormOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <IncotermForm objToEdit={obj ? obj : null}
                                      onSelect={onChange}
                                      onClose={() => setIsEditFormOpen(false)}
                        />
                    </Popover>

                    <Popover
                        id={'show_list'}
                        open={isListTableOpen}
                        anchorEl={anchorEl}
                        onClose={() => setIsListTableOpen(false)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <Box sx={{padding: 2}}>
                            <IncotermTable
                                alreadySelectedObj={obj}
                                onSelect={onChange}
                                onClose={() => setIsListTableOpen(false)}
                            />
                        </Box>
                    </Popover>
                </>
            )}
        </>
    );
}

export default IncotermSelect;