import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {IBank} from "../../../../../../../utils/interfaces/my_documents/IBank";


const useApiGetBanks = (): UseQueryResult<IBank[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined): Promise<IBank[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/bank`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetBanks"],
        queryFn: () => doGet(selectedCompany?.company_token,),
    })
};

export default useApiGetBanks