import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {useMyDocuments} from "../../../../../../../../utils/contexts/my_documents/my_documents-context";
import {useAuth} from "../../../../../../../../utils/contexts/auth-context";
import {useAppGarage} from "../../../../../../../../utils/contexts/appGarage-context";
import {IDocRow} from "../../../../../../../../utils/interfaces/my_documents/doc/IDocRow";


const useApiGetDocRow = (pk: number | undefined): UseQueryResult<IDocRow, CustomAxiosError> => {
    const {selectedSystemYear} = useAppGarage();
    const {selectedCompany} = useAuth();
    const {selectedDocDetail} = useMyDocuments();
    const {get} = useApiCalls()

    async function doGet(doc_year: number | undefined, doc_id: number | undefined, pk: number | undefined): Promise<IDocRow> {
        try {
            const response = await get(`/api/company/${selectedCompany?.company_token}/my_documents/doc/${doc_year}/${doc_id}/doc_row/${pk}`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetDocRow", pk],
        enabled: !!selectedDocDetail && !!selectedSystemYear && !!pk,
        queryFn: () => doGet(selectedSystemYear, selectedDocDetail?.id, pk),
    })

};

export default useApiGetDocRow;