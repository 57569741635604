import React, {useEffect, useRef, useState} from 'react';

import {IChoice} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IChoice";
import ChoiceRow from "./ChoiceRow";
import AnimatedRow from "../../../../../ui/AnimatedRow";
import AgDiv from "../../../../../ui/divs/AgDiv";

interface Props {
    scrollableHeight?: number;
    choices: IChoice[]
}

const ChoiceList = ({scrollableHeight = 100, choices: initialChoices}: Props) => {
    const [choices, setChoices] = useState<IChoice[]>(initialChoices);
    const [filteredData, setFilteredData] = useState<IChoice[] | undefined>(choices);
    const [totalPcs, setTotalPcs] = useState<number>()
    const [totalSqm, setTotalSqm] = useState<number>()
    const [totalLwgSqm, setTotalLwgSqm] = useState<number>()
    const [totalCoefficientSqm, setTotalCoefficientSqm] = useState<number>()

    useEffect(() => {
        setChoices(initialChoices)
    }, [initialChoices]);

    useEffect(() => {
        if (choices) {
            const total_pcs = choices.reduce((accumulator: any, object: IChoice) => {
                return accumulator + object.pcs_produced;
            }, 0);
            const total_sqm = choices.reduce((accumulator: any, object: IChoice) => {
                return accumulator + object.sqm_produced;
            }, 0);
            const total_lwg_sqm = choices.reduce((accumulator: any, object: IChoice) => {
                return accumulator + object.lwg_sqm_produced;
            }, 0);

            const total_coefficient_sqm = choices.reduce((accumulator: any, object: IChoice) => {
                return accumulator + object.coefficient_sqm_produced;
            }, 0);

            setTotalPcs(total_pcs);
            setTotalSqm(total_sqm);
            setTotalLwgSqm(total_lwg_sqm);
            setTotalCoefficientSqm(total_coefficient_sqm)
            setFilteredData(choices);
        }

    }, [choices]);


    const mapped_data =
        filteredData?.map((obj: IChoice, index: any) =>
            <AnimatedRow key={obj.id} index={index / 3}>
                <ChoiceRow choice={obj}/>
            </AnimatedRow>
        )


    return (

        <>
            <div className="p-4 ml-8">
                <div>
                    <div className='flex flex-row capitalize font-bold'>
                        <AgDiv label={'choice'} value={''}
                               minWidth="320px" maxWidth="320px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'pcs_produced'} value={''}
                               minWidth="150px" maxWidth="150px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'sqm_produced'} value={''}
                               minWidth="150px" maxWidth="150px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'lwg_sqm_produced'} value={''}
                               minWidth="180px" maxWidth="180px" className={'AgDivHeader'}

                        />
                        <AgDiv label={'coefficient_sqm_produced'} value={''}
                               minWidth="180px" maxWidth="180px" className={'AgDivHeader'}

                        />


                    </div>
                    {mapped_data}
                    <div className='flex flex-row'>
                        <AgDiv value={'Totals:'}
                               minWidth="320px" maxWidth="320px"
                               className={'agDiv_transparent_div ml-1'}

                        />
                        <AgDiv label='' value={totalPcs && totalPcs.toFixed(2)}
                               minWidth="150px" maxWidth="150px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalSqm && totalSqm.toFixed(2)}
                               minWidth="150px" maxWidth="150px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalLwgSqm && totalLwgSqm.toFixed(2)}
                               minWidth="180px" maxWidth="180px" className={'AgDivFooter'}/>
                        <AgDiv label='' value={totalCoefficientSqm && totalCoefficientSqm.toFixed(2)}
                               minWidth="180px" maxWidth="180px" className={'AgDivFooter'}/>
                    </div>
                </div>


            </div>
        </>)


};

export default ChoiceList;