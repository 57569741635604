import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../../logics/api_config";


const useApiCreateMacroLinea = (companyToken: string | undefined): UseMutationResult<void, CustomAxiosError, any, unknown> => {

    const {post} = useApiCalls();

    async function doCreate({companyToken, data}: { companyToken?: string, data: any }): Promise<void> {
        try {
            await post(`/api/company/${companyToken}/tannery_production/articles/macro_linee_produzione/`, data);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, any>(
        {
            mutationFn: (data) => doCreate({companyToken, data})
        }
    );
};

export default useApiCreateMacroLinea;