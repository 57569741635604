import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";


const useApiCreateFinalMeasurement = (companyToken: string | undefined): UseMutationResult<void, CustomAxiosError, any, unknown> => {
    const queryClient = useQueryClient();

    const {post} = useApiCalls();

    async function doCreate({companyToken, data}: {
        companyToken?: string,
        data: any
    }): Promise<void> {
        try {
            await post(`/api/company/${companyToken}/tannery_production/order_schedule/final_measurement/`, data);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, any>(
        {
            mutationFn: (data) => doCreate({companyToken, data}),
            onSuccess: async () => {
                // await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingRecipe']})
                // await queryClient.invalidateQueries({queryKey: ['getDyeToDye']})
                // await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleCurrent']});
                // await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleHistorical']});
                // await queryClient.invalidateQueries({queryKey: ['getTanneryDyeingScheduleDrafts']});
                // await queryClient.invalidateQueries({queryKey: ['getDyeByOrder']});
                // await queryClient.invalidateQueries({queryKey: ['getCompanyOrderScheduleByColor']});
                // await queryClient.invalidateQueries({queryKey: ['getCompanyOrderSchedule']});
            }
        }
    );
};


export default useApiCreateFinalMeasurement;