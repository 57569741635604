import React, {useEffect, useState} from 'react';
import {useToast} from "../../../../../../utils/contexts/toast-context";
import {Box} from "@mui/material";
import MuiInputTextField from "../../../../../ui/mui/mui-input/MuiInputTextField";
import MuiFabButtonBack from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonBack";
import MuiFabButtonSaveAs from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSaveAs";
import MuiFabButtonSave from "../../../../../ui/mui/mui_buttons/fab/extended/MuiFabButtonSave";
import {useTranslation} from "react-i18next";
import {
    IMyCompanyBank
} from "../../../../../../utils/interfaces/my_company/my_company_registry/my_company_bank/IMyCompanyBank";
import useApiCreateMyCompanyBank from "./api-hooks/useApiCreateMyCompanyBank";
import useApiUpdateMyCompanyBank from "./api-hooks/useApiUpdateMyCompanyBank";
import useApiGetMyCompanyBank from "./api-hooks/useApiGetMyCompanyBank";
import {IBank} from "../../../../../../utils/interfaces/my_documents/IBank";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import BankSelect from "../../../my_documents/fiscal_registry/bank/BankSelect";
import useApiDeleteMyCompanyBank from "./api-hooks/useApiDeleteMyCompanyBank";
import MuiAlertDialog from "../../../../../ui/mui/mui_modal/MuiAlertDialog";


interface Props {
    objToEdit: IMyCompanyBank | null | undefined;
    onClose: () => void;
    onSelect?: (obj: IMyCompanyBank | undefined) => void,
}

const MyCompanyBankForm: React.FC<Props> = ({objToEdit, onClose, onSelect}) => {
    const {displayError, displayMessage} = useToast();
    const {t} = useTranslation()
    const {selectedCompany} = useAuth()

    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const deleteMutation = useApiDeleteMyCompanyBank(objToEdit?.id);
    const {mutateAsync: apiDelete} = deleteMutation;
    const handleConfirmDelete = async () => {
        if (objToEdit) {
            try {
                await apiDelete({pk: objToEdit.id});
            } catch (error) {

            }
            setConfirmationOpen(false);
            onClose();
        }

    };
    const handleCancelDelete = () => {
        setConfirmationOpen(false);
    };

    const [iban, setIban] = useState<string>();
    const [bank, setBank] = useState<IBank | undefined>();

    const handleBankChange = (obj: IBank | undefined) => {
        setBank(obj);
    };


    const createMutation = useApiCreateMyCompanyBank();
    const updateMutation = useApiUpdateMyCompanyBank();

    const {
        data: detailedObj,
    } = useApiGetMyCompanyBank(objToEdit?.id);


    useEffect(() => {
        if (detailedObj) {
            setIban(detailedObj.iban);
            setBank(detailedObj.bank);
        }
    }, [detailedObj]);


    const handleSubmit = async () => {
        try {
            const payload = {
                iban: iban,
                bank: bank?.id,
                company: selectedCompany?.id,
            };

            let newData;

            if (detailedObj !== null && detailedObj !== undefined) {
                newData = await updateMutation.mutateAsync({id: detailedObj.id, data: payload});
                displayMessage(t('Updated successfully'));
            } else {
                newData = await createMutation.mutateAsync(payload);
                displayMessage(t('Added successfully'));
            }
            onClose();
            onSelect && onSelect(newData);

        } catch (error) {
        }
    }

    const [allRequirementAreSatisfied, setAllRequirementAreSatisfied] = useState(false);
    useEffect(() => {
        setAllRequirementAreSatisfied(false);
        if (iban && bank) {
            setAllRequirementAreSatisfied(true);
        }
    }, [iban, bank]);


    return (
        <Box sx={{padding: 2}}>
            <form>
                <MuiInputTextField
                    onChange={(e) => setIban(e.target.value)}
                    label='iban'
                    type="text"
                    value={iban || ''}
                    required={false}
                />

                <BankSelect
                    label="select bank"
                    onChange={handleBankChange}
                    className=""
                    obj={bank}
                />


                <Box style={{display: 'flex', justifyContent: 'center'}} className={'mt-2 gap-x-2'}>
                    <MuiFabButtonBack onClick={onClose}/>
                    {/*{objToEdit &&*/}
                    {/*    <MuiFabButtonDelete onClick={() => {*/}
                    {/*        setConfirmationOpen(true)*/}
                    {/*    }}/>}*/}
                    {objToEdit ?
                        <MuiFabButtonSaveAs onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>
                        :
                        <MuiFabButtonSave onClick={handleSubmit} isDisabled={!allRequirementAreSatisfied}/>}
                </Box>
            </form>
            <MuiAlertDialog
                open={isConfirmationOpen}
                title="delete_confirmation"
                content="ask_for_delete"
                onClose={handleCancelDelete}
                onConfirm={handleConfirmDelete}
            />
        </Box>
    );
};

export default MyCompanyBankForm;