import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IStatementOfIntent
} from "../../../../../../../utils/interfaces/my_documents/fiscal_registry/IStatementOfIntent";


const useApiGetStatementOfIntent = (fiscal_registry_id: number | undefined, pk: number | undefined): UseQueryResult<IStatementOfIntent, CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()

    async function doGet(company_token: string | undefined, fiscal_registry_id: number | undefined, pk: number | undefined): Promise<IStatementOfIntent> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/fiscal_registry/${fiscal_registry_id}/statement_of_intent/${pk}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetStatementOfIntent", fiscal_registry_id, pk],
        enabled: !!fiscal_registry_id && !!pk,
        queryFn: () => doGet(selectedCompany?.company_token, fiscal_registry_id, pk),
        refetchInterval: 5000,
    })
};

export default useApiGetStatementOfIntent