import React, {useEffect, useState} from 'react';
import {ILwgSite} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/ILwgSite";
import AgDiv from "../../../../../ui/divs/AgDiv";
import {SiTestcafe} from "react-icons/si";
import LotList from "./LotList";
import {cutString} from "../../../../../../logics/helpers";
import {BiLeaf} from "react-icons/bi";
import {IMacroSpessore} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IMacroSpessore";
import {IMacroCategory} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IMacroCategory";


interface Props {
    obj: IMacroCategory,
}

const MacroCategoryRow = ({obj: initialObj}: Props) => {
    const [obj, setObj] = useState<IMacroCategory>(initialObj);
    const [selectedObj, setSelectedObj] = useState<IMacroCategory | undefined>();

    const handleSelection = () => {
        if (selectedObj?.id === obj.id) {
            setSelectedObj(undefined);
        } else {
            setSelectedObj(obj)
        }
    }

    useEffect(() => {
        setSelectedObj(initialObj)
    }, [initialObj]);


    return (
        <>
            <div
                className={`flex flex-row ${selectedObj?.id === obj.id ? '' : ''}`}
                style={{flex: 1}}
            >
                <div
                    onClick={handleSelection}
                    className={`flex flex-row ${selectedObj?.id === obj.id ? '' : ''}`}
                    // style={{flex: 1}}
                >
                    <AgDiv value={cutString(obj.macro_category_name, 25)}
                           minWidth="320px" maxWidth="320px"

                    />
                    <AgDiv label='' value={obj.pcs_produced.toFixed(2)}
                           minWidth="180px" maxWidth="180px"/>
                    <AgDiv label='' value={obj.sqm_produced.toFixed(2)}
                           minWidth="180px" maxWidth="180px"/>
                    <AgDiv label='' value={obj.lwg_sqm_produced.toFixed(2)}
                           minWidth="180px" maxWidth="180px"/>
                    <AgDiv label='' value={obj.coefficient_sqm_produced.toFixed(2)}
                           minWidth="180px" maxWidth="180px"/>
                </div>
            </div>


        </>
    );
};

export default MacroCategoryRow;
