import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {ITanneryDyeingSchedule} from "../../../../../../utils/interfaces/tannery_dyeing/ITanneryDyeingSchedule";


const useApiDeleteTanneryDyeingSchedule = (companyToken: string | undefined, pk: number | undefined): UseMutationResult<void, CustomAxiosError, {
    companyToken?: string,
    pk?: number
}, unknown> => {
    const queryClient = useQueryClient();
    const {DELETE} = useApiCalls();

    async function doDelete({companyToken, pk}: {
        companyToken?: string,
        pk?: number
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${companyToken}/tannery_dyeing/${pk}/delete`);
        } catch (error) {
            throw error;
        }
    }

    return useMutation<void, CustomAxiosError, { companyToken?: string, pk?: number }>({
        mutationFn: () => doDelete({companyToken: companyToken, pk: pk}),
        onSettled: async () => {
            const previous = queryClient.getQueryData<ITanneryDyeingSchedule[]>(['getTanneryDyeingScheduleHistorical']);

            if (previous) {
                const updated = previous.filter(obj => obj.id !== pk);
                queryClient.setQueryData(['getTanneryDyeingScheduleHistorical'], updated);
            }
        }
    });
};


export default useApiDeleteTanneryDyeingSchedule;

