import {CustomAxiosError, useApiCalls} from "../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {ICompanyApplication} from "../interfaces/accounts/ICompanyApplication";


const useApiGetAvailableCompanyApplications = (
    companyToken: string | undefined
): UseQueryResult<ICompanyApplication[], CustomAxiosError> => {

    const {get} = useApiCalls()

    const doGetAvailableCompanyApplications = async (company_token: string | undefined): Promise<ICompanyApplication[]> => {
        try {
            const response = await get(`/api/company_applications/${company_token}`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ['doGetAvailableCompanyApplications'],
        queryFn: () => doGetAvailableCompanyApplications(companyToken)
    })

};

export default useApiGetAvailableCompanyApplications