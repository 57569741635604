import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useAuth} from "../../../../../../utils/contexts/auth-context";
import {ILwgSite} from "../../../../../../utils/interfaces/tannery_raw_material/ILwgSite";


const useApiUpdateLwgSite = (): UseMutationResult<ILwgSite, CustomAxiosError, {
    id: number;
    data: any
}, unknown> => {
    const queryClient = useQueryClient();
    const {selectedCompany} = useAuth()
    const {put} = useApiCalls()

    const doUpdate = async (company_token: string | undefined,
                            lwg_site_id: number,
                            lwgSiteData: any): Promise<ILwgSite> => {
        try {
            const response = await put(`/api/company/${company_token}/tannery_raw_material/lwg_sites/${lwg_site_id}`, lwgSiteData);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<ILwgSite, CustomAxiosError, {
        id: number;
        data: any
    }, unknown>({
        mutationFn: async ({id, data}) => {
            return await doUpdate(selectedCompany?.company_token, id, data);
        },

        onError: (err, newData, previousData) => {
            const previous = queryClient.getQueryData<ILwgSite[]>(['getCompanyLwgSites']);
            queryClient.setQueryData(
                ['getCompanyLwgSites'],
                previous,
            )
        },
        onSettled: (newData, error, variables, previousData) => {

            if (newData) {
                const previous = queryClient.getQueryData<ILwgSite[]>(['getCompanyLwgSites']);
                if (previous) {
                    const index = previous.findIndex(site => site.id === newData.id);

                    if (index > -1) {
                        previous[index] = newData;
                        const updated = previous.filter(obj => obj.id !== newData.id);
                        updated[index] = newData;

                        queryClient.setQueryData(['getCompanyLwgSites'], updated);
                    }
                }
            }

            // queryClient.invalidateQueries({queryKey: ['getCompanyLwgSite', newData?.id]})
            // queryClient.invalidateQueries({queryKey: ["getCompanyLwgSites"]});
        }
    });
};

export default useApiUpdateLwgSite;

// note_marco ['doUpdate con aggiornamento immediata (invalidare nel componente per evitare tempi di attesa del reftech ']