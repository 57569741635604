import {CustomAxiosError, useApiCalls} from "../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IFiscalRegistry} from "../../../../../../utils/interfaces/my_documents/fiscal_registry/IFiscalRegistry";
import {useAuth} from "../../../../../../utils/contexts/auth-context";


const useApiGetFiscalRegistries = (): UseQueryResult<IFiscalRegistry[], CustomAxiosError> => {
    const {selectedCompany} = useAuth();

    const {get} = useApiCalls()
    
    async function doGet(company_token: string | undefined): Promise<IFiscalRegistry[]> {
        try {
            const response = await get(`/api/company/${company_token}/my_documents/fiscal_registry`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["useApiGetFiscalRegistries"],
        queryFn: () => doGet(selectedCompany?.company_token),
    })

};

export default useApiGetFiscalRegistries