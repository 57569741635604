import {styled} from "@mui/material/styles";


export const DrawerHeader = styled("div")(({theme}) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginRight: "4px",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
}));
