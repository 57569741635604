import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useQuery, UseQueryResult} from "@tanstack/react-query";
import {IChoiceWithUsedPcs} from "../../../../../../../utils/interfaces/tannery_raw_material/IChoiceWithUsedPcs";


const useApiGetChoicesByLot = (
    companyToken: string | undefined,
    lot_id: number | undefined,
): UseQueryResult<IChoiceWithUsedPcs[], CustomAxiosError> => {
    const {get} = useApiCalls()

    const doGet = async (company_token: string | undefined, lot_id: number | undefined): Promise<IChoiceWithUsedPcs[]> => {
        try {
            const response = await get(`/api/company/${company_token}/tannery_raw_material/lots/${lot_id}/choices`);
            return response?.data;
        } catch (error) {
            throw error;
        }
    }

    return useQuery({
        queryKey: ["getChoicesByLot", lot_id],
        enabled: !!lot_id,
        queryFn: () => doGet(companyToken, lot_id),
    })
};

export default useApiGetChoicesByLot