import {useMutation, UseMutationResult, useQueryClient} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";
import {
    IChemicalProductManualMovement
} from "../../../../../../../utils/interfaces/chemicals_products/IChemicalProductManualMovement";


const useApiCreateManualChemicalProductsMovement = (): UseMutationResult<IChemicalProductManualMovement, CustomAxiosError, any> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {post} = useApiCalls()

    async function doCreate({companyToken, product_id, data}: {
        companyToken?: string,
        product_id: number | undefined,
        data: any
    }): Promise<IChemicalProductManualMovement> {
        try {
            const response = await post(`/api/company/${companyToken}/chemicals_management/chemical_product/${product_id}/manual_movement/`, data);
            return response.data;
        } catch (error) {
            throw error;
        }
    }


    return useMutation<IChemicalProductManualMovement, CustomAxiosError, any>(
        {
            mutationFn: ({product_id, data}: {
                product_id: number | undefined,
                data: any
            }) => {


                return doCreate({
                    companyToken: selectedCompany?.company_token,
                    product_id,
                    data
                })
            },
            onSuccess: async (data) => {
                await queryClient.invalidateQueries({queryKey: ["useApiGetChemicalProductMovements", data.product]});
            },
        }
    );
};


export default useApiCreateManualChemicalProductsMovement;