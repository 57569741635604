import {useMutation, UseMutationResult, useQueryClient} from '@tanstack/react-query';
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";
import {useAuth} from "../../../../../../../utils/contexts/auth-context";


const useApiDeleteMyCompanyBank = (
    pk: number | undefined,
): UseMutationResult<void, CustomAxiosError, {
    pk?: number | undefined
}, unknown> => {
    const {selectedCompany} = useAuth()
    const queryClient = useQueryClient();
    const {DELETE} = useApiCalls()

    async function doDelete({company_token, pk}: {
        company_token?: string,
        pk?: number,
    }): Promise<void> {
        try {
            await DELETE(`/api/company/${company_token}/my_company/my_company_registry/bank/${pk}`,);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, {
        pk?: number | undefined,
    }>(
        {
            mutationFn: () => doDelete({
                company_token: selectedCompany?.company_token,
                pk: pk,
            }),
            onSuccess: async () => {
                await queryClient.invalidateQueries({queryKey: ['useApiGetMyCompanyBanks']});
            },
        })
}


export default useApiDeleteMyCompanyBank;
