import {useMutation, UseMutationResult} from "@tanstack/react-query";
import {CustomAxiosError, useApiCalls} from "../../../../../../../logics/api_config";


const useApiUpdateTanneryDyeingRecipe = (
    companyToken: string | undefined
): UseMutationResult<void, CustomAxiosError, {
    tannery_dyeing_id: number | undefined;
    id: number;
    data: any
}, unknown> => {

    const {put} = useApiCalls();

    async function doUpdate(company_token: string | undefined,
                            tannery_dyeing_id: number | undefined,
                            pk: number,
                            data: any): Promise<void> {
        try {
            await put(`/api/company/${company_token}/tannery_dyeing/${tannery_dyeing_id}/recipe/${pk}`, data);
        } catch (error) {
            throw error;
        }
    }


    return useMutation<void, CustomAxiosError, {
        tannery_dyeing_id: number | undefined;
        id: number;
        data: any;
    }>({
        mutationFn: ({id, tannery_dyeing_id, data}) => doUpdate(companyToken, tannery_dyeing_id, id, data)
    });
};


export default useApiUpdateTanneryDyeingRecipe;