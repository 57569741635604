import React from 'react';
import {Box} from "@mui/material";
import {IconContext} from "react-icons";
import {IMyCompanyWarehouse} from "../../../../../utils/interfaces/my_company/my_company_warehouse/IMyCompanyWarehouse";


const useCustomCellRenderer = (row: IMyCompanyWarehouse, theme: any) => {


    const getIconsForCustomField = (row: IMyCompanyWarehouse, theme: any) => {
        const iconColor = theme.palette.primary.main;

        return (
            // <BsPeople
            //
            // />
            null
        );
    };

    const renderIcons = () => {
        const icons = getIconsForCustomField(row, theme);
        return (
            <IconContext.Provider value={{size: "1.3rem"}}>
                <Box display="flex" sx={{gap: 0.5}}>
                    <span>{icons}</span>
                </Box>
            </IconContext.Provider>
        );
    };

    return {renderIcons};
};

export default useCustomCellRenderer;