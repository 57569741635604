import React, {useEffect, useState} from 'react';
import AgDiv from "../../../../../ui/divs/AgDiv";
import {GiCow} from "react-icons/gi";
import {IChoice} from "../../../../../../utils/interfaces/lwg_dashboard/reports/production/IChoice";
import ChoiceList from "./ChoiceList";
import RecordList from "./RecordList";
import {useTranslation} from "react-i18next";
import {FaSkull} from "react-icons/fa";


interface Props {
    choice: IChoice,
}

const ChoiceRow = ({choice: initialChoice}: Props) => {
    const {t} = useTranslation()
    const [choice, setChoice] = useState<IChoice>(initialChoice);
    const [selectedChoice, setSelectedChoice] = useState<IChoice | undefined>();

    const handleSelection = () => {
        if (selectedChoice?.id === choice.id) {
            setSelectedChoice(undefined);
        } else {
            setSelectedChoice(choice)
        }
    }

    useEffect(() => {
        setChoice(initialChoice)
    }, [initialChoice]);


    return (
        <>
            <div
                className={`flex flex-row ${selectedChoice?.id === choice.id ? 'font-bold' : ''}`}
                style={{flex: 1}}
            >
                <div
                    onClick={handleSelection}
                    className={`flex flex-row ${selectedChoice?.id === choice.id ? 'font-bold' : ''}`}
                    // style={{flex: 1}}
                >
                    <AgDiv value={choice.choice_id}
                           minWidth="320px" maxWidth="320px"
                           icons={[
                               choice.has_zero_records &&
                               <FaSkull className='animate-pulse text-red-600' title={t("has_record_zero")}/>,
                               <GiCow color={'#60b8c3'}/>]}
                    />
                    <AgDiv value={choice.pcs_produced}
                           minWidth="150px" maxWidth="150px"

                    />

                    <AgDiv value={choice.sqm_produced.toFixed(2)}
                           minWidth="150px" maxWidth="150px"

                    />
                    <AgDiv value={choice.lwg_sqm_produced.toFixed(2)}
                           minWidth="180px" maxWidth="180px"
                    />
                    <AgDiv value={choice.coefficient_sqm_produced.toFixed(2)}
                           minWidth="180px" maxWidth="180px"
                    />

                </div>


            </div>
            {selectedChoice?.id === choice.id && <RecordList records={choice.records}/>}


        </>
    );
};

export default ChoiceRow;
